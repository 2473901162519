import React from 'react'
import { Input, Icon } from 'rsuite'

import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

import './CustomInput.scss'

/*
Parameters:
- required: value, onChange, placeholder
- optional: border(type boolean), underline(type boolean, priority on border), borderColor, style, inputType (ex: password, number), icon (ex: <Icon icon="key" />), componentClass, regex, textColor

Ex:
-basic
<CustomInput value={this.state.value} onChange={this.onChangeValue} placeholder={"Basic"} />

-underline & style
<CustomInput value={this.state.value} onChange={this.onChangeValue} placeholder={"Underline"} underline={true} style={{ marginTop: "20px", marginBottom: "40px", backgroundColor: "white" }} />

-border & color
<CustomInput value={this.state.value} onChange={this.onChangeValue} placeholder={"Border"} border={true} borderColor={"red"} />

-inputType password
<CustomInput value={this.state.value} onChange={this.onChangeValue} placeholder={"Password"} underline={true} inputType={"password"} />

-icon & border
<CustomInput value={this.state.value} onChange={this.onChangeValue} placeholder={"Icon & Border"} border={true} icon={<Icon icon="envelope" />} />

-componentClass
<CustomInput value={this.state.value} onChange={this.onChangeValue} placeholder={"ComponentClass"} border={true} componentClass={"textarea"} icon={<Icon icon="envelope" />} />

-regex
<CustomInput value={this.state.value} onChange={this.onChangeValue} placeholder={"PassRegex"} border={true} style={{margin: "20px 0px"}}
	inputType={"password"} icon={<Icon icon="envelope" />} regex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/} />
*/

let defaultClassName = "CustomInput-Basic"
let defaultStyle = { margin: "20px 0px" }
let defaultType = "text"
let defaultComponentClass = "input"
let defaultTextColor = "#221d5d"
let defaultIsDisabled = false

class CustomInput extends React.Component {
	state = {
		initialPlaceholder: this.props.placeholder,
		placeholder: this.props.placeholder,

		isPassVisible: false,
		isFocused: false
	}

	onFocus = () => {
		this.setState({
			placeholder: "",
			isFocused: true
		})
	}

	onLostFocus = () => {
		this.setState({
			placeholder: this.state.initialPlaceholder,
			isFocused: false
		})
	}

	toggleIsPassVisible = () => {
		let oldValue = this.state.isPassVisible

		this.setState({
			isPassVisible: !oldValue
		})
	}

	render() {
		let className = defaultClassName
		if (this.props.underline) {
			className = "CustomInput-Underline"
		}
		if (this.props.border) {
			className = "CustomInput-Border"
		}
		if (this.props.className !== undefined) {
			className += " " + this.props.className
		}

		let style = defaultStyle
		if (this.props.style !== undefined) {
			style = { ...style, ...this.props.style }
		}
		if (this.props.borderColor !== undefined) {
			style = { ...style, borderColor: this.props.borderColor }
		}

		let type = defaultType
		if (this.props.inputType !== undefined) {
			type = this.props.inputType

			if (type === "password" && this.state.isPassVisible) {
				type = defaultType
			}
		}

		let componentClass = defaultComponentClass
		if (this.props.componentClass !== undefined) {
			componentClass = this.props.componentClass
		}

		const regex = this.props.regex

		let textColor = defaultTextColor
		if (this.props.textColor !== undefined) {
			textColor = this.props.textColor
		}

		let isDisabled = defaultIsDisabled
		if (this.props.disabled !== undefined) {
			isDisabled = this.props.disabled
		}

		return (
			<div className={className} style={style}>
				<div className="CustomInput-TopText">
					{this.state.isFocused || this.props.value !== "" ? this.props.placeholder : ""}
				</div>

				{this.props.icon !== undefined && <div style={{ alignSelf: componentClass === "input" ? "center" : "flex-start", marginTop: componentClass === "input" ? "0px" : "7px" }}>
					{this.props.icon}
				</div>}

				<Input
					style={{ border: "none", color: textColor, backgroundColor: this.props.style !== undefined && this.props.style.backgroundColor !== undefined ? this.props.style.backgroundColor : "#E5F7FE" }}
					value={this.props.value}
					onChange={this.props.onChange}
					onFocus={this.onFocus}
					onBlur={this.onLostFocus}
					placeholder={this.state.placeholder}
					type={type}
					componentClass={componentClass}
					disabled={isDisabled}
				/>

				{this.props.canShowPass === "canShowPass" && <div style={{ display: "flex", alignItems: "center", margin: "0px 10px" }} onClick={this.toggleIsPassVisible} >
					{this.state.isPassVisible
						? <AiOutlineEye style={{ fontSize: "20px" }} />
						: <AiOutlineEyeInvisible style={{ fontSize: "20px" }} />
					}
				</div>}

				{regex !== undefined && <div style={{ alignSelf: "center" }}>
					{regex.test(this.props.value)
						? <Icon icon="check-circle" style={{ color: "green" }} />
						: <Icon icon="close-circle" style={{ color: "red" }} />
					}
				</div>}
			</div>
		)
	}
}

export default CustomInput;
