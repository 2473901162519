import React from 'react'
import { Icon } from 'rsuite'
import './CodeModal.scss'
import Modal from "react-responsive-modal";
import CustomInput from '../custom/CustomInput';
import { BackAndConfirmButtons } from '../Helper';

class CodeModal extends React.Component {
	componentDidMount() {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}
	}

	render() {
		const styles = {
			fontFamily: "sans-serif",
			textAlign: "center",
		};

		return (
			<div style={styles}>
				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-code-events',
					closeButton: 'modal-close-button',
				}} center open={this.props.showModal} onClose={this.props.toggleModal} focusTrapped={false}>
					<h6>Te rog introdu codul evenimentului primit de la organizator</h6>

					<CustomInput value={this.props.value} onChange={this.props.onChange} placeholder={"Cod eveniment (ex: 1A4Cdgwe)"} icon={<Icon icon='qrcode' />} />

					<BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Confirma"} functionBack={this.props.toggleModal} functionConfirm={() => this.props.moveToEvent(this.props.eventCode)}/>
				</Modal>
			</div>
		)
	}
}

export default CodeModal
