import React from 'react'

import { Stage, Layer, Rect, Text, Circle, Group, Star, Wedge, Line } from 'react-konva';
import HeaderWhite from '../../../../components/HeaderWhite';
import './AdminSeatingChart.scss'
import { Button, Icon, Input, InputNumber } from 'rsuite'
import { toast } from 'react-toastify'
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/core";
import axios from 'axios';

import Modal from 'react-responsive-modal'

import AddTablesData from './Components/AddTablesData';
import {
	BackAndConfirmButtons,
	ConfirmButton,
	seatingChartCanvasHeight,
	seatingChartCanvasWidth
} from '../../../components/Helper';
import Loader from "../../../components/Loader";
import AdminSeatingChartModal from "./Components/AdminSeatingChartModal";


let roundValue = 10

let elementsTypes = {
	roundTable: "roundTable",
	squareTable: "squareTable",
	rectangleTable: "rectangleTable",
	scene: "scene",
	danceRing: "danceRing",
	candyBar: "candyBar",
	entranceAndExit: "intrare/Iesire",
	prezidiuTable: "masaPrezidiu",
	toilet: "toaleta",
	wall: "wall",
	stair: "stair",
	bar: "bar",
	cocktailBar: 'cocktailBar',
	shotBar: 'shotBar',
	smokingPlace: 'smokingPlace',
	terrace: 'terrace',

	showSeatingChartComp:false
}

class AdminSeatingChart extends React.Component {
	state = {
		eventId: "",
		eventData: null,
		color: 'white',
		isDragging: false,
		x: 200,
		y: 200,
		id: 0,
		tableCount: 1,
		selected: null,
		loading: true,
		delete: false,
		tableNumber: "",
		toRender: [],
		positions: [],
		elements: {},
		zoom: 1,

		isSettingTableNumber: true,
		isPlacingParticipants: false,

		isPublished: false,
		seatingId: -1,

		editWall: null,
		editWallHeight: 0,
		editWallWidth: 0,


		editDanceRing: null,
		editDanceRingHeight: 0,
		editDanceRingWidth: 0,

		editPrezidiu: null,
		editPrezidiuRotate: false,

		editScene: null,
		editSceneRotate: false,



		openModal: false,
		tableInfo: { peopleAtTable: [] },
		modalOption: "",
		refreshSeatingModal: 0,

		//for option addTable
		tableType: "roundTable",
		tableSize: "",

		//for option addWall
		wallThickness: 10,
		wallLength: 60,
		isWallVertical: true,

		showIconsNames: true,
		addElems: false,
		showBackConfirmModal: false,

		showSideList: false
	}

	removeElement = (id, callback = () => {}) => {

		let table = this.state.positions.filter(elem => elem.id === parseInt(id.split('-')[1]))

		if(table.length === 0) {
			this.saveConfig(false, false)
			callback()
			return
		}

		if (table && table[0] && (table[0].type === elementsTypes.roundTable || table[0].type === elementsTypes.squareTable)) {
			let renderData = this.state.toRender.filter(elem => elem.props.id === id)

			let index = 0

			while (!renderData[0].props.children[index]) {
				index++
			}

			if (renderData[0].props.children[index + 1].props.text !== this.state.tableCount - 1) {
				toast.error("Se poate sterge doar ultima masa sau alte elemente")
				return
			} else {
				let newTablecount = this.state.tableCount

				this.setState({
					tableCount: newTablecount - 1
				})
			}
		}

		let newRender = this.state.toRender.filter(elem => elem.props.id !== id)
		let newPositions = this.state.positions.filter(elem => elem.id !== parseInt(id.split('-')[1]))

		this.setState({
			toRender: newRender,
			positions: newPositions
		}, () => {
			this.saveConfig(false, false)
			callback()
		})
	}

	setScreenOrientation = () => {
		window.location.reload();
	}

	async componentDidMount() {
		window.addEventListener('orientationchange', this.setScreenOrientation);

		/*
		if(window.innerWidth >= 1200) {
			this.setState({
				showSideList: true
			})
		}*/

		this.init()
	}

	async init() {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		this.setState({
			eventId: event_info.data.id,
			isPublished: event_info.data.isSeatingChartDone,
			eventData: event_info.data
		})

		let seating = event_info.data.seatingChart

		if (seating === null) {
			//no table data
			this.setState({
				loading: false
			})

			return
		}

		//show tables
		this.setState({
			positions: seating.positions,
			seatingId: seating.id,
			isSettingTableNumber: false,
			loading: false
		})

		var maxId = 0;

		seating.positions.map(element => {
			if (maxId < element.id) {
				maxId = element.id
			}

			this.addElement(false, element.type, element)

			return null;
		})

		this.setState({
			id: maxId + 1
		})
	}

	countTableParticipants = (peopleAtTable) => {

		let nr= 0;

		if(!peopleAtTable || peopleAtTable.length === 0) {
			return 0
		}

		for(let i=0; i<peopleAtTable.length; i++) {
			if(peopleAtTable[i].nrPeople) {
				nr = nr + peopleAtTable[i].nrPeople
			} else {
				nr = nr+ 1
			}
		}

		return nr

	}

	addElement = (newPos, elemType, info) => {
		let index = this.state.positions.length;

		let my_id = this.state.id
		this.setState({
			id: my_id + 1
		})

		let tableNumber = this.state.tableCount
		var isTable = false

		if (elemType === elementsTypes.roundTable || elemType === elementsTypes.squareTable || elemType === elementsTypes.rectangleTable) {
			isTable = true

			this.setState({
				tableCount: tableNumber + 1
			})
		}

		let obj =
			<Group
				key={(newPos === false) ? info.id : my_id}
				x={(newPos === false) ? info.x : 100}
				y={(newPos === false) ? info.y : 200}
				id={(newPos === false) ? elemType + "-" + info.id : elemType + "-" + my_id}
				draggable
				onDragStart={() => {
					this.setState({
						isDragging: true
					});
				}}
				onTap={() => {
					if(this.state.delete === true) {
						this.removeElement((newPos === false) ? elemType + "-" + info.id : elemType + "-" + my_id)
						return
					}

					if(isTable) {
						this.setState({ openModal: true, modalOption: "showPeople", tableInfo: info, tableNumber: tableNumber, refreshSeatingModal: this.state.refreshSeatingModal+1  })
						return
					}

					if(elemType === elementsTypes.wall) {
						this.setState({ openModal: true, modalOption: "editWall", editWall: info, editWallWidth: info.wallWidth, editWallHeight: info.wallHeight, editWallVertical: info.wallHeight >= info.wallWidth  })
						return
					}

					if(elemType === elementsTypes.prezidiuTable) {
						this.setState({ openModal: true, modalOption: "editPrezidiu", editPrezidiu: info, editPrezidiuRotate: info.rotate})
						return
					}

					if(elemType === elementsTypes.danceRing) {
						this.setState({ openModal: true, modalOption: "editDanceRing", editDanceRing: info, editDanceRingWidth: info.danceRingWidth ? info.danceRingWidth : 100, editDanceRingHeight: info.danceRingHeight ? info.danceRingHeight : 100 })
						return
					}

					if(elemType === elementsTypes.scene) {
						this.setState({ openModal: true, modalOption: "editScene", editScene: info, editSceneRotate: info.rotate})
						return
					}
				}}

				onClick={() => {

					if(this.state.delete === true) {
						this.removeElement((newPos === false) ? elemType + "-" + info.id : elemType + "-" + my_id)
						return
					}

					if(isTable) {
						this.saveConfig(false, false)
						this.setState({ openModal: true, modalOption: "showPeople", tableInfo: info, tableNumber: tableNumber, refreshSeatingModal: this.state.refreshSeatingModal+1 })
						return
					}

					if(elemType === elementsTypes.wall) {
						this.setState({ openModal: true, modalOption: "editWall", editWall: info, editWallWidth: info.wallWidth, editWallHeight: info.wallHeight, editWallVertical: info.wallHeight >= info.wallWidth  })
						return
					}

					if(elemType === elementsTypes.prezidiuTable) {
						this.setState({ openModal: true, modalOption: "editPrezidiu", editPrezidiu: info, editPrezidiuRotate: info.rotate})
						return
					}

					if(elemType === elementsTypes.danceRing) {
						this.setState({ openModal: true, modalOption: "editDanceRing", editDanceRing: info, editDanceRingWidth: info.danceRingWidth ? info.danceRingWidth : 100, editDanceRingHeight: info.danceRingHeight ? info.danceRingHeight : 100 })
						return
					}

					if(elemType === elementsTypes.scene) {
						this.setState({ openModal: true, modalOption: "editScene", editScene: info, editSceneRotate: info.rotate})
						return
					}
				}}
				onDragEnd={e => {
					let elements = [...this.state.positions];

					for (var i = 0; i < elements.length; i++) {
						if (elements[i] !== null && ((newPos && elements[i].id === my_id) || (info.id>-1 &&  elements[i].id === info.id))) {

							elements[i].x = Math.round(e.target.x() / roundValue) * roundValue;
							elements[i].y = Math.round(e.target.y() / roundValue) * roundValue;

							this.setState({
								isDragging: false,
								positions: elements,
								toRender: [],
								tableCount: 1
							});

							this.setElements(elements)
							break;
						}
					}
				}}
			>
				{/* Scene */}

				{elemType === elementsTypes.scene && <Rect id={elemType + "-" + this.state.positions.length} x={200}
					y={200}
					width={150}
					height={50}
					fill={this.state.color}
					shadowBlur={5}
					rotation={info.rotate ? -90 : 0}
				/>}

				{elemType === elementsTypes.scene && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={info.rotate ? 220 : 255}
					rotation={info.rotate ? -90 : 0}
					y={((typeof this.state.positions[index] === 'undefined') ? 220 : this.state.positions[index].y) + (info.rotate ? -70 : 0) } text="Scena"

				/>}

				{/* Candy Bar */}

				{elemType === elementsTypes.candyBar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.candyBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={184}
					y={(typeof this.state.positions[index] === 'undefined') ? 190 : this.state.positions[index].y} text="Candy"
				/>}

				{elemType === elementsTypes.candyBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 200 : this.state.positions[index].y} text="Bar"
				/>}

				{/* Entrance & Exit */}

				{elemType === elementsTypes.entranceAndExit && <Rect id={elemType + "-" + this.state.positions.length} x={200}
					y={200}
					width={80}
					height={25}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.entranceAndExit && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={207}
					y={(typeof this.state.positions[index] === 'undefined') ? 205 : this.state.positions[index].y} text="Intrare/Iesire"
				/>}

				{/* Dance Ring */}

				{elemType === elementsTypes.danceRing && <Rect id={elemType + "-" + this.state.positions.length} x={200}
					y={200}
					width={info.danceRingWidth ? info.danceRingWidth : 100}
					height={info.danceRingHeight ? info.danceRingHeight : 100}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.danceRing && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={info.danceRingWidth ? info.danceRingWidth/2 + 200 - 13 : 235}
					y={info.danceRingWidth ?  info.danceRingHeight/2 + 195 :((typeof this.state.positions[index] === 'undefined') ? 250 : this.state.positions[index].y)} text="Ring"
				/>}

				{/* Toilet */}

				{elemType === elementsTypes.toilet && <Wedge
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					radius={50}
					angle={60}
					rotation={-120}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.toilet && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 160 : this.state.positions[index].y} text="WC"
				/>}

				{/* Prezidiu Table */}

				{elemType === elementsTypes.prezidiuTable && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={80}
					height={40}
					fill={this.state.color}
					shadowBlur={5}
					cornerRadius={10}
					rotation={info.rotate ? -90 : 0}
				/>}

				{elemType === elementsTypes.prezidiuTable && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={info.rotate ? 216 : 202}
					rotation={info.rotate ? -90 : 0}
					y={((typeof this.state.positions[index] === 'undefined') ? 213 : this.state.positions[index].y) + (info.rotate ? -15 : 0) } text="Masa Prezidiu"
				/>}

				{/* Wall */}

				{elemType === elementsTypes.wall && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={newPos ? this.state.isWallVertical ? this.state.wallThickness : this.state.wallLength : info.wallWidth}
					height={newPos ? this.state.isWallVertical ? this.state.wallLength : this.state.wallThickness : info.wallHeight}
					fill={this.state.color}
					shadowBlur={5}
					cornerRadius={0}
				/>}

				{/* Stairs */}

				{elemType === elementsTypes.stair && <Line
					id={elemType + "-" + this.state.positions.length}
					points={[0, 0, 10, 0, 10, 10, 20, 10, 20, 20, 30, 20, 30, 30, 40, 30, 40, 40, 0, 40]}
					fill={this.state.color}
					stroke={"grey"}
					strokeWidth={2}
					closed={true}
					x={200}
					y={200}
				/>}

				{/* Bar */}
				{elemType === elementsTypes.bar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}
				{elemType === elementsTypes.bar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 195 : this.state.positions[index].y} text="Bar"
				/>}

				{/* Cocktail Bar */}
				{elemType === elementsTypes.cocktailBar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}
				{elemType === elementsTypes.cocktailBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={178}
					y={(typeof this.state.positions[index] === 'undefined') ? 190 : this.state.positions[index].y} text="Cocktail"
				/>}

				{elemType === elementsTypes.cocktailBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 200 : this.state.positions[index].y} text="Bar"
				/>}

				{/* Shot Bar */}
				{elemType === elementsTypes.shotBar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}
				{elemType === elementsTypes.shotBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={187}
					y={(typeof this.state.positions[index] === 'undefined') ? 190 : this.state.positions[index].y} text="Shot"
				/>}

				{elemType === elementsTypes.shotBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 200 : this.state.positions[index].y} text="Bar"
				/>}


				{/* Round Table */}
				{elemType === elementsTypes.roundTable && <Circle
					id={elemType + "-" + this.state.positions.length}
					x={100}
					y={200}
					width={40}
					height={40}
					fill={info && info.peopleAtTable &&
					this.countTableParticipants(info.peopleAtTable) === info.tableSize ? '#D61C4E'
						: this.countTableParticipants(info.peopleAtTable) > 0 ? '#D36B00' : 'green'}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.roundTable && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "white"}
					x={97}
					y={(typeof this.state.positions[index] === 'undefined') ? 195 : this.state.positions[index].y} text={tableNumber}
				/>}

				{/* Square Table */}

				{elemType === elementsTypes.squareTable && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={100}
					y={200}
					width={40}
					height={40}
					fill={info && info.peopleAtTable &&
					this.countTableParticipants(info.peopleAtTable) === info.tableSize ? '#D61C4E'
						: this.countTableParticipants(info.peopleAtTable) > 0 ? '#D36B00' : 'green'}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.squareTable && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "white"}
					x={116}
					y={(typeof this.state.positions[index] === 'undefined') ? 215 : this.state.positions[index].y} text={tableNumber}
				/>}

				{/* Rectangular Table */}

				{elemType === elementsTypes.rectangleTable && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={100}
					y={200}
					width={50}
					height={30}
					fill={info && info.peopleAtTable &&
					this.countTableParticipants(info.peopleAtTable) === info.tableSize ? '#D61C4E'
						: this.countTableParticipants(info.peopleAtTable) > 0 ? '#D36B00' : 'green'}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.rectangleTable && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "white"}
					x={121}
					y={(typeof this.state.positions[index] === 'undefined') ? 210 : this.state.positions[index].y} text={tableNumber}
				/>}


				{/* Smoking place */}
				{elemType === elementsTypes.smokingPlace && <Rect id={elemType + "-" + this.state.positions.length}
																  x={200}
															   y={200}
															   width={120}
															   height={40}
															   fill={this.state.color}
															   shadowBlur={5}
				/>}
				{elemType === elementsTypes.smokingPlace && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={215}
					y={((typeof this.state.positions[index] === 'undefined') ? 215 : this.state.positions[index].y)} text="Loc pentru fumat"
				/>}

				{/* Terrace */}
				{elemType === elementsTypes.terrace && <Rect id={elemType + "-" + this.state.positions.length}
																  x={200}
																  y={200}
																  width={120}
																  height={40}
																  fill={this.state.color}
																  shadowBlur={5}
				/>}
				{elemType === elementsTypes.terrace && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={240}
					y={((typeof this.state.positions[index] === 'undefined') ? 215 : this.state.positions[index].y)} text="Terasă"
				/>}

			</Group>

		let current_objs = [...this.state.toRender, obj];

		this.setState({
			toRender: current_objs
		})

		if (newPos === true) {
			let positions = [...this.state.positions, { id: my_id, x: 100, y: 200, type: elemType }];
			// let positions = [...this.state.positions, { id: my_id, x: 100, y: 200, type: elementsTypes.squareTable, tableSize: info.tableSize, peopleAtTable: [] }];

			if (isTable) {
				positions[positions.length - 1].tableSize = Number(this.state.tableSize)
				positions[positions.length - 1].peopleAtTable = []
			}

			if (elemType === elementsTypes.wall) {
				positions[positions.length - 1].wallWidth = this.state.isWallVertical ? this.state.wallThickness : this.state.wallLength
				positions[positions.length - 1].wallHeight = this.state.isWallVertical ? this.state.wallLength : this.state.wallThickness
			}

			this.setState({
				positions: positions
			})
		}
	}

	setElements = (elems) => {
		var maxId = 0;

		elems.map(element => {
			if (maxId < element.id) {
				maxId = element.id
			}

			this.addElement(false, element.type, element)


			return null;
		})

		this.setState({
			positions: elems,
			id: maxId + 1
		})
	}

	saveConfig = async (moveToSeatParticipants = false, showToast = true) => {
		let obj = {
			toRender: [],
			positions: this.state.positions
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'seating-charts/' + this.state.seatingId, obj, auth)
			.then(_ => {
				if(showToast)
				toast.success("Configurația meselor a fost salvată!")

				if (moveToSeatParticipants === true) {
					this.props.history.push("/organizer/seatParticipants/" + this.props.match.params.id)
				}
			})
			.catch(err => {
				console.log(err);
				toast.error("S-a produs o eroare, vă rugăm să reîncercați")
			})
	}

	resetData = async () => {
		let obj = {
			toRender: [],
			positions: []
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'seating-charts/' + this.state.seatingId, obj, auth)
			.then(_ => {
				toast.success("Configuratie mese stearsa")
			})
			.catch(err => {
				console.log(err);
				toast.error("S-a produs o eroare, va rog reincercati")
			})

		this.setState({
			toRender: [],
			positions: [],
			isSettingTableNumber: true,
			tableCount: 1
		})

		this.closeModal()
	}

	finishSettingTableNumber = (positions, seatingId) => {
		this.finishSettingParticipants(positions)

		this.setState({
			seatingId: seatingId,
			isSettingTableNumber: false,
			positions: positions
		})
	}

	finishSettingParticipants = (positions) => {
		this.setState({
			isPlacingParticipants: false,
			positions: positions
		})

		if (this.state.toRender.length === 0) {
			var maxId = -1;

			positions.map(element => {
				if (element.id > maxId) {
					maxId = element.id
				}

				this.addElement(false, element.type, element)

				return null;
			})

			this.setState({
				id: maxId + 1
			})
		}
	}

	modalAddTable = () => {
		if (this.state.tableSize === "") {
			toast.error("Va rog specificati cate persoane sunt la masa")
			return
		}

		this.addElement(true, this.state.tableType, { id: -1 })

		this.setState({
			// tableSize: "",
			openModal: false
		})
	}

	addParticipantsToTable = async () => {
		await this.saveConfig(false, false)
			this.setState({
				showSideList: !this.state.showSideList,
				delete: false, addElems: '',
			})
	}

	modalAddElems = (elemType) => {
		this.addElement(true, elemType, { id: -1 })

		this.closeModal()
		this.setState({
			addElems:false
		})
	}

	closeModal = () => {
		this.setState({
			openModal: false,
			showSeatingChartComp: false
		})
	}

	goBack = () => {
		this.props.history.go(-1)
		// this.props.history.push("/organizer/plan/" + this.props.match.params.id)
	}

	toggleShowIconNames = () => {
		//let prev = this.state.showIconsNames

		//this.setState({
		//	showIconsNames: !prev
		//})
	}

	goToPlanning = () => {
		this.props.history.push('/organizer/plan/' + this.props.match.params.id)
	}

	showData = () => {
		console.log("state");
		console.log(this.state);
		console.log("render");
		console.log(this.state.toRender);
		console.log("pos");
		console.log(this.state.positions);
	}

	publishSeatingChart = async () => {
		let publishState = this.state.isPublished

		let obj = {
			seatingInfo: !publishState,
			isSeatingChartDone: !publishState
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(_ => {
				toast.success("Configuratie mese salvata")
			})
			.catch(err => {
				console.log(err);
				toast.error("S-a produs o eroare, va rog reincercati")
			})

		if (publishState === true) {
			toast.success("Publicarea a fost anulata")
		} else {
			toast.success("Publicarea a fost efectuata")
		}

		this.setState({
			isPublished: !publishState
		})
	}

	getParticipantsRows = (tableInfo) => {
		let info = tableInfo.peopleAtTable

		let totalPeopleAtTable = 0

		for (let i = 0; i < info.length; i++) {
			totalPeopleAtTable += info[i].nrPeople
		}

		var rows = []

		info.map(elem => {
			rows.push(elem.prenume)

			if (elem.namePartner !== "") {
				rows.push(elem.namePartner)
			}

			if (elem.kidsCount !== null) {
				for (var i = 0; i < elem.kidsCount; i++) {
					rows.push("copil")
				}
			}

			return null
		})

		if (window.innerWidth < 700) {
			return <div>
				{rows.map((elem, index) => {
					return <div key={index}>{index + 1}. {elem}</div>
				})}

				<div style={{color: (totalPeopleAtTable === tableInfo.tableSize ? 'red' : 'green')}}>Invitati la masa: {totalPeopleAtTable}/{tableInfo.tableSize}</div>
			</div>
		}

		let tableSize = tableInfo.tableSize

		var radius = 250
		var heigth = 200
		var width = 200
		if (tableInfo.type === elementsTypes.rectangleTable) {
			heigth = 150
			width = 250
		}
		var angle = 0
		// var step = (2 * Math.PI) / rows.length //dupa nr oameni asezati la mese
		var step = (2 * Math.PI) / tableSize //dupa nr oameni max la mesa

		let x = 0
		let y = 0

		let chairX = 0
		let chairY = 0

		return <div className={tableInfo.type === elementsTypes.roundTable ? "RoundTable" : tableInfo.type === elementsTypes.squareTable ? "SquareTable" : "RectangleTable"}>
			{rows.map((elem, index) => {
				if (tableInfo.type === elementsTypes.roundTable) {
					x = Math.round(width / 2 + radius * Math.cos(angle))
					y = Math.round(heigth / 2 + radius * Math.sin(angle))// - step * tableSize / 4

					chairX = Math.round(width / 2 + radius * Math.cos(angle) * 0.75)
					chairY = Math.round(heigth / 2 + radius * Math.sin(angle) * 0.75)
				} else if (tableInfo.type === elementsTypes.squareTable) {
					let distance = radius * Math.sin(angle - step)
					let correctionForDistance = radius / (tableSize / 3)
					let correctionValue = 0.7

					let finalDistanceX = 0
					let finalDistanceY = 0
					let finalDistanceChairX = 0
					let finalDistanceChairY = 0

					if (index < tableSize / 4) {
						finalDistanceX = radius
						finalDistanceY = distance + correctionForDistance
						finalDistanceChairX = finalDistanceX * correctionValue
						finalDistanceChairY = finalDistanceY
					} else if (index < tableSize / 2) {
						distance = radius * Math.cos(angle - step)
						finalDistanceX = distance - correctionForDistance
						finalDistanceY = radius
						finalDistanceChairX = finalDistanceX
						finalDistanceChairY = finalDistanceY * correctionValue
					} else if (index < 3 * tableSize / 4) {
						finalDistanceX = - radius
						finalDistanceY = distance - correctionForDistance
						finalDistanceChairX = finalDistanceX * correctionValue
						finalDistanceChairY = finalDistanceY
					} else {
						distance = radius * Math.cos(angle - step)
						finalDistanceX = distance + correctionForDistance
						finalDistanceY = - radius
						finalDistanceChairX = finalDistanceX
						finalDistanceChairY = finalDistanceY * correctionValue
					}

					x = Math.round(width / 2 + finalDistanceX)
					y = Math.round(heigth / 2 + finalDistanceY/1.5)
					chairX = Math.round(width / 2 + finalDistanceChairX)
					chairY = Math.round(heigth / 2 + finalDistanceChairY/1.5)
				} else {
					//rectangle table
					if (index < tableSize / 2) {
						x = -50 + 840 / tableSize * index
						y = 250
						chairY = 200
					} else {
						x = -50 + 840 / tableSize * (index - tableSize / 2)
						y = -100
						chairY = -50
					}

					chairX = x
				}

				angle += step

				let elemStyle = {
					position: "absolute",
					left: `${x}px`,
					top: `${y}px`,
					transform: "translate(-50%, -50%)"
				}

				let chairStyle = {
					width: "30px",
					height: "30px",
					border: "1px solid black",
					borderRadius: "50%",

					position: "absolute",
					left: `${chairX}px`,
					top: `${chairY}px`,
					transform: "translate(-50%, -50%)"
				}

				let nameParts = elem.split(" ")

				return <div key={index}>
					<div style={elemStyle}>
						{nameParts.map((part, partIndex) => { return <p key={partIndex}>{part}</p> })}
					</div>

					<div style={chairStyle}></div>
				</div>
			})}
		</div>
	}

	changeWallLength = (amount) => {
		this.setState({
			wallLength: Number(amount)
		})
	}

	render() {
		if (this.state.loading) {
			return <div className="SetParticipantsTables-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>


			</div>
		}

		if (this.state.isSettingTableNumber) {
			return <AddTablesData history={this.props.history} goToNext={this.finishSettingTableNumber} eventData={this.state.eventData} event_id={this.state.eventId} />
		}

		// if (this.state.isPlacingParticipants) {
		// 	return <SetParticipantsTables history={this.props.history} goToNext={this.finishSettingParticipants}
		// 		positions={this.state.positions} event_id={this.props.match.params.id} seatingId={this.state.seatingId} />
		// }

		const override = css`
        display: block;
        margin: 25px auto;
        border-color: #221d5d;
        `;

		let toRender = (<BeatLoader
			css={override}
			size={15}
			//size={"150px"} this also works
			color={"#00afcc"}
		/>)

		let finalToRender = this.state.toRender.map(element => {
			return element
		})

		if (this.state.loading === false) {
			let customModalAddTableButton = (name, type) => {
				return <Button style={{ marginLeft: "10px", marginRight: "10px", marginBottom:"5px", color: this.state.tableType === type ? "white" : "green" }}
					onClick={() => this.setState({ tableType: type })} color={"green"} appearance={this.state.tableType === type ? "primary" : "ghost"}>
					{name}
				</Button>
			}

			let customModalAddElemsButton = (elemType, buttonDescription) => {
				return <div className={'addElem'} onClick={() => this.modalAddElems(elemType)}>
					{buttonDescription}
				</div>
			}

			toRender = (
				<div className='SeatingChart-Page'>
					<HeaderWhite shadow={true} goBack={() => this.setState({showBackConfirmModal: true})} />

					<Modal classNames={{
						overlay: 'modal-overlay',
						modal: 'modal',
						closeButton: 'modal-close-button',
					}} open={this.state.showBackConfirmModal} onClose={this.closeModal} center focusTrapped={false} showCloseIcon={false}>

						<div style={{marginTop:'15px', marginBottom:'15px', fontSize:'16px'}}>

							<p>Doriți să salvați modificările?</p>
							<BackAndConfirmButtons
								style={{marginBottom:'10px'}}
								functionBack={this.goBack}
								textBack={'Nu'}
								functionConfirm={async () => {

									await this.saveConfig()
									this.goBack()

								}}
								textConfirm={'Da'}
							/>
						</div>

					</Modal>

					{window.innerWidth <= 0 && <p style={{ margin: "20px 0px" }}>Pentru a beneficia de toate functionalitatile va rugam sa folositi un PC sau un laptop</p>}

					<Modal classNames={{
						overlay: 'modal-overlay',
						modal: 'std-modal',
						closeButton: 'modal-close-button',
					}} focusTrapped={false} open={this.state.openModal} onClose={this.closeModal} center>
						<div className={window.innerWidth < 700 ? "SeatingChart-Modal-Container-mobile" : "SeatingChart-Modal-Container"}>
							{this.state.modalOption === "addTable" && <div className="SeatingChart-Modal-Content">
								<div style={{ display: "flex", flexDirection: (window.innerWidth < 700 ? 'column' : 'row') }}>
									<div style={{marginBottom:"5px"}}> Tipul mesei: </div>
									{customModalAddTableButton("Rotunda", elementsTypes.roundTable)}

									{customModalAddTableButton("Patrata", elementsTypes.squareTable)}

									{customModalAddTableButton("Dreptunghiulara", elementsTypes.rectangleTable)}
								</div>

								<Input type="number" value={this.state.tableSize} onChange={value => { this.setState({ tableSize: value }) }} placeholder="Nr persoane la masa" style={{ marginTop: "30px", marginBottom: "30px" }} />

								<div style={{textAlign:'center'}}>
								<Button color="green" onClick={this.modalAddTable}> Adaugă </Button>
								</div>
							</div>}

							{/* {this.state.modalOption === "addElems" && <div className="SeatingChart-Modal-Content">
								{customModalAddElemsButton(elementsTypes.scene, "Adauga Scena")}

								{customModalAddElemsButton(elementsTypes.danceRing, "Adauga Ring")}

								{customModalAddElemsButton(elementsTypes.candyBar, "Adauga Candy Bar")}

								{customModalAddElemsButton(elementsTypes.entranceAndExit, "Adauga Intrare/Iesire")}

								{customModalAddElemsButton(elementsTypes.prezidiuTable, "Adauga Masa Prezidiu")}

								{customModalAddElemsButton(elementsTypes.toilet, "Adauga Toaleta")}

								<Button style={{ width: "200px", alignSelf: 'center' }} onClick={() => this.setState({ modalOption: "addTable" })} color="green">
									Adauga Masa
								</Button>

								<Button style={{ width: "200px", alignSelf: 'center' }} onClick={() => this.setState({ modalOption: "addWall" })} color="green">
									Adauga Zid
								</Button>

								{customModalAddElemsButton(elementsTypes.stair, "Adauga Scara")}
							</div>} */}

							{this.state.modalOption === "addWall" && <div className="SeatingChart-Modal-Content">
								<div className="Wall-Dimensions">
									<div className="Len">
										<div style={{marginTop:'10px'}}>Lungime</div>
										<InputNumber value={this.state.wallLength} onChange={nr => this.changeWallLength(nr)} step={10} />
									</div>

									<div className="Types">
										<Button color="green" appearance={this.state.isWallVertical ? "primary" : "ghost"} onClick={() => this.setState({ isWallVertical: true })}>
											Vertical
										</Button>

										<Button color="green" appearance={!this.state.isWallVertical ? "primary" : "ghost"} onClick={() => this.setState({ isWallVertical: false })}>
											Orizontal
										</Button>
									</div>
								</div>

								<div className="Wall-Example-Container">
									<div style={{
										border: "1px solid blue",
										width: (this.state.isWallVertical ? this.state.wallThickness : this.state.wallLength) + "px",
										height: (this.state.isWallVertical ? this.state.wallLength : this.state.wallThickness) + "px"
									}}></div>
								</div>

								<div style={{ alignSelf: "center", marginTop: "20px" }}>
									<Button color="green" onClick={() => this.modalAddElems(elementsTypes.wall)}>Adaugă zid</Button>
								</div>
							</div>}

							{this.state.modalOption === 'editPrezidiu' &&
							<div className="SeatingChart-Modal-Content">

								<div>
								<p>Alegeți orientarea:</p>
								<div style={{display:'flex', flexDirection:'row', maxWidth:'500px', alignItems:'center', margin:'0 auto', width:'100%',alignSelf:'center'}}>
								<Button color="green" appearance={!this.state.editPrezidiuRotate ? "primary" : "ghost"} style={{flex:1}} onClick={() => {
										this.setState({
											editPrezidiuRotate: false,
										})
								}}>
									Orizontal
								</Button>

								<Button color="green" appearance={this.state.editPrezidiuRotate ? "primary" : "ghost"} style={{flex:1, marginLeft:'10px'}} onClick={() => {
										this.setState({
											editPrezidiuRotate: true
										})
								}}>
									Vertical
								</Button>
								</div>
								</div>

								<div style={{ alignSelf: "center", marginTop: "20px" }}>
									<Button color="green" onClick={() => {

										let newPositions = [...this.state.positions]
										let newElement = this.state.editPrezidiu

										for(let i=0; i<newPositions.length; i++) {
											if(newPositions[i].id === newElement.id) {
												newPositions[i].rotate = this.state.editPrezidiuRotate
												newPositions[i].x = newPositions[i].x+1

												newElement = newPositions[i]
											}
										}

										this.removeElement(newElement.type+'-'+newElement.id, async() => {

											this.setState({
												positions: newPositions,
												openModal: false
											}, () => {
												this.addElement(false, newElement.type, newElement)
											})
										})

									}
									}>Salvează</Button>
								</div>
							</div>
							}

							{this.state.modalOption === 'editScene' &&
							<div className="SeatingChart-Modal-Content">

								<div>
									<p>Alegeți orientarea:</p>
									<div style={{display:'flex', flexDirection:'row', maxWidth:'500px', alignItems:'center', margin:'0 auto', width:'100%',alignSelf:'center'}}>
										<Button color="green" appearance={!this.state.editSceneRotate ? "primary" : "ghost"} style={{flex:1}} onClick={() => {
											this.setState({
												editSceneRotate: false,
											})
										}}>
											Orizontal
										</Button>

										<Button color="green" appearance={this.state.editSceneRotate ? "primary" : "ghost"} style={{flex:1, marginLeft:'10px'}} onClick={() => {
											this.setState({
												editSceneRotate: true
											})
										}}>
											Vertical
										</Button>
									</div>
								</div>

								<div style={{ alignSelf: "center", marginTop: "20px" }}>
									<Button color="green" onClick={() => {

										let newPositions = [...this.state.positions]
										let newElement = this.state.editScene

										for(let i=0; i<newPositions.length; i++) {
											if(newPositions[i].id === newElement.id) {
												newPositions[i].rotate = this.state.editSceneRotate
												newPositions[i].x = newPositions[i].x+1

												newElement = newPositions[i]
											}
										}

										this.removeElement(newElement.type+'-'+newElement.id, async() => {

											this.setState({
												positions: newPositions,
												openModal: false
											}, () => {
												this.addElement(false, newElement.type, newElement)
											})
										})

									}
									}>Salvează</Button>
								</div>
							</div>
							}

							{this.state.modalOption === "editDanceRing" && <div className="SeatingChart-Modal-Content">
								<div className="Wall-Dimensions">
									<div className="Len">
										<div style={{marginTop:'10px'}}>Lungime</div>
										<InputNumber value={this.state.editDanceRingWidth} onChange={nr => {
												this.setState({editDanceRingWidth: nr})
										}} step={10} />
									</div>
									<div className="Len">
										<div style={{marginTop:'10px'}}>Înălțime</div>
										<InputNumber value={this.state.editDanceRingHeight} onChange={nr => {
											this.setState({editDanceRingHeight: nr})
										}} step={10} />
									</div>
								</div>

								<div className="Wall-Example-Container">
									<div style={{
										border: "1px solid blue",
										width: (this.state.editDanceRingWidth) + "px",
										height: (this.state.editDanceRingHeight) + "px"
									}}></div>
								</div>

								<div style={{ alignSelf: "center", marginTop: "20px" }}>
									<Button color="green" onClick={() => {

										let newPositions = [...this.state.positions]
										let newElement = this.state.editDanceRing

										for(let i=0; i<newPositions.length; i++) {
											if(newPositions[i].id === newElement.id) {
												newPositions[i].danceRingHeight = parseInt(this.state.editDanceRingHeight)
												newPositions[i].danceRingWidth = parseInt(this.state.editDanceRingWidth)
												newPositions[i].x = newPositions[i].x+1

												newElement = newPositions[i]
											}
										}

										this.removeElement(newElement.type+'-'+newElement.id, async() => {

											this.setState({
												positions: newPositions,
												openModal: false
											}, () => {
												this.addElement(false, newElement.type, newElement)
											})
										})

									}
									}>Salvează</Button>
								</div>
							</div>}

							{this.state.modalOption === "editWall" && <div className="SeatingChart-Modal-Content">
								<div className="Wall-Dimensions">
									<div className="Len">
										<div style={{marginTop:'10px'}}>Lungime</div>
										<InputNumber value={this.state.editWallVertical ? this.state.editWallHeight : this.state.editWallWidth} onChange={nr => {

											if(this.state.editWallVertical) {
												this.setState({editWallHeight: nr})
											} else {
												this.setState({editWallWidth: nr})
											}

										}} step={10} />
									</div>

									<div className="Types">
										<Button color="green" appearance={this.state.editWallVertical ? "primary" : "ghost"} onClick={() => {
											if(!this.state.editWallVertical) {
												this.setState({
													editWallVertical: true,
													editWallWidth: this.state.editWallHeight,
													editWallHeight: this.state.editWallWidth
												})
											}
										}}>
											Vertical
										</Button>

										<Button color="green" appearance={!this.state.editWallVertical ? "primary" : "ghost"} onClick={() => {
											if(this.state.editWallVertical) {
												this.setState({
													editWallVertical: false,
													editWallWidth: this.state.editWallHeight,
													editWallHeight: this.state.editWallWidth
												})
											}
										}}>
											Orizontal
										</Button>
									</div>
								</div>

								<div className="Wall-Example-Container">
									<div style={{
										border: "1px solid blue",
										width: (this.state.editWallWidth) + "px",
										height: (this.state.editWallHeight) + "px"
									}}></div>
								</div>

								<div style={{ alignSelf: "center", marginTop: "20px" }}>
									<Button color="green" onClick={() => {

										let newPositions = [...this.state.positions]
										let newElement = this.state.editWall

										for(let i=0; i<newPositions.length; i++) {
											if(newPositions[i].id === newElement.id) {
												newPositions[i].wallHeight = parseInt(this.state.editWallHeight)
												newPositions[i].wallWidth = parseInt(this.state.editWallWidth)
												newPositions[i].x = newPositions[i].x+1

												newElement = newPositions[i]
											}
										}

										this.removeElement(newElement.type+'-'+newElement.id, async() => {

											this.setState({
												positions: newPositions,
												openModal: false
											}, () => {
													this.addElement(false, newElement.type, newElement)
											})
										})

									}
									}>Salveaza zid</Button>
								</div>
							</div>}

							{this.state.modalOption === "resetElems" && <div className="SeatingChart-Modal-Content">
								<p>Această operațiune va șterge toate mesele, elementele adăugate, așezarea la masă a participanților și procesul va fi reluat.</p>
								<br/><br/>
								<Button color="red" onClick={this.resetData} style={{ marginBottom: "20vh" }} >Confirmă resetarea</Button>
							</div>}

							{this.state.modalOption === "showPeople" && <div className="SeatingChart-Modal-Content">


								<div style={{display:'block'}}>

									{
										this.state.showSeatingChartComp ?
											<>
												<ConfirmButton
													style={{width:'100%', maxWidth:'500px'}}
													function={() => {
														this.setState({
															showSeatingChartComp: false
														})
													}}
													text={'Plan locație'}
												/>

											<AdminSeatingChartModal
												key={this.state.refreshSeatingModal}
												id_eveniment={this.props.match.params.id}
												onFinish={(closeModal = false) => {

													this.setState({
														toRender: [],
														positions: [],
														isSettingTableNumber: false,
														tableCount: 1
													}, () => {
														this.init()
														if (closeModal)
															this.setState({
																showSeatingChartComp:false,
																openModal: false
															})
													})
												}}
											/>
											</>
											:<>

												<ConfirmButton
													style={{width:'100%', maxWidth:'500px'}}
													function={() => {
														this.setState({
															showSeatingChartComp: true
														})
													}}
													text={'Așează invitați la mese'}
												/>

												{
													this.state.tableInfo && this.state.tableInfo.peopleAtTable && this.countTableParticipants(this.state.tableInfo.peopleAtTable) > 0 ?
														<div style={{textAlign:'center'}}>
															Ocupare: {this.state.tableInfo && this.state.tableInfo.peopleAtTable ? this.countTableParticipants(this.state.tableInfo.peopleAtTable) : 0}/{this.state.tableInfo.tableSize}
														</div>
														:null
												}


											{this.state.tableInfo.peopleAtTable === undefined || this.state.tableInfo.peopleAtTable.length === 0
												?
												<div style={{textAlign: 'center', marginBottom: '30px'}}>La această masă nu
													a fost așezat niciun invitat!</div>
												: <div key={this.state.refreshSeatingModal}
													   style={{alignItems: "center", position: 'relative'}}
													   className={"minHeightContainer"}>
													<h4 className={window.innerWidth < 700 ? "" : "TableNumber"}
														style={{textAlign: 'center'}}> Masa {this.state.tableNumber}</h4>
													{this.getParticipantsRows(this.state.tableInfo)}
												</div>}
											</>
									}




									</div>
							</div>}

							{this.state.modalOption === "options" && <div className="SeatingChart-Modal-Content" style={{ alignItems: "center" }}>
								<div>
									<Button onClick={this.saveConfig} color="green"> Salveaza Configuratie </Button>
								</div>

								<div>
									<Button onClick={() => this.setState({ modalOption: "resetElems" })} color="red" > Reseteaza configuratia </Button>
								</div>

								<div>
									<Button color={this.state.isPublished ? "red" : "green"} onClick={this.publishSeatingChart}> {this.state.isPublished ? "Anuleaza publicare" : "Publica plan"} </Button>
								</div>
							</div>}

							{/*
							<div style={{ alignSelf: 'center', marginTop:'30px' }}>
								{this.state.modalOption !== "showPeople" && <Button onClick={this.closeModal} color="red">Renunță</Button>}
							</div> */}
						</div>
					</Modal>

					{/* {window.innerWidth > 900 && <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'space-between', width: "100vw", overflowX: "scroll", margin: "20px 0px" }}>
						<Button onClick={() => this.setState({ openModal: true, modalOption: "addElems" })} appearance="primary"> Adauga alte elemente </Button>

						<Button color="red" onClick={() => { this.setState({ delete: !this.state.delete }) }} appearance={this.state.delete ? "default" : "ghost"}> Sterge Element </Button>

						<Button color="green" onClick={this.addParticipantsToTable} > Aseaza Invitati </Button>

						<Button onClick={() => this.setState({ openModal: true, modalOption: "options" })} color="blue" > Optiuni </Button>

						<Button color="green" onClick={this.showData}> Arata data </Button>
					</div>} */}

					<div className='SeatingChart-container-organizer'>
						{window.innerWidth > 0 && <div className='Icons-Bar'>
							{/*
							<div className='Home-Icon' onClick={this.toggleShowIconNames}>
								<Icon icon={"bars"} size={"3x"} />
							</div>*/}

							<div className='Icons'>

								<div className={"text-item"}>
									Zoom
								</div>

								<div className={'item zoom '} onClick={() => {
									if(this.state.zoom < 3) {
										this.setState({
											zoom: this.state.zoom + 0.1
										})
									}
								}}>
									<Icon icon={"plus-circle"} size={"2x"}/>
								</div>
								<div className={'item zoom'} onClick={() => {
									if(this.state.zoom > 0) {
										this.setState({
											zoom: this.state.zoom - 0.1
										})
									}
								}}>
									<Icon icon={"minus-circle"} size={"2x"}/>
								</div>


								<div className={'item first ' + (this.state.addElems ? 'active' : '')}
									 onClick={() => { this.setState({ addElems: !this.state.addElems, delete: '', showSideList: false }) }}>
									<Icon icon={"plus-circle"} size={"3x"}/>
									{this.state.showIconsNames && <div>Adaugă elemente</div>}
								</div>

								<div className={'item ' + (this.state.delete ? 'active' : '')}  onClick={() => { this.setState({ delete: !this.state.delete, addElems: '', showSideList: false }) }}>
									<Icon icon={"times-circle"} style={{ color: this.state.delete ? "red" : "" }} size={"3x"} />
									{this.state.showIconsNames && <div>Șterge elemente</div>}
								</div>

								<div className={'item ' + (this.state.showSideList ? 'active' : '')} onClick={this.addParticipantsToTable}>
									<Icon icon={"user-plus"} size={"3x"} />

									{this.state.showIconsNames && <div>Așează participanți</div>}
								</div>

								<div className={'item save'} onClick={this.saveConfig}>
									<Icon icon={"save"} size={"3x"} />

									{this.state.showIconsNames && <div>Salvează plan</div>}
								</div>

								<div className={'item'}>
									<Icon icon={"reload"} size={"3x"} onClick={() => this.setState({ modalOption: "resetElems", openModal: true, showSideList: false })} />

									{this.state.showIconsNames && <div>Resetează plan</div>}
								</div>

								<div className={'item'} onClick={this.publishSeatingChart}>
									<Icon icon={"send"} size={"3x"} />

									{this.state.showIconsNames && <div>Publică plan</div>}
								</div>

								<div className={'item'} onClick={this.goToPlanning}>
									<Icon icon={"close"} size={"3x"} />

									{this.state.showIconsNames && <div>Înapoi</div>}
								</div>
							</div>
						</div>}

						{this.state.addElems && <div className='Options'>
							<div className='Options-Title'>Adauga elemente</div>

							{customModalAddElemsButton(elementsTypes.scene, "Adaugă Scenă")}

							{customModalAddElemsButton(elementsTypes.danceRing, "Adaugă Ring")}

							{customModalAddElemsButton(elementsTypes.candyBar, "Adaugă Candy Bar")}

							{customModalAddElemsButton(elementsTypes.entranceAndExit, "Adaugă Intrare/Iesire")}

							{customModalAddElemsButton(elementsTypes.prezidiuTable, "Adaugă Masă Prezidiu")}

							{customModalAddElemsButton(elementsTypes.toilet, "Adaugă Toaletă")}

							<div className={"addElem"} onClick={() => this.setState({ modalOption: "addTable", openModal: true, addElems:false })} color="green">
								Adaugă masă
							</div>

							<div className={"addElem"} onClick={() => this.setState({ modalOption: "addWall", openModal: true, addElems:false })} color="green">
								Adaugă zid
							</div>

							{customModalAddElemsButton(elementsTypes.stair, "Adaugă Scară")}

							{customModalAddElemsButton(elementsTypes.bar, "Adaugă Bar")}
							{customModalAddElemsButton(elementsTypes.cocktailBar, "Adaugă Cocktail Bar")}
							{customModalAddElemsButton(elementsTypes.shotBar, "Adaugă Shot Bar")}
							{customModalAddElemsButton(elementsTypes.smokingPlace, "Adaugă Loc pentru fumat")}
							{customModalAddElemsButton(elementsTypes.terrace, "Adaugă Terasă")}
						</div>}

						<div className={"drawing-area " + (this.state.showSideList ? 'hide-on-mobile' : '')}>
						<Stage
							width={seatingChartCanvasWidth} height={seatingChartCanvasHeight} style={{  }}
							scaleX={this.state.zoom}
							scaleY={this.state.zoom}
						>
							<Layer>
								{finalToRender}
							</Layer>
						</Stage>
						</div>


						{
							this.state.showSideList ?
								<div className={"side-list-cont"}>
									<AdminSeatingChartModal
										key={this.state.refreshSeatingModal}
										id_eveniment={this.props.match.params.id}
										onFinish={(closeModal = false) => {

											this.setState({
												toRender: [],
												positions: [],
												isSettingTableNumber: false,
												tableCount: 1
											}, () => {
												this.init()
												if (closeModal)
													this.setState({
														showSeatingChartComp: false,
														showSideList: false
													})
											})



										}}
										showTopCloseButton={true}
									/>
								</div>
								: null
						}

					</div>
				</div>
			)
		}

		if (this.state.loading === true)
			toRender = (<BeatLoader
				css={override}
				size={15}
				//size={"150px"} this also works
				color={"#00afcc"}
			/>)

		return (
			<div>
				{toRender}
			</div>
			// <DeviceOrientation lockOrientation={'landscape'}>
			// 	<Orientation orientation='landscape' alwaysRender={false}>
			// 		{toRender}
			// 	</Orientation>

			// 	<Orientation orientation='portrait' alwaysRender={false}>
			// 		<div className="SeatingChart-container">
			// 			<div className="SeatingChart-contents">
			// 				<HeaderWhite shadow={true} goBack={this.goBack} />

			// 				{/* <h5 style={{ marginTop: "20px", width: "70%", margin: "auto" }}> Ecranul este prea mic pentru a vizualiza planul meselor. Va rugam ca pentru crearea planului meselor sa intrati de pe un laptop sau calculator! </h5>

			// 				<BackButton text={"Înapoi"} function={() => this.props.history.push("/organizer/plan/" + this.props.match.params.id)} /> */}

			// 				<div>Pentru a face modificari, va rog intrati de pe un device cu ecran mai mare. </div>
			// 				<SeatingChartForExports seatingChart={{positions: this.state.positions}} responses_data={[]} />

			// 				{/* <Button appearance="primary" style={{ width: "70%", margin: "auto", fontWeight: "500" }} onClick={() => this.props.history.push({
			// 					pathname: "/plan/" + this.props.match.params.id,
			// 					state: { seating: "yes" }
			// 				})}>Înapoi </Button> */}
			// 			</div>
			// 		</div>
			// 	</Orientation>
			// </DeviceOrientation>
		);
	}
}

export default AdminSeatingChart;
