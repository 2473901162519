import React from 'react'
import {Button, Icon} from 'rsuite'
import './CheckPresence.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import axios from 'axios'
import { toast } from 'react-toastify'
import CustomInput from '../../components/custom/CustomInput'
import {BackAndConfirmButtons, ShowPackageData} from '../../components/Helper'
import NavbarColor from '../../../components/NavbarColor'
import Loader from "../../components/Loader";
import Modal from "react-responsive-modal";

class CheckPresence extends React.Component {
	state = {
		positions: [],
		participantsData: [],
		displayData: [],

		currentSearch: "",

		totalParticipants: 0,
		prezentParticipants: 0,
		tutorialModal: false,

		packageData: {},
		notify_paticipants_limit: 0,

		isSendingData: false,
		loading: true
	}


	getData = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		let seating = event_info.data.seatingChart

		let positions = []
		if (seating !== null) {
			positions = seating.positions
		}

		this.setState({
			notify_paticipants_limit: event_info.data.notify_paticipants_limit,
			tutorialModal: !event_info.data.tutorials || (event_info.data.tutorials && !event_info.data.tutorials.checkPresence)
		})

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {

				let invites = response.data.responses.filter((element) => !element.deleted)
				//take all data
				let data = invites.map((invitee, index) => {
					let ans = { ...invitee.response }
					ans.responseIndex = index
					ans.id = invitee.id
					ans.user = invitee.user
					ans.is_checker = invitee.is_checker
					ans.is_present = invitee.is_present

					return ans
				})

				if (data.length === 0) {
					this.setState({
						positions: [],
						participantsData: [],
						displayData: [],
						packageData: response.data,
						loading: false
					})

					toast.info("Niciun raspuns in acest moment")

					return
				}

				//get only tables from data
				let tablePositions = positions.filter(elem => elem.type === "roundTable" || elem.type === "squareTable" || elem.type === "rectangleTable")

				//set tables for participants from server data
				for (let i =0; i < tablePositions.length; i++) {
					var peopleAtTable = tablePositions[i].peopleAtTable



					for (let j = 0; j < peopleAtTable.length; j++) {

						for(let x=0; x<data.length; x++) {
							if(data[x].id === peopleAtTable[j].responseId) {
								data[x].table = i
							}
						}

						//if (data[peopleAtTable[j].responseIndex] !== undefined) {
						//	data[peopleAtTable[j].responseIndex].table = i
						//}
						//if(data[peopleAtTable[j].responseId === 13016]) {
						//	console.log(data[peopleAtTable[j]])
						//}
					}
				}


				//take only data from people who will be participating
				let participants = data.filter(ans => ans.answer === "yes")

				let total = 0
				let prezent = 0

				//set total seats needed
				for (let i = 0; i < participants.length; i++) {
					participants[i].seatsNeeded = 1 + (participants[i].insotit ? 1 : 0) + (participants[i].cuCopii ? Number(participants[i].nr_copii) : 0)
					participants[i].participantIndex = i

					if (participants[i].is_present === undefined) {
						participants[i].is_present = false
					}

					total += participants[i].seatsNeeded

					if (participants[i].is_present) {
						prezent += participants[i].seatsNeeded
					}
				}

				this.setState({
					positions: tablePositions,
					participantsData: participants,
					displayData: participants,
					packageData: response.data,
					totalParticipants: total,
					prezentParticipants: prezent,
					notify_paticipants_limit: event_info.data.notify_paticipants_limit,
					loading: false
				})
			})
	}

	updateNotifyParticipantsLimit = async (value) => {
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let payload = {
				id_eveniment: this.props.match.params.id,
				notify_paticipants_limit: value
			}

			await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/updateNotifyParticipantsLimit', payload, auth);

			this.getData();
	}

	componentDidMount = async () => {
		this.getData()
	}

	onChange = (value, data = null) => {
		let participantsData = this.state.participantsData

		if (data !== null) {
			participantsData = data
		}

		value = value.toLocaleLowerCase()

		let filteredByName = participantsData.filter(element => {
			return element.nume.toLocaleLowerCase().indexOf(value) !== -1 || element.prenume.toLocaleLowerCase().indexOf(value) !== -1
		})

		this.setState({
			currentSearch: value,
			displayData: filteredByName
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				checkPresence: true
			},
			auth)

	}

	goBack = () => {
		this.props.history.push("/organizer/guests/" + this.props.match.params.id)
	}

	render() {
		if (this.state.loading === true) {
			return <div className="CheckPresence-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let widthStyle = { width: '20%', textAlign: "center" }
		let isBigScreen = true

		if (window.innerWidth < 700) {
			isBigScreen = false
			widthStyle = { width: '33%', textAlign: "center" }
		}

		return (
			<div className="CheckPresence-container">
				<HeaderWhite goBack={this.goBack} />

				<div className="CheckPresence-contents">
					<div style={{ fontSize: "30px", fontWeight: "bold" }}>Verifica Prezenta</div>

					<div className='Attendance'>
						Invitati prezenti: {this.state.prezentParticipants} / {this.state.totalParticipants} persoane
						{/* {this.state.totalParticipants > 0 ? this.state.prezentParticipants / this.state.totalParticipants : 0}% */}
					</div>

					<div className={"participants-limit"}>

						<div className={"participants-limit-title"}>
							Trimite notificare cand prezenta atinge
						</div>

						<select
							className={"select-participants-limit-alert"}
							value={this.state.notify_paticipants_limit}
							onChange={(e) => {
								this.setState({notify_paticipants_limit: e.target.value})
								this.updateNotifyParticipantsLimit(e.target.value)
							}}
						>
							<option value={0}>--</option>
							<option value={10}>10%</option>
							<option value={20}>20%</option>
							<option value={30}>30%</option>
							<option value={40}>40%</option>
							<option value={50}>50%</option>
							<option value={60}>60%</option>
							<option value={70}>70%</option>
							<option value={80}>80%</option>
							<option value={90}>90%</option>
							<option value={100}>100%</option>

						</select>
					</div>

					<CustomInput style={{ backgroundColor: "#E5F7FE" }} value={this.state.currentSearch} onChange={value => this.onChange(value, null)} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

					<div className="data-table" style={{ width: "100%" }}>
						<div className="header">
							<div style={widthStyle}>Nume Participant</div>
							<div style={widthStyle}>Total Persoane</div>
							{isBigScreen && <div style={widthStyle}>Masa</div>}
							<div style={widthStyle}>Prezenta</div>
						</div>

						{this.state.displayData.map((element, index) => {
							return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
								<div style={widthStyle}> {element.prenume} </div>
								<div style={widthStyle}> {element.seatsNeeded} </div>
								{isBigScreen && <div style={widthStyle}> {element.table === undefined ? "Nu este asezat" : element.table + 1}</div>}
								<div style={widthStyle}> {element.is_present ? "Prezent" : "Absent"} </div>
							</div>
						})}
					</div>


					<BackAndConfirmButtons
						functionBack={this.goBack}
						textBack={'Înapoi'}
						functionConfirm={()=> this.setState({tutorialModal:true})}
						textConfirm={"Informații utile"}
					/>

					<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							În acest meniu poți vizualiza în timp real cati invitati sunt prezenți la evenimentul tău, astfel incat sa știi exact cand e momentul sa incepi dansul mirilor, servirea aperitivelor sau alte momente importante . Nu trebuie sa fii mereu alert; seteaza din timp procentul de persoane care trebuie sa fie prezente pentru a iniția activitatea și aplicația îți va trimite notificare cand pragul a fost atins.
							<br/><br/>Nu ti-am zis ca ne-am ganditi la tot?
						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default CheckPresence;
