import React from 'react'
import {Button, Dropdown, Icon} from 'rsuite'
import './TicketsPage.scss'

import {toast} from 'react-toastify'
import Modal from "react-responsive-modal";
import {BackAndConfirmButtons, BackButton, ConfirmButton} from '../../components/Helper';
import PdfViewer from "../../components/PdfViewer/PdfViewer";
import {copyToClipboard} from "../../functions";
import {FormattedMessage} from "react-intl";

class BuyTickets extends React.Component {
    state = {
        ticketsTypes: [],
        displayTickets: [],
        ticketsDomain: "twelve.ro/cart",
        chosenTickets: [],
        chosenTicketsAmount: [],
        count: [1, 2, 3, 4, 5],
        forRevelion: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],

        showModal: false
    }

    componentDidMount = () => {
        if (this.props.canBuy) {
            var newTicketTypes = []

            if (this.props.event_data.tickets_types) {
                this.props.event_data.tickets_types.forEach((ticket, index) => {
                    if (ticket.active !== false) {

                        if (this.props.event_data.questions_condition_index > -1 &&
                            ticket.questionConditionValue && ticket.questionConditionValue !== '') {

                            //Check ticket availability
                            if (this.props.participantData
                                && this.props.participantData.questions_answers
                                && this.props.participantData.questions_answers.length > 0
                                && this.props.participantData.questions_answers[this.props.event_data.questions_condition_index] === ticket.questionConditionValue
                            ) {
                                newTicketTypes.push({
                                    ticketTitle: ticket.title,
                                    code: ticket.code,
                                    description: ticket.description,
                                    id: index,
                                    domain: ticket.domain
                                })
                            }

                        } else {

                            newTicketTypes.push({
                                ticketTitle: ticket.title,
                                code: ticket.code,
                                description: ticket.description,
                                id: index,
                                domain: ticket.domain
                            })
                        }
                    }
                })
            }

            this.setState({
                ticketsTypes: [...newTicketTypes].reverse(),
                displayTickets: [...newTicketTypes].reverse(),
                ticketsDomain: this.props.event_data.tickets_types && this.props.event_data.tickets_types[0] && this.props.event_data.tickets_types[0].domain !== undefined ? this.props.event_data.tickets_types[0].domain : "twelve.ro/cart"
            }, () => {
                if (newTicketTypes.length === 1) {
                    this.selectTicket(0)
                }
            })


        }
    }

    selectTicket = (index) => {
        let newDisplayTickets = [...this.state.displayTickets]

        let newChosenTickets = [...this.state.chosenTickets, this.state.displayTickets[index]]
        let newChosenTicketsAmount = [...this.state.chosenTicketsAmount, 1]

        //newDisplayTickets.splice(index, 1)

        this.setState({
            displayTickets: newDisplayTickets,
            chosenTickets: newChosenTickets,
            chosenTicketsAmount: newChosenTicketsAmount
        })
    }

    unselectTicket = (index) => {
        let newChosenTickets = [...this.state.chosenTickets]
        newChosenTickets.splice(index, 1)

        let newChosenTicketsAmount = [...this.state.chosenTicketsAmount]
        newChosenTicketsAmount.splice(index, 1)

        let newDisplayTickets = [...this.state.ticketsTypes]

        newChosenTickets.map(ticket => newDisplayTickets = newDisplayTickets.filter(elem => elem.id !== ticket.id))

        this.setState({
            displayTickets: newDisplayTickets,
            chosenTickets: newChosenTickets,
            chosenTicketsAmount: newChosenTicketsAmount
        })
    }

    setAmount = (index, amount) => {
        let newChosenTicketsAmount = [...this.state.chosenTicketsAmount]

        newChosenTicketsAmount[index] = amount

        this.setState({
            chosenTicketsAmount: newChosenTicketsAmount
        })
    }

    validateData = () => {
        if (this.state.chosenTickets.length === 0) {
            toast.error(<FormattedMessage id={"event_page.tickets.buy.please_select_a_ticket"} />)
            return
        }

        this.setState({
            showModal: true
        })
    }

    confirmData = (generateLink = false) => {


        var url = "https://" + this.state.chosenTickets[0].domain + "/?set_cart="


        this.state.chosenTickets.map((ticket, index) => {
            ticket.code.split(",").map(cod => url += "" + this.state.chosenTicketsAmount[index] + "x" + cod + ',')

            return null
        })


        url = url.substring(0, url.length - 1)

        url += "&auid=" + this.props.user_data.id + "&aeid=" + this.props.event_id

        if (generateLink === true) {
            return url
        } else {
            this.setState({
                displayTickets: this.state.ticketsTypes,
                chosenTickets: [],
                chosenTicketsAmount: [],
                showModal: false
            })
            if (window.flutter_inappwebview) {
                window.flutter_inappwebview.callHandler('openLink', url)
            } else if (window.alityAppView) {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'openLink',
                    url: url,
                }))
            } else {
                window.open(url)
            }
        }
    }

    render() {
        let customStyle = {
            backgroundColor: "#E5F7FE",
            borderRadius: "10px",
            margin: "15px 0 5px 0"
        }

        return (
            <div className="Tickets-content">
                <h4 style={{
                    marginBottom: "30px",
                    color: "#05AFF2",
                    fontWeight: "bold"
                }}> {this.props.prenume} {this.props.nume}</h4>



                {
                    this.props.event_data.tickets_attachment && this.props.event_data.tickets_attachment.length > 0 ?
                        this.props.event_data.tickets_attachment[0].url.includes('.pdf') ?
                            <PdfViewer pdfUrl={this.props.event_data.tickets_attachment[0].url}/>
                            :
                            <img  alt={""}
                                src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + this.props.event_data.tickets_attachment[0].url}
                                style={{width: '100%', marginBottom: '10px'}}/>
                        :
                        null
                }

                {this.props.participantData.questions_answers_tickets !== null && this.props.participantData.questions_answers_tickets.length > 0 &&
                    <div className="section">
                        <div onClick={this.props.showQuestions}><Icon icon="pencil"
                                                                      style={{color: "#05AFF2", marginRight: "10px"}}/>
                            <FormattedMessage id={"event_page.tickets.buy.show_questions_answer"} />
                        </div>
                    </div>}

                <p><FormattedMessage id={"event_page.ticket_not_registered_notice"} /></p>
                <br/>

                {/*
				<div className="section">
					<div onClick={() => this.props.setTab("show")}><Icon icon="ticket" style={{ color: "#05AFF2", marginRight: "10px" }} />Afiseaza biletele cumparate</div>
				</div> */}

                {this.props.canBuy ? <div>
                    {this.state.displayTickets.length > 0
                        ?
                        this.state.displayTickets.length === 1 ?
                            <div className="section"
                                 style={{...customStyle, textAlign: 'center', padding: '8px 0 8px 0'}}>
                                <div onClick={() => this.selectTicket(0)}><Icon icon="ticket" style={{
                                    color: "#05AFF2",
                                    marginRight: "10px"
                                }}/><FormattedMessage id={"event_page.tickets.buy.title"} />
                                </div>
                            </div>
                            :
                            <Dropdown title={<><Icon icon="ticket" style={{color: "#05AFF2", marginRight: "10px"}}/><FormattedMessage id={"event_page.tickets.buy.title"} /></>} className="dropdown_scroll" style={customStyle}>
                                {this.state.displayTickets.map((option, index) => {
                                    return <Dropdown.Item key={index} eventKey={option.ticketTitle}
                                                          onClick={() => this.selectTicket(index)}>{option.ticketTitle}</Dropdown.Item>
                                })}
                            </Dropdown>
                        :
                        <p style={{marginBottom: "30px"}}> {this.state.ticketsTypes.length > 0 ? <FormattedMessage id={"event_page.tickets.buy.tickets"} /> : <FormattedMessage id={"event_page.tickets.buy.no_tickets"} />}</p>
                    }

                    {this.state.chosenTickets.map((choice, index) => {
                        return <div key={index} className="section">
                            <p style={{fontWeight: "bold"}}>{choice.ticketTitle}</p>

                            <div className="description">
                                {choice.description}
                            </div>

                            {this.props.event_data.tip_eveniment !== "congressx" ?
                                <>
                            <p style={{marginTop: "10px"}}><FormattedMessage id={"event_page.tickets.buy.how_many"} /></p>
                            <div className="options">
                                {this.props.event_data.tip_eveniment !== "revelionX" //era cu egalitate pt revelion
                                    ? <Dropdown style={{
                                        color: "#221d5d",
                                        backgroundColor: "#addaec",
                                        margin: "10px 0px",
                                        borderRadius: "10px",
                                        maxWidth:'150px',
                                    }} title={this.state.chosenTicketsAmount[index]} className="dropdown_scroll">
                                        {this.state.forRevelion.map((option, jIndex) => {
                                            return <Dropdown.Item key={jIndex} eventKey={option}
                                                                  onClick={() => this.setAmount(index, option)}>
                                                {option}
                                            </Dropdown.Item>
                                        })}
                                    </Dropdown>
                                    :
                                    this.state.count.map((elem, ind) => {
                                        return <div key={ind}
                                                    className={this.state.chosenTicketsAmount[index] === elem ? "active" : "default"}
                                                    onClick={() => this.setAmount(index, elem)}>
                                            {elem}
                                        </div>
                                    })
                                }
                            </div>
                                </>:null}


							<div className="delete">
								<Button style={{ borderRadius: "20px" }} color="red" onClick={() => this.unselectTicket(index)} ><Icon icon="trash" /> <FormattedMessage id={"general.delete"} /></Button>
							</div>

                        </div>
                    })}
                </div>
                :
                <div className={""}>
                    <FormattedMessage id={"event_page.tickets.sell_tickets_stopped"} />
                </div>}

                {this.props.canBuy && this.state.chosenTickets.length > 0 && <>
                    <ConfirmButton style={{marginBottom: "20px",width:'100%', maxWidth:'320px'}} text={<FormattedMessage id={"event_page.tickets.buy.pay"} />} function={this.validateData}/>

                    {
                        this.props.event_data && this.props.event_data.tip_eveniment === 'congress' ?
                            window.alityAppView ?
                                <BackButton text={<FormattedMessage id={"event_page.tickets.buy.send_company_link"} />}
                                            function={() => {
                                                window.ReactNativeWebView.postMessage(JSON.stringify({
                                                    action: 'shareUrl',
                                                    url: this.confirmData(true),
                                                }))
                                            }}
                                            style={{width:'100%', maxWidth:'320px'}}
                                />
                                :
                                <BackButton text={<FormattedMessage id={"event_page.tickets.buy.generate_company_link"} />}
                                            function={() => {
                                                copyToClipboard(this.confirmData(true))
                                                toast.success(<FormattedMessage id={"event_page.tickets.buy.company_link_copied"} />)
                                            }}
                                            style={{width:'100%', maxWidth:'320px'}}
                                />
                            : null}
                </>}

                {/* <div>
					<p>Dacă ați achiziționat deja un bilet dar această secțiune nu s-a actualizat vă rugăm apăsați pe butonul de mai jos.</p>
					<Button onClick={this.props.showModal} color="blue" style={{ alignSelf: 'center', marginTop: 10, marginBottom: 10 }}>Am cumpărat deja bilet</Button>
				</div> */}

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal',
                        closeButton: 'modal-close-button',
                    }}
                    center
                    open={this.state.showModal}
                    onClose={() => this.setState({showModal: false})}
                    showCloseIcon={true}
                    closeOnOverlayClick={true}
                    closeOnEsc={false}
                >
                    <div style={{minWidth: "250px", maxWidth: "600px"}}>
                        <div style={{paddingTop: '20px'}}><FormattedMessage id={"event_page.tickets.buy.confirm_modal.title"} /></div>
                        {this.state.chosenTickets.map((choice, index) => {
                            return <div key={index} style={{
                                textAlign: "center",
                                margin: "5px 0px",
                                backgroundColor: "#E5F7FE",
                                color: "#221d5d",
                                borderRadius: "20px",
                                padding: "10px 15px"
                            }}>
                                <p>{this.state.chosenTicketsAmount[index]} <span
                                    style={{color: 'green'}}>x</span> {choice.ticketTitle}</p>
                            </div>
                        })}

                        <div style={{marginTop: '5px', marginBottom: '5px'}}>
                            <span style={{color: 'red'}}>
                                <FormattedMessage id={"event_page.tickets.buy.confirm_modal.text1"} /></span><br/><br/>
                            <span style={{color: 'red'}}><FormattedMessage id={"event_page.tickets.buy.confirm_modal.text2"} /></span> <FormattedMessage id={"event_page.tickets.buy.confirm_modal.text3"} />
                        </div>

                        <BackAndConfirmButtons textBack={<FormattedMessage id={"general.back"} />} textConfirm={<FormattedMessage id={"general.confirm"} />}
                                               functionBack={() => this.setState({showModal: false})}
                                               functionConfirm={this.confirmData}/>
                    </div>
                </Modal>


            </div>
        )
    }
}

export default BuyTickets;
