import React, {lazy, Suspense} from 'react'
import Loader from "../Loader";

const InviteTemplateRender = lazy(() => import("./InviteTemplateRender"))

export default function InviteTemplateRenderLazy(
    {
        templateDetails,
        weddingData,
        locations,
        mode,
        audioLink = '',
        image1Link = '',
        image2Link = '',
        image3Link = '',
        coverImageLink = '',
        toggleModal = (val) => {
        },
        hideCountdown = false
    }
) {

    return <Suspense fallback={<div style={{padding: "30px 0"}}><Loader/></div>}>

        <InviteTemplateRender
            templateDetails={templateDetails}
            weddingData={weddingData}
            locations={locations}
            mode={mode}
            audioLink={audioLink}
            image1Link={image1Link}
            image2Link={image2Link}
            image3Link={image3Link}
            coverImageLink={coverImageLink}
            toggleModal={toggleModal}
            hideCountdown={hideCountdown}
        />

    </Suspense>

}
