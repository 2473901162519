import React from 'react'
import { Button, Icon } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'

import HeaderWhite from '../../../../../components/HeaderWhite'

import './SetParticipantsTables.scss'
import {BackAndConfirmButtons, ConfirmButton} from '../../../../components/Helper'
import NavbarColor from '../../../../../components/NavbarColor'

import SVG_Budget_Edit from '../../../../assets/BudgetSVG/edit-budget-icon.svg'
import CustomInput from '../../../../components/custom/CustomInput'
import Loader from "../../../../components/Loader";
import {countSeatedFamilies, getWeddingParticipantsPackageNumber} from "../../../../../functions";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

class SetParticipantsTables extends React.Component {
	state = {
		seatingId: -1,
		positions: [],
		allPositions: [],
		participantsData: [],
		tutorialModal: false,

		currentSearch: "",
		loading: true,
		noSeatingChart: false,

		chooseTableModal: false,
		participantIndex: 0,

		showBackConfirmModal: false,

		orderByTable: 0
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		let seating = event_info.data.seatingChart

		this.setState({
			tutorialModal: !event_info.data.tutorials || (event_info.data.tutorials && !event_info.data.tutorials.participantsTables),
			event_data: event_info.data
		})

		if (seating === null) {
			this.setState({
				loading: false,
				noSeatingChart: true
			})

			return
		}

		let positions = seating.positions
		let seatingId = seating.id

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(response => {

				//let participantsTemp = response.data.responses.filter(ans => ans.response.answer === "yes")

				let invites = response.data.responses.filter((element) => !element.deleted)

				//take all data
				let data = invites.map((invitee, index) => {

					var ans = invitee.response
					ans.responseIndex = index
					ans.responseId = invitee.id

					return ans
				})

				//get only tables from data
				let tablePositions = positions.filter(elem => elem.type === "roundTable" || elem.type === "squareTable" || elem.type === "rectangleTable")

				//set tables for participants from server data
				for (let i =0; i < tablePositions.length; i++) {
					var peopleAtTable = tablePositions[i].peopleAtTable



					for (let j = 0; j < peopleAtTable.length; j++) {

						for(let x=0; x<data.length; x++) {
							if(data[x].responseId === peopleAtTable[j].responseId) {
								data[x].table = i
							}
						}
					}
				}

				//take only data from people who will be participating
				let participants = data.filter(ans => ans.answer === "yes")


				//set total seats needed
				for (let i = 0; i < participants.length; i++) {
					participants[i].seatsNeeded = 1 + (participants[i].insotit ? 1 : 0) + (participants[i].cuCopii ? Number(participants[i].nr_copii) : 0)
				}


				this.setState({
					seatingId: seatingId,
					positions: tablePositions,
					allPositions: positions,
					participantsData: participants,
					loading: false
				})
			})
	}

	confirmData = async () => {
		var newPositions = [...this.state.allPositions]

		var posI = 0

		for (var i = 0; i < newPositions.length; i++) {
			if (newPositions[i].id === this.state.positions[posI]) {
				newPositions[i] = this.state.positions[posI]

				posI++
			}
		}

		let obj = {
			toRender: [],
			positions: newPositions,
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'seating-charts/' + this.state.seatingId, obj, auth)
			.then(_ => {
				toast.success("Salvare efectuată cu succes!")
			})
			.catch(err => {
				console.log(err);
				toast.error("S-a produs o eroare, va rugăm reîncercați")
			})
	}

	chooseTable = (tableIndex) => {
		var newParticipantsData = [...this.state.participantsData]
		var newTableData = [...this.state.positions]

		let participant = newParticipantsData[this.state.participantIndex]

		//check if participant already at a table
		if (participant.table !== undefined) {
			//remove participant data from table
			newTableData[participant.table].peopleAtTable = newTableData[participant.table].peopleAtTable.filter(elem => participant.responseIndex !== elem.responseIndex)
		}

		//set table index for participant
		newParticipantsData[this.state.participantIndex].table = tableIndex

		//add participant to table data
		newTableData[tableIndex].peopleAtTable[newTableData[tableIndex].peopleAtTable.length] = {
			name: participant.nume,
			prenume: participant.prenume,
			namePartner: participant.insotit ? participant.nume_insotitor : "",
			kidsCount: participant.cuCopii ? participant.nr_copii : 0,
			nrPeople: participant.seatsNeeded,
			responseIndex: participant.responseIndex,
			responseId: participant.responseId
		}

		this.setState({
			participantsData: newParticipantsData,
			positions: newTableData,
			chooseTableModal: false
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				participantsTables: true
			},
			auth)

	}

	changeSearch = (value) => {
		this.setState({
			currentSearch: value
		})
	}

	openModal = (index) => {
		this.setState({
			chooseTableModal: true,
			participantIndex: index
		})
	}

	closeModal = () => {
		this.setState({
			chooseTableModal: false
		})
	}

	goToSeatingChart = () => {
		this.props.history.push("/organizer/adminSeatingChart/" + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <div className="SetParticipantsTables-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		if (this.state.noSeatingChart) {
			return <div className="SetParticipantsTables-container">
				<HeaderWhite shadow={true} goBack={this.goBack} />

				<div className="NoTablePlan">
					<div className='Details'>Nu aveți planul de mese creat. Pentru a așeza participanții la mese vă rog să-l creați.</div>

					<Modal
						classNames={{
							overlay: 'modal-overlay',
							modal: 'modal-no-top',
							closeButton: 'modal-close-button',
						}}
						open={this.state.tutorialModal}
						focusTrapped={false}
						onClose={this.updateTutorial}
					>
						<div style={{marginTop: "20px"}}>
							<h3>Informații utile</h3>
							<br/>
							<p>
								Ai primit răspuns afirmativ la invitație? Și ai creat planul locației! Acum e momentul sa ii si așezi la masa. Observa în dreptul fiecărei familii sau invitat numărul de persoane și apăsa butonul cu roșu „Alege masa”. Poți face oricand modificări prin accesarea butonului verde cu numărul mesei, din acest meniu.
								<br/>Stabilește punctele de interes ale locației (scena, ring de dans, cocktaibar, photobooth etc.) numărul și poziția meselor. Din aceasta sectiune se poate accesa planul locației pentru o vederea de ansamblu a pozitionarii meselor.
								<br/>Încă nu te-am convins sa stai la masa Ality? ;)

							</p>
							<Button
								onClick={this.updateTutorial}
								color="green"
								style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
						</div>
					</Modal>

					<BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Creaza"} functionBack={this.goBack} functionConfirm={this.goToSeatingChart} />
				</div>
			</div>
		}

		let tableChoosingModal = this.state.participantsData.length === 0
			? <p> </p>
			: <Modal classNames={{
				overlay: 'modal-overlay',
				modal: 'std-modal',
				closeButton: 'modal-close-button',
			}} open={this.state.chooseTableModal} onClose={this.closeModal} center>
				<div className="SetParticipantsTables-Modal-Container">
					{
						(getWeddingParticipantsPackageNumber(this.state.event_data) !== 2 && getWeddingParticipantsPackageNumber(this.state.event_data) !== 4) && countSeatedFamilies(this.state.positions) >= 5 ?
							<div style={{textAlign:'center'}}>
								<br/>
								<h4> Limita de maxim 5 familii așezate la mese a fost atinsă.</h4>
								<br/>
								<h4>Pentru a elimina această limită trebuie să achiziționați pachetul PREMIUM.</h4>
								<br/>
								<p>

									<ConfirmButton
										style={{width:'100%', marginTop:'30px'}}
										text={"Achiziționează pachetul PREMIUM"}
										function={() => {
											this.props.history.push('/organizer/package/'+this.props.match.params.id)
										}}
									/>

								</p>
							</div>
							:
							<>
								<h3> Alegeti masa
									pentru {this.state.participantsData[this.state.participantIndex].prenume} </h3>
								<h4> Are nevoie
									de {this.state.participantsData[this.state.participantIndex].seatsNeeded === 1 ? "un loc" : this.state.participantsData[this.state.participantIndex].seatsNeeded + " locuri"}</h4>

								<div className="Modal-Content">
									{this.state.positions.map((td, index) => {
										let freeSeats = td.tableSize

										td.peopleAtTable.map(elem => {
											freeSeats -= elem.nrPeople

											return null
										})

										return <div key={index} style={{marginBottom: "5px"}}>
											<p style={{marginTop: "20px"}}> Masa {index + 1} : {td.type === "roundTable" ? "rotunda" : td.type === "squareTable" ? "patrata" : "dreptunghiulara"}, {td.tableSize - freeSeats} / {td.tableSize} </p>

											{td.peopleAtTable.length > 0 && <div style={{marginLeft: "10px"}}>
												<p>La masa in acest moment:</p>

												{td.peopleAtTable.map((elem, index) => {
													return <p key={index}>
														total
														persoane {elem.nrPeople}: {elem.prenume} {elem.name}{elem.namePartner === "" ? "" : ", insotit de " + elem.namePartner}{elem.kidsCount !== 0 ? ", " + elem.kidsCount + " copii" : ""}
													</p>
												})}
											</div>}

											{freeSeats >= this.state.participantsData[this.state.participantIndex].seatsNeeded &&
											<div>
												<Button color="green" onClick={() => this.chooseTable(index)}> Aseaza la
													aceasta masa </Button>
											</div>}
										</div>
									})}
								</div>

								<div style={{alignSelf: 'center'}}>
									<Button onClick={this.closeModal} color="red"
											style={{borderRadius: "20px"}}>Inchide</Button>
								</div>
							</>
					}
				</div>
			</Modal>

		let dispParticipantsData = this.state.participantsData.map((elem, index) => {
			elem.elemIndex = index

			return elem
		})

		dispParticipantsData = dispParticipantsData.filter(element => {

			let name = element.prenume + " " + element.nume

			return (name.toLocaleLowerCase().indexOf(this.state.currentSearch.toLocaleLowerCase()) !== -1) ||
				element.nume_insotitor.toLocaleLowerCase().indexOf(this.state.currentSearch.toLocaleLowerCase()) !== -1
		})

		if(this.state.orderByTable === 0) {
			dispParticipantsData = dispParticipantsData.sort((a,b) => (a.table > -1 ? a.table : -1) - b.table);
		} else {
			dispParticipantsData = dispParticipantsData.sort((a,b) => b.table - a.table);
		}

		let widthStyle = {
			width: "33%",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			borderRadius: "0px",
			textAlign: "center"
		}

		let displayInvites = dispParticipantsData.map((element, index) => {
			return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
				<div style={widthStyle}> {element.prenume} </div>
				<p style={widthStyle}> {element.seatsNeeded} </p>
				{/* <div style={widthStyle} onClick={() => this.openModal(index)}> {element.table === undefined ? "-" : element.table}</div> */}

				<div style={{ ...widthStyle, color: element.table === undefined ? "red" : "#19b899" }}
					onClick={() => this.openModal(element.elemIndex)}> {element.table === undefined
						? "Alege"
						: <div>{element.table + 1} <img src={SVG_Budget_Edit} alt="card" style={{ height: "20px" }} /></div>
					}</div>
			</div>
		})

		if (window.innerWidth > 1000) {
			widthStyle = {
				width: "13%",
				overflow: "hidden",
				whiteSpace: "nowrap",
				textOverflow: "ellipsis",
				borderRadius: "0px",
				textAlign: "center"
			}

			displayInvites = dispParticipantsData.map((element, index) => {
				// console.log(element);
				return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
					<div style={widthStyle}> {element.prenume} </div>
					<p style={widthStyle}> {element.insotit ? element.nume_insotitor : "-"} </p>
					<div style={widthStyle}> {element.cuCopii ? element.nr_copii : "-"} </div>
					<p style={widthStyle}> {element.seatsNeeded} </p>
					<div style={widthStyle}> {element.table === undefined ? "-" : element.table + 1}</div>
					<div style={{ ...widthStyle, color: element.table === undefined ? "red" : "green" }} onClick={() => this.openModal(element.elemIndex)}> {element.table === undefined ? "Alege" : "Schimba"}</div>
				</div>
			})

			return <div className="SetParticipantsTables-container">
				<HeaderWhite shadow={true} goBack={() => {
					this.setState({
						showBackConfirmModal: true
					})
				}} />


				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.showBackConfirmModal} onClose={this.closeModal} center focusTrapped={false} showCloseIcon={false}>

					<div style={{marginTop:'15px', marginBottom:'15px', fontSize:'16px'}}>

						<p>Doriți să salvați modificările?</p>
						<BackAndConfirmButtons
							style={{marginBottom:'10px'}}
							functionBack={this.goBack}
							textBack={'Nu'}
							functionConfirm={async () => {

								await this.confirmData()
								this.goBack()

							}}
							textConfirm={'Da'}
						/>
					</div>

				</Modal>

				{tableChoosingModal}

				<div className="SetParticipantsTables-contents">
					<div style={{ fontSize: "30px", fontWeight: "bold", marginTop:"10px" }}>Așează invitații la mese</div>

					<div className={"grid-row"}>
						<div className={"grid-col"}>
							<div className='add-response-button'>
								<Icon icon="edit" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.goToSeatingChart} />
								<div onClick={this.goToSeatingChart}>Accesați plan locație</div>
							</div>
						</div>
						<div className={"grid-col"}>
							<Button
								onClick={()=> this.setState({tutorialModal:true})}
								color="green"
								style={{backgroundColor: "#05AFF2", overflow:'visible', borderRadius: "20px", width: "100%", marginTop:"10px", marginBottom: "20px"}}>Informații utile</Button>

						</div>
					</div>




					<CustomInput value={this.state.currentSearch} onChange={this.changeSearch} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

					<div className="data-table" style={{ width: "100%" }}>
						<div className="header">
							<div style={widthStyle}>Nume</div>
							<div style={widthStyle}>Însoțitor</div>
							<div style={widthStyle}>Nr Copii</div>
							<div style={widthStyle}>Total Pers</div>
							<div style={widthStyle}><div onClick={() => {
								this.setState({
									orderByTable: this.state.orderByTable ? 0 : 1
								})
							}}>Masa{
								this.state.orderByTable === 0 ?
									<MdKeyboardArrowUp style={{position:"relative", top:"3px"}} />
									:
									<MdKeyboardArrowDown style={{position:"relative", top:"3px"}} />
							}</div></div>
							<div style={widthStyle}>Opțiuni</div>
						</div>

						{displayInvites}
					</div>



					<BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Confirmă"} functionBack={this.goBack} functionConfirm={this.confirmData} />
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Ai primit răspuns afirmativ la invitație? Și ai creat planul locației! Acum e momentul sa ii si așezi la masa. Observa în dreptul fiecărei familii sau invitat numărul de persoane și apăsa butonul cu roșu „Alege masa”. Poți face oricand modificări prin accesarea butonului verde cu numărul mesei, din acest meniu.
							<br/>Stabilește punctele de interes ale locației (scena, ring de dans, cocktaibar, photobooth etc.) numărul și poziția meselor. Din aceasta sectiune se poate accesa planul locației pentru o vederea de ansamblu a pozitionarii meselor.
							<br/>Încă nu te-am convins sa stai la masa Ality? ;)

						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return <div className="SetParticipantsTables-container">
			<HeaderWhite shadow={true} goBack={this.goBack} />

			{tableChoosingModal}

			<div className="SetParticipantsTables-contents">
				<div style={{ fontSize: "30px", fontWeight: "bold" }}>Așează invitații la mese</div>

				<div className={"grid-row"}>
					<div className={"grid-col"}>
						<div className='add-response-button'>
							<Icon icon="edit" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.goToSeatingChart} />
							<div onClick={this.goToSeatingChart}>Accesați plan locație</div>
						</div>
					</div>
					<div className={"grid-col"}>
						<Button
							onClick={()=> this.setState({tutorialModal:true})}
							color="green"
							style={{backgroundColor: "#05AFF2", overflow:'visible', borderRadius: "20px", width: "100%", marginTop:"10px", marginBottom: "20px"}}>Informații utile</Button>

					</div>
				</div>

				<CustomInput value={this.state.currentSearch} onChange={this.changeSearch} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

				<div className="data-table" style={{ width: "100%" }}>
					<div className="header">
						<p style={widthStyle}>Nume</p>
						<div style={widthStyle}>Total Pers</div>
						<div style={widthStyle}><div onClick={() => {
							this.setState({
								orderByTable: this.state.orderByTable ? 0 : 1
							})
						}}>Masa{
							this.state.orderByTable === 0 ?
								<MdKeyboardArrowUp style={{position:"relative", top:"3px"}} />
								:
								<MdKeyboardArrowDown style={{position:"relative", top:"3px"}} />
						}</div></div>
					</div>

					{displayInvites}
				</div>

				<BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Confirmă"} functionBack={this.goBack} functionConfirm={this.confirmData} />
			</div>

			<Modal
				classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-no-top',
					closeButton: 'modal-close-button',
				}}
				open={this.state.tutorialModal}
				focusTrapped={false}
				onClose={this.updateTutorial}
			>
				<div style={{marginTop: "20px"}}>
					<h3>Informații utile</h3>
					<br/>
					<p>
						Ai primit răspuns afirmativ la invitație? Și ai creat planul locației! Acum e momentul sa ii si așezi la masa. Observa în dreptul fiecărei familii sau invitat numărul de persoane și apăsa butonul cu roșu „Alege masa”. Poți face oricand modificări prin accesarea butonului verde cu numărul mesei, din acest meniu.
						<br/>Stabilește punctele de interes ale locației (scena, ring de dans, cocktaibar, photobooth etc.) numărul și poziția meselor. Din aceasta sectiune se poate accesa planul locației pentru o vederea de ansamblu a pozitionarii meselor.
						<br/>Încă nu te-am convins sa stai la masa Ality? ;)

					</p>
					<Button
						onClick={this.updateTutorial}
						color="green"
						style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
				</div>
			</Modal>

			<NavbarColor history={this.props.history} />
		</div>
	}
}

export default SetParticipantsTables
