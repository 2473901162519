import React from 'react'
import './IntroAnimationConfigComp.scss'
import {
    getIntroAnimationConfigValues,
    getTemplateConfigValues
} from "../../../InviteTemplatesFunctions";
import {Toggle} from "rsuite";
import {useIntl} from "react-intl";
import ConfigurationFields from "./components/ConfigurationFields/ConfigurationFields";
import AddAudio from "../../../../organizer/mainPages/EditInvite/components/AddAudio";
import {toast} from "react-toastify";


export default function IntroAnimationConfigComp(
    {
        templateDetails,
        setTemplateDetails,
        isUsingAudio,
        initialWeddingData,
        audioType,
        serverAudioUrl,
        deleteServerAudio,
        allowAudioRequest,
        customAudioLink, customAudioSecond,
        songSelected,
        setAudioType,
        setAudioBlobData,
        setCustomAudioLink,
        setCustomAudioSecond,
        setDeleteServerAudio,
        setSongSelected,
        setIsUsingAudio
    }
) {

    const intl = useIntl();
    const availableAnimations = getTemplateConfigValues(templateDetails.id).introAnimations
    const toggleStyle = {
        margin: "10px 0px",
        textAlign: 'center',
        maxWidth: "500px",
        alignSelf: 'center'
    }


    return (<div className={"intro-animation-config-comp"}>


        {availableAnimations.length === 1 ?
            <div style={{marginBottom: '1rem'}}>
                {intl.formatMessage({id: 'intro_animation.config.enable_question'})}
                <br/>
                <Toggle
                    style={toggleStyle}
                    checked={!!(templateDetails.introAnimationId && templateDetails.introAnimationId === availableAnimations[0])}
                    size="lg"
                    checkedChildren={intl.formatMessage({id: 'general.yes'})}
                    unCheckedChildren={intl.formatMessage({id: 'general.no'})}
                    onChange={(value) => {
                        let newTemplateDetails = {...templateDetails}
                        if (value) {
                            newTemplateDetails.introAnimationId = availableAnimations[0]

                            //Define default configuration values
                            let introAnimationConfiguration = {}
                            let configurationValues = getIntroAnimationConfigValues(availableAnimations[0]).configuration
                            configurationValues.forEach(item => {
                                introAnimationConfiguration[item.title] = item.value
                            })
                            newTemplateDetails.introAnimationConfiguration = introAnimationConfiguration
                        } else {
                            newTemplateDetails.introAnimationId = 0
                        }
                        setTemplateDetails(newTemplateDetails)
                    }}/>
            </div> : null}

        {templateDetails.introAnimationId ?
            <div>
                {/* introAnimation template configuration */}


                <ConfigurationFields
                    introAnimationId={templateDetails.introAnimationId}
                    configuration={templateDetails.introAnimationConfiguration}
                    setConfiguration={(value) => {
                        let newTemplateDetails = {...templateDetails}
                        newTemplateDetails.introAnimationConfiguration = value
                        setTemplateDetails(newTemplateDetails)
                    }}
                />

            </div>
            : null}
        {!window.flutter_inappwebview ? <div className="Section">
                <div className="Title">Doriți să adăugați o melodie sau să înregistrați un mesaj audio pentru invitație?
                </div>

                <div className="Options-Row">
                    <div className={isUsingAudio ? "active-option" : "inactive-option"}
                         onClick={_ => {
                             if (!initialWeddingData.packages ||
                                 (initialWeddingData.packages && initialWeddingData.packages['wedding_participants']
                                     && initialWeddingData.packages['wedding_participants'].package === 0
                                 )) {
                                 toast.warn('Această functionalitate este disponibilă doar după achiziționarea unui pachet!')
                                 return;
                             }
                             setIsUsingAudio(true)
                         }} style={{marginRight: "30px"}}>Da
                    </div>
                    <div className={!isUsingAudio ? "active-option" : "inactive-option"}
                         onClick={_ => setIsUsingAudio(false)}>Nu
                    </div>
                </div>
            </div>
            : <div className="Section">
                <div className="Title">Pentru a adauga un mesaj audio va rugam sa folositi un
                    laptop/pc
                </div>
            </div>}

        {isUsingAudio &&
            <AddAudio
                audioType={audioType} serverAudioUrl={serverAudioUrl}
                deleteServerAudio={deleteServerAudio}
                allowAudioRequest={allowAudioRequest}
                customAudioLink={customAudioLink}
                customAudioSecond={customAudioSecond}
                songSelected={songSelected}
                setAudioType={setAudioType}
                setAudioBlobData={setAudioBlobData}
                setCustomAudioLink={setCustomAudioLink}
                setCustomAudioSecond={setCustomAudioSecond}
                setDeleteServerAudio={setDeleteServerAudio}
                setSongSelected={setSongSelected}
            />}

    </div>)
}
