import React from "react";
import './Walkthrough.scss'
import HeaderWhite from "../HeaderWhite";

export default function WalkthroughOverlayBack({action}) {


    return (
                    <div className={"walkthrough-overlay-back"}>

                        <HeaderWhite style={{ width: "100%" }} overlayBack={true} goBack={action} />

                    </div>
    )

}