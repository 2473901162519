import React from 'react'
import './NavBarEvents.scss'
import { FormattedMessage } from 'react-intl';
import { Icon } from 'rsuite';

class NavBarEvents extends React.Component {
	state = {
		elements: this.props.tabOptions
	}

	render() {
		let navElements = this.state.elements.map((element) => {
			return <div key={element}>
				{this.props.isRedCircleNeeded(element) && <div className="NavBar-RedCircle-container"><div className="NavBar-RedCircle"></div></div>}
				<p onClick={() => this.props.changeMenu(element)} style={element === 'Check-in' ? {backgroundColor: 'rgb(25, 184, 153)'} : {} } className={this.props.activePage === element ? "active-item" : "menu-item"}>
					<FormattedMessage id={"event_page.navbar."+element} /></p>
			</div>
		})

		return (
			<div className="NavBarEvents-container">
				{(window.flutter_inappwebview || window.alityAppView)  ?
					<div className="Burger-Button">
						<Icon onClick={this.props.showSideBar} icon="bars" size="lg" />
						<div className={this.props.isDotNeeded ? "Red-Dot" : ""}></div>
					</div>
					:null
				}

				<div className="NavBarEvents-contents">
					{navElements}
				</div>
			</div>
		)
	}
}

export default NavBarEvents;
