import React from 'react'
import './InfoPage.scss'
import axios from 'axios'
import PdfViewer from '../../components/PdfViewer/PdfViewer'
import AdModal from '../../components/AdModal';
import YouTube from "react-youtube";

let adFieldName = "info"

class InfoPage extends React.Component {
	state = {
		event_data: this.props.event_data,

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}

	componentDidMount = async () => {
		if (this.props.event_data.informatii_utile.length > 0 && this.props.participantData !== null) {
			if (this.props.participantData.last_informatii_utile !== this.props.event_data.informatii_utile[0].url) {
				//update local data
				this.props.participantData.last_informatii_utile = this.props.event_data.informatii_utile[0].url

				//update server data
				let auth = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					}
				}

				let obj = {
					last_informatii_utile: this.props.participantData.last_informatii_utile,
				}

				axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
			}
		}
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		if (this.state.showAdModal) {
			return <div className="SeatingChart-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		return (
			this.state.event_data.informatii_utile.length === 0
				? <div className="SeatingChart-container">
					<p>Pdf-ul cu informatii nu a fost incarcat</p>
				</div>
				: <>

					{
						this.state.event_data.id_eveniment === 'JPbCWC2OFX_' || this.state.event_data.id_eveniment === 'xnmnJmO8tb' ?
							<YouTube videoId={'qLrfmvMVFSE'} containerClassName={"youtube-video"} />
							:<PdfViewer pdfUrl={this.state.event_data.informatii_utile[0].url} />
					}
				</>
		)
	}
}

export default InfoPage;
