import React from 'react'
import {Icon} from 'rsuite'

import './ParticipantsTab.scss'
import CustomInput from '../../components/custom/CustomInput'
import {ConfirmButton} from '../../components/Helper'
import Loader from "../../components/Loader";
import Modal from "react-responsive-modal";
import {fixWebsiteUrl, generateVCard, getReadableWebsite} from "../../functions";

class ParticipantsTab extends React.Component {
    state = {
        search: '',
        loading: false,
    }

    componentDidMount = async () => {

    }

    hasPublicData = (user) => {

        if ((user.phone && user.phone.length > 0) ||
            (user.second_email && user.second_email.length > 0) ||
            (user.company && user.company.length > 0) ||
            (user.function && user.function.length > 0) ||
            (user.linkedin && user.linkedin.length > 0) ||
            (user.instagram && user.instagram.length > 0) ||
            (user.facebook && user.facebook.length) > 0
        ) {
            return true
        }

    }

    getInfoRow = (icon, name, text) => {
        return <div className="Row">
            <Icon icon={icon} style={{margin: "0px", color: "#06aff2", fontSize: '18px'}}/>

            <div className="Data">
                <div>{name}</div>

                <p style={{color: "#121213", fontSize: "16px"}}>{text}</p>
            </div>
        </div>
    }

    openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    render() {

        if (this.state.loading === true) {
            return <div className="ParticipantsTab-container">
                <Loader/>
            </div>
        }
        return <div className="ParticipantsTab-container">

            {
                !this.props.event_data.participants_details || this.props.event_data.participants_details.length === 0?
                    <p style={{ fontSize: 20, textAlign:'center' }}>Pentru moment nu există niciun participant confirmat</p>
                    :<>
                        <CustomInput
                            style={{marginBottom:'5px'}}
                            value={this.state.search}
                            onChange={(val) => {
                                this.setState({search: val})
                            }}
                            placeholder={"Căutare participanți..."}
                        />
                    </>
            }

            {
                this.props.event_data.participants_details && this.props.event_data.participants_details.map((user, i) => (

                    ((this.state.search.length > 0 &&
                        (user.first_name.toLowerCase().trim() + ' ' + user.last_name.toLowerCase().trim()).includes(this.state.search.toLowerCase().trim())
                ) || this.state.search.length === 0) ?
                    <div key={i} className={"user-row " + (this.hasPublicData(user) ? "clickable" : "")} onClick={() => {
                        if (this.hasPublicData(user)) {
                            this.setState({
                                currentUser: user
                            })
                        }
                    }}>

                        {
                            user.image ?
                                <div className={"image"}>
                                    <img  alt={""} src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + user.image.url}/>
                                </div>
                                : null
                        }
                        <div className={"name"}>
                            {user.last_name} {user.first_name}
                        </div>
                        <div className={"icon"}>
                            {
                                this.hasPublicData(user) ?
                                    <Icon icon={"unlock"} size="2x" style={{margin: "0 5px", color: "#06aff2"}}/>
                                    :
                                    <Icon icon={"lock"} size="2x" style={{margin: "0 5px", color: "#d9d9d9"}}/>
                            }
                        </div>

                    </div>
                        :null
                ))
            }

            {this.state.currentUser ?
                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal participantstab-modal',
                        closeButton: 'modal-close-button',
                    }}
                    center
                    open={!!this.state.currentUser}
                    onClose={() => this.setState({currentUser: null})}
                    focusTrapped={false}
                >
                            <div style={{marginTop: "15px", marginBottom: "15px"}}>

                                {
                                    this.state.currentUser.image ?
                                        <div className={"image"}>
                                            <img  alt={""}
                                                src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + this.state.currentUser.image.url}/>
                                        </div>
                                        : null
                                }

                                <div className={"user-rows"}>
                                {this.getInfoRow("user-info", "Nume", this.state.currentUser.last_name)}
                                {this.getInfoRow("user-info", "Prenume", this.state.currentUser.first_name)}

                                {
                                    this.state.currentUser.email && this.state.currentUser.email.length > 0 ?
                                        this.getInfoRow("at", "Email", this.state.currentUser.email)
                                        : null
                                }

                                {
                                    this.state.currentUser.second_email && this.state.currentUser.second_email.length > 0 ?
                                        this.getInfoRow("at", "Email muncă", this.state.currentUser.second_email)
                                        : null
                                }

                                {
                                    this.state.currentUser.phone && this.state.currentUser.phone.length > 0 ?
                                        this.getInfoRow("phone", "Telefon", this.state.currentUser.phone)
                                        : null
                                }

                                {
                                    this.state.currentUser.company && this.state.currentUser.company.length > 0 ?
                                        this.getInfoRow("building", "Companie/Instituție", this.state.currentUser.company)
                                        : null
                                }

                                {
                                    this.state.currentUser.function && this.state.currentUser.function.length > 0 ?
                                        this.getInfoRow("certificate", "Funcție/Titulatură", this.state.currentUser.function)
                                        : null
                                }

                                    {
                                        this.state.currentUser.website && this.state.currentUser.website.length > 0 ?
                                            <span onClick={() => {
                                                if (this.state.currentUser.website) {
                                                    this.openLink(fixWebsiteUrl(this.state.currentUser.website))
                                                }
                                            }}>
                                                {this.getInfoRow("internet-explorer", "Website",  <><Icon icon={'link'} style={{color: "#06aff2"}}/> {getReadableWebsite(this.state.currentUser.website)}</>)}
                                    </span>
                                            : null
                                    }
                                </div>

                                {
                                    (this.state.currentUser.linkedin && this.state.currentUser.linkedin.length > 0) ||
                                    (this.state.currentUser.instagram && this.state.currentUser.instagram.length > 0) ||
                                    (this.state.currentUser.facebook && this.state.currentUser.facebook.length > 0) ?
                                        <div className={"social"}>
                                            {
                                                this.state.currentUser.linkedin && this.state.currentUser.linkedin.length > 0 ?
                                                    <span onClick={() => {
                                                        if (this.state.currentUser.linkedin) {
                                                            this.openLink('https://www.linkedin.com/in/' + this.state.currentUser.linkedin)
                                                        }
                                                    }}>
                                                        <Icon icon={'linkedin'} size="2x" style={{color: "#0077B5"}}/>
                                                    </span>
                                                    : null
                                            }

                                            {
                                                this.state.currentUser.instagram && this.state.currentUser.instagram.length > 0 ?
                                                    <span onClick={() => {
                                                        if (this.state.currentUser.instagram) {
                                                            this.openLink('https://www.instagram.com/' + this.state.currentUser.instagram)
                                                        }
                                                    }}>
                                    <Icon icon={'instagram'} size="2x" style={{color: "#C13584"}}/>
                                    </span>
                                                    : null
                                            }

                                            {
                                                this.state.currentUser.facebook && this.state.currentUser.facebook.length > 0 ?
                                                    <span onClick={() => {
                                                        if (this.state.currentUser.facebook) {
                                                            this.openLink('https://www.facebook.com/' + this.state.currentUser.facebook)
                                                        }
                                                    }}>
                                    <Icon icon={'facebook-official'} size="2x" style={{color: "#4267B2"}}/>
                                    </span>
                                                    : null
                                            }
                                        </div>
                                        : null
                                }

                            </div>


                            <ConfirmButton
                                style={{width: '100%'}}
                                text={'Adaugă contact în agendă'}
                                function={() => {
                                    let fileContent = generateVCard(this.state.currentUser)
                                    const element = document.createElement("a");
                                    const file = new Blob([fileContent], {type: 'text/vcard'});
                                    element.href = URL.createObjectURL(file);

                                    //console.log(JSON.stringify(fileContent))
                                    //this.openLink('http://localhost:1337/event/generateVCard?data=' + JSON.stringify(fileContent))

                                    if (window.alityAppView) {
                                        window.ReactNativeWebView.postMessage(JSON.stringify({
                                            action: 'openLink',
                                            url: 'https://admin.ality.ro/event/generateVCard?data=' + JSON.stringify(fileContent),
                                        }))
                                    } else {
                                        element.download = this.state.currentUser.first_name.trim() + '_' + this.state.currentUser.last_name.trim() + '.vcf';
                                        document.body.appendChild(element); // Required for this to work in FireFox
                                        element.click();
                                    }
                                }}
                            />
                </Modal>
                : null
            }

        </div>
    }
}

export default ParticipantsTab;
