import React from 'react'
import { Button } from 'rsuite'
import { toast } from 'react-toastify'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { SiWaze, SiGooglemaps } from 'react-icons/si';
import { getUrlFromImage } from '../../../components/Helper';
import moment from "moment-timezone";
import {getWeddingTitle} from "../../../functions";

class GeneralTab extends React.Component {
	state = {
		event_data: this.props.event_data,
		backgroundImage: "",
		eventType: "",
		// showModal: false,
		eventDate: "",
		numeMire: "",
		numeMireasa: "",
		locations: [],

		showFullDescription: false,

		loading: true
	}

	componentDidMount = () => {
		this.setState({
			numeMire: this.props.event_data.prenumeMire,
			numeMireasa: this.props.event_data.prenumeMireasa,
			eventDate: this.props.event_data.dataEvent.split("T")[0],
			eventDescription: this.props.event_data.eventDescription,
			eventType: this.props.event_data.eventType,
			backgroundImage: this.props.event_data.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.props.event_data.backgroundImage)}` : "",
			locations: this.props.event_data.locatii,
		})

		// if (this.props.user_data !== null) {
		// 	if (this.props.response_data === null) {
		// 		this.setState({
		// 			showModal: true
		// 		})
		// 	}
		// } else {
		// 	if (localStorage.getItem("responseSend") !== "yes") {
		// 		this.setState({
		// 			showModal: true
		// 		})
		// 	}
		// }

		this.setState({
			loading: false
		})
	}

	convertMonth = (id) => {
		let months = {
			"01": "Ianuarie",
			"02": "Februarie",
			"03": "Martie",
			"04": "Aprilie",
			"05": "Mai",
			"06": "Iunie",
			"07": "Iulie",
			"08": "August",
			"09": "Septembrie",
			"10": "Octombrie",
			"11": "Noiembrie",
			"12": "Decembrie"
		}

		return months[id];
	}

	toggleModalFinal = () => {
		let current = this.state.showModal;
		toast.success("Multumim pentru raspunsuri!")
		this.setState({
			showModal: !current
		})
	}

	toggleImg = () => {
		let current = this.state.showModal;
		this.setState({
			showModal: !current
		})
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	maps = (x, y) => {
		this.openLink("http://maps.google.com/?q=" + x + "," + y);
	}

	waze = (x, y) => {
		this.openLink("https://www.waze.com/ul?ll=" + x + "%2C" + y + "&navigate=yes&zoom=17");
	}

	render() {
		if (this.state.loading === true) {
			return <div className="GeneralTab-container"><p> Se încarcă...</p></div>
		}

		let locations = this.state.locations.map((locatie, index) => {
			let time = new Date(locatie.time)
			let day = new Date(locatie.time)

			if (locatie.day !== undefined) {
				day = new Date(locatie.day)
			}

			return (
				<div className="Location" key={index}>
					<div className="Type"> {locatie.name} </div>

					<div className="Address"> {locatie.label ? locatie.label : locatie.address} </div>

					<div className="Data-Line">
						<div className="Data"> {moment(time).format('HH:mm')} </div>

						<div className="Data"> {moment(day).format('DD-MM-YYYY')}</div>
					</div>

					{locatie.pinx && locatie.piny ?
					<div className="Data-Line">
						<Button onClick={() => this.waze(locatie.pinx, locatie.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#05AFF2", width: "150px", marginRight: "20px" }}>
							<SiWaze style={{ marginRight: "5px" }} /> Waze
						</Button>

						<Button onClick={() => this.maps(locatie.pinx, locatie.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#19B899", width: "150px" }}>
							<SiGooglemaps style={{ marginRight: "5px" }} /> Google Maps
						</Button>
					</div>:null}
				</div>
			)
		})

		let date = this.state.eventDate.split("-");

		let backgroundImageStyle = {
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundImage: `url(${this.state.backgroundImage})`
		}

		let maxLettersToShow = 70

		return (
			<div className="GeneralTab-container">
				{/* <ImgModal
					showModal={this.state.showModal}
					toggleModal={this.toggleImg}
					toggleModalFinal={this.toggleModalFinal}
					event_id={this.props.event_id}
					event_date={date[2] + " " + this.convertMonth(date[1]) + " " + date[0]}
					user_data={this.props.user_data}
					event_data={this.props.event_data}
					history={this.props.history}
					updateResponse={this.props.updateResponse}
				/> */}

				<div className="Image" style={backgroundImageStyle}></div>

				<div className="Data"> {date[2]} {this.convertMonth(date[1])} {date[0]} </div>

				<div className="Title">{getWeddingTitle(this.state.event_data)}</div>

				<div className={this.state.showFullDescription ? "Full-Description" : "Description"}>
					{this.state.eventDescription.length > maxLettersToShow && !this.state.showFullDescription
						? <div> {this.state.eventDescription.substring(0, maxLettersToShow)}...</div>
						: this.state.eventDescription
					}
				</div>

				<div style={{ alignSelf: "center", color: "black" }}>
					{
						this.state.eventDescription.length > maxLettersToShow
							? <p onClick={() => { this.state.showFullDescription ? this.setState({ showFullDescription: false }) : this.setState({ showFullDescription: true }) }} style={{ fontSize: "11px" }}>
								{this.state.showFullDescription ? "Mai putine detalii " : "Mai multe detalii "}
								{this.state.showFullDescription ? <BsChevronUp /> : <BsChevronDown />}
							</p>
							: <p></p>
					}
				</div>

				{locations}
			</div>
		)
	}
}

export default GeneralTab;
