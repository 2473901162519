import React from 'react'
import './GeneralTab.scss'
import { Button } from 'rsuite'
import Axios from 'axios'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { SiWaze, SiGooglemaps } from 'react-icons/si';
import moment from "moment-timezone";

class GeneralTab extends React.Component {

	state = {
		eventDate: "",
		numeMire: "",
		numeMireasa: "",
		locations: [],
		eventType: "",
		backgroundImage: "",

		showFullDescription: false,

		loading: true
	}

	async componentDidMount() {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}

		try {
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let event_info = await Axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.id, auth);

			this.setState({
				numeMire: event_info.data.prenumeMire,
				numeMireasa: event_info.data.prenumeMireasa,
				eventDate: event_info.data.dataEvent.split("T")[0],
				eventDescription: event_info.data.eventDescription,
				eventType: event_info.data.eventType,
				backgroundImage: event_info.data.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${event_info.data.backgroundImage.url}` : ""
			})
			this.setState({
				locations: event_info.data.locatii,
			})
		}
		catch (e) {
			console.log(e);
		}

		this.setState({
			loading: false
		})
	}

	convertMonth = (id) => {
		let months = {
			"01": "Ianuarie",
			"02": "Februarie",
			"03": "Martie",
			"04": "Aprilie",
			"05": "Mai",
			"06": "Iunie",
			"07": "Iulie",
			"08": "August",
			"09": "Septembrie",
			"10": "Octombrie",
			"11": "Noiembrie",
			"12": "Decembrie"
		}

		return months[id];
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	maps = (x, y) => {
		this.openLink("http://maps.google.com/?q=" + x + "," + y);
	}

	waze = (x, y) => {
		this.openLink("https://www.waze.com/ul?ll=" + x + "%2C" + y + "&navigate=yes&zoom=17");
	}

	render() {
		if (this.state.loading === true) {
			return <div className="GeneralTab-container"><p> Se încarcă...</p></div>
		}

		let date = this.state.eventDate.split("-");

		let locations = this.state.locations.map((locatie, index) => {
			let time = new Date(locatie.time)
			let day = new Date(locatie.time)

			if (locatie.day !== undefined) {
				day = new Date(locatie.day)
			}

			return (
				<div className="Location" key={index}>
					<div className="Type"> {locatie.name} </div>

					<div className="Address"> {locatie.label ? locatie.label : locatie.address} </div>

					<div className="Data-Line">
						<div className="Data"> {moment(time).format('HH:mm')} </div>

						<div className="Data"> {moment(day).format('DD-MM-YYYY')}</div>
					</div>

					{locatie.pinx && locatie.piny ?
						<div className="Data-Line">
							<Button onClick={() => this.waze(locatie.pinx, locatie.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#05AFF2", width: "150px", marginRight: "20px" }}>
								<SiWaze style={{ marginRight: "5px" }} /> Waze
							</Button>

							<Button onClick={() => this.maps(locatie.pinx, locatie.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#19B899", width: "150px" }}>
								<SiGooglemaps style={{ marginRight: "5px" }} /> Google Maps
							</Button>
						</div>:null}
				</div>
			)
		})

		let backgroundImageStyle = {
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'center',
			backgroundImage: this.state.backgroundImage !== undefined ? `url(${this.state.backgroundImage})` : ''
		}

		let maxLettersToShow = 70

		return (
			<div className="GeneralTab-container">
				<div className="Image" style={backgroundImageStyle}></div>

				<div className="Data"> {date[2]} {this.convertMonth(date[1])} {date[0]} </div>

				{this.state.eventType === "Nunta" &&
					<div className="Title"> {this.state.numeMireasa} si {this.state.numeMire} </div>
				}

				{this.state.eventType === "Botez" &&
					<div className="Title"> Botez {this.state.numeMire} </div>
				}

				{this.state.eventType === "Petrecere" &&
					<div className="Title"> Aniversare {this.state.numeMire} </div>
				}

				<div className={this.state.showFullDescription ? "Full-Description" : "Description"}>
					{this.state.eventDescription.length > maxLettersToShow && !this.state.showFullDescription
						? <div> {this.state.eventDescription.substring(0, maxLettersToShow)}...</div>
						: this.state.eventDescription
					}
				</div>

				<div style={{ alignSelf: "center", color: "black" }}>
					{
						this.state.eventDescription.length > maxLettersToShow
							? <p onClick={() => { this.state.showFullDescription ? this.setState({ showFullDescription: false }) : this.setState({ showFullDescription: true }) }} style={{ fontSize: "11px" }}>
								{this.state.showFullDescription ? "Mai putine detalii " : "Mai multe detalii "}
								{this.state.showFullDescription ? <BsChevronUp /> : <BsChevronDown />}
							</p>
							: <p></p>
					}
				</div>

				{locations}
			</div>
		)
	}
}

export default GeneralTab;
