import React from 'react'
import { Button } from 'rsuite'
import './UploadExcelResponses.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import { toast } from 'react-toastify'
import axios from 'axios'
import Loader from "../../components/Loader";
import NavbarColor from "../../../components/NavbarColor";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/react";


class UploadExcelResponses extends React.Component {
    constructor(props) {
        super(props)
        this.fileInput = React.createRef()
    }
    state = {
        event_data: {},
        uploadedFile:null,

        loading: true,
        uploadLoading: false
    }

    componentDidMount = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let response = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

        this.setState({
            event_data: response.data,
            loading: false
        })
    }

    uploadFile = async () => {

        this.setState({
            uploadLoading: true
        })


        let auth = {
            headers: {
                "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let formData = new FormData()
        formData.append("file", this.state.uploadedFile)

        axios.post(process.env.REACT_APP_DOMAIN + 'responses/importExcelResponses/' + this.props.match.params.id, formData, auth)
            .then(response => {
                toast.success('Participanții au fost încărcați!')
                this.goBack()
            })
            .catch(err => {
                toast.error("A apărut o eroare. Vă rugăm să verificați fișierul și să reîncercați!")
                this.setState({
                    uploadLoading: false,
                    uploadedFile: null
                })
            })
    }

    downloadDemoFile = async () => {
        fetch('/assets/excel/exemplu_import_ality.xlsx', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    `exemplu_import_ality.xlsx`,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);
            });
    }

    goBack = () => {
        this.props.history.go(-1)
    }

    render() {
        if (this.state.loading) {
            return <div className="UploadExcelResponses-container">
                <HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

                <Loader/>

                <NavbarColor history={this.props.history} />
            </div>
        }

        if (this.state.uploadLoading) {
            return <div className="UploadExcelResponses-container">
                <HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

                <div className={"loader-cont"}>
                    <p style={{marginBottom:'30px'}}>Procesăm fișierul. Vă rugăm așteptați...</p>
                    <BeatLoader color={"#00B7AD"} loading={true} css={css} size={30} />
                </div>

                <NavbarColor history={this.props.history} />
            </div>
        }


        return (
            <div className="UploadExcelResponses-container">
                <HeaderWhite goBack={this.goBack} />


                <div className="UploadExcelResponses-contents">

                    <br/>
                    <h3>Încarcă participanții din Excel</h3>

                    <br/>
                    <p>Te rugăm să descarci fișierul model de mai jos și să îl completezi păstrând formatul acestuia.</p>
                    <Button
                        onClick={() => this.downloadDemoFile()}
                        style={{ marginTop: 10, marginBottom: 20, borderRadius: "80px", backgroundColor: "#00afcc", color: "white" }}
                        color="green">Descarcă fișier model</Button>

                    <br/>
                    <p>După ce îl completezi, îl poți încărca folosind butonul următor.</p>

                    <input ref={this.fileInput}
                            multiple={false}
                           accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                           type="file" style={{ display: 'none' }}
                           onChange={(ev) => {
                               this.setState({
                                   uploadedFile: ev.target.files[0]
                               }, () => {
                                   this.uploadFile()
                               })

                           }}
                    />

                        <Button
                            onClick={() => this.fileInput.current.click()}
                            style={{ marginTop: 10, marginBottom: 20, borderRadius: "80px", backgroundColor: "#05aff2", color: "white" }}
                            color="green">Încarcă fișierul completat</Button>

                </div>

                <NavbarColor history={this.props.history} />
            </div>
        )
    }
}

export default UploadExcelResponses;