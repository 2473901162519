import React, {useState} from 'react'
import './LocationsContainerStyleComp.scss'
import {Icon} from "rsuite";

export default function LocationsContainerStyleComp({styles, setStyles, borderBottomRadius = false}) {

    const [showSection, setShowSection] = useState('')


    const fonts = [
        {
            title: 'Dancing Script',
            class: 'style-font-dancing-script'
        },
        {
            title: 'Mynerve',
            class: 'style-font-mynerve'
        },
        {
            title: 'Kaushan',
            class: 'style-font-kaushan'
        },
        {
            title: 'Merienda',
            class: 'style-font-merienda'
        },
        {
            title: 'Pacifico',
            class: 'style-font-pacifico'
        },
        {
            title: 'Caveat',
            class: 'style-font-caveat'
        },
        {
            title: 'Allura',
            class: 'style-font-allura'
        },
        {
            title: 'Allison',
            class: 'style-font-allison'
        },
        {
            title: 'Karla',
            class: 'style-font-karla'
        },
        {
            title: 'Marhey',
            class: 'style-font-marhey'
        },
        {
            title: 'Style Script',
            class: 'style-font-style-script'
        },
        {
            title: 'Arial',
            class: 'style-font-arial'
        },
        {
            title: 'Verdana',
            class: 'style-font-verdana'
        },
    ]


    const toggleSection = (section) => {

        if (showSection === section) {
            setShowSection('')
        } else {
            setShowSection(section)
        }

    }

    let currentFont = styles.font ? styles.font : 'style-font-dancing-script'
    let currentFontColor = styles.color ? styles.color : ''
    let currentBorderColor = styles.borderColor ? styles.borderColor : ''

    return (
        <div className={"locations-container-style-comp " + (borderBottomRadius ? 'border-bottom-radius' : '')}>

            <div className={"controls"}>
                <div className={"text-style-col " + (showSection === 'font' ? 'selected' : '')}
                     onClick={() => toggleSection('font')}>
                    <div><Icon icon="font"/></div>
                </div>

                <div className={"text-style-col " + (showSection === 'color' ? 'selected' : '')}
                     onClick={() => toggleSection('color')}>
                    <div className={"text-style-col-title"}>Font</div>
                    <div className={"text-style-color-swatch"}
                         style={{background: currentFontColor ? currentFontColor : '#000000'}}
                    ></div>
                </div>


                <div className={"text-style-col " + (showSection === 'borderColor' ? 'selected' : '')}
                     onClick={() => toggleSection('borderColor')}>
                    <div className={"text-style-col-title"}>Bordură</div>
                    <div className={"text-style-color-swatch"}
                         style={{background: currentBorderColor ? currentBorderColor : '#000000'}}
                    ></div>
                </div>

            </div>

            <div className={"sections"}>
                {/* sections */}
                {
                    showSection === 'font' ?
                        <div className={"selector"}>

                            {
                                fonts.map((font, index) => (
                                    <div
                                        key={index}
                                        className={"selector-item " + (currentFont === font.class ? 'selected' : '')}
                                        onClick={() => setStyles({...styles, font: font.class})}
                                    >
                                        <span className={font.class}>{font.title}</span>
                                    </div>
                                ))
                            }

                        </div>
                        : null
                }


                {
                    showSection === 'color' ?
                        <div className={"selector"}>
                            <input
                                style={{width: '100%', height: '40px', border: 0}}
                                value={currentFontColor}
                                type={"color"} onChange={(e) => setStyles({...styles, color: e.target.value})}/>
                        </div>
                        : null
                }

                {
                    showSection === 'borderColor' ?
                        <div className={"selector"}>
                            <input
                                style={{width: '100%', height: '40px', border: 0}}
                                value={currentBorderColor}
                                type={"color"} onChange={(e) => setStyles({...styles, borderColor: e.target.value})}/>
                        </div>
                        : null
                }

            </div>

        </div>
    )

}
