import React, {useState, useEffect} from 'react'
import './Testimonials.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import Modal from "react-responsive-modal";
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {useHistory, useParams} from "react-router-dom";
import SettingCard from "../../mainPages/Profile/components/SettingCard";


export default function TestimonialsListItems() {

    let history = useHistory()
    let {id} = useParams()

    const [items, setItems] = useState(false)
    const [previewItem, setPreviewItem] = useState(null)

    useEffect(() => {
        getItems()
        // eslint-disable-next-line
    }, [])

    const getItems = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id
        }

        let info = await axios.post(process.env.REACT_APP_DOMAIN + 'memory-booth-files/get-wedding-files',payload, auth);

        if(info.data && info.data.length > 0) {
            setItems(info.data)
        }

    }

    const goBack = () => {
        history.goBack()
    }


    return (
        <div className="ts-config-container">
            <HeaderWhite goBack={goBack}/>

            <div className={"ts-config-inner"}>

                {
                    items && items.length > 0 && items.map((item,i) => (
                        <SettingCard
                            key={i}
                            icon=""
                            onClick={() => {
                                setPreviewItem(item)
                            }}
                            name={item.name}
                        />
                    ))
                }

            </div>

            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal',
                closeButton: 'modal-close-button',
            }} open={previewItem !== null}
                   onClose={() => {setPreviewItem(null)}}
                   onEscKeyDown={() => {setPreviewItem(null)}}
                   onOverlayClick={() => {setPreviewItem(null)}}>

                {
                    previewItem !== null ?
                        <div className={"mb-modal-container"}>

                            Nume: {previewItem.name}<br/>
                            Email: {previewItem.email}<br/>

                            <br/>

                            <video
                                src={previewItem.url}
                                width={'100%'}
                                height={250}
                                autoPlay={true}
                                loop={true}
                                muted={false}
                            />
                        </div>
                        : null
                }
            </Modal>

            <NavbarColor history={history}/>
        </div>
    )

}
