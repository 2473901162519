import React from 'react'
import './AdModal.scss'
import Modal from 'react-responsive-modal'
import { Button } from 'rsuite';

let waitTime = 3000;

class AdModal extends React.Component {
	state = {
		canClose: false
	}

	componentDidMount = () => {
		this.timer = setTimeout(
			() => this.setState({ canClose: true }),
			waitTime
		);
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	fileClicked = () => {
		let link = this.props.adData.link
		if (link === "" || link === undefined || link === null) {
			return
		}

		this.openLink(this.props.adData.link)
	}

	render() {
		if (this.props.adData === null && this.props.showModal) {
			return <div className="AdModal-Container">
				<Modal
					classNames={{
						overlay: 'modal-overlay z3000',
						modal: 'modal size',
						closeButton: 'modal-close-button',
					}}
					open={this.props.showModal}
					onClose={this.props.closeModal}
					showCloseIcon={this.state.canClose}
					focusTrapped={false}
					closeOnOverlayClick={this.state.canClose}
					closeOnEsc={this.state.canClose}
				>
					<p></p>
				</Modal>
			</div>
		}

		let body = <div>
			<h3 style={{wordBreak: "break-all"}}>{this.props.adData.title}</h3>
			<img style={{ width: "100%", height: "100%", aspectRatio: 135 / 76 }} src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + '' + this.props.adData.image} onClick={this.fileClicked} alt="adImage" />
		</div>

		if (this.props.adData.fileType === "video") {
			body = <div style={{ height: "80vh", width: "100%" }}>
				<h3 style={{wordBreak: "break-all"}}>{this.props.adData.title}</h3>
				{this.props.showModal && <video style={{ width: "100%", height: "100%", aspectRatio: 135 / 76 }} src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + '' + this.props.adData.video} autoPlay={true} muted={true} loop={true} onClick={this.fileClicked} />}
			</div>
		}

		return (
			<div className="AdModal-Container">
				<Modal
					classNames={{
						overlay: 'modal-overlay z3000',
						modal: 'modal size',
						closeButton: 'modal-close-button',
					}}
					open={this.props.showModal}
					onClose={this.props.closeModal}
					showCloseIcon={false}
					focusTrapped={false}
					closeOnOverlayClick={this.state.canClose}
					closeOnEsc={this.state.canClose}
				>
					{body}

					{this.state.canClose && <Button style={{marginTop: "10px"}} color="red" appearance="ghost" onClick={this.props.closeModal}>Close</Button>}
				</Modal>
			</div>
		)
	}
}

export default AdModal;