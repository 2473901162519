import React from 'react'
import './AuctionTab.scss'
import {Button, InputNumber} from "rsuite";
import Modal from "react-responsive-modal";
import axios from "axios";
import {toast} from "react-toastify";
import Loader from "../../../components/Loader";


class AuctionTab extends React.Component {
    state = {
        items: [],
        auctionItem: false,
        showModal: false,
        initialLoading: true,

        bidValue: '',
        bidLoading: false
    }

    interval = null;



    componentDidMount = async () => {

        let preselectedAuctionItemId = 0
        if(this.props.urlParams) {
            if(this.props.urlParams['auction_item_id']) {
                preselectedAuctionItemId = parseInt(this.props.urlParams['auction_item_id'])
            }
            if(this.props.urlParams['?auction_item_id']) {
                preselectedAuctionItemId = parseInt(this.props.urlParams['?auction_item_id'])
            }
        }


        this.getAuctionItems(preselectedAuctionItemId)

        this.interval = setInterval(
            () => this.getAuctionItems(),
            5000
        )
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval)
        }
    }

    async getAuctionItems(preselectedAuctionItemId = 0) {

        let auth = {}

        if (localStorage.getItem('jwt')) {
            auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }
        }

        let payload = {
            id_eveniment: this.props.event_data.id_eveniment
        }

        axios.post(process.env.REACT_APP_DOMAIN + 'auction-items/getAuctionForEvent', payload, auth)
            .then(response => {

                this.setState({
                    initialLoading: false,
                    items: response.data.auction_items
                })

                if(preselectedAuctionItemId > 0) {
                    let selectedItem = response.data.auction_items.find(item => item.id === preselectedAuctionItemId)
                    if(selectedItem) {
                        this.setState({
                            auctionItem: selectedItem,
                            showModal:true
                        })
                    }
                }

                if(this.state.auctionItem) {
                    let selectedItem = response.data.auction_items.find(item => item.id === this.state.auctionItem.id)
                    if(selectedItem) {
                        this.setState({
                            auctionItem: selectedItem
                        })
                    } else {
                        this.setState({
                            auctionItem: false,
                            showModal: false,
                            bidValue: ''
                        })
                    }
                }
            })

    }

    async placeBid() {

        if (this.state.bidLoading) {
            return
        }

        let currentBid = 0

        if (this.state.auctionItem.highestBid) {
            currentBid = this.state.auctionItem.highestBid.value
        } else {
            if (this.state.auctionItem.starting_price) {
                currentBid = this.state.auctionItem.starting_price
            }
        }


        if (this.state.bidValue <= currentBid) {
            toast.error("Valoarea ofertată trebuie să fie mai mare decât oferta curentă")
            return
        }

        this.setState({
            bidLoading: true
        })

        let obj = {
            id: this.state.auctionItem.id,
            bid: parseInt(this.state.bidValue + "")
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'auction-items/placeBid', obj, auth)
            .then((response) => {

                this.setState({
                    bidLoading: false
                })

                if (response.data.status === 1) {
                    toast.success("Oferta a fost plasată!")
                } else if (response.data.status === 2) {
                    toast.error("Valoarea ofertată trebuie să fie mai mare decât oferta curentă")
                    return
                } else {
                    toast.error("S-a produs o erorare. Vă rugăm reîncercați")
                    return
                }

                this.getAuctionItems()


                this.setState({
                    bidValue: ''
                })

            })
            .catch(_ => {
                toast.error("S-a produs o erorare. Vă rugăm reîncercați")
                this.setState({
                    bidLoading: false
                })
            })

    }

    openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    maps = (x, y) => {
        this.openLink("http://maps.google.com/?q=" + x + "," + y);
    }

    waze = (x, y) => {
        this.openLink("https://www.waze.com/ul?ll=" + x + "%2C" + y + "&navigate=yes&zoom=17");
    }

    render() {

        return (
            <div className="AuctionTab-container">

                {this.state.initialLoading ? <Loader/>
                    :
                    <div className="AuctionTab-Feed">

                        {!this.state.items || this.state.items.length === 0 ?
                            <p style={{marginTop: "20px"}}>Nu există articole la această licitație.</p>
                            : null}

                        {
                            this.state.items && this.state.items.map((item, i) => {

                                let hasImage = item.image1 !== null && item.image1 !== undefined

                                let backgroundImageStyle = {
                                    backgroundRepeat: 'no-repeat',
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundImage: hasImage ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${item.image1.url})` : ''
                                }

                                return <div key={i}
                                            className={"Events-Card-Container " + (item.alreadyRedeemed ? 'redeemed' : '')}
                                            style={backgroundImageStyle} onClick={() => {
                                    this.setState({
                                        auctionItem: item,
                                        showModal: true
                                    })
                                }}>
                                    {hasImage && <div className="Picture"></div>}

                                    {item.highestBid ?
                                        <div className={"auction-item-price"}>
                                            {item.highestBid.value} RON
                                        </div>
                                        :
                                        item.starting_price ?
                                            <div className={"auction-item-price"}>
                                                {item.starting_price} RON
                                            </div>
                                            :
                                            null
                                    }


                                    {<div className="Open-Event">Click aici pentru a licita</div>}

                                    <div className="Title">
                                        <div className="Text font-family-poppins-700-bold"
                                             style={{marginBottom: "5px"}}>{item.title}</div>
                                    </div>
                                </div>
                            })
                        }

                    </div>}

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}

                    open={this.state.showModal}
                    onClose={() => {
                        this.setState({
                            auctionItem: false,
                            showModal: false
                        })
                    }}
                    focusTrapped={false}
                >
                    <div style={{marginTop: "20px"}}>

                        {
                            this.props.participantData ?
                                <>

                                    {this.state.auctionItem.title} <br/>
                                    {this.state.auctionItem.description}<br/>


                                    <br/>
                                    <div style={{textAlign:"center", marginBottom:"10px"}}>
                                    {this.state.auctionItem.highestBid ?
                                        <p>
                                            Oferta actuală<br/><strong style={{fontSize:'1.3rem'}}>{this.state.auctionItem.highestBid.value} RON</strong>
                                        </p>
                                        :
                                        this.state.auctionItem.starting_price ?
                                            <p>
                                                Oferta actuală<br/><strong style={{fontSize:'1.3rem'}}>{this.state.auctionItem.starting_price} RON</strong>
                                            </p>
                                            :
                                            null
                                    }</div>

                                    {this.props.participantData && this.state.auctionItem.highestBid ?
                                        <>
                                            {this.state.auctionItem.highestBid.participant === this.props.participantData.id ?
                                                <div className={"auction-item-modal-highest-offer-notice"}>Oferta dumneavoastră este cea mai mare</div>
                                                : null}
                                        </>

                                        : null
                                    }

                                    <InputNumber value={this.state.bidValue}
                                                 onChange={value => this.setState({bidValue: value > 0 ? parseInt(value) : ''})}
                                                 placeholder={"Introdu oferta ta"}
                                                 step={1}
                                                 style={{
                                                     backgroundColor: "#E5F7FE",
                                                     borderRadius: "10px",
                                                     width: "100%",
                                                     marginBottom: "15px",
                                                     marginTop: "10px",
                                                     borderColor: "#ccc",
                                                     overflow:"hidden"
                                                 }}
                                                 type={"number"}/>

                                    <Button onClick={() => this.placeBid()} color="green"
                                            style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%"}}
                                    >Licitează</Button>


                                    {this.state.auctionItem.image1 || this.state.auctionItem.image2 || this.state.auctionItem.image3 ? <div style={{marginTop:"25px"}}>
                                        Fotografii
                                        </div>:null}

                                    {this.state.auctionItem.image1 && this.state.auctionItem.image1.url ?
                                        <div style={{textAlign:"center", marginBottom:"10px"}}>
                                            <img className={"auction-item-modal-image"}
                                                 src={"https://" + process.env.REACT_APP_IMAGE_DOMAIN + this.state.auctionItem.image1.url}
                                                 alt={""}/> </div> : null}

                                    {this.state.auctionItem.image2 && this.state.auctionItem.image2.url ?
                                        <div style={{textAlign:"center", marginBottom:"10px"}}><img className={"auction-item-modal-image"}
                                             src={"https://" + process.env.REACT_APP_IMAGE_DOMAIN + this.state.auctionItem.image2.url}
                                                                               alt={""}/></div> : null}

                                    {this.state.auctionItem.image3 && this.state.auctionItem.image3.url ?
                                        <div style={{textAlign:"center"}}><img className={"auction-item-modal-image"}
                                             src={"https://" + process.env.REACT_APP_IMAGE_DOMAIN + this.state.auctionItem.image3.url}
                                                                               alt={""}/></div> : null}
                                </>
                                :
                                <>
                                    <br/>
                                    Pentru a participa la licitație trebuie să vă autentificați.
                                    <br/>

                                    <Button onClick={() => {
                                        let redirectBackUrl = window.location.pathname
                                        redirectBackUrl = redirectBackUrl.substring(1).replace('public/', '')

                                        this.props.history.push('/login?redirectBackTo=' + redirectBackUrl);
                                    }} style={{
                                        width: "100%",
                                        marginTop: '20px',
                                        borderRadius: "20px",
                                        backgroundColor: "#05AFF2",
                                        color: "white"
                                    }}>
                                        Autentificare
                                    </Button>
                                    <br/>
                                </>
                        }
                    </div>
                </Modal>

            </div>
        )
    }
}

export default AuctionTab;
