import React from 'react'
import './TestimonialsTab.scss'
import {Button} from "rsuite";

export default function TestimonialsTab({event_id, user_data}) {

    const recordVideo = () => {

        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('testimonial', 'start', user_data.first_name + ' ' + user_data.last_name, user_data.email, event_id).then(res => {
                if (res === "NULL") {
                    console.log('null')
                    return;
                }
            })
        } else {
            //const imageSrc = this.state.webcamRef.current.getScreenshot();
        }
    }

        return (
            <div className="TestimonialsTab-container">
                <div className="TestimonialsTab-content">


                    <br/><br/>

                    <Button
                        style={{ marginTop: "20px" }}
                        size="lg"
                        appearance="primary"
                        onClick={() => recordVideo()}> Start </Button>


                </div>
            </div>
        )

}