import React from 'react'
import './MainInvite2.scss'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../../components/custom/CustomInput'
import { toast } from 'react-toastify'
import {
	BackAndConfirmButtons,
	ConfirmButton,
	getUrlFromImage, PlayAudio,
	PlayVideo
} from '../../../components/Helper'
import Loader from "../../../components/Loader";
import {getAudioLink, getWeddingParticipantsPackageNumber} from "../../../functions";
import moment from "moment-timezone";
import {Icon} from "rsuite";

class MainInviteW extends React.Component {
	state = {
		saveTheDateType: "",
		event: {},
		showModal: false,
		contents: [],
		backgroundImageLink: "",
		isUsingAudio: false,
		serverAudioUrl: null,

		loading: true,
		raspuns: "",
		prenume: "",
		nume: "",
		insotit: false,

		noPackage: false,
		showDemoInviteModal: false
	}

	componentDidMount = async () => {
		document.body.classList.add('body-scroll')
		this.setSaveTheDateSentDate()


		axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id)
			.then(async response => {    let audioLink = await getAudioLink(response.data.invite);

				if (audioLink && audioLink !== ''){
					this.setState({
						serverAudioUrl: audioLink,
						isUsingAudio: true
					})
				}

				let packageNumber = getWeddingParticipantsPackageNumber(response.data)
				this.setState({
					event: response.data,
					contents: response.data.saveTheDate,
					saveTheDateType: response.data.saveTheDate.saveTheDateType,
					loading: false,
					backgroundImageLink: response.data.saveTheDate.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(response.data.saveTheDate.backgroundImage)}` : "",
					noPackage: packageNumber < 1,
					showDemoInviteModal: packageNumber < 1
				})
			})
	}

	componentWillUnmount() {
		document.body.classList.remove('body-scroll')
	}

	setSaveTheDateSentDate = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			id_eveniment: this.props.match.params.id
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/setSaveTheDateSentDate',
			obj,
			auth)

	}

	openModal = (ans) => {
		this.setState({
			showModal: true,
			raspuns: ans
		})
	}

	closeModal = () => {
		this.setState({
			showModal: false
		})
	}

	createInvite = () => {
		if (this.state.prenume === "") {
			toast.error("Va rog introduceti prenumele invitatului")
			return
		}

		let ans = "yes"
		if (this.state.raspuns === "Nu particip") {
			ans = "no"
		}
		if (this.state.raspuns === "Nu stiu daca particip") {
			ans = "don't know"
		}

		var obj = {
			raspuns: ans,
			insotit: this.state.insotit,
			nume: this.state.nume,
			prenume: this.state.prenume,
			event: this.state.event.id
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/create', obj)
			.then(response => {
				toast.success("Răspuns trimis! Vă mulțumim!")

				this.props.history.push('/events');
			})
	}

	render() {
		if (this.state.loading) {
			return <Loader/>
		}

		if (this.state.loading === true)
			return <p> Loading.. </p>

		let style = {
			backgroundRepeat: "no-repeat",
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundImage: 'url(' + this.state.backgroundImageLink + ')',
			backgroundColor: "rgba(0, 0, 0, 0.5)",
			backgroundBlendMode: 'darken'
		}

		let customStyle = {
			color: this.state.contents.fontColor,
			fontFamily: this.state.contents.fontFamily,
			fontSize: this.state.contents.fontSize
		}

		let date = this.state.contents.dataEvent.split('T')[0].split("-")

		let body = <div></div>

		if (this.state.saveTheDateType === "Personal") {
			body = <div style={{ marginTop: "0px", display:'flex', flex:'1', overflow:'hidden', alignItems:'flex-start', flexDirection:'column'}}>

				<div style={{display:'flex', height:'50px', width:'100%', maxHeight:'50px', justifyContent:'center', padding:'10px'}}>
					<img  alt={""} src={require('../../../assets/logo.png')} style={{height:'100%'}} onClick={() => this.props.history.push('/')}/>
				</div>
				<div style={{display:'flex', flex:'1', overflow:'hidden', height:'60vh', maxHeight:'60vh', width:'100%'}}>
				<img src={this.state.backgroundImageLink} alt={"Invitatie personala"} style={{ width: "100%", objectFit:'contain' }} />
				</div>
				{this.state.isUsingAudio && <PlayAudio
				link={this.state.serverAudioUrl}/>}
			</div>
		}

		if (this.state.saveTheDateType === "Video") {
			let linkParts = this.state.contents.linkVideo.split("watch?v=")
			let link = ""
			if (linkParts.length === 1) {
				link = linkParts[0]
			} else {
				link = linkParts[0] + "embed/" + linkParts[1]
			}

			body = <div className="MainInvite-contents" style={customStyle}>
				{/* <iframe src={link.split("&")[0]}
					frameBorder='0'
					allow='autoplay; encrypted-media'
					allowFullScreen={true}
					title='video'
					style={{ width: "80vw", height: "500px", margin: "20px auto" }}
				/> */}
				<PlayVideo link={link.split("&")[0]} style={{ width: "80vw", height: "500px", margin: "20px auto" }} />
			</div>
		}

		if (this.state.saveTheDateType === "Custom") {
			body = <div className="PreviewSaveTheDate-container" style={style} >
				<div className="PreviewSaveTheDate-contents" style={customStyle}>
					<div style={{ fontSize: `${Number(this.state.contents.fontSize.split("px")[0]) + 5}px`, }}> Save The Date </div>

					<div>
						<div> {this.state.event.prenumeMireasa} & {this.state.event.prenumeMire} </div>

						<div> {date[2] + "-" + date[1] + "-" + date[0]} - {this.state.contents.localitate} </div>
					</div>

					<div className="description"> {this.state.contents.description}</div>
					{this.state.isUsingAudio && <PlayAudio
						link={this.state.serverAudioUrl}/>}
				</div>
			</div>
		}

		if(this.state.noPackage && this.state.event.savethedate_sent_date &&
			(moment().diff(moment(this.state.event.savethedate_sent_date), 'hours') >= 48)) {

			return (
				<div className={"SaveTheDate-outer"}>
					<h6 style={{marginTop:'4rem'}}>Ne pare rău, invitația numai poate fi accesata, au trecut cele 48 de ore de la prima accesare. Dacă totuși doriți sa mai testați anumite informații care apar pe invitație vă rugăm să accesați secțiunea “Previzualizare”
						<br/><br/>Pentru a trimite invitația către invitați este necesar sa achiziționați unul din pachetele disponibile în aplicație.
					</h6>

					{
						window.alityAppView ?
							<div className={"invite-mobile-back-button"}>
								<Icon onClick={() => this.props.history.push('/')} icon="angle-left"
									  className={"back-icon"}/>
							</div>
							: null
					}

					<ConfirmButton
						text={'Vezi pachete'}
						function={() => this.props.history.push('/organizer/package/' + this.props.match.params.id)}
					/>
				</div>
			)
		}

		return (
			<div className={"SaveTheDate-outer"}>
			<div className="SaveTheDate" style={this.props.disableAction ? {display:'block', height:'auto'} : {}}>

				{
					window.alityAppView ?
						<div className={"invite-mobile-back-button"}>
							<Icon onClick={() => this.props.history.push('/')} icon="angle-left"
								  className={"back-icon"}/>
						</div>
						: null
				}

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.showDemoInviteModal}
					focusTrapped={false}
					onClose={() => {
						this.setState({
							showDemoInviteModal: false
						})
					}}
				>

					<div style={{marginTop:'2.5rem', marginBottom:'1rem'}}>
						<h6>Vizualizarea acestei invitații este posibilă timp de 48 de ore de la prima accesare și vă rugăm să o folosiți pentru a testa informațiile prezente pe invitație: numele persoanelor, numele și adresa locațiilor, întrebările personalizate. De asemenea puteți testa și modul de distribuire al invitaților dar și modul de primire a răspunsurilor.
							<br/><br/>Dacă toate detaliile sunt corecte și doriți sa distribuiti invitațiile online prin intermediul aplicației Ality, trebuie să achiziționați unul din pachetele disponibile în aplicație.</h6>
					</div>

					<ConfirmButton
						text={'Vezi invitația'}
						function={() => this.setState({showDemoInviteModal:false})}
					/>
				</Modal>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal SaveTheDateModal',
					closeButton: 'modal-close-button',
				}} open={this.state.showModal} onClose={this.closeModal}>
					<div style={{ marginTop: "20px" }}>
						<div> Răspuns dat: <strong>{this.state.raspuns.toLowerCase()}</strong><br/><br/> Vă rugăm să vă introduceți numele dumneavoastră pentru a trimite răspuns.</div>

						<CustomInput value={this.state.prenume} onChange={value => this.setState({ prenume: value })} placeholder={"Prenume, Nume"} />


						{
							this.state.raspuns.toLowerCase() === 'particip' ?
								<div className="data-row">
									<div className={!this.state.insotit ? "active-option" : "inactive-option"}
										 onClick={_ => this.setState({insotit: false})}>
										Vin singur/ă
									</div>

									<div className={this.state.insotit ? "active-option" : "inactive-option"}
										 onClick={_ => this.setState({insotit: true})}>
										Vin insoțit/ă
									</div>
								</div>
								:
								null
						}

						<BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Trimite"} functionBack={this.closeModal} functionConfirm={this.createInvite} />
					</div>
				</Modal>

				{body}

				{!this.props.disableAction &&
				<div className="Answer">
					<p style={{ fontSize: "20px" }}>Doriți să participați?</p>

					<div className="Options">
						<div className="Yes" onClick={() => this.openModal("Particip")}>Particip</div>

						<div className="No" onClick={() => this.openModal("Nu particip")}>Nu Particip</div>

						<div className="NotSure" onClick={() => this.openModal("Nu stiu daca particip")}>Nu știu</div>
					</div>
				</div>}
			</div>
			</div>
		)
	}
}

export default MainInviteW;
