import React, {useEffect, useState} from "react";
import logo from "../assets/logo.png";
import {Button} from "rsuite";
import Modal from "react-responsive-modal";

export default function MobileModal() {
    const [mobileModalVisible, setMobileModalVisible] = useState(false)
    const [operatingSystem, setOperatingSystem] = useState('')

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    const init = async () => {
        setTimeout(() => {
            const os = getMobileOperatingSystem()

            if(window.location.href && (window.location.href.includes('profile') || window.location.href.includes('invite') ||window.location.href.includes('invitation') || window.location.href.includes('saveTheDate'))) {
                return
            }

            if(window.flutter_inappwebview || window.alityAppView ) {
                return
            }

            if(os === 'iOS' || os === 'Android') {
                setOperatingSystem(getMobileOperatingSystem())
                setMobileModalVisible(true)
            }

        }, 4000)
    }

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

    return (
        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-profile',
                closeButton: 'modal-close-button',
            }}
            styles={{
                overlay: {zIndex: '1000000000000000'}
            }}
            open={mobileModalVisible}
            onClose={() => setMobileModalVisible(false)}
            focusTrapped={false}
        >
            <div>

                <img src={logo} style={{ height: "50px" }} alt="logo" />
                <br/><br/><br/>
                <h6>Pentru a fi la curent cu modificările de ultim moment ale evenimentelor la care participați, pentru o utilizare mai ușoară și a beneficia de toate funcționalitățile, vă rugăm să descărcați aplicația Ality.</h6>
                <br/>
                {
                    operatingSystem === 'iOS' ?
                        <a href={"https://apps.apple.com/ro/app/ality/id1575378627"}>
                            <Button color="green" style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: '30px'
                            }}>Descarcă aplicația</Button>
                        </a>
                        :
                        <a href={"https://play.google.com/store/apps/details?id=com.creative.ality"}>
                            <Button color="green" style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: '30px'
                            }}>Descarcă aplicația</Button>
                        </a>
                }
            </div>
        </Modal>
    )
}
