import io from 'socket.io-client';
import React from 'react'
import './FeedTab.scss'
import { Input, Icon, Button, Uploader } from 'rsuite'
import Modal from "react-responsive-modal";
import axios from 'axios'
import fileDownload from 'js-file-download'
import FeedBox from './FeedComponents/FeedBox';
import { toast } from 'react-toastify';
import AdModal from '../../components/AdModal';
import {getBase64FromImage} from "../../components/Helper";
import Loader from "../../components/Loader";

let adFieldName = "feed"

var b64toBlobAndBack = require('based-blob');

let socket = "";

class FeedTab extends React.Component {
	state = {
		attachedImg: null,
		text: "",
		posts: [],
		loading: true,

		showImage: false,
		imageToShow: 0,
		inAppWebView: false,

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}

	componentDidMount = async () => {
		let id = this.props.user_data.id
		let room = this.props.event_data.id_eveniment

		socket = io(`https://${process.env.REACT_APP_IMAGE_DOMAIN}`);

		socket.emit('join', { id, room }, (error) => {
			if (error) {
				alert(error);
			} else {
				alert('success join')
			}
		});

		socket.on('message', (message, error) => {
			let newPost = {}
			newPost.id = message.id
			newPost.user = message.user
			newPost.first_name = message.user.first_name
			newPost.last_name = message.user.last_name
			newPost.link_imagine = message.link_imagine
			newPost.mesaj = message.mesaj
			newPost.timestamp = message.timestamp

			this.setState({
				posts: [...this.state.posts, newPost]
			})
		});

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//Reload messages
		let response = await axios.post(process.env.REACT_APP_DOMAIN + "getMessagesForEvent", { id_eveniment: room }, auth)
		if (response !== null) {
			this.setState({
				posts: response.data
			})
		}

		//in case user didn't buy ticket in current data check server data
		if (!this.props.isTicketPaid) {
			this.props.reloadEventData()
		}

		this.setState({
			loading: false,
			inAppWebView: window.flutter_inappwebview
		})

		if (this.props.participantData !== null && this.props.event_data.messages.length !== Number(this.props.participantData.last_feed_count)) {
			//update local data
			this.props.participantData.last_feed_count = "" + this.props.event_data.messages.length

			//update server data
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let obj = {
				last_feed_count: this.props.participantData.last_feed_count,
			}

			axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
		}
	}

	componentWillUnmount = () => {
		if (this.props.participantData !== null && this.props.event_data.messages.length !== Number(this.props.participantData.last_feed_count)) {
			//update local data
			this.props.participantData.last_feed_count = "" + this.props.event_data.messages.length

			//update server data
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let obj = {
				last_feed_count: this.props.participantData.last_feed_count,
			}

			axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
		}

		socket.close()
	}

	changeMessage = (value) => {
		this.setState({
			text: value
		})
	}

	uploadToS3 = async () => {
		/*
		var obj = new FormData()
		obj.append("image", this.state.attachedImg)
		obj.append("nume_institutie", this.props.event_data.title)
		obj.append("promotie", this.props.event_data.promotie)
		 */

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
				//'Content-Type': 'multipart/form-data'
			}
		}

		let obj = {
			imageLink: await getBase64FromImage(this.state.previewImg[0]),
			nume_institutie: this.state.event_name,
			promotie: new Date().getFullYear()
		}

		axios.post(process.env.REACT_APP_DOMAIN + "uploadFeedImage", obj, auth)
			.then(res => {
			this.sendFeedWithLink(res.data.Location);
		})
	}

	sendFeedWithLink = async (link) => {
		let message = this.state.text

		if(message.length === 0 && link.length === 0) {
			return
		}

		socket.emit('sendMessage', {
			userId: this.props.user_data.id,
			message,
			room: this.props.event_data.id_eveniment,
			link_imagine: link
		}, (error) => {
			if (error) {
				alert(error)
			}
		});

		this.setState({
			attachedImg: null,
			previewImg: [],
			text: "",
		})
	}

	sendFeed = async () => {
		if (this.state.attachedImg !== null) {
			this.uploadToS3()
			return
		}

		this.sendFeedWithLink("")
	}

	addedFile = async (file) => {
		if (file.length === 0) {
			return
		}

		var type = file[0].blobFile.type

		if (type !== "image/jpeg" && type !== "image/jpg" && type !== "image/png") {
			toast.error("Tip fisier gresit")
			this.setState({
				attachedImg: null,
				previewImg: []
			})
			return
		}

		if (file[0] !== undefined) {
			this.setState({
				attachedImg: file[0].blobFile,
				previewImg: file
			})
		}
		else {
			this.setState({
				attachedImg: null,
				previewImg: []
			})
		}
	}

	setShowImage = (index) => {
		this.setState({
			showImage: true,
			imageToShow: index
		})
	}

	closeImage = () => {
		this.setState({
			showImage: false
		})
	}

	downloadImage = () => {
		var url = this.state.posts[this.state.imageToShow].link_imagine;
		if (this.state.inAppWebView) {
			window.flutter_inappwebview.callHandler('download_image', 'down_img', url).then(res => {
				if (res === "fail") {
					toast.error("Descarcare esuata")
				} else {
					toast.success("Imagine descarcata")
				}
			})
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'downloadMedia',
				url: url,
			}))
		} else {
			var parts = url.split("/")
			var name = parts[parts.length - 1]

			axios.get(url, {
				responseType: 'blob',
			})
				.then((res) => {
					fileDownload(res.data, name)
				})
		}
	}

	// b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
	// 	const byteCharacters = atob(b64Data);
	// 	const byteArrays = [];

	// 	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
	// 		const slice = byteCharacters.slice(offset, offset + sliceSize);

	// 		const byteNumbers = new Array(slice.length);
	// 		for (let i = 0; i < slice.length; i++) {
	// 			byteNumbers[i] = slice.charCodeAt(i);
	// 		}

	// 		const byteArray = new Uint8Array(byteNumbers);
	// 		byteArrays.push(byteArray);
	// 	}

	// 	const blob = new Blob(byteArrays, { type: contentType });
	// 	return blob;
	// }

	imageUpload = () => {
		window.flutter_inappwebview.callHandler('addPicture', 'feedPicture').then(async res => {
			if (res === "NULL") {
				return
			}
			// toast.success(atob(res).length)//get image length
			// let img = `data:image/jpeg;base64,${res}`
			// let blobFile = URL.createObjectURL(res)
			// toast(this.b64toBlob(res, "image/jpeg"));

			var contentType = "image/png"

			this.setState({
				// attachedImg: blobFile
				// attachedImg: this.b64toBlob(res, "image/jpeg")
				// attachedImg: res.blobFile
				// attachedImg: `data:image/jpeg;base64,${Buffer.from(res, "base64").toString()}`

				attachedImg: b64toBlobAndBack.toBlob(res, contentType)

				// attachedImg: URL.createObjectURL(this.b64toBlob(res, "image/jpeg"))
			})
		})
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		if (this.state.showAdModal) {
			return <div className="FeedTab-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		if (this.state.loading) {
			return (
				<div className="FeedTab-container">
						<Loader/>
				</div>
			)
		}

		return (
			<div className="FeedTab-container">
				<div className="FeedTab-contents">
					{/*
					{this.props.isTicketPaid || !this.props.event_data.tickets_needed
						?
						*/}<div>
							<div className="FeedTab-Feed">
								<FeedBox posts={this.state.posts} userId={this.props.user_data.id} setShowImage={this.setShowImage} />
							</div>

							<div className="FeedTab-Input">

									<Uploader disabled={(this.state.attachedImg !== null) ? true : null}
										className="uploader" autoUpload={false} onChange={(file) => this.addedFile(file)}
										multiple={false} listType="picture" value={this.state.previewImg}
										fileList={this.state.previewImg} onRemove={(file) => this.setState({ attachedImg: null, previewImg: [] })}>
										<button>
											<Icon icon='camera-retro' size="lg" />
										</button>
									</Uploader>


								<Input style={{margin: "0px 10px"}} className="my-input" onChange={this.changeMessage} value={this.state.text} placeholder={"Scrie un mesaj!"} />

								<Icon icon="send" size="lg" onClick={this.sendFeed} />
							</div>
						</div>

					{/*
						: <p>Va rog achizitionati bilet pentru a participa la feed</p>
					}
					*/}

					<Modal classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-code',
						closeButton: 'modal-close-button',
					}} center open={this.state.showImage} onClose={this.closeImage}>
						<img
							style={window.innerWidth > window.innerHeight ? { width: "60vh", height: "60vh" } : { width: "80vw", height: "80vw" }} alt="ClickedImage"
							src={this.state.posts[this.state.imageToShow] !== undefined ? this.state.posts[this.state.imageToShow].link_imagine : ""}
						/>

						<div style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-around' }}>
							{this.state.inAppWebView || window.alityAppView ?
								<Button onClick={this.downloadImage} color="green">Download</Button>
							:null
							}
							<Button onClick={this.closeImage} color="red">Inapoi</Button>
						</div>
					</Modal>
				</div>
			</div>
		)
	}
}

export default FeedTab;
