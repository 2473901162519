import React from 'react'
import Modal from 'react-responsive-modal'
import { Divider, Button, Tooltip } from 'rsuite'
import './style.scss'
import CustomInput from '../../../components/custom/CustomInput'

class invitesModal extends React.Component {
	state = {
		completat: false
	}

	componentDidMount() {
		if (this.props.mamaMireasa !== "" || this.props.tataMire !== "" || this.props.mamaMire !== "" || this.props.tataMireasa !== "") {
			this.setState({
				completat: true
			})
		}
	}

	tooltip = (label) => {
		return (
			<Tooltip>
				{label}
			</Tooltip>
		);
	}

	completeAdd = () => {
		this.props.toggleModal()
	}

	render() {
		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return (
			<Modal classNames={{
				overlay: 'modal-overlay',
				modal: 'parinti-modal',
				closeButton: 'modal-close-button',
			}} open={this.props.showModal} onClose={this.props.toggleModal} center>

				<h5> Adauga Nume Parinti </h5>

				<Divider />

				{this.props.eventType === "Nunta" && <div className="modal-content">
					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "mamaMireasa")} value={this.props.mamaMireasa} placeholder="Prenume mama mireasa" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "tataMireasa")} value={this.props.tataMireasa} placeholder="Prenume tata mireasa" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "numeParintiMireasa")} value={this.props.numeParintiMireasa} placeholder="Nume parinti mireasa" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "mamaMire")} value={this.props.mamaMire} placeholder="Prenume mama mire" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "tataMire")} value={this.props.tataMire} placeholder="Prenume tata mire" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "numeParintiMire")} value={this.props.numeParintiMire} placeholder="Nume parinti mire" />

					<Button onClick={this.completeAdd} size="lg" block appearance="primary" > Salveaza </Button>
				</div>}

				{this.props.eventType === "Botez" && <div className="modal-content">
					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "mamaMire")} value={this.props.mamaMire} placeholder="Nume Mama" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "tataMire")} value={this.props.tataMire} placeholder="Nume Tata" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "numeParintiMire")} value={this.props.numeParintiMire} placeholder="Nume Parinti" />

					<Button onClick={this.completeAdd} size="lg" block appearance="primary" > Salveaza </Button>
				</div>}

			</Modal>
		)
	}
}

export default invitesModal;