import React from 'react'
import { getUrlFromImage } from '../../components/Helper';
import './EventCard.scss'

class EventCard extends React.Component {
	render() {
		// console.log(this.props.albumData);
		return (
			<div className="EventCard-container-Album">
				{this.props.albumData.image !== null && this.props.albumData.image !== undefined && <div className="Picture" style={{
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					backgroundImage: `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.props.albumData.image)})`
				}} onClick={() => this.props.moveToEdit(this.props.albumData.id)}></div>}

				<div className="Edit-Gift" onClick={() => this.props.moveToEdit(this.props.albumData.id)}>
					Click aici pentru a edita albumul
				</div>

				<div className="Title font-family-poppins-700-bold">
					{this.props.albumData.numeAlbum}
				</div>

				<div className={"Description"}>
					{this.props.albumData.descriereAlbum}
				</div>
			</div>
		)
	}
}

export default EventCard;