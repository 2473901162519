import React from 'react'
import './EventPage.scss'
import HeaderWhite from '../../components/HeaderWhite'
import NavbarColor from '../../components/NavbarColor'
import NavBarEvents from '../components/eventsPage/NavBarEvents'
import axios from 'axios'

import GeneralTab from './EventPage/GeneralTab'
import AlbumsTab from './EventPage/AlbumsTab'
import { Button } from 'rsuite'

import FaceRecogTab from './EventPage/FaceRecogTab/FaceRecogTab'
import MenuTab from './EventPage/MenuTab/MenuTab'
import GiftTab from './EventPage/GiftsTab/GiftTab'
import { LoadingScreen } from '../components/Helper'

class EventPage extends React.Component {
	state = {
		activePage: "Loading",
		event_data: {}
	}

	componentDidMount = async () => {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let weddingData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

		this.setState({
			event_data: weddingData.data,
			activePage: "General"
		})
	}

	changeMenu = (type) => {
		if (type === 'Plan Mese')
			this.openSeatingChart();
		if (type === 'Feed')
			this.moveToFeed();
		this.setState({
			activePage: type
		})
	}

	openSeatingChart = () => {
		this.props.history.push('/organizer/seatingChart/' + this.props.match.params.id)
	}

	moveToFeed = () => {
		this.props.history.push('/organizer/adminFeed/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.activePage === "Loading") {
			return <LoadingScreen />
		}

		let toRender = null;
		if (this.state.activePage === "General")
			toRender = <GeneralTab id={this.props.match.params.id} />

		if (this.state.activePage === "Albume")
			toRender = <AlbumsTab id={this.props.match.params.id} />

		if (this.state.activePage === "Face Recognition")
			toRender = <FaceRecogTab event_id={this.props.match.params.id}  />

		if (this.state.activePage === "Meniu")
			toRender = <MenuTab event_id={this.props.match.params.id} />

		if (this.state.activePage === "Cadouri")
			toRender = <GiftTab event_id={this.props.match.params.id} />

		return (
			<div className="EventPageP-container">
				<HeaderWhite goBack={this.goBack} />

				<NavBarEvents activePage={this.state.activePage} changeMenu={this.changeMenu} event_data={this.state.event_data} />

				<div className="EventPage-contents">
					<div className="PageContent">
						<div>
							{toRender}
						</div>
					</div>
				</div>

				<Button size="lg" className="EventPage-Button" style={{ width: "80%", alignSelf: "center", borderRadius: "20px" }}
					appearance="primary" onClick={this.goBack} color='red'> Înapoi la modul organizator </Button>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default EventPage;
