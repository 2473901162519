import React from 'react'
import MenuBlock from './MenuBlock'
import SettingCard from "../../../../../mainPages/Profile/components/SettingCard";
import SVG_Invite from "../../../../../assets/EventInfoSVG/invitatii-icon.svg";
import axios from "axios";
import SendInviteModal from "../../../../../../components/SendInviteModal/SendInviteModal";
import SendSaveTheDateModal from "../../../../../../components/SendSaveTheDateModal/SendSaveTheDateModal";
import Modal from "react-responsive-modal";
import {Button} from "rsuite";
import {getLanguageFlag, getMaxInvitesNumber} from "../../../../../../functions";
import './stylesheets/MenuBlocks.scss'

class MenuBlocks extends React.Component {

	state = {
		tabs:
			this.props.event.others && this.props.event.others.corporate ?
				[
					{ id: "1", details: "Click pentru a vedea detalii", title: "Informații eveniment" },
					{ id: "3", details: "Click pentru a vedea detalii", title: "Participanți" },
				]
			:
			[
			{ id: "1", details: "Click pentru a vedea detalii", title: "Informații eveniment" },
			{ id: "2", details: "Planificari task-uri si buget", title: "Planificări" },
			{ id: "3", details: "Click pentru a vedea detalii", title: "Participanți" },

			{ id: "5", details: "Click pentru a vedea detalii", title: "Exporturi" },

			{ id: "7", details: "Click pentru a vedea detalii", title: "Cumpără pachet" },
			{ id: "4", details: "Click pentru a vedea detalii", title: "Servicii extra" },
			{ id: "6", details: "Click pentru a vedea detalii", title: "Contactează-ne" },
		],
		showOptionsFor: '',
		isSaveTheDateCreated: !!this.props.event.saveTheDateInfo,
		isInviteCreated: !!this.props.event.invitatieInfo,
		maxInvitesNumber: getMaxInvitesNumber(this.props.event),
		selectedInviteId: 0
	}

	componentDidMount() {

		/*
		if(this.props.event.isAdmin) {
			this.setState({
				tabs: [...this.state.tabs, { id: "8", details: "Click pentru a vedea detalii", title: "Organizatori" }]
			})
		}*/


	}

	goToSaveTheDate = () => {
		if (this.state.isSaveTheDateCreated) {
			let newOptions = this.state.showOptionsFor === 'saveTheDate' ? "" : 'saveTheDate'

			this.setState({
				showOptionsFor: newOptions
			})
		} else {
			this.props.history.push('/organizer/addSaveTheDate/' + this.props.event.id_eveniment)
		}
	}

	goToEditSaveTheDate = () => {
		this.props.history.push('/organizer/editSaveTheDate/' + this.props.event.id_eveniment)
	}

	goToPreviewSaveTheDate = () => {
		this.props.history.push('/organizer/previewSaveTheDate/' + this.props.event.id_eveniment)
	}

	toggleSTDModal = () => {

		/*
		if (window.flutter_inappwebview) {
			let msg = this.state.eventData.prenumeMireasa + " & " + this.state.eventData.prenumeMire + ", va roaga sa salvati aceasta data!"
			window.flutter_inappwebview.callHandler('shareEvent', `${msg} ${process.env.REACT_APP_PAGE}saveTheDate/${this.state.eventData.id_eveniment}`)
			return
		}

		if (window.alityAppView) {
			let msg = this.state.eventData.prenumeMireasa + " & " + this.state.eventData.prenumeMire + ", va roaga sa salvati aceasta data!"
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'shareMessage',
				message: `${msg} ${process.env.REACT_APP_PAGE}saveTheDate/${this.state.eventData.id_eveniment}`,
			}))
			return
		}

*/
		this.setState({
			sendSaveTheDateModal: !this.state.sendSaveTheDateModal
		})
	}

	goToInvite = () => {
		if (this.state.isInviteCreated) {
			let newOptions = this.state.showOptionsFor === 'invite' ? "" : 'invite'

			this.setState({
				showOptionsFor: newOptions
			})
		} else {
			this.props.history.push('/organizer/editInvite/' + this.props.event.id_eveniment)
		}
	}

	goToEditInvite = () => {
		if(this.state.selectedInviteId !== 0) {
			//Go to edit additional invite
			this.props.history.push('/organizer/editInvite/' + this.props.event.id_eveniment + '/' + this.state.selectedInviteId)
		} else {
			//Go to edit primary invite
			this.props.history.push('/organizer/editInvite/' + this.props.event.id_eveniment)
		}
	}

	goToAddNewInvite = () => {
		if((this.props.event.additional_invites.length+1) < this.state.maxInvitesNumber) {
			this.props.history.push('/organizer/editInvite/' + this.props.event.id_eveniment + '/new')
		} else {
			this.props.history.push('/organizer/package/' + this.props.event.id_eveniment)
		}
	}

	goToPreviewInvite = () => {

		if(!this.props.event.tutorials || (this.props.event.tutorials && !this.props.event.tutorials.invitePreview)) {
			this.setState({
				tutorialModalPreview: true
			})
		} else {
			if(this.state.selectedInviteId !== 0) {
				this.props.history.push('/organizer/invitationPreview/' + this.props.event.id_eveniment + '/' + this.state.selectedInviteId)
			} else {
				this.props.history.push('/organizer/invitationPreview/' + this.props.event.id_eveniment)
			}
		}
	}

	toggleInvModal = () => {
		this.setState({
			sendInviteModal: !this.state.sendInviteModal
		})
	}

	updateTutorial = async (tutorialInviteType) => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {}
		obj[tutorialInviteType] = true

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.event.id_eveniment,
			obj,
			auth)

	}


	chooseModal = () => {
		if(!this.props.event.tutorials || (this.props.event.tutorials && !this.props.event.tutorials.inviteSend)) {
			this.setState({
				tutorialModalSendInvite: true
			})
		} else {
			this.toggleInvModal()
		}
	}

	renderMenu = () => {
		let elements = []

		elements.push(<>
			<SettingCard style={{margin:'20px auto', marginTop:0, cursor:'pointer'}} svg={SVG_Invite}
						 onClick={this.goToInvite} name="Invitație" dropDown={this.state.showOptionsFor === 'invite'} />

			{this.state.showOptionsFor === 'invite' && <div>

				{
					this.props.event.additional_invites.length > 0 ?
						<div className={"invites-selection-cont"}>
							<div className={"invite-item " + (this.state.selectedInviteId === 0 ? 'active' : '')}
								 onClick={() => this.setState({selectedInviteId: 0})}>

								{/*eslint-disable-next-line*/}
								Invitația 1 🇷🇴
							</div>
							{
								this.props.event.additional_invites.map((inviteItem,i) => (
									<div key={i} className={"invite-item " + (this.state.selectedInviteId === inviteItem.id ? 'active' : '')}
										 onClick={() => this.setState({selectedInviteId: inviteItem.id})}>
										Invitația {i+2} {getLanguageFlag(inviteItem.language)}
									</div>
								))
							}

						</div>
						:null
				}

				<SettingCard icon="" onClick={this.goToAddNewInvite} name="Creează o nouă invitație" isSmall={true} />

				<SettingCard icon="" onClick={this.goToEditInvite} name="Editează" isSmall={true} />

				<SettingCard icon="" onClick={this.goToPreviewInvite} name="Previzualizează" isSmall={true} />

				<SettingCard icon="" onClick={() => {
					if(!this.props.event.locatii || this.props.event.locatii.length === 0) {
						this.setState({
							tutorialModalSendInviteNotice: true
						})
					} else {
						this.chooseModal()
					}
				}} name="Trimite" isSmall={true} />

				<SettingCard style={{marginBottom:'20px'}} icon="" onClick={() => this.props.history.push('/organizer/raspunsuriInvitati/' + this.props.event.id_eveniment)} name="Răspunsuri" isSmall={true} />

			</div>}
			</>)

		elements = [...elements, this.state.tabs.map((element) => {
			if(element.id === '3') {
				return <>
					<MenuBlock walkthroughActiveIndex={this.props.walkthroughActiveIndex}
								  history={this.props.history}
								  event={this.props.event} moveTo={this.props.moveTo}
								  key={element.id} id={element.id}
								  title={element.title}
								  details={element.details} eventID={this.props.eventID}
								  hasNewResponses={this.props.hasNewResponses}/>

					{this.props.event.eventType === "Nunta" && <SettingCard
						style={{margin:'20px auto', marginTop:0, cursor:'pointer'}}
						svg={SVG_Invite} onClick={this.goToSaveTheDate} name="Save the date" dropDown={this.state.showOptionsFor === 'saveTheDate'} />}

					{this.state.showOptionsFor === 'saveTheDate' && <div>
						<SettingCard icon="" onClick={this.goToEditSaveTheDate} name="Editează" isSmall={true} />

						<SettingCard icon="" onClick={this.goToPreviewSaveTheDate} name="Previzualizează" isSmall={true} />

						<SettingCard icon="" onClick={this.toggleSTDModal} name="Trimite" isSmall={true} />

						<SettingCard style={{marginBottom:'20px'}} icon="" onClick={() => this.props.history.push('/organizer/raspunsuriSaveTheDate/' + this.props.event.id_eveniment)} name="Răspunsuri" isSmall={true} />

					</div>}


				</>

			} else {
				return <MenuBlock walkthroughActiveIndex={this.props.walkthroughActiveIndex}
								  history={this.props.history}
								  event={this.props.event} moveTo={this.props.moveTo}
								  key={element.id} id={element.id}
								  title={element.title}
								  details={element.details} eventID={this.props.eventID}
								  hasNewResponses={this.props.hasNewResponses}/>

			}
		})]

		return elements
	}

	render() {



		return (
			<div className="MenuBlocks-container">
				<div className="MenuBlocks-content">
					{this.renderMenu()}
				</div>

				<SendInviteModal
					showModal={this.state.sendInviteModal}
					setShowModal={(value) => this.setState({sendInviteModal: value})}
					event={this.props.event}
					selectedInviteId={this.state.selectedInviteId}
				/>


				<SendSaveTheDateModal
					showModal={this.state.sendSaveTheDateModal}
					setShowModal={(value) => this.setState({sendSaveTheDateModal: value})}
					event={this.props.event}
				/>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModalPreview}
					focusTrapped={false}
					onClose={() => {
						this.setState({
							tutorialModalPreview: false
						})
						this.updateTutorial('invitePreview')
						if(this.state.selectedInviteId !== 0) {
							this.props.history.push('/organizer/invitationPreview/' + this.props.event.id_eveniment + '/' + this.state.selectedInviteId)
						} else {
							this.props.history.push('/organizer/invitationPreview/' + this.props.event.id_eveniment)
						}
					}}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>Poți previzualiza invitația pentru a fi sigur că toate datele sunt corecte înainte de a o trimite invitațiilor.
							Secțiunea de întrebări personalizate nu este vizibilă în acest ecran, pentru a vizualiza aceste întrebări va rugam ca prima invitația sa fie trimisă către tine.</p>

						<Button
							onClick={() => {
								this.setState({
									tutorialModalPreview: false
								})
								this.updateTutorial('invitePreview')
								if(this.state.selectedInviteId !== 0) {
									this.props.history.push('/organizer/invitationPreview/' + this.props.event.id_eveniment + '/' + this.state.selectedInviteId)
								} else {
									this.props.history.push('/organizer/invitationPreview/' + this.props.event.id_eveniment)
								}
							}}
							color="green"
							style={{
								backgroundColor: "#05AFF2",
								borderRadius: "20px",
								width: "100%",
								marginTop: "20px"
							}}>Ok</Button>
					</div>
				</Modal>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModalSendInviteNotice}
					focusTrapped={false}
					onClose={() => {
						this.setState({
							tutorialModalSendInviteNotice: false
						})
						this.updateTutorial('inviteSendNotice')
						this.setState({
							tutorialModalSendInviteNotice:false
						})
						this.chooseModal()
					}}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>ATENȚIE! Nu aveți nicio locație adaugata pe invitație, iar invitații nu vor ști unde și la ce ora trebuie sa fie prezenți.
							Locațiile se adauga în secțiunea a 3-a (ultima) a invitației.
						</p>

						<Button
							onClick={() => {

								this.updateTutorial('inviteSendNotice')
								this.setState({
									tutorialModalSendInviteNotice:false
								})
								this.chooseModal()
							}}
							color="green"
							style={{
								backgroundColor: "#05AFF2",
								borderRadius: "20px",
								width: "100%",
								marginTop: "20px"
							}}>Ok</Button>
					</div>
				</Modal>
				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModalSendInvite}
					focusTrapped={false}
					onClose={() => {
						this.setState({
							tutorialModalSendInvite: false
						})
						this.updateTutorial('inviteSend')
						this.toggleInvModal()
					}}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>Va rugam sa aveți în vedere ca după primirea primului răspuns la invitație numai puteți aduce modificări la întrebările personalizate așa că va rugam sa acordati multă atenție întrebărilor personalizate înainte de trimiterea invitațiilor.  Pentru a putea aduce modificări la întrebările personalizate va trebui sa stergeti toate răspunsurile primite și apoi o sa puteți modifica întrebările. </p>

						<Button
							onClick={() => {
								this.setState({
									tutorialModalSendInvite: false
								})
								this.updateTutorial('inviteSend')
								this.toggleInvModal()
							}}
							color="green"
							style={{
								backgroundColor: "#05AFF2",
								borderRadius: "20px",
								width: "100%",
								marginTop: "20px"
							}}>Ok</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

export default MenuBlocks;
