import React from 'react'
import {toast} from 'react-toastify'
import HeaderWhite from '../../components/HeaderWhite'
import axios from 'axios'

import './FaceRecogResults.scss'
import Loader from "../../components/Loader";
import NavbarColor from "../../components/NavbarColor";
import {ConfirmButton} from "../../components/Helper";
import fileDownload from "js-file-download";

class FaceRecogResults extends React.Component {
    state = {
        results: [],
        loading: true,
    }


    componentDidMount() {



        let auth = {
            headers: {

            }
        }


        axios.post(process.env.REACT_APP_DOMAIN + 'facerecog/getMyResults', {
            eventID: this.props.match.params.eventID,
            requestID: this.props.match.params.requestID
        }, auth)
            .then(response => {
                if(response.data.status === 1 && response.data.results && response.data.results !== null) {
                    this.setState({
                        results: response.data.results,
                        loading: false
                    })
                } else {
                    toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                }

            })
            .catch(err => {
                    toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                    //this.props.history.push('/')
            })

    }

    downloadPicture = (url) => {

        url = encodeURI(url)

        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('download_image', 'down_img', url).then(res => {
                if (res === "fail") {
                    toast.error("Descarcare esuata")
                } else {
                    toast.success("Imagine descarcata")
                }
            })
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'downloadMedia',
                url: 'https://admin.ality.ro/memorybooth/handleFileDownload?url=' + url,
            }))
        } else {


      //      return
            //window.open(url, '_blank').focus();
            //return

            let parts = url.split("/")
            let name = parts[parts.length - 1]
            axios.get('https://admin.ality.ro/memorybooth/handleFileDownload?url=' + url, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, name)
                })
        }

    }


    render() {
        if(this.state.loading) {
            return <div className="FaceRecogResults-container font-family-poppins-300">
                <HeaderWhite/>

                <h4 className="FaceRecogResults-TopText">Fotografii eveniment</h4>

                <div className="FaceRecogResults-contents">
                    <Loader/>
                </div>

                <NavbarColor history={this.props.history}/>
            </div>
        }

        return <div className="FaceRecogResults-container font-family-poppins-300">
            <HeaderWhite/>

            <h4 className="FaceRecogResults-TopText">Fotografii eveniment</h4>

            <div className="FaceRecogResults-contents">

                <div className={"pictures-cont"}>
                    {
                        this.state.results.map((result,i) => (
                            <div key={i} className={"picture-item"}>
                                <img src={result} alt={""} />

                                <div className={"download-cont"}>
                                    <ConfirmButton
                                        text={"Descarcă"}
                                        function={() => this.downloadPicture(result)}
                                    />
                                </div>
                            </div>
                        ))
                    }
                </div>

            </div>

            <NavbarColor history={this.props.history}/>
        </div>

    }
}

export default FaceRecogResults;
