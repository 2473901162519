import React, {useState, useEffect} from 'react'
import HeaderWhite from '../../../components/HeaderWhite'
import './Testimonials.scss'
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {useHistory, useParams} from "react-router-dom";
import SettingCard from "../../mainPages/Profile/components/SettingCard";
import Loader from "../../components/Loader"

export default function Testimonials() {
    let history = useHistory()
    let {id} = useParams()

    const [currentPackage, setCurrentPackage] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getEvent()
        // eslint-disable-next-line
    }, [])

    const getEvent = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + id, auth);
        setCurrentPackage(info.data.packages && info.data.packages.wedding_testimonials ? info.data.packages.wedding_testimonials : false)
        setLoading(false)
    }

    const goBack = () => {
        history.goBack()
    }

    const goToMoreInfo = () => {
        window.open("https://ality.ro/memorybooth/")
    }

    const goToPackages = () => {
        history.push('/organizer/package/' + id)
    }

    return (
        <div className="ts-config-container">
            <HeaderWhite goBack={goBack}/>
            <div className={"ts-main-container"}>

                {
                    loading ?
                        <Loader/>
                        :
                        <>

            <div className='description'>
                Retine acel moment unic din viața voastră, să poată fi retrăit peste ani, la fel de intens și plin de
                emoție.
            </div>

            <div className='more-info' onClick={goToMoreInfo}>
                Mai multe informatii
            </div>


                {currentPackage ?
                    <>
                        <SettingCard icon="" onClick={() => history.push('/organizer/testimonials/config/' + id)}
                                     name="Configurare"/>

                        <SettingCard icon="" onClick={() => {
                            history.push('/organizer/testimonials/listItems/' + id)
                        }} name="Mesaje primite"/>

                        <div style={{marginTop: '30px'}}></div>
                    </>


                    : <div>
                        <div style={{margin: "20px"}}>
                            Pentru a beneficia de acest serviciu trebuie sa achizitionati un pachet
                            <div onClick={goToPackages} style={{color: "blue"}}>Cumpara pachet</div>
                        </div>
                    </div>
                }
                        </>
                }
            </div>


            <NavbarColor history={history}/>
        </div>
    )
}
