import React, {useEffect, useRef} from "react";
import {FiPauseCircle, FiPlayCircle} from "react-icons/all";

export default function PlayAudioButton({song, play, setCurrentSong}) {

    const audioPlayer = useRef()

    useEffect(() => {
        if (play === false){
       //     document.getElementById('player').
            audioPlayer.current.pause()
        }
    }, [play])
    return <div className={"play-audio-button-container"}>
        <audio
            ref={audioPlayer}
            id={'audio-mp3-' + song.id}
        >
            <source src={song.url} type={"audio/mp3"}/>
        </audio>
        <div className={"play-audio-button"} onClick={() => {
            if (play){
                setCurrentSong(null)
                audioPlayer.current.pause()
            }
            else {
                setCurrentSong(song.id)
                audioPlayer.current.play()
            }
        }}>
           <div className={"icon-container"}>
               {
                   play ?
                       <FiPauseCircle color="green" size="2em"/>:
                       <FiPlayCircle color="gray"  size="2em"/>
               }
           </div>
            <div className={"name-container"}>
                {song.name}
            </div>
        </div>
    </div>

}