import React, { useEffect, useState } from 'react'
import './BackgroundStyleComp.scss'
import { Icon, Uploader } from "rsuite";
import {
    EditPicture2,
} from '../../../../../organizer/components/Helper'

export default function BackgroundStyleComp({
    templateDetails,
    setTemplateDetails,
    backgroundImageLink,
    deleteBackgroundImageLink,
    setDeleteBackgroundImageLink,
    handlePoza,
    poza_invite,
    saveCroppedImage
}) {

    const [showSection, setShowSection] = useState('')

    const currentOverlayColor = templateDetails.overlayColor ? templateDetails.overlayColor : '#F5FAFF'
    const currentOverlayOpacity = templateDetails.overlayOpacity ? templateDetails.overlayOpacity : 0.9

    useEffect(() => {
        if(templateDetails && backgroundImageLink && templateDetails.background !== backgroundImageLink) {
            setTemplateDetails({...templateDetails, background: backgroundImageLink})
        }

        // eslint-disable-next-line
    }, [backgroundImageLink])


    const toggleSection = (section) => {
        if (showSection === section) {
            setShowSection('')
        } else {
            setShowSection(section)
        }
    }

    return (
        <div className={"background-style-comp"}>

            <div className={"controls"}>
                <div className={"text-style-col " + (showSection === 'background' ? 'selected' : '')}
                    onClick={() => toggleSection('background')}>
                    <div className={"text-style-col-title"}>Imagine de fundal</div>
                    <div><Icon icon="image" /></div>
                </div>
                <div className={"text-style-col " + (showSection === 'overlayColor' ? 'selected' : '')}
                    onClick={() => toggleSection('overlayColor')}>
                    <div className={"text-style-col-title"}>Culoare overlay</div>
                    <div className={"text-style-color-swatch"}
                        style={{ background: currentOverlayColor ? currentOverlayColor : '#000000' }}
                    ></div>
                </div>
                <div className={"text-style-col " + (showSection === 'overlayOpacity' ? 'selected' : '')}
                    onClick={() => toggleSection('overlayOpacity')}>
                    <div className={"text-style-col-title"}>Opacitate overlay</div>
                    <div className={"text-style-color-swatch"}
                        style={{ background: currentOverlayColor ? currentOverlayColor : '#000000', opacity: currentOverlayOpacity }}
                    ></div>
                </div>
            </div>

            <div className={"sections"}>
                {/* sections */}


                {
                    showSection === 'background' ?
                        <div className={"selector"}>

                            {backgroundImageLink !== null
                                ? <div>
                                    <p>Imagine fundal</p>
                                    <img style={{ padding: "5px", width: '100%', alignSelf: "center", maxWidth: '250px' }}
                                        src={backgroundImageLink} alt="" />

                                    {poza_invite === null && <div className="Section">
                                        <div className="Title">Doriti sa stergeti imaginea de fundal curenta?</div>

                                        <div className="Options-Row">
                                            <div
                                                className={deleteBackgroundImageLink ? "active-option" : "inactive-option"}
                                                onClick={_ => setDeleteBackgroundImageLink(true)}
                                                style={{ marginRight: "30px" }}>Da
                                            </div>
                                            <div
                                                className={!deleteBackgroundImageLink ? "active-option" : "inactive-option"}
                                                onClick={_ => setDeleteBackgroundImageLink(false)}>Nu
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                                : <p>Nicio imagine adaugata</p>
                            }

                            <div className="Upload-zone">
                                <p> {backgroundImageLink !== null ? "Schimba Fotografia de Fundal (va sterge imaginea curenta)" : "Adauga fotografie de fundal"} </p>

                                <Uploader onChange={handlePoza} accept=".jpg" style={{ borderRadius: "5px" }}
                                    autoUpload={false} multiple={false} disabled={poza_invite !== null}
                                    fileListVisible={false}
                                >
                                    <div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">Click aici pentru a
                                        incarca fotografia
                                    </div>
                                </Uploader>
                            </div>

                            {poza_invite !== null &&
                                <EditPicture2 uploadedImage={poza_invite}
                                    width={291} //1164
                                    height={552} //2210
                                    border={0}
                                    borderRadius={0}
                                    saveCroppedImage={saveCroppedImage} />}
                        </div>
                        : null
                }

                {
                    showSection === 'overlayColor' ?
                        <div className={"selector"}>
                            <input
                                style={{ width: '100%', height: '40px', border: 0 }}
                                value={currentOverlayColor}
                                type={"color"} onChange={(e) => setTemplateDetails({ ...templateDetails, overlayColor: e.target.value })} />
                        </div>
                        : null
                }

                {
                    showSection === 'overlayOpacity' ?
                        <div className={"selector"}>
                            <input
                                style={{ width: '100%', height: '40px', border: 0 }}
                                value={currentOverlayOpacity}
                                type={"range"}
                                min={0}
                                max={1}
                                step={0.05}
                                onChange={(e) => setTemplateDetails({ ...templateDetails, overlayOpacity: e.target.value })} />
                        </div>
                        : null
                }

            </div>

        </div>
    )

}
