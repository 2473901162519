import React from 'react'
import { Button } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import './GiftTab.scss'
import { getUrlFromImage } from '../../../components/Helper'

let isBlockingSetBought = false

class GiftTab extends React.Component {
	state = {
		gifts: [],
		userID: "",
		userData: {},

		loggedIn: false,
		isParticipating: false,
		loading: true,
	}

	componentDidMount = async () => {
		let userId = localStorage.getItem("id")
		if (userId === null) {
			toast.error("Trebuie sa va logati pentru a avea acces la detaliile evenimentului")
			this.props.history.push('/login');
			return
		}

		this.setState({ loggedIn: true, userID: userId })

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.event_id, auth)
			.then((response) => {
				let userData = response.data.responses.filter(elem => elem.user === this.props.user_data.id)

				if (userData.length === 0 || userData[0].response.answer !== "yes") {
					return
				}
				this.setState({
					isParticipating: true,
					userData: userData[0].response
				})
			})
			.catch(err => {
				console.log(err)
				toast.error("Eroare")
			})

		await axios.get(process.env.REACT_APP_DOMAIN + 'gifts/list/byEvent/' + this.props.event_id, auth)
			.then(response => {
				this.setState({
					gifts: response.data,
					loading: false
				})
			})
			.catch(err => {
				console.log(err)
				toast.error("Eroare")
			})
	}

	setBoughtGift = async (index) => {
		if (isBlockingSetBought) {
			return
		}

		isBlockingSetBought = true

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'gifts/' + this.state.gifts[index].id, auth)
			.then(async response => {
				if (response.data.idBoughtBy !== "" && response.data.idBoughtBy !== null) {
					toast.error("Cadoul a fost achizitionat intre timp, va rog reintrati in tab pentru a actualiza")
					return
				}

				let obj = {
					boughtBy: this.state.userData.prenume + " " + this.state.userData.nume,
					idBoughtBy: this.state.userID,
				}

				await axios.post(process.env.REACT_APP_DOMAIN + 'gifts/claimGift', { giftId: this.state.gifts[index].id }, auth)
					// await axios.put(process.env.REACT_APP_DOMAIN + 'gifts/' + this.state.gifts[index].id, obj, auth)
					.then(res => {
						toast.success("Cadou asignat")

						var newGifts = [...this.state.gifts]
						newGifts[index].boughtBy = obj.boughtBy
						newGifts[index].idBoughtBy = obj.idBoughtBy

						this.setState({
							gifts: newGifts
						})
					})
					.catch(err => {
						console.log(err);
						toast.error("Atribuirea cadoului a esuat, va rog reincercati")
					})
			})
			.catch(err => {
				console.log(err);
				toast.error("Error la verificarea statusului cadoului, va rog reincercati sau reintrati in tab")
			})

		isBlockingSetBought = false
	}

	cancelBuyingGift = async (index) => {
		if (Number(this.state.gifts[index].idBoughtBy) !== this.props.user_data.id) {
			toast.error("Nu puteti sterge asignarea cadoului pentru alt participant")
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		// let obj = {
		// 	boughtBy: "",
		// 	idBoughtBy: "",
		// }

		await axios.post(process.env.REACT_APP_DOMAIN + 'gifts/cancelClaimGift', { giftId: this.state.gifts[index].id }, auth)
			// await axios.put(process.env.REACT_APP_DOMAIN + 'gifts/' + this.state.gifts[index].id, obj, auth)
			.then(res => {
				toast.success("Cadou neasignat")

				var newGifts = [...this.state.gifts]
				newGifts[index].boughtBy = ""
				newGifts[index].idBoughtBy = ""

				this.setState({
					gifts: newGifts
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("Anularea atribuirii cadoului a esuat, va rog reincercati")
			})
	}

	openGiftLink = (link) => {

			if (window.flutter_inappwebview) {
				window.flutter_inappwebview.callHandler('openLink', link)
			} else if (window.alityAppView) {
				window.ReactNativeWebView.postMessage(JSON.stringify({
					action: 'openLink',
					url: link,
				}))
			} else {
				window.open(link)
			}

		/*
		if (link.startsWith("http")) {
			window.open(link)
		} else {
			let newLink = "https://" + link

			window.open(newLink)
		}
		 */
	}

	render() {
		if (this.state.loading) {
			return <div className="GiftTab-Container">
				<p>Loading...</p>
			</div>
		}

		if (!this.state.loggedIn) {
			return <div className="GiftTab-Container">
				<p>Va rog sa intrati in cont pentru a accesa acest tab</p>
			</div>
		}

		if (!this.state.isParticipating) {
			return <div className="GiftTab-Container">
				<p>Pentru a vedea aceste date trebuie sa participati la eveniment</p>
			</div>
		}

		return <div className="GiftTab-Container">
			<div className="GiftTab-Content">
				{this.state.gifts.map((gift, index) => {
					return <div key={index} className="GiftTab-Card">
						{gift.picture !== null && <div className="Picture" style={{
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundImage: `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(gift.picture)})`
						}} onClick={() => this.goToEditGift(index)}></div>}

						<div className="Title font-family-poppins-700-bold">
							{gift.title}
						</div>

						<div className={"Description"}>
							{gift.description}
						</div>

						{gift.link !== "" && <div className="Link" onClick={() => this.openGiftLink(gift.link)}>
							Link
						</div>}

						{gift.boughtBy !== "" && gift.boughtBy !== null
							? <div className='Options'>
								{gift.idBoughtBy === this.state.userID
									? <div>
										<div>Va fi cumparat de dumneavoastra.</div>

										<Button appearance="ghost" color="red" style={{ margin: "10px 0px", borderRadius: "20px" }} onClick={() => this.cancelBuyingGift(index)}>
											Anulati cumpararea cadoului?
										</Button>
									</div>
									: <p>Va fi cumparat de: {gift.boughtBy}</p>
								}
							</div>
							: <div className='Options'>
								<div>Nu este ales de niciun participant.</div>

								<Button appearance="ghost" color="green" style={{ margin: "10px 0px", borderRadius: "20px" }} onClick={() => this.setBoughtGift(index)}>
									Il voi cumpara eu
								</Button>
							</div>
						}
					</div>
				})}
			</div>
		</div>
	}
}

export default GiftTab