import React, {useState, useEffect} from 'react'
import './AdminInvite.scss'
import HeaderWhite from '../../../components/HeaderWhite'

import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import {ConfirmButton} from '../../components/Helper';
import {useHistory, useParams} from "react-router-dom";

export default function AdminInvite() {

    let history = useHistory()
    let {uid} = useParams()

   const [invite, setInvite] = useState({})

    useEffect(() => {
        getInvite()
        // eslint-disable-next-line
    }, [])


    const getInvite = async () => {

        if(!localStorage.getItem('jwt') ||  localStorage.getItem('jwt').length === 0)  {
            return;
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            uid: uid
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/getInvite', payload, auth)
            .then(response => {
                setInvite(response.data)
            })
            .catch(err => {
                setInvite({
                    status: 0
                })
            })



    }

    const accept = async () => {
        if(!localStorage.getItem('jwt') ||  localStorage.getItem('jwt').length === 0)  {
            return;
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            uid: uid
        }

       await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/acceptInvite', payload, auth)

        toast.success('Invitatia a fost acceptata!')
        history.push('/organizer/viewEvents')
    }

    return (
        <div className="AdminInvite-container">
            <HeaderWhite/>

            <div className={"AdminInvite-contents"}>


                {
                   invite.status === 0 ?
                        <div>
                            <p>Aceasta invitatie se adreseaza unei alte persoane sau a fost deja acceptata!</p>
                        </div>
                        :null
                }

                {
                    (!localStorage.getItem('jwt') ||  localStorage.getItem('jwt').length === 0)  ?
                        <div>
                            <p>Pentru a putea accepta invitatia va rugam sa va autentificati!</p>

                            <ConfirmButton
                                function={() => history.push('/login?redirectBackTo=organizer/adminInvite/'+uid)}
                                text={"Autentificare"}
                                />
                        </div>
                        :
                        <div>

                            {
                                invite && invite.wedding ?
                                    <>

                                    <p className="Name">

                                        Ai fost invitat ca organizator in cadrul evenimentului <br/>

                                        {invite.wedding.eventType === "Nunta" &&
                                        <> Nunta {invite.wedding.prenumeMire} & {invite.wedding.prenumeMireasa} </>}

                                        {invite.wedding.eventType === "Botez" &&
                                        <> Botez {invite.wedding.prenumeMire}</>}

                                        {invite.wedding.eventType === "Petrecere" &&
                                        <> Petrecere {invite.wedding.prenumeMire}</>}

                                    </p>


                                        <ConfirmButton
                                            text={"Accept invitatia"}
                                            function={() => accept()}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                }

            </div>
            <NavbarColor history={history}/>
        </div>
    )

}
