import React from 'react'
import './AlbumsTab.scss'
import axios from 'axios'
import { getUrlFromImage } from '../../../components/Helper'

class AlbumsTab extends React.Component {
	state = {
		albums: [],
		loading: true
	}

	componentDidMount = async () => {
		if (localStorage.getItem("id") === null) {
			//toast.error("Trebuie sa vă autentificați pentru a accesa această secțiune!")
			this.setState({
				loading: false,
			})
			return
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'albums/list/byEvent/' + this.props.event_id, auth)
			.then(response => {
				//take only albums with link to pictures
				let albumsWithLink = response.data.filter(resp => resp.linkPozeAlbum !== "")

				this.setState({
					albums: albumsWithLink,
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	render() {
		if (this.state.loading === true)
			return (
				<div>
					<p>Se încarcă...</p>
				</div>
			)

		return <div className="AlbumsTab-wedding-container">

			{
				localStorage.getItem("id") === null ?

					<p>Trebuie să vă autentificați pentru a accesa această secțiune!</p>
			:
				this.state.albums.length > 0
					? <div>
						{this.state.albums.map((album, index) => {
							return <div className="AlbumsTab-album-content" key={index}>
								{album.image !== null && album.image !== undefined && <div className="Picture" style={{
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundImage: `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(album.image)})`
								}} onClick={() => this.openLink(album.linkPozeAlbum)}></div>}

								<div className="Title font-family-poppins-700-bold">
									{album.numeAlbum}
								</div>

								<div className={"Description"}>
									{album.descriereAlbum}
								</div>
							</div>
						})}
					</div>
					: <h5>Pozele vor fi disponibile dupa eveniment</h5>
			}
		</div>
	}
}

export default AlbumsTab;
