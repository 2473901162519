import React from 'react'
import './PreviewSaveTheDate.scss'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import { Button } from 'rsuite'
import axios from 'axios';
import {getUrlFromImage, LoadingScreen, PlayAudio, PlayVideo} from '../../components/Helper';

class PreviewSaveTheDate extends React.Component {
	state = {
		saveTheDateType: "",
		event: {},
		showModal: false,
		loading: true,
		contents: [],
		addToCalendarDate: "",
		backgroundImageLink: "",
		isUsingAudio: false,
		serverAudioUrl: null
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
			.then(async response => {
				let date = response.data.dataEvent.split('-');
				let audio = response.data.saveTheDate.audio

				this.setState({
					event: response.data,
					saveTheDateType: response.data.saveTheDate.saveTheDateType,
					contents: response.data.saveTheDate,
					loading: false,
					addToCalendarDate: date[2] + "-" + date[1] + "-" + date[0],
					serverAudioUrl: audio !== null && audio !== undefined ? audio.url : null,
					isUsingAudio: response.data.saveTheDate.audioType === "useAudio",
					backgroundImageLink: response.data.saveTheDate.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(response.data.saveTheDate.backgroundImage)}` : ""
				})
			})
	}

	render() {
		if (this.state.loading) {
			return <LoadingScreen />
		}

		let style = {
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			backgroundImage: 'url(' + this.state.backgroundImageLink + ')'
		}

		let customStyle = {
			color: this.state.contents.fontColor,
			fontFamily: this.state.contents.fontFamily,
			fontSize: this.state.contents.fontSize,
			paddingTop: '30px'
		}

		let date = this.state.contents.dataEvent.split('T')[0].split("-")

		let body = <div></div>

		if (this.state.saveTheDateType === "Personal") {
			body = <div style={{ marginTop: "20px" }}>
				<img src={this.state.backgroundImageLink} alt={"Invitatie personala"} style={{ width: "80vw" }} />


				{this.state.isUsingAudio && <PlayAudio link={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.serverAudioUrl}`} />}
			</div>
		}

		if (this.state.saveTheDateType === "Video") {
			let linkParts = this.state.contents.linkVideo.split("watch?v=")
			let link = ""
			if (linkParts.length === 1) {
				link = linkParts[0]
			} else {
				link = linkParts[0] + "embed/" + linkParts[1]
			}

			let videoId = this.state.contents.linkVideo.split("watch?v=")[1].split("&")[0]

			body = <div className="MainInvite-contents" style={customStyle}>
				<PlayVideo videoId={videoId} link={link.split("&")[0]} style={{ width: "80vw", height: "500px", margin: "20px auto" }} />
			</div>
		}

		if (this.state.saveTheDateType === "Custom") {
			body = <div className="PreviewSaveTheDate-container" style={style} >
				<div className="PreviewSaveTheDate-contents" style={customStyle}>
					<div style={{ fontSize: `${Number(this.state.contents.fontSize.split("px")[0]) + 5}px`, }}> Save The Date </div>

					<div>
						<div> {this.state.event.prenumeMireasa} & {this.state.event.prenumeMire} </div>

						<div> {date[2] + "-" + date[1] + "-" + date[0]} - {this.state.contents.localitate} </div>
					</div>

					<div className="description"> {this.state.contents.description}

					{this.state.isUsingAudio &&
						<><br/><br/><PlayAudio link={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.serverAudioUrl}`} /></>}

					</div>
					</div>
			</div>
		}

		return (
			<div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
				{body}

				<div style={{marginTop:'10px'}}>
					<Button className="action" onClick={() => this.props.history.go(-1)} color="red"> Înapoi </Button>
				</div>
			</div>
		)
	}
}

export default PreviewSaveTheDate;