import React from 'react'
import './CssPreview.scss'
import { getUrlFromImage, LoadingScreen } from '../../../components/Helper';

class PreviewSaveTheDateForExports extends React.Component {
	state = {
		saveTheDateType: "",
		event: {},
		showModal: false,
		loading: true,
		contents: [],
		backgroundImageLink: ""
	}

	componentDidMount = async () => {
		let event_info = this.props.event_data

		this.setState({
			event: event_info,
			saveTheDateType: event_info.saveTheDate && event_info.saveTheDate.saveTheDateType ? event_info.saveTheDate.saveTheDateType : 'Personal',
			contents: event_info.saveTheDate,
			backgroundImageLink: event_info.saveTheDate.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(event_info.saveTheDate.backgroundImage)}` : "",
			loading: false,
		})
	}

	render() {
		if (this.state.loading) {
			return <LoadingScreen />
		}

		let style = {
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			backgroundImage: 'url(' + this.state.backgroundImageLink + ')'
		}

		let customStyle = {
			color: this.state.contents.fontColor,
			fontFamily: this.state.contents.fontFamily,
			fontSize: this.state.contents.fontSize,
		}

		let date = this.state.contents.dataEvent.split('T')[0].split("-")

		let body = <div></div>

		if (this.state.saveTheDateType === "Personal") {
			body = <div style={{ marginTop: "20px" }}>
				<img src={this.state.backgroundImageLink} alt={"Invitatie personala"} style={{ width: "80vw" }} />
			</div>
		}

		// if (this.state.saveTheDateType === "Video") {
		// 	let linkParts = this.state.contents.linkVideo.split("watch?v=")
		// 	let link = ""
		// 	if (linkParts.length === 1) {
		// 		link = linkParts[0]
		// 	} else {
		// 		link = linkParts[0] + "embed/" + linkParts[1]
		// 	}

		// 	let videoId = this.state.contents.linkVideo.split("watch?v=")[1].split("&")[0]

		// 	body = <div className="MainInvite-contents" style={customStyle}>
		// 		<PlayVideo videoId={videoId} link={link.split("&")[0]} style={{ width: "80vw", height: "500px", margin: "20px auto" }} />
		// 	</div>
		// }

		if (this.state.saveTheDateType === "Custom") {
			body = <div className="Export-SaveTheDate-container" style={style} >
				<div className="Export-SaveTheDate-contents" style={customStyle}>
					<div style={{ fontSize: `${Number(this.state.contents.fontSize.split("px")[0]) + 5}px`, }}> Save The Date </div>

					<div>
						<div> {this.state.event.prenumeMireasa} & {this.state.event.prenumeMire} </div>

						<div> {date[2] + "-" + date[1] + "-" + date[0]} - {this.state.contents.localitate} </div>
					</div>

					<div className="description"> {this.state.contents.description}</div>
				</div>
			</div>
		}

		return (
			<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: "100%", textAlign: "center" }}>
				{body}
			</div>
		)
	}
}

export default PreviewSaveTheDateForExports;