import React from 'react'
import './IntermediatePageOrganizer.scss'
import HeaderWhite from '../../components/HeaderWhite'
import axios from 'axios'
import NavbarColor from '../../components/NavbarColor'
import SettingCard from '../mainPages/Profile/components/SettingCard'

import SVG_AddEvent from '../assets/EventPageSVGs/planificari-icon.svg'
import SVG_MyEvents from '../assets/EventPageSVGs/informatii-icon.svg'
import Modal from "react-responsive-modal";
import {ConfirmButton} from "../components/Helper";

class IntermediatePageOrganizer extends React.Component {
	state = {
		showOptions: true,
		showBusinessModal: false
	}

	componentDidMount = () => {
		if (this.props.location.search !== "") {
			let jwt = this.props.location.search.split("?")[1]

			if (jwt === "null") {
				localStorage.clear();
			} else {
				localStorage.setItem("jwt", this.props.location.search.split("?")[1])
			}
		}

		if (this.props.location.search !== "" && localStorage.getItem("id") === null) {
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
				.then(response => {
					localStorage.setItem("id", response.data.id)
					localStorage.setItem("rememberMe", "remember")
				})
		}
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	toggleShowAddOptions = () => {
		let prev = this.state.showOptions

		this.setState({
			showOptions: !prev
		})
	}

	addWedding = () => {
		localStorage.setItem("eventType", "Nunta")

		this.goToAddEvent()
	}

	addChristening = () => {
		localStorage.setItem("eventType", "Botez")

		this.goToAddEvent()
	}

	addParty = () => {
		localStorage.setItem("eventType", "Petrecere")

		this.goToAddEvent()
	}

	goToAddEvent = () => {
		this.props.history.push('/organizer/addEvent')
	}

	goToViewEvent = () => {
		this.props.history.push('/organizer/viewEvents')
	}

	render() {
		return (
			<div className="IntermediatePageOrganizer-container">
				<HeaderWhite />

				<div className="IntermediatePageOrganizer-contents">
					<SettingCard svg={SVG_AddEvent} onClick={this.toggleShowAddOptions} name="Creaza eveniment" dropDown={this.state.showOptions} />

					{this.state.showOptions && <div>
						<SettingCard icon="" onClick={this.addWedding} name="Nunta" isSmall={true} />

						<SettingCard icon="" onClick={this.addChristening} name="Botez" isSmall={true} />

						<SettingCard icon="" onClick={this.addParty} name="Aniversare" isSmall={true} />

						<SettingCard icon="" onClick={() => {this.setState({showBusinessModal: true})}} name="Business" isSmall={true} />
					</div>}

					<SettingCard svg={SVG_MyEvents} onClick={this.goToViewEvent} name="Evenimentele create de mine" dropDown={false} />
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.showBusinessModal}
					focusTrapped={false}
					onClose={() => {
						this.setState({
							showBusinessModal: false
						})
					}}
				>

					<h3>Evenimente Business</h3>

					<p>
						Va mulțumim pentru interesul arătat în a organiza un eveniment cu ajutorul aplicației Ality. Daca sunteti o firma care organizează evenimente și doriți o colaborarea cu noi dorim sa va informam ca se pot organiza următoarele tipuri de evenimente:
					</p>

					<strong>Publice:</strong>
					<ul>
						<li>Festival</li>
						<li>Concert</li>
						<li>Maraton</li>
						<li>Revelion</li>
						<li>Petrecere</li>
					</ul>

					<strong>Corporate:</strong>
					<ul>
						<li>Conferință</li>
						<li>Team Building</li>
						<li>Petrecere Corporate</li>
					</ul>

					<strong>Speciale:</strong>
					<ul>
						<li>Bal absolvire</li>
						<li>Ceremonie absolvire</li>
						<li>Bal de boboci</li>
						<li>Revedere</li>
					</ul>

					<p>Pentru a afla mai multe detalii si pentru a începe o colaborare cu noi vă rugăm sa accesați site-ul nostru:</p>

					<ConfirmButton
						style={{width:'100%'}}
						text={"Evenimente business"}
						function={() => this.openLink('https://ality.ro/evenimente-business/')}
						/>


				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default IntermediatePageOrganizer;