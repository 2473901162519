import React from 'react'
import { Button, Icon } from 'rsuite'

import './EventData.scss'
import AdModal from '../../../components/AdModal';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { SiWaze, SiGooglemaps } from 'react-icons/si';
import { getUrlFromImage } from '../../../components/Helper';
import { locationTypes } from '../../../components/Constants';
import Loader from "../../../components/Loader";
import moment from "moment-timezone";
import {FormattedMessage} from "react-intl";

class EventData extends React.Component {
	state = {
		event_data: this.props.event_data,
		loading: true,

		showAdModal: this.props.showAdModal
	}

	componentDidMount = async () => {
		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}

		this.setState({
			loading: false
		})
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	maps = (x, y) => {
		this.openLink("http://maps.google.com/?q=" + x + "," + y);
	}

	waze = (x, y) => {
		this.openLink("https://www.waze.com/ul?ll=" + x + "%2C" + y + "&navigate=yes&zoom=17");
	}

	closeAdModal = () => {
		this.props.closeAd()

		this.setState({
			showAdModal: false
		})
	}



	render() {
		if (this.state.showAdModal) {
			return <div className="EventData-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		if (this.state.loading === true) {
			return <div className="EventData-container">
				<Loader/>
			</div>
		}
		
		let data = this.state.event_data.data_eveniment.split("T")[0].split("-")

		let maxLettersToShow = 70

		return (
			<div className="GeneralTab-container">
				{
					this.state.event_data.imagine_eveniment && this.state.event_data.imagine_eveniment.length > 0 ?
						<div className="Image" >
							<img alt={""} src={"https://" + process.env.REACT_APP_IMAGE_DOMAIN + getUrlFromImage(this.state.event_data.imagine_eveniment[0])}/>
						</div>
						: null
				}

				<div className="Data"> {data[2]}-{data[1]}-{data[0]} </div>

				<div className="Title"> {this.state.eventType} {this.state.event_data.title} </div>

				<div className={this.state.showFullDescription ? "Full-Description" : "Description"}>
					{this.state.event_data.descriere.length > maxLettersToShow && !this.state.showFullDescription
						? <div> {this.state.event_data.descriere.substring(0, maxLettersToShow)}...</div>
						: this.state.event_data.descriere
					}
				</div>

				<div style={{ alignSelf: "center", color: "black" }}>
					{
						this.state.event_data.descriere.length > maxLettersToShow
							? <p onClick={() => { this.state.showFullDescription ? this.setState({ showFullDescription: false }) : this.setState({ showFullDescription: true }) }} style={{ fontSize: "11px" }}>
								{this.state.showFullDescription ? <FormattedMessage id={"event_page.general_tab.less_details"} /> : <FormattedMessage id={"event_page.general_tab.more_details"} />}
								{this.state.showFullDescription ? <BsChevronUp /> : <BsChevronDown />}
							</p>
							: <p></p>
					}
				</div>

				{this.props.locations.map((location, index) => {

					let time = moment(location.time)
					let day = moment(location.time)

					if(!location.time && location.date) {
						time = moment(location.date)
						day = moment(location.date)
					}

					if (location.day !== undefined) {
						day = moment(location.day, 'DD-MM-YYYY')
					}

					return <div className="Location" key={index}>
						<div className="Type"> <FormattedMessage id={"event_page.general_tab.location"} /> {locationTypes[location.name.toLowerCase()]} </div>

						<div className="Address"> {location.location} </div>

						<div className="Address"> {location.label ? location.label : location.address} </div>

						<div className="Data-Line">
							<div className="Data"> {moment(time).format('HH:mm')} </div>

							<div className="Data"> {moment(day).format('DD-MM-YYYY')}</div>
						</div>

						{location.pinx && location.piny ?
							<div className="Data-Line">
								<Button onClick={() => this.waze(location.pinx, location.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#05AFF2", width: "150px", marginRight: "20px" }}>
									<SiWaze style={{ marginRight: "5px" }} /> Waze
								</Button>

								<Button onClick={() => this.maps(location.pinx, location.piny)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#19B899", width: "150px" }}>
									<SiGooglemaps style={{ marginRight: "5px" }} /> Google Maps
								</Button>
							</div>:null}
					</div>
				})}

				{this.props.event_data.tickets_needed && <div className="Buy-Ticket" onClick={this.props.goToTickets}>
					<Icon icon="ticket" style={{ color: "white", marginRight: "10px" }} />
					<FormattedMessage id={"event_page.general_tab.buy_ticket"} />
				</div>}

				{
					this.props.event_data.tip_eveniment === 'conferinta' || this.props.event_data.tip_eveniment === 'team_building' || this.props.event_data.tip_eveniment === 'corporate_party' ?
						<div className="Buy-Ticket" onClick={this.props.goToInvites}>
							<Icon icon="ticket" style={{color: "white", marginRight: "10px"}}/>
							<FormattedMessage id={"public_event.general_tab.register_now"} />
						</div>
						: null
				}

			</div>
		)
	}
}

export default EventData;
