import React from 'react'
import './FeedBox.scss'
import Linkify from 'react-linkify';
import {timeSince} from "../../../functions";

class FeedBox extends React.Component {
	state = {
		bottomRef: React.createRef(),
	}

	componentDidMount = () => {
		this.state.bottomRef.current.scrollIntoView()
	}

	componentDidUpdate = () => {
		this.state.bottomRef.current.scrollIntoView()
	}

	timestampToReadableFormat = (timestamp) => {
		return timeSince(new Date(timestamp))
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	showPost = (post, index) => {
		const componentDecorator = (href, text, key) => {
			return <a onClick={() => this.openLink(text)} href={href} key={key} target="_blank" rel="noopener noreferrer">
				{text}
			</a>
		};

		return <div className={post.user.id === this.props.userId ? "FeedBox-Post-User" : "FeedBox-Post-Others"}>
			{post.user.id !== this.props.userId && <div style={{ fontWeight: "bold" }}>{post.user.first_name} {post.user.last_name}</div>}

			<div className={"message"}>
				{post.link_imagine !== "" &&
					<img style={{ padding: "5px", width: "150px", height: "150px" }} src={post.link_imagine} onClick={() => this.props.setShowImage(index)} alt="" />
				}

				<Linkify componentDecorator={componentDecorator}>
					{post.mesaj}
				</Linkify>
			</div>

			<div style={{ alignSelf: "flex-end", fontSize: "10px" }}>{this.timestampToReadableFormat(post.timestamp)}</div>
		</div>
	}

	render() {
		return (
			<div className="FeedBox-container">
				{this.props.posts.map((post, index) => {
					return <div key={post.id} style={{ width: "fit-content", alignSelf: post.user.id === this.props.userId ? "flex-end" : "flex-start" }}>
						{this.showPost(post, index)}
					</div>
				})}
				<div ref={this.state.bottomRef}></div>
			</div>
		)
	}
}

export default FeedBox;