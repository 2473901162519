import React, {useState} from "react";
import "./ShareComp.scss"
import {EmailShareButton, WhatsappShareButton} from "react-share";
import whatsapp from "../../organizer/assets/icons/whatsapp.svg";
import gmail from "../../organizer/assets/icons/gmail.svg";

import Modal from 'react-responsive-modal'
import {FormattedMessage} from "react-intl";
export default function ShareComp({url, message}) {

    const [showModal, setShowModal] = useState(false)


    return (
        <div className={"share-comp-cont"}>

            <button className={"share-button"} onClick={() => {
                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'shareUrl',
                        url: url,
                    }))
                } else {
                    setShowModal(true)
                }
            }}>
                <FormattedMessage id={"share_comp.share"} />
            </button>

            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'share-comp-modal',
                closeButton: 'modal-close-button',
            }} open={showModal} onClose={() => setShowModal(false)} center>

                <h5><FormattedMessage id={"share_comp.share"} /></h5>
                <br/>
                <div id="icons">

                    <WhatsappShareButton title={message} separator=" " url={url}
                                         windowWidth={"1000"}
                                         windowHeight={"600"}>
                        <img id="whatsapp" src={whatsapp} alt="" />
                    </WhatsappShareButton>

                    <EmailShareButton url={url}>
                        <img id="messenger" src={gmail} alt="" />
                    </EmailShareButton>
                </div>
            </Modal>

        </div>
    )
}
