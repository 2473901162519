import React from 'react'
import HeaderWhite from '../../components/HeaderWhite'
import NavbarColor from '../../components/NavbarColor'
import './NoPremiumPackage.scss'
import {ConfirmButton} from "../components/Helper";

class NoPremiumPackage extends React.Component {

    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        return (
            <div className="NoPremiumPackage-container">
                <HeaderWhite shadow={true} goBack={this.goBack}/>

                <div className={"NoPremiumPackage-contents"}>
                    <br/>
                    <h4> Pentru a accesa această opțiune trebuie să achiziționați pachetul PREMIUM.</h4>
                    <br/>
                    <p>

                        <ConfirmButton
                            style={{width:'100%', marginTop:'30px'}}
                            text={"Achiziționează pachetul PREMIUM"}
                            function={() => {
                                this.props.history.push('/organizer/package/'+this.props.match.params.id)
                            }}
                            />

                    </p>
                </div>

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default NoPremiumPackage;
