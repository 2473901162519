import React, {useEffect, useState} from "react";
import logo from "../assets/logo.png";
import {Button} from "rsuite";
import Modal from "react-responsive-modal";

export default function UpdateAppModal() {
    const [mobileModalVisible, setMobileModalVisible] = useState(false)
    const [operatingSystem, setOperatingSystem] = useState('')

    useEffect(() => {
        init()
        // eslint-disable-next-line
    }, [])

    const init = async () => {
        setTimeout(() => {
            let os = getMobileOperatingSystem()

            if(window.location.href && (window.location.href.includes('invitation') || window.location.href.includes('saveTheDate'))) {
                return
            }


            // Condition
            // Check if this is the older app
            if(window.flutter_inappwebview || (window.alityAppView && window.alityAppVersionNr && window.alityAppVersionNr < 19)) {

                if (os === 'iOS' || os === 'Android') {
                    setOperatingSystem(getMobileOperatingSystem())
                    setMobileModalVisible(true)
                }
            }

        }, 4000)
    }

    const getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
            return "Windows Phone";
        }

        if (/android/i.test(userAgent)) {
            return "Android";
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return "iOS";
        }

        return "unknown";
    }

   const openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    return (
        <Modal
            classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-profile',
                closeButton: 'modal-close-button',
            }}
            center
            open={mobileModalVisible}
            onClose={() => setMobileModalVisible(false)}
            focusTrapped={false}
        >
            <div>

                <img src={logo} style={{ height: "50px" }} alt="logo" />
                <br/><br/><br/>
                <h5>Vă rugăm să actualizați aplicația!</h5>
                <br/>
                <h7>Lucrăm constant pentru a îmbunătăți performanțele aplicației și a asigura o experiență cât mai plăcută în timpul utilizării acesteia.</h7>
                <br/>
                {
                    operatingSystem === 'iOS' ?
                            <Button color="green" style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: '30px'
                            }} onClick={() => openLink("https://apps.apple.com/ro/app/ality/id1575378627")}>Actualizează aplicația</Button>
                        :
                            <Button color="green" style={{
                                backgroundColor: "#05AFF2",
                                borderRadius: "20px",
                                width: "100%",
                                marginTop: '30px'
                            }} onClick={() => openLink("https://play.google.com/store/apps/details?id=com.creative.ality")}>Actualizează aplicația</Button>
                }
            </div>
        </Modal>
    )
}
