import React from 'react'
import SettingCard from '../Profile/components/SettingCard'
import './ExtraServicesPage.scss'

import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import { Button } from 'rsuite'

import SVG_Face_Recog from '../../assets/ExtraServicesSVGs/face-recog-icon.svg'
import SVG_Testimoniale_Video from '../../assets/ExtraServicesSVGs/testimoniale-video-icon.svg'
import SVG_Memory_Booth from '../../assets/ExtraServicesSVGs/memory-booth-icon.svg'

class ExtraServicesPage extends React.Component {
	goToFaceRecog = () => {
		this.props.history.push('/organizer/faceRecog/' + this.props.match.params.id)
	}

	goToTestimoniale = () => {
		this.props.history.push('/organizer/testimonials/main/' + this.props.match.params.id)
	}

	goToMemoryBooth = () => {
		this.props.history.push('/organizer/memorybooth/main/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.push("/organizer/adminEventPage/" + this.props.match.params.id)
	}

	render() {
		return (
			<div className="ExtraServicesPage-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<div className="Title">Servicii extra</div>

				<div className="ExtraServicesPage-contents">
					<SettingCard svg={SVG_Face_Recog} onClick={this.goToFaceRecog} name="Face recognition" />

					<SettingCard svg={SVG_Testimoniale_Video} onClick={this.goToTestimoniale} name="Testimoniale video" />

					<SettingCard svg={SVG_Memory_Booth} onClick={this.goToMemoryBooth} name="Memory booth" />

					<div>
						<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
							Înapoi
						</Button>
					</div>
				</div>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default ExtraServicesPage;