import React from 'react'
import { Icon } from 'rsuite'
import { toast } from 'react-toastify'
import './ContactUs.scss'
import axios from 'axios'
import CustomInput from '../components/custom/CustomInput'
import { ConfirmButton } from '../components/Helper'
import Modal from "react-responsive-modal";
import {FormattedMessage} from "react-intl";
import {injectIntl} from 'react-intl';

class ContactUs extends React.Component {

	state = {
		event_data: this.props.event_data,
		is_sending: false,
		nume: "",
		phone: "",
		title: "",
		text: "",
		showResponseModal: this.props.unreadMessages && this.props.unreadMessages > 0
	}

	setter = (key, value) => {
		this.setState({ [key]: value })
	}

	changeText = (value) => {
		this.setState({
			text: value
		})
	}

	sendMessage = async () => {
		//check validity
		if (this.state.title === "") {
			toast.error(<FormattedMessage id={"event_page.contact_us.alert.add_title"} />)
			return
		}

		if (this.state.text === "") {
			toast.error(<FormattedMessage id={"event_page.contact_us.alert.add_content"} />)
			return
		}

		if (this.state.is_sending) {
			return
		}

		this.setState({
			is_sending: true
		})

		//send data
		var obj = {
			nume: this.state.nume,
			prenume: "",
			email: this.props.user_data.email,
			telefon: this.state.phone,
			facultate: this.state.event_data.title,
			titlu: this.state.title,
			mesaj: this.state.text,
			id_eveniment: this.state.event_data.id_eveniment
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//show success/fail message
		await axios.post(process.env.REACT_APP_DOMAIN + "contactUs/addMessage", obj, auth)
			.then((response) => {
				toast.success(<FormattedMessage id={"event_page.contact_us.message_sent"} />)
			})
			.catch(err => {
				toast.error(<FormattedMessage id={"general.alerts.error_occurred"} />)
				return
			})

		//empty fields
		this.setState({
			is_sending: false,
			nume: "",
			phone: "",
			title: "",
			text: ""
		})
	}

	getInfoRow = (icon, name, text) => {
		return <div className="Row">
			<Icon icon={icon} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />

			<div className="Data">
				<div>{name}</div>

				<p style={{ color: "#121213", fontSize: "16px" }}>{text}</p>
			</div>
		</div>
	}

	closeResponseModal = async () => {
		this.setState({showResponseModal: false})

		var obj = {
			id_eveniment: this.state.event_data.id_eveniment
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + "contactUs/markAsCustomerSeen", obj, auth)
			.then((response) => {
				this.props.clearUnreadMessages()
			})
			.catch(err => {

			})
	}

	render() {

		return (
			<div className="ContactUs-container">
				<div className="ContactUs-contents">
					<div className="Section">
						{this.getInfoRow("at", <FormattedMessage id={"general.email"} />, this.props.user_data.email)}

						{this.getInfoRow("frame", <FormattedMessage id={"event_page.contact_us.event_name"} />, this.state.event_data.title)}
					</div>

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.nume} onChange={value => this.setter("nume", value)} placeholder={this.props.intl.formatMessage({id: 'general.last_name'})}
						icon={<Icon icon={"avatar"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />
					<CustomInput style={{ margin: "10px 0px" }} value={this.state.phone} onChange={value => this.setter("phone", value)} placeholder={this.props.intl.formatMessage({id: 'general.phone'}) + " (" + this.props.intl.formatMessage({id: 'general.optional'}) + ")"}
						inputType={"number"} icon={<Icon icon={"phone"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.title} onChange={value => this.setter("title", value)} placeholder={this.props.intl.formatMessage({id: 'event_page.contact_us.title'})}
						icon={<Icon icon={"pencil"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.text} onChange={this.changeText} placeholder={this.props.intl.formatMessage({id: 'event_page.contact_us.write_a_message'})}
						componentClass={"textarea"} icon={<Icon icon={"pencil"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<ConfirmButton loading={this.state.is_sending} text={this.props.intl.formatMessage({id: 'event_page.contact_us.send'})} function={this.sendMessage} />
				</div>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.showResponseModal} onClose={() => this.closeResponseModal()} center focusTrapped={false}>


					<div style={{marginTop:'20px'}}>
						{this.props.intl.formatMessage({id: 'event_page.contact_us.message_received_from_organizer'})}
					</div>

					<ConfirmButton text={"Ok"} function={() => this.closeResponseModal()} />

				</Modal>

			</div>
		)
	}
}

export default injectIntl(ContactUs);
