import React from 'react'
import {Button, Icon} from 'rsuite'
import './Tickets.scss'

import { toast } from 'react-toastify'
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/react";
import {isJsonString} from "../../../functions";
import GoogleWebLoginButton from "../../../components/GoogleLogin/GoogleWebLoginButton";
import {FormattedMessage, injectIntl} from 'react-intl';

class Tickets extends React.Component {

	isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

	state = {
		ticketsTypes: [],
		displayTickets: [],
		chosenTickets: [],
		chosenTicketsAmount: [],
		count: [1, 2],

		canBuy: false
	}

	onAppMessage = async (message) => {

		let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false

		if(data) {
			// eslint-disable-next-line
			switch (data.action) {
				case 'signInWithApple':
					this.appleSignIn(data.firstName, data.lastName, data.email, data.accessToken)
					break
				case 'signInWithFacebook':
					this.facebookSignIn({accessToken: data.accessToken})
					break
				case 'signInWithGoogle':
					this.googleSignIn({tokenId: data.accessToken})
					break
			}
		}
	}


	componentDidMount = () => {
		[window, document].forEach(
			el => el.addEventListener('message', this.onAppMessage))

		var canBuyTickets = this.props.event_data.sell_tickets

		if (canBuyTickets) {
			var newTicketTypes = []

			if (this.props.event_data.tickets_types) {
				this.props.event_data.tickets_types.map((ticket, index) => newTicketTypes.push({
					ticketTitle: ticket.title,
					code: ticket.code,
					description: ticket.description,
					id: index
				}))
			}


			this.setState({
				ticketsTypes: newTicketTypes,
				displayTickets: newTicketTypes
			})
		}

		this.setState({
			canBuy: canBuyTickets
		})
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.onAppMessage)
		document.removeEventListener("message", this.onAppMessage)
	}

	requestPushToken = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}
		if(window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('getPushNotificationToken')
				.then(token => {

					let data = {
						token: token
					}

					axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
				})
		}

		if(window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'getPushToken',
				data: localStorage.getItem('jwt')
			}))
		}
	}

	loginGoogle = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginGoogle')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
						}
					} else {
						this.googleSignIn({tokenId: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithGoogle'
			}))
		}
	}

	googleSignIn = (response) => {

		if (!response.tokenId) {
			return;
		}

		let tokenId = response.tokenId
		//tokenId = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE4MmU0NTBhMzVhMjA4MWZhYTFkOWFlMWQyZDc1YTBmMjNkOTFkZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE3MDU1NjE0NDAyMTA4NTczOTI3IiwiZW1haWwiOiJ2bGFkLml2YW5jaXUyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiQVFtbllaazQ5czRoZk5VWHV1dW53dyIsIm5hbWUiOiJWbGFkIEl2YW5jaXUiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKeEJpMm9yVktacnlrY1NwRHJnRjh2T3lUM0JVLXg1OTdsV2xBM2g0UT1zOTYtYyIsImdpdmVuX25hbWUiOiJWbGFkIiwiZmFtaWx5X25hbWUiOiJJdmFuY2l1IiwibG9jYWxlIjoicm8iLCJpYXQiOjE2NDQ0MDYzNjgsImV4cCI6MTY0NDQwOTk2OCwianRpIjoiYTA5Mjk3OTBhODliMDliMDVhZDA0MzY3NzM4YjQ0ZDIzOGM1OWU3YSJ9.GfRbRzpfxQVz5OR5T3N_1RvXljePBvrHUwhqAUetJ9_YgLZCulsiKkbuWznKlgtPIVn_6IeF_HE6jxbgpgqDg_0w5KqsKLAp18Tc0ICZqsOhOwCrQYw9USajjc4YmgFMtO_rtytDqvAx67jZFPsnnNarcVUXlni5gmg1mmsRMNw_xnaGc7w9T0wOqTz36og_z2spjxzSNrK3Gp6BE2wy5mt0KvpYFKq8nJJOJLp8FHMLKLhCKUOGiRK8bnHc_RFJ3x8WxmYwBIT9floaS-4YII5mSSN9y2XLYLVKnPa_N2mWinNNe-DsNkby1QJQ8lw2mW5gGoGTuwCFJiSkBCdTMA"

		let obj = {
			tokenId: tokenId
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/google', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', "remember")

					this.requestPushToken()


					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.props.event_data) {
						this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	loginFacebook = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginFacebook')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
						}
					} else {
						this.facebookSignIn({accessToken: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithFacebook'
			}))
		}
	}

	facebookSignIn = (response) => {

		if (!response.accessToken) {
			return;
		}

		let accessToken = response.accessToken

		let obj = {
			accessToken: accessToken
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/facebook', obj)
			.then(async(response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()


					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.props.event_data) {
						this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	appleSignIn = (firstName, lastName, email, accessToken) => {

		let obj = {
			accessToken: accessToken,
			email: email,
			first_name: firstName,
			last_name: lastName
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/apple', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.props.event_data) {
						this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	loginApple = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginApple')
				.then(data => {
					let response = data.split('|')
					this.appleSignIn(response[2], response['1'], response['3'], response['0'])
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithApple'
			}))
		}
	}

	selectTicket = (index) => {
		var newDisplayTickets = [...this.state.displayTickets]

		var newChosenTickets = [...this.state.chosenTickets, this.state.displayTickets[index]]
		var newChosenTicketsAmount = [...this.state.chosenTicketsAmount, ""]

		newDisplayTickets.splice(index, 1)

		this.setState({
			displayTickets: newDisplayTickets,
			chosenTickets: newChosenTickets,
			chosenTicketsAmount: newChosenTicketsAmount
		})
	}

	unselectTicket = (index) => {
		var newChosenTickets = [...this.state.chosenTickets]
		newChosenTickets.splice(index, 1)

		var newChosenTicketsAmount = [...this.state.chosenTicketsAmount]
		newChosenTicketsAmount.splice(index, 1)

		var newDisplayTickets = [...this.state.ticketsTypes]

		newChosenTickets.map(ticket => newDisplayTickets = newDisplayTickets.filter(elem => elem.id !== ticket.id))

		this.setState({
			displayTickets: newDisplayTickets,
			chosenTickets: newChosenTickets,
			chosenTicketsAmount: newChosenTicketsAmount
		})
	}

	setAmount = (index, amount) => {
		var newChosenTicketsAmount = [...this.state.chosenTicketsAmount]

		newChosenTicketsAmount[index] = amount

		this.setState({
			chosenTicketsAmount: newChosenTicketsAmount
		})
	}


	goToLogin = () => {
		localStorage.setItem('buyingTickets', true);
		localStorage.setItem('eventId', this.props.event_data.id_eveniment);
		this.props.history.push("/login")
	}

	goToSignup = () => {
		localStorage.setItem('buyingTickets', true);
		localStorage.setItem('eventId', this.props.event_data.id_eveniment);
		this.props.history.push("/signup")
	}

	render() {
		return (
			<div className="Tickets-container-p">

				{
					this.state.loading ?
						<div className={"loading-overlay"}>
							<BeatLoader color={"#00B7AD"} loading={true} css={css} size={30} />
							<span><FormattedMessage id={"general.loading"} /></span>
						</div>
						:
						null
				}

				<div className="Tickets-content">
					{/* {this.state.canBuy && <div>
						{this.state.displayTickets.length > 0
							? <Dropdown title={"Adaugati bilet"} className="dropdown_scroll" style={{ border: "1px solid black", margin: "20px 0px" }}>
								{this.state.displayTickets.map((option, index) => {
									return <Dropdown.Item key={index} eventKey={option.ticketTitle} onClick={() => this.selectTicket(index)}>{option.ticketTitle}</Dropdown.Item>
								})}
							</Dropdown>
							: <p style={{marginBottom: "30px"}}>Niciun bilet adaugat de organizator</p>
						}

						{this.state.chosenTickets.map((choice, index) => {
							return <div key={index} style={{ margin: "20px 0px", border: "1px solid black", padding: "10px" }}>
								<p>Bilet: {choice.ticketTitle}</p>

								<div style={{ maxHeight: "100px", overflowX: "scroll", wordBreak: "break-word" }}>
									{choice.description}
								</div>

								<div>
									{this.state.count.map((elem, ind) => {
										return <Button key={ind} appearance={this.state.chosenTicketsAmount[index] === elem ? "default" : "ghost"}
											color="green" style={{ margin: "20px 10px" }} onClick={() => this.setAmount(index, elem)}>
											{elem}
										</Button>
									})}
								</div>

								<Button color="red" onClick={() => this.unselectTicket(index)} >Sterge Bilet</Button>
							</div>
						})}
					</div>} */}

					{this.state.canBuy && <div style={{ display: "flex", flexDirection: "column" }}>
						<p><FormattedMessage id={"public_event.tickets.intro"} /></p>

						<Button style={{ margin: "20px auto" }} color="green" onClick={this.goToLogin}><FormattedMessage id={"public_event.tickets.log_in"} /></Button>

						<div className={"social-login-container"}>
						{
							window.flutter_inappwebview || window.alityAppView ?

								<div style={{flexDirection:'column',width:'100%'}}>
									<button onClick={this.loginGoogle}
											className={"social-login-button social-google-login"}
											style={{width:'100%'}}>
										<Icon icon="google"/> Login Google
									</button>

									<button onClick={this.loginFacebook}
											className={"social-login-button social-facebook-login"}
											style={{width:'100%', marginTop:'10px'}}
									>
										<Icon icon="facebook"/> Login Facebook
									</button>

									{
										this.isIOS ?
											<button onClick={this.loginApple}
													className={"social-login-button social-apple-login"}
													style={{width: '100%', marginTop: '10px'}}
											>
												<Icon icon="apple"/> Login Apple
											</button>
											: null
									}
								</div>
								:
								<>

									<GoogleWebLoginButton
										callbackFunction={(val) => this.googleSignIn(val)}
									/>

									<FacebookLogin
										appId="243653927956471"
										autoLoad={false}
										fields="name,email"
										//onClick={(item) => this.facebookSignIn(item)}
										callback={(item) => this.facebookSignIn(item)}
										//cookie
										//
										disableMobileRedirect={true}
										//isMobile={true}
										redirectUri={'https://app.ality.ro'}
										render={renderProps => (
											<button
												className={"social-login-button social-facebook-login"}
												style={{marginLeft: '5px'}}
												type={"button"}
												onClick={renderProps.onClick}
												disabled={renderProps.disabled}
											><Icon icon="facebook"/> Facebook</button>
										)}
									/>
								</>
						}
						</div>
						<Button style={{ margin: "20px auto" }} color="green" onClick={this.goToSignup}><FormattedMessage id={"public_event.tickets.register"} /></Button>
					</div>}

					{!this.state.canBuy && <div><FormattedMessage id={"public_event.tickets.cannot_buy"} /></div>}
				</div>
			</div>
		)
	}
}

export default injectIntl(Tickets);
