import React from 'react'
import axios from 'axios'



class CustomTransaction extends React.Component {
    state = {
        transaction: false,
        netopiaDetails: false,

        loading: true
    }

    constructor() {
        super();
        this.netopiaForm = React.createRef();
    }

    componentDidMount = async () => {
        this.initPayment()
    }

    initPayment = async () => {

            let payload = {
                transactionUid: this.props.match.params.id
            }

            // get packages data
            await axios.post(process.env.REACT_APP_DOMAIN + 'transactions/payCustomTransaction', payload)
                .then(response => {
                    if (response.data.data) {
                        this.setState({
                            netopiaDetails: response.data
                        })
                    }
                })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.netopiaDetails !== this.state.netopiaDetails && this.state.netopiaDetails !== false) {
            document.getElementById('netopiaForm').submit();
            this.netopiaForm.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }))
        }
    }

    render() {
        return <div className="Package-container">

                <form id={"netopiaForm"} method={"post"} ref={(ref) => { this.netopiaForm = ref; }} action={this.state.netopiaDetails ? this.state.netopiaDetails.redirectUrl : ''}>
                    {
                        this.state.netopiaDetails !== false ?
                            <>
                                <input type={"hidden"} name={"env_key"} value={this.state.netopiaDetails.envKey} />
                                <input type={"hidden"} name={"data"} value={this.state.netopiaDetails.data} />
                            </>
                            : null
                    }
                </form>

        </div>
    }
}

export default CustomTransaction;