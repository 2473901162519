import React, {useContext, useEffect, useState} from 'react'
import './NotificationsScreen.scss'
import HeaderWhite from "../../components/HeaderWhite";
import NavbarColor from "../../components/NavbarColor";
import {useHistory} from "react-router-dom";
import moment from "moment-timezone";
import axios from "axios";
import LanguageContext from "../../contexts/LanguageContext";
import Loader from "../../components/Loader";
import {toast} from "react-toastify";


export default function NotificationsScreen () {
    let history = useHistory();
    // eslint-disable-next-line
    const {unreadNotifications, setUnreadNotifications} = useContext(LanguageContext)

    const [notifications, setNotifications] = useState([])
    const [initialLoading, setInitialLoading] = useState(true)

    useEffect(() => {
        getNotifications()
// eslint-disable-next-line
    }, []);

    const getNotifications = async () => {
        let jwt = localStorage.getItem('jwt')

        if(jwt) {
            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            axios.get(process.env.REACT_APP_DOMAIN + 'notifications/list', auth)
                .then(response => {
                    if(response && response.data && response.data.status === 1) {
                        setNotifications(response.data.notifications)
                        setUnreadNotifications(0)
                    }
                    setInitialLoading(false)
                })
                .catch(err => {
                    if(err && err.response && err.response.status === 401) {
                        localStorage.clear()
                        toast.error("Informațiile de autentificare au expirat, vă rugăm să vă autentificați!")
                        history.push('/login');
                    } else {
                        toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                    }
                })
        } else {
            history.push('/login');
        }
    }

    const hasAction = (notification) => {

        if(notification.data &&
            (notification.data.eventId
                || notification.data.news
                || notification.data.weddingId
                || notification.data.gift
                || notification.data.response
                || notification.data.responseInvite
                || notification.data.responseSaveTheDate
                || notification.data.weddingAdmin
                || notification.data.weddingTasksAdmin
                || notification.data.feedbackForm
                || notification.data.customUrl))
            {

                return true
            } else {
                return false
            }
        }

    return (
        <div className="Notifications-Screen-container">
            <HeaderWhite goBack={() => history.goBack()}/>



            <div className="Notifications-Screen-contents">
                {initialLoading ?
                    <Loader/>
                    :
                    <div className={"notifications-list"}>
                        {notifications && notifications.length > 0 ? notifications.map((notification,i) => (
                            <div key={i}
                                 className={"notification-item " + (notification.seen ? "" : "unseen") + " " +
                                     (hasAction(notification) ? "action" : "")}
                            onClick={() => {

                                let data = notification.data
                                if(data) {
                                    if(data.eventId) {
                                        history.push('/eventPage/' + data.eventId)
                                    }
                                    if (data.news) {
                                        history.push('/news/' )
                                    }

                                    if (data.weddingId) {
                                        history.push('/eventPageW/' + data.weddingId)
                                    }

                                    if (data.gift) {
                                        history.push('/organizer/plan/gifts/' + data.weddingIdOrganiser)
                                    }

                                    //No longer used
                                    if (data.response) {
                                        history.push('/organizer/guests/' + data.weddingIdOrganiser)
                                    }

                                    if (data.responseInvite) {
                                        history.push('/organizer/raspunsuriInvitati/' + data.weddingIdOrganiser )
                                    }

                                    if (data.responseSaveTheDate) {
                                        history.push('/organizer/raspunsuriSaveTheDate/' + data.weddingIdOrganiser)
                                    }

                                    if (data.weddingAdmin) {
                                        history.push('/organizer/adminEventPage/' + data.weddingIdOrganiser)
                                    }

                                    if (data.weddingTasksAdmin) {
                                        history.push('/organizer/plan/tasks/' + data.weddingIdOrganiser)
                                    }

                                    if (data.feedbackForm) {
                                        history.push('/feedbackForm')
                                    }

                                    if (data.customUrl) {
                                        history.push(data.customUrl.replace('https://app.ality.ro', ''))
                                    }


                                }

                            }}>
                                <div className={"left-col"}>
                                    <img src={"/images/icons/bell.svg"} style={{width:'25px', marginTop:'2px'}} alt={"notification"} />
                                </div>
                                <div className={"right-col"}>
                                    <div className={"title"}>{notification.title}</div>
                                    <div className={"message"}>{notification.message}</div>
                                    <div className={"date"}>{moment(notification.created_at).format('HH:mm DD/MM/YYYY')}</div>
                                </div>
                            </div>)):
                        <div style={{marginTop:'3rem'}}>Nu ați primit nicio notificare.</div>
                        }
                    </div>}
            </div>


            <NavbarColor history={history} />
        </div>
    )

}
