import React from 'react'
import './MemoryBoothTab.scss'
import {Icon} from 'rsuite'
import fileDownload from 'js-file-download'
import {toast} from 'react-toastify';
import axios from 'axios'

class MemoryBoothTab extends React.Component {
    state = {

        items: this.props.event_data.memory_booth_files

    }

    downloadImage = (url) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('download_image', 'down_img', url).then(res => {
                if (res === "fail") {
                    toast.error("Descarcare esuata")
                } else {
                    toast.success("Imagine descarcata")
                }
            })
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'downloadMedia',
                url: url,
            }))
        } else {
            var parts = url.split("/")
            var name = parts[parts.length - 1]

            axios.get(url, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, name)
                })
        }
    }


    render() {

        return (
            <div className="MemoryBoothTab-container">
                <div className="MemoryBoothTab-Feed">

                    {
                        this.state.items && this.state.items.map((item, i) => (
                            <div className={"item"}>
                                <div className={"head"}>

                                    <span
                                        className={"sender"}>{item.name !== null && item.name !== '' && item.name !== 'null' ? item.name : 'Anonim'}</span>
                                    {
                                        item.secondaryRecipient && item.secondaryRecipient !== '' ?
                                            <> <span className={"to"}>catre</span> <span
                                                className={"recipient"}>{item.secondaryRecipient}</span></> : ''
                                    }

                                </div>

                                <div className={"preview"}>
                                    <div className={"row"}>
                                        <div className={"left"}>
                                            {
                                                item.url.includes('.gif') || item.url.includes('.png') || item.url.includes('.jpg') ?
                                                    <img src={item.url} alt={""}/>
                                                    : null
                                            }

                                            {
                                                item.url.includes('.mp4') ?
                                                    <video controls style={{width:'100%'}}>
                                                        <source src={item.url} type={"video/mp4"}/>
                                                    </video>
                                                    : null
                                            }
                                        </div>


                                                <div className={"right"}>
                                                    <div className={"btn-download"} onClick={() => {
                                                        this.downloadImage(item.url)
                                                    }}>
                                                        <Icon icon="download"/>
                                                    </div>
                                                </div>


                                    </div>

                                </div>


                            </div>
                        ))
                    }

                </div>
            </div>
        )
    }
}

export default MemoryBoothTab;
