import React from 'react'
import { Button } from 'rsuite'
import axios from "axios"
import Modal from 'react-responsive-modal'

import './BalVotes.scss'
import { toast } from 'react-toastify'
import { getUrlFromImage } from '../../components/Helper'

let isSendingVote = false

class BalVotes extends React.Component {
	state = {
		event_data: this.props.event_data,
		is_published: this.props.event_data.published_ball_votes,
		has_voted: this.props.participantData.ballVote,

		candidates: [],
		maleCandidates: [],
		femaleCandidates: [],

		screenChoice: "",
		maleChoice: { name: "" },
		femaleChoice: { name: "" },

		loading: true,
		showModal: false
	}

	componentDidMount = async () => {
		let candidates = this.props.event_data.prom_candidates

		let maleCandidates = candidates.filter(candidate => candidate.gender === "male")
		let femaleCandidates = candidates.filter(candidate => candidate.gender === "female")

		this.setState({
			candidates: candidates,
			maleCandidates: maleCandidates,
			femaleCandidates: femaleCandidates,
			loading: false
		})
	}

	goToVoteMale = () => {
		this.setState({
			screenChoice: "MaleVote"
		})
	}

	voteMale = (index) => {
		this.setState({
			screenChoice: "",
			maleChoice: this.state.maleCandidates[index]
		})
	}

	goToVoteFemale = () => {
		this.setState({
			screenChoice: "FemaleVote"
		})
	}

	voteFemale = (index) => {
		this.setState({
			screenChoice: "",
			femaleChoice: this.state.femaleCandidates[index]
		})
	}

	back = () => {
		this.setState({
			screenChoice: ""
		})
	}

	sendData = () => {
		if (isSendingVote) {
			return
		}
		isSendingVote = true

		let obj = {
			id_participant: this.props.participantData.id_participant,
			id_male: this.state.maleChoice.id,
			id_female: this.state.femaleChoice.id
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + "events/ballVote", obj, auth)
			.then(response => {
				toast.success("Vot trimis")

				this.props.participantData.ballVote = true
				this.setState({
					has_voted: true
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare va rog reintrati in tab")
			})
	}

	candidateData = (candidateList, saveFunction) => {
		return <div className="BalVotes-contents">
			{candidateList.map((candidate, index) => {
				let link = ""

				if (candidate.image !== null) {
					link = `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(candidate.image)}`
				}

				return <div key={index} className="BalVotes-candidates">
					<div className="Picture">
						<img style={{ height: "90px", width: "90px" }} src={link} alt="Imagine candidat" />
					</div>

					<div className="Text">
						<p>{candidate.name}</p>

						<div>
							<Button color="green" onClick={() => saveFunction(index)}>Voteaza</Button>
						</div>
					</div>
				</div>
			})}

			<Button color="red" style={{ marginTop: "30px" }} onClick={this.back}>
				Inapoi
			</Button>
		</div>
	}

	render() {
		if (this.state.loading) {
			return <div className="BalVotes-container">
				<p style={{ alignSelf: "center" }}>Loading</p>
			</div>
		}

		if (!this.state.is_published) {
			return <div className="BalVotes-container">
				<h4 style={{ alignSelf: "center" }}>Voturile sunt dezactivate in acest moment</h4>
			</div>
		}

		if (this.state.event_data.stop_ball_votes) {
			return <div className="BalVotes-container">
				<h4 style={{ alignSelf: "center" }}>Trimiterea voturilor a fost oprita</h4>
			</div>
		}

		if (this.state.has_voted) {
			return <div className="BalVotes-container">
				<h4 style={{ alignSelf: "center" }}>Ati votat</h4>
			</div>
		}

		let body = <div className="BalVotes-contents">
			{this.state.maleChoice.name !== "" && <p>Vot baiat pentru : {this.state.maleChoice.name}</p>}
			<div>
				<Button color="green" style={{ marginBottom: "20px" }} onClick={this.goToVoteMale}>
					{this.state.maleChoice.name === "" ? "Voteaza Mister" : "Schimba vot"}
				</Button>
			</div>

			{this.state.femaleChoice.name !== "" && <p>Vot fata pentru: {this.state.femaleChoice.name}</p>}
			<div>
				<Button color="green" style={{ marginBottom: "20px" }} onClick={this.goToVoteFemale}>
					{this.state.femaleChoice.name === "" ? "Voteaza Miss" : "Schimba vot"}
				</Button>
			</div>

			{this.state.maleChoice.name !== "" && this.state.femaleChoice.name !== "" && <Button color="green" onClick={() => this.setState({ showModal: true })}>Trimite voturi</Button>}

			<Modal
				classNames={{
					overlay: 'modal-overlay votes',
					modal: 'modal votesSize',
					closeButton: 'modal-close-button',
				}}
				open={this.state.showModal}
				onClose={() => this.setState({ showModal: false })}
				center
			>
				<h4 style={{ color: "red", margin: "40px auto" }}>Sunteti sigur de voturi? Nu pot fi schimbate dupa confirmare</h4>

				<Button color="green" onClick={this.sendData}>Confirm</Button>
			</Modal>
		</div>

		if (this.state.screenChoice === "MaleVote") {
			body = this.candidateData(this.state.maleCandidates, this.voteMale)
		}

		if (this.state.screenChoice === "FemaleVote") {
			body = this.candidateData(this.state.femaleCandidates, this.voteFemale)
		}

		return <div className="BalVotes-container">
			{body}
		</div>
	}
}

export default BalVotes;