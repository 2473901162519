import React from 'react'
import {Button, Dropdown} from 'rsuite'
import './CustomModal.scss'
import {toast} from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../../components/custom/CustomInput'
import {CustomQuestions} from '../../../components/Helper'
import {FormattedMessage} from "react-intl";
import {injectIntl} from 'react-intl';
var sent = false;

class CustomModal extends React.Component {
    state = {
        event_data: this.props.event_data,
        areQuestionsPublished: this.props.event_data.published_questions === true,
        questions: [],
        questionsAnswers: [],
        hasCustomQuestions: false,

        name: this.props.user_data.last_name,
        surname: this.props.user_data.first_name,
        hasClaseSerii: false,
        clase_serii: [],
        seria: "",
        phase: 0,
        createParticipantLoading: false,

        hadNames: false,

        loading: true
    }

    componentDidMount = () => {
        let hasQuestions = this.props.event_data.activate_questions && this.props.event_data.published_questions

        this.setState({
            hasCustomQuestions: hasQuestions
        })

        if (hasQuestions) {
            let questions = []
            let answers = []

            for (var i = 0; i < this.props.event_data.questions.length; i++) {
                let question = {...this.props.event_data.questions[i]}

                let elem = {
                    title: question.title,
                    type: question.type,
                    options: []
                }

                if(typeof question.conditionQuestionIndex !== "undefined" && question.condition && question.conditionQuestionIndex > -1) {
                    elem['condition'] = question.condition
                    elem['conditionQuestionIndex'] = question.conditionQuestionIndex
                }

                if (elem.type === 1 || elem.type === 2)
                    question.options.map(element => {
                        elem.options.push({label: element, value: element})

                        return null
                    })

                questions.push(elem)
                answers.push("")
            }


            this.setState({
                questions: questions,
                questionsAnswers: answers
            })
        }

        if (this.props.event_data.activate_clase_serii) {
            this.setState({
                hasClaseSerii: true,
                clase_serii: this.props.event_data.clase_serii.split("\n")
            })
        }

        if(!this.props.event_data.activate_clase_serii && this.state.name && this.state.name.length > 0 &&
        this.state.surname && this.state.surname.length > 0) {
            if(this.props.showModal) {
                this.setState({

                    hasCustomQuestions: hasQuestions,
                    hadNames: true
                }, () => {
                    this.nextPhase()
                })
                //this.nextPhase()
            }
        } else {
            this.setState({
                loading: false
            })
        }



    }

    setName = (value) => {
        this.setState({
            name: value
        })
    }

    setSurname = (value) => {
        this.setState({
            surname: value
        })
    }

    changeAnswers = (newAnswers) => {
        this.setState({
            questionsAnswers: newAnswers
        })
    }

    saveAnswers = async () => {

        //Validate fields

        let valid = true
        this.state.questionsAnswers.forEach((answer,i) => {

            //Check for conditions
            if(typeof this.state.questions[i].conditionQuestionIndex !== "undefined" && this.state.questions[i].conditionQuestionIndex > -1) {

                    let conditionValid = false

                    //Find conditions
                    let orConditions = this.state.questions[i].condition.split('||')
                    for(let j=0; j<orConditions.length; j++) {

                        if(orConditions.length > 0) {
                            if (this.state.questionsAnswers[this.state.questions[i].conditionQuestionIndex] === orConditions[j]) {
                                conditionValid = true
                            }
                        }
                    }

                    if(conditionValid) {
                        if(!answer || answer.length === 0) {
                            valid = false
                        }
                    }

            } else {
                if(!answer || answer.length === 0) {
                    valid = false
                }
            }

        })

        if(!valid) {
            toast.error(<FormattedMessage id={"event_page.general_tab.custom_modal_update.please_answer_all_questions"} />)
            return
        }


        if (sent) {
            return;
        }

        if (this.state.createParticipantLoading) {
            return;
        } else {
            this.setState({
                createParticipantLoading: true
            })
        }

        let inviteBasedEvent = false
        if(this.props.event_data.tip_eveniment === 'conferinta' ||
            this.props.event_data.tip_eveniment === 'team_building' ||
            this.props.event_data.tip_eveniment === 'corporate_party' ||
            this.props.event_data.questions_requires_approval === true)
        {
            inviteBasedEvent = true
        }



        sent = true;

        let obj = {
            nume: this.state.name,
            prenume: this.state.surname,
            // tip_eveniment: this.props.event_data.tip_eveniment,
            tip_eveniment: this.state.isEventTypeHighschool === true ? "absolvire_liceu" : "absolvire_facultate",
            clasa_serie: this.state.seria,
            id_eveniment: this.props.event_data.id_eveniment,
            id_user: localStorage.getItem("id"),
            cod_eveniment: this.props.event_data.id_eveniment,
            nume_eveniment: this.props.event_data.title,
            email: this.props.user_data.email,
            questions_answers: JSON.stringify(this.state.questionsAnswers),
            last_plan_mese: "",
            last_informatii_utile: "",
            last_link_poze_privat: "",
            last_link_poze_public: "",
            last_feed_count: "0",
            last_feedback_state: "",
            last_announcement_count: "0",
        }

        if(inviteBasedEvent) {
            obj['inviteStatus'] = 4
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + "createParticipant", obj, auth)
            .then(_ => {
                this.setState({
                    createParticipantLoading: false
                })

                if(inviteBasedEvent) {
                    toast.success("Cererea de participare a fost trimisă! Vă mulțumim! ")
                } else {
                    toast.success("Mulțumim pentru participare!")

                }
                sent = false
                if(this.props.event_data.id_eveniment === 'xnmnJmO8tb' || this.props.event_data.id_eveniment === 'JPbCWC2OFX_') {
                    this.props.toggleModal(true)
                } else {
                    this.props.toggleModal();
                }
                return
            })
            .catch(_ => {
                this.setState({
                    createParticipantLoading: false
                })
                toast.error("S-a produs o eroare. Va rugăm reîncercați!")
                sent = false
            })
    }

    nextPhase = () => {
        let currentPhase = this.state.phase

        if (currentPhase === 0) {
            if (!this.state.name || this.state.name.length === 0 || !this.state.surname || this.state.surname.length === 0) {
                toast.error("Va rog completati numele si prenumele")
                return
            }

            if (this.state.hasClaseSerii && this.state.seria === "") {
                toast.error("Va rog alegeti clasa")
                return
            }
        }

        if (!this.state.hasCustomQuestions) {
            this.saveAnswers()
            return
        }

        this.setState({
            phase: currentPhase + 1,
            loading: false
        })
    }

    prevPhase = () => {
        let currentPhase = this.state.phase

        this.setState({
            phase: currentPhase - 1
        })
    }

    render() {
        if (this.state.loading) {
            return <></>
        }

        const styles = {
            fontFamily: "sans-serif",
            textAlign: "center",
        };

        var body = <div></div>

        if (this.state.phase === 0) {
            body = (
                <div>


                    <p><FormattedMessage id={"event_page.general_tab.custom_modal.intro"} /></p>

                    {
                        this.props.event_data.participantsLimit && this.props.event_data.participantsLimit > 0 &&
                        this.props.event_data.confirmedParticipantsCount
                        && this.props.event_data.confirmedParticipantsCount >= this.props.event_data.participantsLimit ?

                            <p style={{color:'red', marginBottom:'30px'}}><FormattedMessage id={"event_page.general_tab.custom_modal.limit_alert"} /></p>
                            :null
                    }

                    {
                        this.state.name && this.state.name.length > 0 &&
                        this.state.surname && this.state.surname.length > 0 ?
                            <>
                                <CustomInput style={{margin: "10px 0px"}} value={this.state.name}
                                             onChange={this.setName} placeholder={this.props.intl.formatMessage({id: "general.last_name"})} />
                                <CustomInput style={{margin: "10px 0px"}} value={this.state.surname}
                                             onChange={this.setSurname} placeholder={this.props.intl.formatMessage({id: "general.first_name"})} />
                            </>
                            :
                            <>
                                <CustomInput style={{margin: "10px 0px"}} value={this.state.name}
                                             onChange={this.setName} placeholder={this.props.intl.formatMessage({id: "general.last_name"})} />
                                <CustomInput style={{margin: "10px 0px"}} value={this.state.surname}
                                             onChange={this.setSurname} placeholder={this.props.intl.formatMessage({id: "general.first_name"})} />
                            </>
                    }

                    {this.state.hasClaseSerii &&
                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                        <Dropdown style={{color: "#221d5d", backgroundColor: "#E5F7FE"}}
                                  title={this.state.seria === "" ? <FormattedMessage id={"event_page.general_tab.custom_modal.grupa_serie"} /> : this.state.seria}
                                  activeKey={this.state.seria} className="dropdown_scroll rs_expandable">
                            {this.state.clase_serii.map((grupa, index) => {
                                return <Dropdown.Item key={index} eventKey={grupa}
                                                      onClick={() => this.setState({seria: grupa})}>{grupa}</Dropdown.Item>
                            })}
                        </Dropdown>
                    </div>
                    }

                    <Button onClick={this.nextPhase} style={{
                        marginTop: '20px',
                        borderRadius: "20px",
                        backgroundColor: "#05AFF2",
                        color: "white",
                        width:"100%"
                    }}>
                        {!this.state.hasCustomQuestions ? <FormattedMessage id={"event_page.general_tab.custom_modal.confirm_participation"} /> : <FormattedMessage id={"event_page.general.continue"} />}
                    </Button>

                </div>
            )
        }

        if (this.state.phase > 0) {
            body = (
                <div className="body">

                    <p><FormattedMessage id={"event_page.general_tab.custom_modal_update.please_fill_in_the_form"} /></p>

                    <CustomQuestions
                        questions={this.state.questions} answers={this.state.questionsAnswers}
                                     changeAnswers={this.changeAnswers} required={true} intl={this.props.intl}
                    />

                    <div className="buttons">
                        {
                            this.state.hadNames ?
                                null
                                :
                                <Button onClick={this.prevPhase} appearance="ghost" style={{
                                    marginTop: "20px",
                                    marginRight: '20px',
                                    borderRadius: "20px",
                                    border: "1px solid #1E1C59",
                                    backgroundColor: "white",
                                    color: "#1E1C59"
                                }}><FormattedMessage id={"event_page.general.back"} /></Button>
                        }


                        <Button onClick={this.saveAnswers} style={{
                            marginTop: '20px',
                            borderRadius: "20px",
                            backgroundColor: "#05AFF2",
                            color: "white"
                        }}>
                            <FormattedMessage id={"event_page.general_tab.custom_modal_update.send_answer"} />
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div style={styles}>
                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal customModalDisableScroll',
                        closeButton: 'modal-close-button',
                    }}
                    center
                    open={localStorage.getItem('jwt') ? (this.state.loading ? false :this.props.showModal) : false}
                    onClose={this.props.toggleModal}
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                >
                    {body}
                </Modal>
            </div>
        )
    }
}

export default injectIntl(CustomModal);
