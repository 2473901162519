import React from 'react'
import { Icon, Checkbox } from 'rsuite';
import './Signup.scss'
import { IoMdArrowRoundBack } from 'react-icons/io';
import Fade from 'react-reveal/Fade';
import axios from 'axios';
import { Button } from 'rsuite';
import { toast } from 'react-toastify';
import TermsModal from './Modals/terms'
import ConfidModal from './Modals/confid'
import CustomInput from '../components/custom/CustomInput';
import {injectIntl} from 'react-intl';
class Signup extends React.Component {
	state = {
		name: "",
		surname: "",
		email: "",
		phone: "",
		password1: "",
		password2: "",
		showTermsModal: false,
		showConfidModal: false,
		checkedTerms: false,
		checkedConfid: false,

		pass1Valid: false,
		pass2Valid: false
	}

	changeCheckedTermsState = () => {
		let state = this.state.checkedTerms

		this.setState({
			checkedTerms: !state
		})
	}

	changeCheckedConfidState = () => {
		let state = this.state.checkedConfid

		this.setState({
			checkedConfid: !state
		})
	}

	toggleTermsModal = () => {
		let current = this.state.showTermsModal;
		this.setState({
			showTermsModal: !current
		})
	}

	toggleConfidModal = () => {
		let current = this.state.showConfidModal;

		this.setState({
			showConfidModal: !current
		})
	}

	onChange = (value, key) => {
		//check if email ends in space and remove it
		if (value[value.length - 1] === " ") {
			value = value.substring(0, value.length - 1)
		}

		if (key.startsWith("password")) {
			var isValid = true

			const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

			if (!passRegex.test(value)) {
				isValid = false
			}

			if (key.indexOf("1") !== -1) {
				this.setState({
					pass1Valid: isValid
				})
			} else {
				this.setState({
					pass2Valid: isValid
				})
			}
		}

		this.setState({
			[key]: value
		})
	}

	changeContext = () => {
		if (this.state.email === "" || this.state.password1 === "" || this.state.password2 === "") {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_fill_all_fields'}));
			return;
		}

		//Validate phone number
		//Disabled for now
		//if(this.state.phone.length < 10 || (!(parseInt(this.state.phone) > 0))) {
		//	toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_phone_number'}))
		//	return
		//}

		// eslint-disable-next-line
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!emailRegex.test(this.state.email.toLowerCase())) {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_email'}))

			if (this.state.email.indexOf(' ') >= 0) {
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_email'}))
			}

			return;
		}

		const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

		if (!passRegex.test(this.state.password1)) {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_password'}))
			return
		}

		if (this.state.password1 !== this.state.password2) {
			toast.error(this.props.intl.formatMessage({id: 'profile.reset_password.passwords_not_match'}))
			return;
		}

		if (this.state.name === "" || this.state.surname === "") {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_fill_all_fields'}));
			return;
		}

		if (!this.state.checkedConfid || !this.state.checkedTerms) {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_agree_terms'}))
			return;
		}

		let obj = {
			first_name: this.state.surname,
			last_name: this.state.name,
			email: this.state.email.toLowerCase(),
			password: this.state.password1,
			phone: this.state.phone
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'auth/local/register', obj)
			.then(async response => {
				toast.success(this.props.intl.formatMessage({id: 'signup.account_created'}))

				//send mail confirmation request
				await axios.post(process.env.REACT_APP_DOMAIN + 'auth/send-email-confirmation', { email: this.state.email.toLowerCase() })
					.then(_ => {
						toast.success(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
						localStorage.setItem("newAccount", "yes")
					})

				this.props.history.push('/login');
			})
			.catch(err => {
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.email_already_used'}))
			})
	}

	render() {
		return (
			<div className="Signup-container">
				<Fade cascade>
					<TermsModal toggleModal={this.toggleTermsModal} showModal={this.state.showTermsModal} />

					<ConfidModal toggleModal={this.toggleConfidModal} showModal={this.state.showConfidModal} />

					<div className="Signup-one">
						<IoMdArrowRoundBack onClick={() => this.props.history.go(-1)} style={{ fontSize: "20px" }} />

						<p>{this.props.intl.formatMessage({id: 'signup.intro'})}</p>
					</div>

					<div className="Signup-two">
						<p>{this.props.intl.formatMessage({id: 'signup.name_desc'})}
						</p>

						<CustomInput value={this.state.name} onChange={(value) => this.onChange(value, "name")} placeholder={this.props.intl.formatMessage({id: 'general.last_name'})} icon={<Icon icon="user" />} />

						<CustomInput value={this.state.surname} onChange={(value) => this.onChange(value, "surname")} placeholder={this.props.intl.formatMessage({id: 'general.first_name'})} icon={<Icon icon="user" />} />

						<CustomInput value={this.state.email} onChange={(value) => this.onChange(value, "email")} placeholder={this.props.intl.formatMessage({id: 'general.email'})}
									 icon={<Icon icon="envelope" />}
							// eslint-disable-next-line
							regex={/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/} />

						<CustomInput value={this.state.phone} icon={<Icon icon="phone" />} onChange={(val) => {
							this.setState({phone: val.replace(/\D/g,'')})
						}}
									 inputType={'number'}
									 placeholder={this.props.intl.formatMessage({id: 'general.phone'}) + ' (' + this.props.intl.formatMessage({id: 'general.optional'}) + ')'}/>

						<p>{this.props.intl.formatMessage({id: 'signup.password_desc'})}
						</p>

						<CustomInput value={this.state.password1} onChange={(value) => this.onChange(value, "password1")} placeholder={this.props.intl.formatMessage({id: 'general.password'})} icon={<Icon icon="key" />}
							inputType={"password"} canShowPass={"canShowPass"} regex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/} />

						<CustomInput value={this.state.password2} onChange={(value) => this.onChange(value, "password2")} placeholder={this.props.intl.formatMessage({id: 'general.confirm_password'})} icon={<Icon icon="key" />}
							inputType={"password"} canShowPass={"canShowPass"} regex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/} />

						{this.state.pass1Valid && this.state.pass2Valid && this.state.password1 !== this.state.password2 && <div style={{ color: "red" }}>
							{this.props.intl.formatMessage({id: 'profile.reset_password.passwords_not_match'})}
						</div>}

						<div className="checkbox-content">
							<Checkbox onChange={this.changeCheckedTermsState} checked={this.state.checkedTerms} />

							<p>{this.props.intl.formatMessage({id: 'profile.reset_password.i_agree_with'})} <span onClick={this.toggleTermsModal} style={{ textDecoration: "underline" }}>{this.props.intl.formatMessage({id: 'profile.reset_password.terms'})}</span> </p>
						</div>

						<div className="checkbox-content" >
							<Checkbox onChange={this.changeCheckedConfidState} checked={this.state.checkedConfid} />

							<p>{this.props.intl.formatMessage({id: 'profile.reset_password.i_agree_with'})} <span onClick={this.toggleConfidModal} style={{ textDecoration: "underline" }}>{this.props.intl.formatMessage({id: 'profile.reset_password.privacy'})}</span> </p>
						</div>
					</div>

					<div className="Signup-three">
						<Button onClick={this.changeContext} className="Continue-button"> {this.props.intl.formatMessage({id: 'signup.continue'})} </Button>

						<p id="disclaimer">{this.props.intl.formatMessage({id: 'signup.disclaimer'})} </p>
					</div>
				</Fade>
			</div>
		)
	}
}

export default injectIntl(Signup);
