import React from 'react'
import './stylesheets/MenuBlock.scss'
import { Icon } from 'rsuite'

import SVG_Planificari from '../../../../../assets/EventPageSVGs/planificari-icon.svg'
import SVG_Participanti from '../../../../../assets/EventPageSVGs/participanti-icon.svg'
import SVG_Informatii from '../../../../../assets/EventPageSVGs/informatii-icon.svg'
import SVG_Extra_Services from '../../../../../assets/EventPageSVGs/extra-services-icon.svg'
import SVG_Exports from '../../../../../assets/EventPageSVGs/export-icon.svg'
import SVG_Contact from '../../../../../assets/EventPageSVGs/contact-icon.svg'
import SVG_Packet from '../../../../../assets/GuestInfoSVG/cadou-dar-icon.svg'
import SVG_Walkthrough_Arrow from '../../../../../../assets/icons/walkthrough_arrow.svg'


class MenuBlock extends React.Component {
	state = {
		status: false,
		showOptionsFor: "",
		loading: true,
		who: "",
	}

	async componentDidMount() {
		if (this.props.id === '1') {
			this.setState({
				who: "info",
				loading: false
			})
		} else if (this.props.id === '2') {
			this.setState({
				who: "plan",
				loading: false
			})
		} else if (this.props.id === '3') {
			this.setState({
				who: "guests",
				loading: false
			})
		} else if (this.props.id === '4') {
			this.setState({
				who: "extra",
				loading: false
			})
		} else if (this.props.id === '5') {
			this.setState({
				who: "exports",
				loading: false
			})
		} else if (this.props.id === '6') {
			this.setState({
				who: "contact_us",
				loading: false
			})
		} else if (this.props.id === '7') {
			this.setState({
				who: "package",
				loading: false
			})
		} else if (this.props.id === '8') {
			this.setState({
				who: "administrators",
				loading: false
			})
		}
	}

	render() {
		let svgHeight = { height: "40px" }

		return (<>
			<div onClick={() => {
				if(this.props.id === '5') {
					this.props.moveTo(this.state.who, this.state.status)
				} else if(this.props.id === '6') {
					this.props.moveTo(this.state.who, this.state.status)
				} else if(this.props.id === '8') {
					this.props.moveTo(this.state.who, this.state.status)
				} else {
					this.props.moveTo(this.state.who, this.state.status)
				}
			}} id={this.state.status !== true ? null : "activeBlock"}
				 className={"MenuBlock-container "
					 + (this.props.walkthroughActiveIndex === this.props.id ? 'walkthrough-active' : '') + ' '
					 + (this.props.walkthroughActiveIndex === this.props.id && this.props.id === '7' ? 'walkthrough-active-absolute' : '')
			} style={{ marginTop: this.props.id === '7' ? "70px" : "0px" }}>

				{this.props.walkthroughActiveIndex === this.props.id ?
					<div className={"walkthrough-arrow"}>
						<img  alt={""} src={SVG_Walkthrough_Arrow}/>
					</div> : null
				}

				<div className="MenuBlock-content" id={"menu-content-" + this.props.id}>
					<div className="left">
						{this.props.id === '1' && <img src={SVG_Informatii} alt="Informatii" style={svgHeight} />}

						{this.props.id === '2' && <img src={SVG_Planificari} alt="Planificari" style={svgHeight} />}

						{this.props.id === '3' && <img src={SVG_Participanti} alt="Participanti" style={svgHeight} />}

						{this.props.id === '4' && <img src={SVG_Extra_Services} alt="Participanti" style={svgHeight} />}

						{this.props.id === '5' && <img src={SVG_Exports} alt="Participanti" style={svgHeight} />}

						{this.props.id === '6' && <img src={SVG_Contact} alt="Participanti" style={svgHeight} />}

						{this.props.id === '7' && <img src={SVG_Packet} alt="Participanti" style={svgHeight} />}

						{this.props.id === '8' && <img src={SVG_Participanti} alt="Administratori" style={svgHeight} />}

						{this.props.id === '9' && <img src={SVG_Informatii} alt="Informatii utile" style={svgHeight} />}
					</div>

					<div className="middle">
						<h5 id="title"> {this.props.title} </h5>
					</div>

					<div className="right">
						<Icon icon="angle-right" size="2x" />

						{this.props.id === '3' && this.props.hasNewResponses && <div className='redBlinkingText'>Nou</div>}
					</div>
				</div>
			</div>

			</>
		)
	}
}

export default MenuBlock;
