import React, {useState} from 'react'
import './TextStyleComp.scss'
import {Icon} from "rsuite";

export default function TextStyleComp({
                                          styles,
                                          setStyles,
                                          borderBottomRadius = false,
                                          options = ['font', 'font-size', 'color', 'alignment', 'underline']
                                      }) {

    const [showSection, setShowSection] = useState(options.length === 1 ? options[0] : '')


    const fonts = [
        {
            title: 'Dancing Script',
            class: 'style-font-dancing-script'
        },
        {
            title: 'Mynerve',
            class: 'style-font-mynerve'
        },
        {
            title: 'Kaushan',
            class: 'style-font-kaushan'
        },
        {
            title: 'Merienda',
            class: 'style-font-merienda'
        },
        {
            title: 'Pacifico',
            class: 'style-font-pacifico'
        },
        {
            title: 'Caveat',
            class: 'style-font-caveat'
        },
        {
            title: 'Allura',
            class: 'style-font-allura'
        },
        {
            title: 'Allison',
            class: 'style-font-allison'
        },
        {
            title: 'Karla',
            class: 'style-font-karla'
        },
        {
            title: 'Marhey',
            class: 'style-font-marhey'
        },
        {
            title: 'Style Script',
            class: 'style-font-style-script'
        },
        {
            title: 'Arial',
            class: 'style-font-arial'
        },
        {
            title: 'Verdana',
            class: 'style-font-verdana'
        },
    ]

    const fontSizes = [
        {
            title: 'Mai mic',
            class: 'style-font-size-smaller'
        },
        {
            title: 'Normal',
            class: 'style-font-size-normal'
        },
        {
            title: 'Mare',
            class: 'style-font-size-bigger'
        },
        {
            title: 'Cel mai mare',
            class: 'style-font-size-biggest'
        },
    ]


    const toggleSection = (section) => {

        if (showSection === section) {
            setShowSection('')
        } else {
            setShowSection(section)
        }

    }

    let currentFont = styles.font ? styles.font : 'style-font-dancing-script'
    let currentFontSize = styles.fontSize ? styles.fontSize : 'style-font-size-normal'
    let currentFontColor = styles.color ? styles.color : ''
    let currentAlignment = styles.alignment ? styles.alignment : ''

    return (
        <div className={"text-style-comp " + (borderBottomRadius ? 'border-bottom-radius' : '')}>

            {options.length > 1 && <div className={"controls"}>
                {options.includes('font') &&
                    <div className={"text-style-col " + (showSection === 'font' ? 'selected' : '')}
                         onClick={() => toggleSection('font')}>
                        <div><Icon icon="font"/></div>
                    </div>}
                {options.includes('font-size') &&
                    <div
                        className={"text-style-col " + (showSection === 'font-size' ? 'selected' : '')}
                        onClick={() => toggleSection('font-size')}>
                        <div><Icon icon="text-height"/></div>
                    </div>}
                {options.includes('underline') &&
                    <div
                        className={"text-style-col " + (styles.textDecoration === 'underline' ? 'active' : '')}
                        onClick={() => setStyles({
                            ...styles,
                            textDecoration: (styles.textDecoration === 'underline' ? 'none' : 'underline')
                        })}>
                        <div><Icon icon="underline"/></div>
                    </div>}
                {options.includes('color') &&
                    <div className={"text-style-col " + (showSection === 'color' ? 'selected' : '')}
                         onClick={() => toggleSection('color')}>
                        <div className={"text-style-color-swatch"}
                             style={{background: currentFontColor ? currentFontColor : '#000000'}}
                        ></div>
                    </div>}
                {options.includes('alignment') &&
                    <div
                        className={"text-style-col " + (showSection === 'alignment' ? 'selected' : '')}
                        onClick={() => toggleSection('alignment')}>
                        <div>
                            {
                                currentAlignment === 'style-alignment-left' ?
                                    <Icon icon="align-left"/>
                                    :
                                    currentAlignment === 'style-alignment-center' ?
                                        <Icon icon="align-center"/>
                                        :
                                        currentAlignment === 'style-alignment-right' ?
                                            <Icon icon="align-right"/>
                                            :
                                            <Icon icon="align-center"/>
                            }
                        </div>
                    </div>}
            </div>}

            <div className={"sections"}>
                {/* sections */}
                {
                    showSection === 'font' ?
                        <div className={"selector"}>

                            {
                                fonts.map((font, index) => (
                                    <div
                                        key={index}
                                        className={"selector-item " + (currentFont === font.class ? 'selected' : '')}
                                        onClick={() => setStyles({...styles, font: font.class})}
                                    >
                                        <span className={font.class}>{font.title}</span>
                                    </div>
                                ))
                            }

                        </div>
                        : null
                }

                {
                    showSection === 'font-size' ?
                        <div className={"selector"}>

                            {
                                fontSizes.map((fontSize, index) => (
                                    <div
                                        key={index}
                                        className={"selector-item " + (currentFontSize === fontSize.class ? 'selected' : '')}
                                        onClick={() => setStyles({...styles, fontSize: fontSize.class})}
                                    >
                                        <span className={fontSize.class}>{fontSize.title}</span>
                                    </div>
                                ))
                            }

                        </div>
                        : null
                }

                {
                    showSection === 'color' ?
                        <div className={"selector"}>
                            <input
                                style={{width: '100%', height: '40px', border: 0}}
                                value={currentFontColor}
                                type={"color"} onChange={(e) => setStyles({...styles, color: e.target.value})}/>
                        </div>
                        : null
                }

                {
                    showSection === 'alignment' ?
                        <div className={"selector selector-row"}>

                            <div
                                className={"selector-item " + (currentAlignment === 'style-alignment-left' ? 'selected' : '')}
                                onClick={() => setStyles({...styles, alignment: 'style-alignment-left'})}
                            >
                                <span className={'style-alignment-center'}> <Icon icon="align-left"/></span>
                            </div>

                            <div
                                className={"selector-item " + (currentAlignment === 'style-alignment-center' ? 'selected' : '')}
                                onClick={() => setStyles({...styles, alignment: 'style-alignment-center'})}
                            >
                                <span className={'style-alignment-center'}> <Icon icon="align-center"/></span>
                            </div>

                            <div
                                className={"selector-item " + (currentAlignment === 'style-alignment-right' ? 'selected' : '')}
                                onClick={() => setStyles({...styles, alignment: 'style-alignment-right'})}
                            >
                                <span className={'style-alignment-center'}> <Icon icon="align-right"/></span>
                            </div>

                        </div>
                        : null
                }
            </div>

        </div>
    )

}
