import React from 'react'
import { Icon, Input } from 'rsuite'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import axios from 'axios'

import './MyEvents.scss'
import { BackAndConfirmButtons, getUrlFromImage, ShareModal } from '../../components/Helper';
import NavbarColor from '../../components/NavbarColor';
import HeaderWhite from '../../components/HeaderWhite';
import { eventName_to_eventType } from '../../components/Constants';
import moment from "moment-timezone";
import Loader from "../../components/Loader";
import {getDefaultWeddingImage, getEventNextDate, getWeddingFirstDate, getWeddingTitle} from "../../functions";
import {FormattedMessage, injectIntl} from 'react-intl';

var isMarkingFavourite = false

let filterToType = eventName_to_eventType

class MyEvents extends React.Component {
	state = {
		eventsParticipations: [],

		weddingsParticipations: [],

		publicEvents: ["Toate", "Concert", "Festival", "Maraton", "Revelion"],
		specialEvents: ["Toate", "Ceremonie Absolvire", "Bal Absolvire", "Ceremonie & Bal", "Bal Boboci", "Revedere"],
		corporateEvents: ["Toate", "Team Building", "Petreceri Corporate", "Conferinta"],
		personalEvents: ["Toate", "Active", "Favorite", "Terminate"],
		filterChoice: "Active",
		descriptionIndex: -1,
		descriptionIndexWedding: -1,

		showSearch: false,
		searchInput: "",

		showModal: false,
		showShareModal: false,
		deleteTarget: { id: -1 },
		deleteType: "",
		customUrl: false,

		loading: true
	}

	componentDidMount = async () => {
		if (localStorage.getItem('jwt') === null) {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_log_in'}))
			this.props.history.push('/login');
			return
		}

		let oldChoice = null

		if (oldChoice === null) {
			oldChoice = "Active"
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'getMyEvents', auth)
			.then(response => {
				let events = [...response.data.events].reverse()
				let weddings = [...response.data.weddings].reverse()

				events.forEach((event,j) => {
					events[j].event.data_eveniment = getEventNextDate(event.event).toISOString()
				})
				events = events.sort(function(a,b){
					return new Date(a.event.data_eveniment) - new Date(b.event.data_eveniment);
				});

				weddings.forEach((wedding, j) => {
					weddings[j].event[0].dataEvent = getWeddingFirstDate(wedding.event[0]).date.toISOString()
				})
				weddings = weddings.sort(function(a,b){
					return new Date(a.event[0].dataEvent) - new Date(b.event[0].dataEvent);
				});

				this.setState({
					eventsParticipations: events,
					weddingsParticipations: weddings,
					filterChoice: oldChoice,
					loading: false
				})

				this.requestPushToken()
			})
			.catch(err => {
				if(err && err.response && err.response.status === 401) {
					localStorage.clear()
					toast.error("Informațiile de autentificare au expirat, vă rugăm să vă autentificați!")
					this.props.history.push('/login');
				} else {
					toast.error('A apărut o eroare! Vă rugăm reîncercați!')
				}
			})
	}

	requestPushToken = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}
		if(window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('getPushNotificationToken')
				.then(token => {

					let data = {
						token: token
					}
					axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
				})
		}

		if(window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'getPushToken',
				data: localStorage.getItem('jwt')
			}))
		}
	}

	checkNews = (participant) => {
		if (participant.event.activate_informatii_utile !== false && participant.event.informatii_utile.length > 0 && participant.last_informatii_utile !== participant.event.informatii_utile[0].url) {
			return true
		}

		if (participant.event.activate_plan_mese !== false && participant.event.plan_mese.length > 0 && participant.last_plan_mese !== participant.event.plan_mese[0].url) {
			return true
		}

		if (participant.event.activate_album !== false && participant.event.albums && "" + participant.event.albums.length !== participant.last_link_poze_public) {
			return true
		}

		if (participant.event.activate_feed !== false && participant.event.count_messages !== Number(participant.last_feed_count)) {
			return true
		}

		if (participant.last_feedback_state !== "checked" && participant.event.activate_feedback_questions && participant.event.published_feedback_questions) {
			return true
		}

		if (participant.event.count_announcements > Number(participant.last_announcement_count)) {
			return true
		}

		if (participant.event.contact_unread_responses && participant.event.contact_unread_responses>0) {
			return true
		}

		return false
	}

	moveToEvent = (eventCode) => {
		this.props.history.push('/eventPage/' + eventCode)
	}

	moveToWedding = (eventCode) => {
		this.props.history.push('/eventPageW/' + eventCode)
	}

	markAsFavourite = async (participant) => {
		if (isMarkingFavourite) {
			return
		}

		isMarkingFavourite = true

		var isFavourite = participant.favourite

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			favourite: !isFavourite
		}

		await axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + participant.id, obj, auth)
			.then(_ => {
				toast.success(isFavourite ? "Eveniment scos de la favorit" : "Eveniment adaugat la favorit")

				let newParticipations = [...this.state.eventsParticipations]

				for (let i = 0; i < newParticipations.length; i++) {
					if (newParticipations[i].id === participant.id) {
						newParticipations[i].favourite = !isFavourite
					}
				}

				this.setState({
					eventsParticipations: newParticipations
				})
			})
			.catch(err => {
				toast.error("Operatia nu s-a putut finaliza, va rog reincercati sau reveniti mai tarziu")
			})

		isMarkingFavourite = false
	}

	markWeddingAsFavourite = async (participant) => {
		if (isMarkingFavourite) {
			return
		}

		isMarkingFavourite = true

		let isFavourite = participant.is_favourite

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			is_favourite: !isFavourite
		}

		await axios.post(process.env.REACT_APP_DOMAIN + "responses/updateMyResponse/" + participant.id, obj, auth)
			.then(_ => {
				toast.success(isFavourite ? "Eveniment scos de la favorit" : "Eveniment adaugat la favorit")

				let newWedParticipations = [...this.state.weddingsParticipations]

				for (let i = 0; i < newWedParticipations.length; i++) {
					if (newWedParticipations[i].id === participant.id) {
						newWedParticipations[i].is_favourite = !isFavourite
					}
				}

				this.setState({
					weddingsParticipations: newWedParticipations
				})
			})
			.catch(err => {
				toast.error("Operatia nu s-a putut finaliza, va rog reincercati sau reveniti mai tarziu")
			})

		isMarkingFavourite = false
	}

	openModal = (target, type) => {
		this.setState({
			showModal: true,
			deleteTarget: target,
			deleteType: type
		})
	}

	closeModal = () => {
		this.setState({
			showModal: false,
			deleteTarget: { id: -1 }
		})
	}

	deleteParticipant = () => {
		let target = this.state.deleteTarget

		this.closeModal()

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			event: null,
			user: null
		}

		let link = process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + target.id

		if (this.state.deleteType === "responses") {
			link = process.env.REACT_APP_DOMAIN + "responses/updateMyResponse/" + target.id
		}

		axios.put(link, obj, auth)
			.then(_ => {
				toast.success("Participare stearsa")

				if (this.state.deleteType === "participants") {
					let newParticipations = [...this.state.eventsParticipations]

					for (let i = 0; i < newParticipations.length; i++) {
						if (newParticipations[i].id === target.id) {
							newParticipations.splice(i, 1)
						}
					}

					this.setState({
						eventsParticipations: newParticipations
					})
				}
				else {
					let newWedParticipations = [...this.state.weddingsParticipations]

					for (let i = 0; i < newWedParticipations.length; i++) {
						if (newWedParticipations[i].id === target.id) {
							newWedParticipations.splice(i, 1)
						}
					}

					this.setState({
						weddingsParticipations: newWedParticipations
					})
				}
			})
			.catch(err => {
				toast.error("Operatia nu s-a putut finaliza, va rog reincercati sau reveniti mai tarziu")
			})
	}

	openShareModal = (data, type) => {
		let link = ""
		let category = ""

		if (type === "event") {
			link = data.title

			let categoryFound = false
			//look in public events type
			for (let i = 0; i < this.state.publicEvents.length; i++) {
				if (data.tip_eveniment === filterToType[this.state.publicEvents[i]]) {
					category = "Pu"
					categoryFound = true
					break
				}
			}

			if (!categoryFound) {
				//look in special events type
				for (let i = 0; i < this.state.specialEvents.length; i++) {
					if (data.tip_eveniment === filterToType[this.state.specialEvents[i]]) {
						category = "Sp"
						categoryFound = true
						break
					}
				}
			}

			if (!categoryFound) {
				//look in corporate events type
				for (let i = 0; i < this.state.corporateEvents.length; i++) {
					if (data.tip_eveniment === filterToType[this.state.corporateEvents[i]]) {
						category = "Co"
						categoryFound = true
						break
					}
				}
			}
		}

		if (type === "wedding") {
			category = "Pr"

			link = data.eventType + " "

			if (data.eventType === "Nunta") {
				link += data.prenumeMireasa + " si " + data.prenumeMire
			}

			if (data.eventType === "Botez" || data.eventType === "Petrecere") {
				link += data.prenumeMire
			}
		}

		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('shareEvent', `${process.env.REACT_APP_PAGE}events/${encodeURI(link)}/${category}`)
			return
		}

		let customUrl = false
		if(data.slug && data.slug.length > 0) {
			customUrl = process.env.REACT_APP_PAGE + data.slug
		}


		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'shareUrl',
				url: customUrl ? customUrl : `${process.env.REACT_APP_PAGE}events/${encodeURI(link)}/${category}`,
			}))
			return
		}

		this.setState({
			showShareModal: true,
			linkToShare: encodeURI(link),
			linkCategory: category,
			customUrl: customUrl
		})
	}

	closeShareModal = () => {
		this.setState({
			showShareModal: false
		})
	}

	changeEventType = (newType) => {
		this.setState({
			filterChoice: newType
		})

		localStorage.setItem('choiceInMyEvents', newType)

		this.closeSearch()
	}

	setShowSearch = () => {
		this.setState({
			showSearch: true,
			typeChoice: "Toate"
		})
	}

	closeSearch = () => {
		this.setState({
			showSearch: false,
			searchInput: "",
			typeChoice: "Toate"
		})
	}

	changeSearch = (value) => {
		this.setState({
			eventDescriptionIndex: -1,
			weddingDescriptionIndex: -1,
			searchInput: value
		})
	}


	render() {
		if (this.state.loading) {
			return <div className="Events-container">
				<HeaderWhite />

				<h4 className="Events-TopText"> {this.props.intl.formatMessage({id: 'events.attending_events.title'})} </h4>

				<div className="Events-Filter">
					<p style={{ marginLeft: "20px" }}> {this.props.intl.formatMessage({id: 'general.filters'})} </p>
				</div>

				<div className="Events-contents">
					<Loader/>
				</div>

				<NavbarColor history={this.props.history} active={"My_Events"} />
			</div>
		}

		let dispEvents = [...this.state.eventsParticipations]
		let dispWeddings = [...this.state.weddingsParticipations]

		if (this.state.filterChoice === "Favorite") {
			dispEvents = dispEvents.filter(elem => elem.favourite)
			dispWeddings = dispWeddings.filter(elem => elem.is_favourite)
		}

		if (this.state.filterChoice === "Terminate") {
			//dispEvents = dispEvents.filter(elem => !elem.event.activ)
			//dispWeddings = dispWeddings.filter(elem => new Date(elem.event[0].dataEvent) < new Date())
		}

		if (this.state.filterChoice === "Active") {

			let newDispEvents = []

			for(let j=0; j<dispEvents.length; j++) {
				if(dispEvents[j].event.data_sfarsit_eveniment && dispEvents[j].event.data_sfarsit_eveniment.length>0) {
					if (moment(dispEvents[j].event.data_sfarsit_eveniment).endOf('day') < moment()) {
						continue;
					} else {
						newDispEvents.push(dispEvents[j])
					}
				} else {
					if (moment(dispEvents[j].event.data_eveniment).endOf('day') < moment()) {
						continue;
					} else {
						newDispEvents.push(dispEvents[j])
					}
				}

			}

			dispEvents = newDispEvents


			//dispEvents = dispEvents.filter(elem =>
			//	moment(elem.event.data_eveniment).endOf('day')>= new Date()
			//)
			dispWeddings = dispWeddings.filter(elem =>
				moment(elem.event[0].dataEvent).endOf('day')>= new Date()
			)
		}

		if (this.state.showSearch) {
			let value = this.state.searchInput

			dispEvents = dispEvents.filter(elem => {
				return elem.event.title.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1
			})
			dispWeddings = dispWeddings.filter(elem => {
				let title = elem.event[0].eventType + " " + elem.event[0].prenumeMire + " si " + elem.event[0].prenumeMireasa
				if (elem.eventType === "Botez") {
					title = "Botez " + elem.prenumeMire
				}
				if (elem.eventType === "Petrecere") {
					title = "Petrecere " + elem.prenumeMire
				}

				title = title.toLocaleLowerCase()

				return title.indexOf(value.toLocaleLowerCase()) !== -1 || title.indexOf(value.toLocaleLowerCase()) !== -1
			})
		}


		return (
			<div className="Events-container">
				<HeaderWhite />

				{this.state.showSearch
					? <h4 className="Events-TopText">
						<Icon className="icon-left" icon="search" color="white" />

						<Input className="search-bar" value={this.state.searchInput} onChange={this.changeSearch} placeholder={this.props.intl.formatMessage({id: 'events.search.placeholder'})} />

						<Icon className="icon-right" icon="close" style={{ color: "white", width: "20px", marginRight:'10px' }} onClick={this.closeSearch} />
					</h4>
					: <h4 className="Events-TopText">
						{this.props.intl.formatMessage({id: 'events.attending_events.title'})}

						<div className={"search-box"} onClick={this.setShowSearch}>
							<Icon className="search-icon" icon="search" style={{ color: "white", width: "20px" }} />
							<span className={"search-text"}>{this.props.intl.formatMessage({id: 'general.search'})}</span>
						</div>
						{/*<Icon className="icon-right" icon="search" style={{ color: "white", width: "20px" }} onClick={this.setShowSearch} />*/}
					</h4>
				}

				<div className="Events-Filter">
					{this.state.personalEvents.map((type, index) => {
						return <div key={index} className={this.state.filterChoice === type ? "Events-Status-Choice" : "Events-Status-Option"} onClick={() => this.changeEventType(type)}>
							{/*{type === 'Terminate' ? 'Finalizate' : type} */}
							{this.props.intl.formatMessage({id: 'events.attending_events.types.' + type})}
						</div>
					})}
				</div>

				<div>
					<div className="Events-contents">
						<Modal classNames={{
							overlay: 'modal-overlay',
							modal: 'modal-code-myEvents',
							closeButton: 'modal-close-button',
						}} center open={this.state.showModal} onClose={this.closeModal}>
							<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
								<h5 style={{ marginTop: "15px" }}>
									{this.props.intl.formatMessage({id: 'events.attending_events.delete.text'})}
									</h5>

								<BackAndConfirmButtons textBack={this.props.intl.formatMessage({id: 'general.back'})} textConfirm={this.props.intl.formatMessage({id: 'general.confirm'})} functionBack={this.closeModal} functionConfirm={this.deleteParticipant} />
							</div>
						</Modal>

						<div>
							{

								// eslint-disable-next-line
								dispEvents.map((participant, index) => {

								if(this.state.filterChoice === 'Terminate') {

									if(participant.event.data_sfarsit_eveniment && participant.event.data_sfarsit_eveniment.length>0) {
										if (moment(participant.event.data_sfarsit_eveniment).endOf('day') < moment()) {

										} else {
											// eslint-disable-next-line
											return
										}
									} else {
										if (moment(participant.event.data_eveniment).endOf('day') < moment()) {

										} else {
											// eslint-disable-next-line
											return
										}
									}


								}

								let backgroundImageStyle = {
									backgroundRepeat: 'no-repeat',
									backgroundSize: 'cover',
									backgroundPosition: 'center',
									backgroundImage: participant.event.imagine_eveniment.length > 0 ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(participant.event.imagine_eveniment[0])})` : ''
								}

								if(participant.event.id_eveniment === 'G7xFEKFwMs') {
									backgroundImageStyle.backgroundPosition = 'top center'
								}

								return <div key={index} className="Events-Card-Container">
									{participant.event.imagine_eveniment.length > 0 && <div className="Picture" onClick={() => { this.moveToEvent(participant.cod_eveniment) }}>
										<img src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(participant.event.imagine_eveniment[0])}`} alt={""} loading={"lazy"}/>
									</div>}

									<div className="Open-Event" onClick={() => { this.moveToEvent(participant.cod_eveniment) }}>
										{this.props.intl.formatMessage({id: 'events.event_card.click_to_open'})}
									</div>

									<div className="Title">
										<div className="Text font-family-poppins-700-bold" onClick={() => { this.moveToEvent(participant.cod_eveniment) }}>{participant.event.title}</div>

										<div className={participant.favourite ? "FavoriteIcon-Filled" : "FavoriteIcon"}>
											<Icon icon={participant.favourite ? "heart" : "heart-o"} size="2x" onClick={() => this.markAsFavourite(participant)} />
										</div>
									</div>

									<div className="Icons">
										{this.checkNews(participant) && <div className="New">{this.props.intl.formatMessage({id: 'events.attending_events.news'})}</div>}

										{
											window.flutter_inappwebview ?
												<div className="Data" onClick={() => {
													window.flutter_inappwebview.callHandler('addToCalendar', participant.event.title, '', '', participant.event.data_eveniment)
												}}>{moment(participant.event.data_eveniment).format("DD-MM-YYYY")}</div>
												:
												window.alityAppView ?
													<div className="Data" onClick={() => {
														window.ReactNativeWebView.postMessage(JSON.stringify({
															action: 'addToCalendar',
															title: participant.event.title,
															date: participant.event.data_eveniment
														}))
													}}>{moment(participant.event.data_eveniment).format("DD-MM-YYYY")}</div> :
												<div className="Data">{moment(participant.event.data_eveniment).format("DD-MM-YYYY")}</div>
										}

										{
											participant.event.privat
												? <div className="PrivateEvent">
													<Icon icon="lock" />
													{this.props.intl.formatMessage({id: 'events.attending_events.private'})}
												</div>
												: <div className="PublicEvent">
													<Icon icon="unlock" />
													PUBLIC
												</div>
										}

										<div className="Delete" onClick={() => this.openModal(participant, "participants")}>
											<Icon icon="trash2" />
											{this.props.intl.formatMessage({id: 'events.attending_events.delete'})}
										</div>

										{
											participant.event.id !== 97 ?
												<div className="Share"
													 onClick={() => this.openShareModal(participant.event, "event")}>
													<Icon icon="share"/>
													SHARE
												</div>
												: null
										}

									</div>

									<div className={this.state.descriptionIndex === index ? "Full-Description" : "Description"}>
										{participant.event.descriere}
									</div>

									<div style={{ alignSelf: "center", color: "black", width: "200px" }}>
										{
											participant.event.descriere.length > 25
												? <div style={{ margin: "10px 0px" }} onClick={() => { this.state.descriptionIndex === index ? this.setState({ descriptionIndex: -1 }) : this.setState({ descriptionIndex: index, descriptionIndexWedding: -1 }) }}>
													{this.state.descriptionIndex === index ? <FormattedMessage id={"event_page.general_tab.less_details"} /> : <FormattedMessage id={"event_page.general_tab.more_details"} />}
													{this.state.descriptionIndex === index ? <BsChevronUp /> : <BsChevronDown />}
												</div>
												: <div></div>
										}
									</div>
								</div>
							})}

							{
								// eslint-disable-next-line
								dispWeddings.map((participant, index) => {

								if(this.state.filterChoice === 'Terminate') {
									if(moment(new Date(participant.event[0].dataEvent)) >= moment(new Date())) {
										return null
									}
								}

								let firstDate = getWeddingFirstDate(participant.event[0])

								return <div key={participant.id} className="Events-Card-Container">


									{participant.event[0].backgroundImage ?
										<div className="Picture" onClick={() => {
											this.moveToWedding(participant.event[0].id_eveniment)
										}}>
											<img
												src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(participant.event[0].backgroundImage)}`}
												alt={""} loading={"lazy"}/>
										</div>
										:
										<div className="Picture" onClick={() => {
											this.moveToWedding(participant.event[0].id_eveniment)
										}}>
											<img src={getDefaultWeddingImage(participant.event[0])} alt={""} loading={"lazy"}/>
										</div>
									}
									<div className="Open-Event" onClick={() => { this.moveToWedding(participant.event[0].id_eveniment) }}>
										{this.props.intl.formatMessage({id: 'events.event_card.click_to_open'})}
									</div>

									<div className="Title">
										<div className="Text font-family-poppins-700-bold" onClick={() => { this.moveToWedding(participant.event[0].id_eveniment) }}>
											{getWeddingTitle(participant.event[0])}
										</div>

										<div className={participant.is_favourite ? "FavoriteIcon-Filled" : "FavoriteIcon"}>
											<Icon icon={participant.is_favourite ? "heart" : "heart-o"} size="2x" onClick={() => this.markWeddingAsFavourite(participant)} />
										</div>
									</div>

									<div className="Icons">
										{
											window.flutter_inappwebview ?
												<div className="Data" onClick={() => {
													window.flutter_inappwebview.callHandler('addToCalendar', getWeddingTitle(participant.event[0]), '', '', firstDate.date)
												}}>{firstDate.preview}</div>
												:
												window.alityAppView ?
													<div className="Data" onClick={() => {
														window.ReactNativeWebView.postMessage(JSON.stringify({
															action: 'addToCalendar',
															title: getWeddingTitle(participant.event[0]),
															date: firstDate.date
														}))
													}}>{firstDate.preview}</div> :
												<div className="Data">{firstDate.preview}</div>
										}

										<div className="PrivateEvent">
											<Icon icon="lock" />
											{this.props.intl.formatMessage({id: 'events.attending_events.private'})}
										</div>

										<div className="Delete" onClick={() => this.openModal(participant, "responses")}>
											<Icon icon="trash2" />
											{this.props.intl.formatMessage({id: 'events.attending_events.delete'})}
										</div>

										<div className="Share" onClick={() => this.openShareModal(participant.event[0], "wedding")} >
											<Icon icon="share" />
											SHARE
										</div>
									</div>

									<div className={this.state.descriptionIndexWedding === index ? "Full-Description" : "Description"}>
										{participant.event[0].eventDescription}
									</div>

									<div style={{ alignSelf: "center", color: "black", width: "200px" }}>
										{
											participant.event[0].eventDescription.length > 25
												? <p onClick={() => { this.state.descriptionIndexWedding === index ? this.setState({ descriptionIndexWedding: -1 }) : this.setState({ descriptionIndex: -1, descriptionIndexWedding: index }) }}>
													{this.state.descriptionIndexWedding === index ? <FormattedMessage id={"event_page.general_tab.less_details"} /> : <FormattedMessage id={"event_page.general_tab.more_details"} />}
													{this.state.descriptionIndexWedding === index ? <BsChevronUp /> : <BsChevronDown />}
												</p>
												: <div></div>
										}
									</div>
								</div>
							})}
						</div>
					</div>
				</div>

				<ShareModal link={this.state.linkToShare} customUrl={this.state.customUrl} showModal={this.state.showShareModal} closeModal={this.closeShareModal} linkCategory={this.state.linkCategory} />

				<NavbarColor history={this.props.history} active={"My_Events"} />
			</div>
		)
	}
}

export default injectIntl(MyEvents);
