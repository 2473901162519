import React from 'react'
import {InputGroup, Input, Icon, SelectPicker, Button} from 'rsuite'
import './Responses.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../components/custom/CustomInput'
import { toast } from 'react-toastify'
import { BackAndConfirmButtons, BackButton, DataCards, ShowPackageData } from '../../components/Helper'
import { MdKeyboardArrowDown } from 'react-icons/md';
import moment from "moment-timezone";
import Loader from "../../components/Loader";
import {getWeddingParticipantsPackageNumber} from "../../../functions";

let participa = "yes";
let nuParticipa = "no";
let nuStieDacaParticipa = "don't know";
let all = "toti";

const ansOptions = [{ label: 'Participa', value: 'yes' }, { label: 'Nu știe daca participa', value: "don't know" },
{ label: 'Nu Participa', value: 'no' }]

class SaveTheDateResponses extends React.Component {
	state = {
		eventId: "",
		screen: "Tabel",

		invites: [],
		dispInv: [],

		totalAnswers: 0,
		yesAnswers: 0,
		dontKnowAnswers: 0,
		noAnswers: 0,
		modalRaport: false,
		modalInfo: false,

		currentSearch: "",
		participationChoice: all,
		timeChoice: all,
		tutorialModal: false,
		deleteModalOpen: false,

		packageData: {},

		raspuns: "",
		insotit: false,
		nume: "",
		prenume: "",
		modifIndex: -1,
		event: null,

		loading: true
	}

	componentDidMount = async () => {
		this.getData()
	}

	getData = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_data = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

		this.setState({
			eventId: event_data.data.id,
			event: event_data.data,
			tutorialModal: !event_data.data.tutorials || (event_data.data.tutorials && !event_data.data.tutorials.responsesSaveTheDate)
		})

		//get answers
		await axios.get(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {
				let yesAnswers = 0
				let dontKnowAnswers = 0
				let noAnswers = 0

				let invites = response.data.responses.map((invitee, index) => {
					if (invitee.raspuns === "yes") {

						let totalPeople = 1

						if (invitee.insotit) {
							totalPeople++
						}

						if (invitee.cuCopii) {
							totalPeople += Number(invitee.nr_copii)
						}

						yesAnswers = yesAnswers + totalPeople


					} else if (invitee.raspuns === "no") {
						noAnswers++
					} else {
						dontKnowAnswers++
					}

					invitee.index = index

					return invitee
				})

				invites = invites.sort(function(a,b){
					// Turn your strings into dates, and then subtract them
					// to get a value that is either negative, positive, or zero.
					return new Date(b.created_at) - new Date(a.created_at);
				});

				if(invites.length >= 10) {
					//Ask for review
					if (window.alityAppView) {
						window.ReactNativeWebView.postMessage(JSON.stringify({
							action: 'askForReview'
						}))
					}
				}

				this.setState({
					invites: invites,
					dispInv: invites,
					packageData: response.data,
					totalAnswers: invites.length,
					yesAnswers: yesAnswers,
					dontKnowAnswers: dontKnowAnswers,
					noAnswers: noAnswers,
					loading: false
				})

				let totalResponses = response.data.totalResponses
				let receivedResponses = response.data.responses.length
				let seenResponses = event_data.data.count_seen_save_the_date_responses

				let updateSeenRsponses = false
				let newSeenResponsesCount = seenResponses

				if (totalResponses > seenResponses) {
					if (totalResponses === receivedResponses) {
						//limit not passed
						updateSeenRsponses = true
						newSeenResponsesCount = totalResponses
					} else {
						//limit passed, check 2
						if (receivedResponses > seenResponses) {
							updateSeenRsponses = true
							newSeenResponsesCount = receivedResponses
						}
					}
				}

				if (updateSeenRsponses) {
					let obj = {
						count_seen_save_the_date_responses: newSeenResponsesCount
					}

					let auth = {
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						}
					}

					axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + event_data.data.id, obj, auth)
				}
			})
	}

	filterInvites = (filterStep, filterChoice) => {
		let allParticipants = [...this.state.invites];

		//filter by participation
		let participationFilterChoice = this.state.participationChoice
		if (filterStep === 1) {
			participationFilterChoice = filterChoice
		}
		let filteredByParticipation = allParticipants.filter(element => participationFilterChoice === all || element.raspuns === participationFilterChoice)

		//filter by name
		let nameFilterChoice = this.state.currentSearch.toLocaleLowerCase()
		if (filterStep === 2) {
			nameFilterChoice = filterChoice.toLocaleLowerCase()
		}
		let filteredByName = filteredByParticipation.filter(element => {
			return element.nume.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(nameFilterChoice) !== -1
		})

		//filter by time interval
		let intervalFilterChoice = this.state.timeChoice
		if (filterStep === 3) {
			intervalFilterChoice = filterChoice.toLocaleLowerCase()
		}

		let now = new Date().getTime()
		let filteredByInterval = filteredByName.filter(element => {
			if (intervalFilterChoice === all) {
				return true
			}

			let interval = 86400000//oneDayInMilliseconds

			if (intervalFilterChoice === "7") {
				interval *= 7
			} else if (intervalFilterChoice === "30") {
				interval *= 30
			}

			return new Date(element.created_at).getTime() > now - interval
		})

		this.setState({
			dispInv: filteredByInterval
		})
	}

	changeParticipationChoice = (choice) => {
		this.setState({
			participationChoice: choice,
			modalRaport: false
		})

		this.filterInvites(1, choice)
	}

	onChange = (value) => {
		this.setState({
			currentSearch: value
		})

		this.filterInvites(2, value)
	}

	changeTimeChoice = (choice, shouldCloseModal = true) => {
		this.setState({
			timeChoice: choice,
			modalRaport: false
		})

		this.filterInvites(3, choice)
	}

	closeRaport = () => {
		this.setState({
			modalRaport: false
		})
	}

	goToAddResponse = () => {
		if(getWeddingParticipantsPackageNumber(this.state.event) < 1) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}
		this.setState({
			screen: "Add",
			modalRaport: true
		})
	}

	goToEditResponse = (index) => {

		this.setState({
			screen: "Edit",
			modifIndex: index,
			raspuns: this.state.dispInv[index].raspuns,
			prenume: this.state.dispInv[index].prenume,
			nume: this.state.dispInv[index].nume,
			insotit: this.state.dispInv[index].insotit ? true : false,
			data: this.state.dispInv[index].created_at,
			modalRaport: true
		})
	}

	showTable = () => {
		this.setState({
			screen: "Table",
			nume: "",
			prenume: "",
			raspuns: "",
			modifIndex: -1,
			modalRaport: false
		})
	}

	createInvite = () => {
		if (this.state.raspuns === "") {
			toast.error("Va rog alegeti raspunsul participantului")
			return
		}

		if (this.state.prenume === "") {
			toast.error("Va rog introduceti prenumele invitatului")
			return
		}

		var obj = {
			raspuns: this.state.raspuns,
			insotit: this.state.insotit,
			nume: this.state.nume,
			prenume: this.state.prenume,
			event: this.state.eventId
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'response-save-the-dates', obj, auth)
			.then(response => {
				toast.success("Raspuns trimis")

				obj.index = this.state.invites.length

				this.getData()
				this.showTable()
			})
	}

	saveChange = () => {
		if (this.state.raspuns === "") {
			toast.error("Va rog alegeti raspunsul participantului")
			return
		}

		if (this.state.prenume === "") {
			toast.error("Va rog introduceti prenumele invitatului")
			return
		}

		let index = this.state.modifIndex

		var obj = {
			raspuns: this.state.raspuns,
			insotit: this.state.insotit,
			nume: this.state.nume,
			prenume: this.state.prenume
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'response-save-the-dates/' + this.state.invites[index].id, obj, auth)
			.then(response => {
				toast.success("Raspuns modificat")

				let elem = this.state.invites[index]
				elem.nume = obj.nume
				elem.prenume = obj.prenume
				elem.raspuns = obj.raspuns
				elem.insotit = obj.insotit

				let newInvites = [...this.state.invites]
				newInvites.splice(index, 1, elem)

				this.setState({
					invites: newInvites,
					dispInv: newInvites
				})


				this.showTable()
			})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

	await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				responsesSaveTheDate: true
			},
			auth)

	}

	toggleModalInterval = () => {
		this.setState({
			screen: "Time",
			modalRaport: true
		})
	}

	toggleModalParticipation = () => {
		this.setState({
			screen: "Participation",
			modalRaport: true
		})
	}

	openModalInfo = () => {
		this.setState({
			modalInfo: true
		})
	}

	closeModalInfo = () => {
		this.setState({
			modalInfo: false
		})
	}

	deleteResponse= () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.delete(process.env.REACT_APP_DOMAIN + 'response-save-the-date/delete/' + this.state.invites[this.state.modifIndex].id, auth)
			.then(response => {
				toast.success("Raspuns modificat")

				this.goBack()
			})
	}

	goBack = () => {
		this.props.history.goBack()
	}

	render() {

		if (this.state.loading === true) {
			return <div className="Responses-container">
				<HeaderWhite shadow={true} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let rowStyle = {
			overflow: "hidden",
			textOverflow: "ellipsis",
			borderRadius: "0px",
			textAlign: "center"
		}

		return (
			<div className="Responses-container">
				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-response SaveTheDateResponseModal',
					closeButton: 'modal-close-button',
				}} open={this.state.modalRaport} onClose={this.closeRaport}>
					{this.state.screen === "Participation" && <div className="Participation-Filter">
						<div>Sortează după răspunsuri: </div>

						<div className="Participation-Options">
							<div className={this.state.participationChoice === all ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(all)}>Toate</div>
							<div className={this.state.participationChoice === participa ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(participa)}>Participa</div>
							<div className={this.state.participationChoice === nuParticipa ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(nuParticipa)}>Nu participa</div>
							<div className={this.state.participationChoice === nuStieDacaParticipa ? "Active-Option" : "Option"} onClick={() => this.changeParticipationChoice(nuStieDacaParticipa)}>Indecis</div>
						</div>
					</div>}

					{this.state.screen === "Time" && <div className="Participation-Filter">
						<div>Sortează după răspunsuri: </div>

						<div className="Participation-Options">
							<div className={this.state.timeChoice === all ? "Active-Option" : "Option"} onClick={() => this.changeTimeChoice(all)}>Toate</div>
							<div className={this.state.timeChoice === "24" ? "Active-Option" : "Option"} onClick={() => this.changeTimeChoice("24")}>Ultimele 24 ore</div>
							<div className={this.state.timeChoice === "7" ? "Active-Option" : "Option"} onClick={() => this.changeTimeChoice("7")}>Ultimele 7 zile</div>
							<div className={this.state.timeChoice === "30" ? "Active-Option" : "Option"} onClick={() => this.changeTimeChoice("30")}>Ultimele 30 zile</div>
						</div>
					</div>}

					{this.state.screen === "Add" && <div style={{ display: "flex", flexDirection: "column", margin: "10px auto" }}>

						<CustomInput value={this.state.prenume} onChange={value => this.setState({ prenume: value })} placeholder={"Prenume, Nume invitat"} />

						<SelectPicker
							searchable={false}
							style={{ marginBottom: 20, marginTop: "10px" }}
							data={ansOptions}
							appearance="default"
							onChange={(value) => this.setState({ raspuns: value })}
							value={this.state.raspuns}
							placeholder="Răspuns"
						/>

						{
							this.state.raspuns === 'yes' ?

								<div className="data-row">
									<div className={this.state.insotit !== true ? "active-option" : "inactive-option"}
										 onClick={_ => this.setState({insotit: false})}>
										Vin singur/ă
									</div>

									<div className={this.state.insotit === true ? "active-option" : "inactive-option"}
										 onClick={_ => this.setState({insotit: true})}>
										Vin insoțit/ă
									</div>
								</div>
								:
								null
						}

						<BackAndConfirmButtons textBack={"Închide"} textConfirm={"Adaugă"} functionBack={this.showTable} functionConfirm={this.createInvite} />
					</div>}

					{this.state.screen === "Edit" && <div style={{ display: "flex", flexDirection: "column", margin: "10px auto" }}>

						<div style={{textAlign:'left', marginTop:'20px'}}>
							<CustomInput
								value={this.state.data ? moment(this.state.data).format('DD-MM-YYYY HH:mm') : ''}
								onChange={() => {}}
								style={{ margin: "10px 0px" }}
								disabled={true}
								placeholder={"Data răspuns"} />
						</div>

						<CustomInput style={{marginBottom:'10px'}} value={this.state.prenume} onChange={value => this.setState({ prenume: value })} placeholder={"Prenume, Nume invitat"} />

						<SelectPicker
							searchable={false}
							style={{ marginBottom: 20, marginTop: "10px" }}
							data={ansOptions}
							appearance="default"
							onChange={(value) => this.setState({ raspuns: value })}
							value={this.state.raspuns}
							placeholder="Răspuns"
						/>

						{
							this.state.raspuns === 'yes' ?

								<div className="data-row">
									<div className={!this.state.insotit ? "active-option" : "inactive-option"}
										 onClick={_ => this.setState({insotit: false})}>
										Vin singur/ă
									</div>

									<div className={this.state.insotit ? "active-option" : "inactive-option"}
										 onClick={_ => this.setState({insotit: true})}>
										Vin insoțit/ă
									</div>
								</div>
								:
								null
						}

						<div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
							<Button onClick={() => this.setState({
								deleteModalOpen: true
							})} style={{flex:1, marginTop: 20, marginBottom: 20, marginRight:'10px', borderRadius: "80px" }} color="red">Sterge raspuns</Button>
							<Button onClick={this.saveChange} style={{flex:1, marginTop: 20, marginBottom: 20, marginLeft:'10px', borderRadius: "80px", backgroundColor: "#00afcc", color: "white" }} color="green">Salveaza modificari</Button>
						</div>

						<BackButton text={"Înapoi"} function={this.showTable} />




					</div>}
				</Modal>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.modalInfo} onClose={this.closeModalInfo}>
					<div className="Responses-participation-modal">
						<DataCards cards={[
							{
								data: `Total raspunsuri: ${this.state.totalAnswers}`,
								color: "#05AFF2"
							},
							{
								data: `Participa: ${this.state.yesAnswers}`,
								color: "#19B899"
							},
							{
								data: `Nu participa: ${this.state.noAnswers}`,
								color: "#F26157"
							},
							{
								data: `Indecisi: ${this.state.dontKnowAnswers}`,
								color: "#E3B505"
							}
						]} />

						<BackButton text={"Închide"} function={this.closeModalInfo} />
					</div>
				</Modal>

				<HeaderWhite goBack={this.goBack} />

				<div className='Responses-content'>
					<div className='title'>Raspunsuri Save the Date</div>

					<div className='options' style={{justifyContent: "center"}}>
						<div className='answers' onClick={this.openModalInfo}> Sumar raspunsuri </div>
					</div>

					<div className='add-response-button'>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.goToAddResponse} />
						<div onClick={this.goToAddResponse}>Adaugă răspuns</div>
					</div>

					<InputGroup style={{ margin: "10px auto" }}>
						<InputGroup.Addon>
							<Icon icon="search" />
						</InputGroup.Addon>

						<Input onChange={this.onChange} placeholder="Numele invitatului" />
					</InputGroup>

					<div className="data-table">
						<div className="header">
							<div style={{ ...rowStyle, marginLeft: "5px" }}>Nr</div>

							<div style={{ ...rowStyle, width: '30%', fontWeight: "bold" }}>Nume</div>

							<div style={{ ...rowStyle, width: "30%" }} className='clickable-elem' onClick={this.toggleModalInterval}>
								Răspuns <MdKeyboardArrowDown />
							</div>

							<div style={{ ...rowStyle, width: "30%" }} className='clickable-elem' onClick={this.toggleModalParticipation}>
								Stare <MdKeyboardArrowDown />
							</div>
						</div>

						{[...this.state.dispInv].map((elem, index) => {
							return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
								<div style={{ ...rowStyle, marginLeft: "5px" }}>
									{this.state.dispInv.length - index}
								</div>

								<div style={{ ...rowStyle, width: "30%", fontWeight: "bold" }} onClick={() => this.goToEditResponse(index)}>
									{elem.prenume}
								</div>

								<div style={{ ...rowStyle, width: "30%", textAlign: "right" }}>
									{elem.created_at ? moment(elem.created_at).format('DD-MM-YYYY') : '-'}
								</div>

								<div style={{ ...rowStyle, width: "30%", textAlign: "right" }}>
									{elem.raspuns === "yes" ? "Participa" : elem.raspuns === "no" ? "Nu participa" : "Indecis"}
								</div>
							</div>
						})}
					</div>

					<BackAndConfirmButtons
						functionBack={this.goBack}
						textBack={'Înapoi'}
						functionConfirm={()=> this.setState({tutorialModal:true})}
						textConfirm={"Informații utile"}
					/>
				</div>

				<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Lasa-te pe mana noastra si nu o sa ai parte de surprize neplăcute! În acest meniu poți vizualiza răspunsurile invitatilor referitoare la prezența la evenimentul tău! Vei primi o notificare de fiecare data cand un răspuns este primit. Verifica în secțiunea „Sumar” totalitatea răspunsurilor centralizate: „Da”, „Nu”, „Nu știu”. Pentru cei pe care ii „depășește” tehnologia, poti adauga manual răspunsul acestora.
						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.deleteModalOpen} onClose={() => this.setState({deleteModalOpen: false})}>

					<div style={{ display: "flex", flexDirection: "column", marginTop: "20px", width: "200px" }}>
						<h5>Raspunsul se va sterge si nu se va mai putea recupera!</h5>

						<Button onClick={this.deleteResponse} style={{ alignSelf: "center", marginTop: "40px", borderRadius: "80px" }} color="red"> Confirm stergerea raspunsului </Button>
					</div>
				</Modal>



				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default SaveTheDateResponses;
