import React from 'react'
import './ViewAlbums.scss'
import NavbarColor from '../../components/NavbarColor'
import HeaderWhite from '../../components/HeaderWhite'
import EventCard from './AddAlbums/EventCard'
import axios from 'axios'
import { Button, Icon } from 'rsuite'
import { toast } from 'react-toastify'
import Modal from "react-responsive-modal";
import Loader from "../components/Loader";
import {BackAndConfirmButtons} from "../components/Helper";

class ViewAlbums extends React.Component {
	state = {
		isPublished: false,
		eventId: -1,
		albums: [],
		tutorialModal: false,
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id)
			.then(response => {
				this.setState({
					isPublished: response.data.areAlbumsPublished,
					eventId: response.data.id,
					tutorialModal: !response.data.tutorials || (response.data.tutorials && !response.data.tutorials.albums)
				})
			})
			.catch(err => {
				console.log(err);
			})

		await axios.get(process.env.REACT_APP_DOMAIN + 'albums/list/byEvent/' + this.props.match.params.id, auth)
			.then(response => {
				this.setState({
					albums: response.data,
					loading: false
				})
			})
	}

	changeStatus = () => {
		let newStatus = !this.state.isPublished

		let obj = {
			areAlbumsPublished: newStatus
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		toast.info("Se trimit modificarile, va rog asteptati")

		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(_ => {
				toast.success("Modificari trimise")
				this.setState({
					isPublished: newStatus
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare")
			})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				albums: true
			},
			auth)

	}

	moveToAddAlbum = () => {
		this.props.history.push('/organizer/addAlbum/' + this.props.match.params.id);
	}

	moveToEdit = (id) => {
		this.props.history.push('/organizer/editAlbum/' + this.props.match.params.id + "/" + id)
	}

	goBack = () => {
		this.props.history.push("/organizer/info/" + this.props.match.params.id)
	}

	render() {
		if (this.state.loading === true) {
			return <div className="ViewAlbums-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let cards = this.state.albums.map((album, index) => {
			return <EventCard key={index} moveToEdit={this.moveToEdit} albumData={album} />
		})

		return (
			<div className="ViewAlbums-container">
				<HeaderWhite goBack={this.goBack} />

				<div className="ViewAlbums-contents">
					<h4 id="title">Albume</h4>

					<div className="Status">
						<div className="Details">Status: {this.state.isPublished ? "Publicat" : "Nepublicat"}</div>

						<div className={!this.state.isPublished ? "Published" : "Unpublished"} onClick={this.changeStatus}>
							{!this.state.isPublished ? "Publica" : "Anuleaza publicare"}
						</div>
					</div>

					<div className='add-button-a'>
						<Icon icon="plus-circle" size="lg" style={{ color: "#05AFF2", marginRight: "10px" }} onClick={this.moveToAddAlbum} />
						<div onClick={this.moveToAddAlbum}>Adauga album</div>
					</div>

					<div className="ViewAlbums-cards">
						{cards}
					</div>

					<div style={{maxWidth:'700px', width:'100%', margin:'auto'}}>
						<BackAndConfirmButtons
							functionBack={this.goBack}
							textBack={'Înapoi'}
							functionConfirm={()=> this.setState({tutorialModal:true})}
							textConfirm={"Informații utile"}
						/>
					</div>
				</div>



				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Cat de frustrant este sa participi la un eveniment și sa astepti zile în sir sau chiar saptamani pana primesti pozele si filmarile? Noi știm! De aceea, în aplicația Ality ai posibilitatea de a posta pozele și filmările direct in platforma, notificand și invitații! Tare, nu?
						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default ViewAlbums;