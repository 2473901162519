import React from 'react'
import './MenuTab.scss'
import PdfViewer from '../../../components/PdfViewer/PdfViewer';
import axios from 'axios'

class MenuTab extends React.Component {
	state = {
		pdfMeniuLink: undefined
	}

	componentDidMount = async () => {
		//if (localStorage.getItem("id") === null) {
			//toast.error("Trebuie să vă autentificați pentru a accesa această secțiune!")
			//this.props.history.push('/login');
		//	return
		//}

		let eventInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.event_id);

		this.setState({
			pdfMeniuLink: eventInfo.data.menuFile !== null ? eventInfo.data.menuFile.url : ""
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		return <div className="MenuTab-contents">
			<h3 style={{ margin: "20px 0px" }}>Meniu</h3>

			<div className="MenuTab-MenuData">
				{this.state.pdfMeniuLink === undefined
					? <p>Pdf-ul meniului nu a fost incarcat</p>
					: <PdfViewer pdfUrl={this.state.pdfMeniuLink} />
				}
			</div>
		</div>
	}
}

export default MenuTab;
