import React from 'react'
import './NavBarEvents.scss'

class NavBarEvents extends React.Component {
	state = {
		elements: [
			{ name: "General" },
			{ name: "Plan Mese" },
			{ name: "Feed" },
			{ name: "Albume" },
			{ name: "Face Recognition" },
			{ name: "Cadouri" },
			{ name: "Meniu" }
		]
	}

	componentDidMount = () => {
		var newTabOptions = [{name: "General"}]

		if (this.props.event_data.isSeatingChartDone)
			newTabOptions.push({name: "Plan Mese"})

		newTabOptions.push({name: "Feed"})

		if (this.props.event_data.areAlbumsPublished) {
			newTabOptions.push({name: "Albume"})
		}

		if (this.props.event_data.isUsingFaceRecog) {
			newTabOptions.push({name: "Face Recognition"})
		}

		if (this.props.event_data.areGiftsPublished) {
			newTabOptions.push({name: "Cadouri"})
		}

		if (this.props.event_data.menuFile !== null) {
			newTabOptions.push({name: "Meniu"})
		}

		this.setState({
			elements: newTabOptions
		})
	}

	render() {
		let navElements = this.state.elements.map((element) => {
			return <p key={element.name} onClick={() => this.props.changeMenu(element.name)} className={this.props.activePage === element.name ? "active-item" : "menu-item"}> {element.name} </p>
		})

		return (
			<div className="NavBarEvents-container">
				<div className="NavBarEvents-contents">
					{navElements}
				</div>
			</div>
		)
	}
}

export default NavBarEvents;