import React from 'react'
import { Checkbox } from 'rsuite'
import axios from "axios"
import Modal from 'react-responsive-modal'

import './PopularityVotes.scss'
import { toast } from 'react-toastify'
import { BackAndConfirmButtons, ConfirmButton } from '../../components/Helper'
import CustomInput from '../../components/custom/CustomInput'

class PopularityVotes extends React.Component {
	state = {
		has_voted: this.props.participantData.popularity_vote,

		candidates: [],
		disp_candidates: [],
		search: "",
		vote1: -1,
		vote2: -1,

		loading: true,
		showModal: false,
		isSendingVote: false
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			id_eveniment: this.props.event_id,
		}

		await axios.post(process.env.REACT_APP_DOMAIN + "getPaidParticipants", obj, auth)
			.then(response => {
				let candidates = response.data.map((candidate, index) => {
					let new_candidate = { ...candidate }

					new_candidate.response_index = index

					return new_candidate
				})

				this.setState({
					candidates: candidates,
					disp_candidates: candidates
				})
			})
			.catch(err => {
				console.log(err);
			})

		this.setState({
			loading: false
		})
	}

	back = () => {
		this.setState({
			screenChoice: ""
		})
	}

	filterCandidates = (value) => {
		let new_display_data = [...this.state.candidates]

		new_display_data = new_display_data.filter(candidate => {
			let nume = candidate.prenume + " " + candidate.nume

			return nume.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1
		})

		this.setState({
			disp_candidates: new_display_data,
			search: value
		})
	}

	sendData = async () => {
		if (this.state.isSendingVote) {
			return
		}
		this.setState({
			isSendingVote: true,
			showModal: false
		})

		let obj = {
			id_eveniment: this.props.event_id,
			id_participant_1: this.state.candidates[this.state.vote1].id,
			id_participant_2: this.state.candidates[this.state.vote2].id,
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + "votePopularity", obj, auth)
			.then(response => {
				toast.success("Vot trimis")

				this.props.participantData.popularity_vote = true
				this.setState({
					has_voted: true
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare va rog reintrati in tab")
			})

		this.setState({
			isSendingVote: false
		})
	}

	voteForCandidate = (index) => {
		if (this.state.vote1 === index) {
			this.setState({
				vote1: -1
			})

			return
		}

		if (this.state.vote2 === index) {
			this.setState({
				vote1: -1,
				vote2: this.state.vote1
			})

			return
		}

		this.setState({
			vote1: this.state.vote2,
			vote2: index
		})
	}

	setShowModal = () => {
		this.setState({
			showModal: true
		})
	}

	getName = (index) => {
		if (index !== -1) {
			let candidate = this.state.candidates[index]

			return candidate.prenume + " " + candidate.nume
		} else {
			return ""
		}
	}

	getRanks = () => {
		let nr_candidates_to_show = 10
		let candidates = [...this.state.candidates]

		//remove candidate with no votes
		candidates = candidates.filter(candidate => candidate.popularity_count > 0)

		for (let i = 0; i < candidates.length; i++) {
			for (let j = i + 1; j < candidates.length; j++) {
				if (candidates[i].popularity_count < candidates[j].popularity_count) {
					let aux = candidates[i]
					candidates[i] = candidates[j]
					candidates[j] = aux
				}
			}
		}

		//takes first nr_candidates_to_show candidates
		candidates = candidates.filter((_, index) => index < nr_candidates_to_show)

		let place = 1
		let votes = candidates[0].popularity_count

		//add places to candidates, keeping same number of votes on same place
		candidates = candidates.map(candidate => {
			if (candidate.popularity_count < votes) {
				place++
				votes = candidate.popularity_count
			}

			candidate.place = place

			return candidate
		})

		return candidates
	}

	render() {
		if (this.state.loading) {
			return <div className="PopularityVotes-container">
				<p style={{ alignSelf: "center" }}>Loading</p>
			</div>
		}

		//TODO: show result
		if (this.props.event_data.display_popularity_votes) {
			return <div className="PopularityVotes-container">
				<h4 style={{ alignSelf: "center" }}>Rezultat vot</h4>
				{this.getRanks().map((candidate, index) => {
					return <div key={index}>
						<div style={{ fontSize: "16px", margin: "10px 0px" }}> {candidate.place}. {candidate.prenume} {candidate.nume} - {candidate.popularity_count} voturi</div>
					</div>
				})}
			</div>
		}

		if (this.state.has_voted) {
			return <div className="PopularityVotes-container">
				<h4 style={{ alignSelf: "center" }}>Ati votat</h4>
			</div>
		}

		return <div className="PopularityVotes-container">
			<CustomInput value={this.state.search} onChange={this.filterCandidates} placeholder={"Nume participant"} />

			<div className="PopularityVotes-contents">
				{this.state.disp_candidates.map((candidate, index) => {
					let personal_index = candidate.response_index

					return <div key={index} className="PopularityVotes-candidates">
						<Checkbox onChange={_ => this.voteForCandidate(personal_index)} checked={this.state.vote1 === personal_index || this.state.vote2 === personal_index} >
							{candidate.prenume} {candidate.nume}
						</Checkbox>
					</div>
				})}
			</div>

			{this.state.vote1 !== -1 && this.state.vote2 !== -1 && <ConfirmButton loading={this.state.isSendingVote} text={"Voteaza"} function={this.setShowModal} />}

			<Modal
				classNames={{
					overlay: 'modal-overlay votes',
					modal: 'modal votesSize',
					closeButton: 'modal-close-button',
				}}
				open={this.state.showModal}
				onClose={() => this.setState({ showModal: false })}
				center
			>
				<h4 style={{ margin: "40px auto" }}>
					Ai votat pentru: <br></br>{this.getName(this.state.vote1)}<br></br>{this.getName(this.state.vote2)}
				</h4>

				<h4 style={{ margin: "40px auto" }}>Sunteti sigur de vot? Nu poate fi schimbat dupa confirmare</h4>

				<BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Confirm"} functionBack={() => this.setState({ showModal: false })} functionConfirm={this.sendData} />
			</Modal>
		</div >
	}
}

export default PopularityVotes;