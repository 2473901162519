import React from 'react'
import { Icon, Button } from 'rsuite'
import './HostessPage.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import axios from 'axios'
import { toast } from 'react-toastify'
import CustomInput from '../../components/custom/CustomInput'
import {BackAndConfirmButtons, ShowPackageData} from '../../components/Helper'
import NavbarColor from '../../../components/NavbarColor'
import Loader from "../../components/Loader";
import Modal from "react-responsive-modal";

class HostessPage extends React.Component {
	state = {
		participantsData: [],
		displayData: [],

		currentSearch: "",

		packageData: {},
		tutorialModal: false,

		isSendingData: false,
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let event_data = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
		this.setState({
			tutorialModal: !event_data.data.tutorials || (event_data.data.tutorials && !event_data.data.tutorials.hostess)
		})

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {

				let invites = response.data.responses.filter((element) => !element.deleted)

				//take all data
				let data = invites.map((invitee, index) => {
					let ans = { ...invitee.response }
					ans.responseIndex = index
					ans.id = invitee.id
					ans.user = invitee.user
					ans.is_checker = invitee.is_checker

					return ans
				})

				if (data.length === 0) {
					this.setState({
						participantsData: [],
						packageData: response.data,
						displayData: [],
						loading: false
					})

					toast.info("Niciun raspuns in acest moment")

					return
				}
				//take only data from people who will be participating
				let participants = data.filter(ans => ans.answer === "yes")

				//set total seats needed
				for (let i = 0; i < participants.length; i++) {
					participants[i].seatsNeeded = 1 + (participants[i].insotit ? 1 : 0) + (participants[i].cuCopii ? Number(participants[i].nr_copii) : 0)
					participants[i].participantIndex = i
				}

				this.setState({
					participantsData: participants,
					displayData: participants,
					packageData: response.data,
					loading: false
				})
			})
	}

	onChange = (value, data = null) => {
		let participantsData = this.state.participantsData

		if (data !== null) {
			participantsData = data
		}

		value = value.toLocaleLowerCase()

		let filteredByName = participantsData.filter(element => {
			return element.nume.toLocaleLowerCase().indexOf(value) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(value) !== -1
		})



		this.setState({
			currentSearch: value,
			displayData: filteredByName
		})
	}

	markAsHost = async (index) => {
		let user = this.state.displayData[index].user
		if (user === undefined || user === null || user === -1) {
			toast.error("Acest participant nu are un cont")
			return
		}

		this.setState({
			isSendingData: true
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//set checker
		let responseID = this.state.displayData[index].id

		let responseOBJ = {
			is_checker: true
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'responses/update/' + responseID, responseOBJ, auth)
			.then(_ => {
				toast.success("Invitat ales ca host")

				let newData = [...this.state.participantsData]
				newData[this.state.displayData[index].participantIndex].is_checker = true

				this.onChange(this.state.currentSearch, newData)
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare, va rog reincercati")
			})

		this.setState({
			isSendingData: false
		})
	}

	unmarkAsHost = (index) => {
		this.setState({
			isSendingData: true
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//set checker
		let responseID = this.state.displayData[index].id

		let responseOBJ = {
			is_checker: false
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'responses/update/' + responseID, responseOBJ, auth)
			.then(response => {
				toast.success("Pozitia de host anulata")

				let newData = [...this.state.participantsData]
				newData[this.state.displayData[index].participantIndex].is_checker = false

				this.onChange(this.state.currentSearch, newData)

				this.setState({
					isSendingData: false
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare, va rog reincercati")
			})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				hostess: true
			},
			auth)

	}

	goBack = () => {
		this.props.history.push("/organizer/guests/" + this.props.match.params.id)
	}

	render() {
		if (this.state.loading) {
			return <div className="CheckPresence-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="HostessPage-container">
				<HeaderWhite goBack={this.goBack} />

				<div className="HostessPage-contents">
					<div style={{ fontSize: "30px", fontWeight: "bold" }}>Alege Hostess</div>

					<CustomInput style={{ backgroundColor: "#E5F7FE" }} value={this.state.currentSearch} onChange={value => this.onChange(value, null)} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

					<div className="data-table" style={{ width: "100%" }}>
						<div className="header">
							<div style={{ width: "70%" }}>Nume Participant</div>
							<div style={{ width: "30%", textAlign: "center" }}>Alege hostess</div>
						</div>

						{this.state.displayData.map((element, index) => {
							return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
								<div style={{ width: "70%" }}> {element.prenume} </div>
								<div style={{ width: "30%", display: "flex", justifyContent: "center" }}>
									{element.user === null
										? <div>N/A</div>
										: element.is_checker
											? <Button style={{ width: "100px", borderRadius: "20px", backgroundColor: "#F26157", color: "white" }} onClick={() => this.unmarkAsHost(index)} loading={this.state.isSendingData}>
												Anuleaza
											</Button>
											: <Button style={{ width: "100px", borderRadius: "20px", backgroundColor: "#19B899", color: "white" }} onClick={() => this.markAsHost(index)} loading={this.state.isSendingData}>
												Alege
											</Button>
									}
								</div>
							</div>
						})}
					</div>

					<BackAndConfirmButtons
						functionBack={this.goBack}
						textBack={'Înapoi'}
						functionConfirm={()=> this.setState({tutorialModal:true})}
						textConfirm={"Informații utile"}
					/>

					<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Acest meniu îți oferă posibilitatea de a „Alege” (din secțiunea cu verde) hostess din lista invitaților care si-au facut cont în aplicație. Invitații care au răspuns invitației și nu si-au creat  cont, vor apărea cu „N/A” și nu pot fi aleși hostess.<br/><br/>
							Nicio grija! Cei care nu fac parte din lista invitatilor, le poți trimite invitația apasand „Invita hostess” și introducand adresa de e-mail a persoanei respective. Iar de aici, 3 pași simpli: accesare e-mail, acceptare invitație și creare cont. Gata! Acum, persoana invitată de tine poate accesa detaliile evenimentului.
						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default HostessPage;
