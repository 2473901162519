import React from 'react'
import {Toggle, Icon} from 'rsuite'
import {toast} from 'react-toastify'
import HeaderWhite from '../../components/HeaderWhite'
import NavbarColor from '../../components/NavbarColor'
import ResetModal from './ResetModal/ResetPass'
import ResetName from './ResetModal/ResetName'
import axios from 'axios'
import {Modal} from 'rsuite';

import './Profile.scss'
import {BackAndConfirmButtons} from "../../components/Helper";
import EmailChangeModal from "./EmailChangeModal/EmailChangeModal";
import Loader from "../../components/Loader";
import {fixWebsiteUrl, getReadableWebsite} from "../../functions";
import {injectIntl} from 'react-intl';
let isPressingToggle = false

class Profile extends React.Component {
    state = {
        showResetModal: false,
        showEmailChangeModal: false,
        forceReset: false,
        showNameModal: false,
        showChangeEmailModal: false,
        showDeleteModal: false,
        deleteLoading: false,

        name: "",
        surname: "",
        email: "",
        showNotifications: false,

        notificationsNews: true,
        notificationsEvents: true,
        notificationsPublicEvents: true,
        notificationsAll: true,

        wantsToReceiveEmails: true,
        isQrChecker: false,

        phone: '',
        second_email: '',
        linkedin: '',
        imageLink: null,
        company:'',
        function: '',
        instagram: '',
        facebook: '',
        website: '',

        loading: true
    }

    adminEmails = [
        'flavian.popescu@yahoo.com',
        'vlad@softclaire.ro',
        'andrei.radulescu90@gmail.com',
        'christian.casian@gmail.com',
        'roxana.munteanu@greenrevolution.ro'
    ]

    componentDidMount() {
        if (localStorage.getItem('jwt') === null) {
            toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_log_in'}))
            this.props.history.push('/login?redirectBackTo=profile');
            return
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
            .then(response => {
                let isQrChecker = response.data.role === 3|| response.data.qr_checker_participants.length > 0

                if (isQrChecker) {
                    localStorage.setItem("QRChecker", "Checker")
                }

                this.setState({
                    name: response.data.last_name,
                    surname: response.data.first_name,
                    email: response.data.email,
                    isQrChecker: isQrChecker,
                    notificationsNews: response.data.notification_news,
                    notificationsEvents: response.data.notification_events,
                    notificationsPublicEvents: response.data.notification_public_events,
                    notificationsAll: response.data.notification_news && response.data.notification_events && response.data.notification_public_events,
                    wantsToReceiveEmails: response.data.wants_to_receive_emails !== false,
                    imageLink: response.data.image && response.data.image.url ? 'https://' + process.env.REACT_APP_IMAGE_DOMAIN + response.data.image.url : '',
                    phone: response.data.phone,
                    second_email: response.data.second_email,
                    linkedin: response.data.linkedin,
                    company: response.data.company,
                    function: response.data.function,
                    instagram: response.data.instagram,
                    facebook: response.data.facebook,
                    website: response.data.website,
                    loading: false
                })

                this.requestPushToken()

                let shouldResetPassword = localStorage.getItem("changePassword") === "yes"
                if (shouldResetPassword) {
                    this.setState({
                        showResetModal: true,
                        forceReset: true,
                    })
                }

                let showResetPassword = localStorage.getItem("changePasswordGracefully") === "yes"
                if (showResetPassword) {
                    this.setState({
                        showResetModal: true
                    })
                    localStorage.removeItem('changePasswordGracefully')
                }
            })
            .catch(err => {

                if(err && err.response && err.response.status === 401) {
                    localStorage.clear()
                    toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_log_in'}))
                    this.props.history.push('/login');
                } else {
                    toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
                }

            })
    }

    requestPushToken = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }
        if(window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('getPushNotificationToken')
                .then(token => {

                    let data = {
                        token: token
                    }
                    axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
                })
        }

        if(window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'getPushToken',
                data: localStorage.getItem('jwt')
            }))
        }
    }

    goToMyTickets = () => {
        this.props.history.push("/mytickets")
    }

    goToMyInvites = () => {
        this.props.history.push("/myinvites")
    }

    goToOrganizerPanel = () => {
        window.location.replace('https://admin.ality.ro/admin')
    }

    checkTicket = () => {
        this.props.history.push("/qrscan/checkTicket")
    }

    goToParticipantsTag = () => {
        this.props.history.push("/qrscan/participantsTag")
    }

    changePassword = () => {
        this.setState({
            showResetModal: true,
        })
    }

    changeName = () => {
        this.setState({
            showNameModal: true,
        })
    }

    setNameAndSurname = (name, surname) => {
        this.setState({
            name: name,
            surname: surname
        })
    }

    closeResetModal = () => {
        this.setState({
            showResetModal: false,
            forceReset: false
        })
    }

    closeNameModal = () => {
        this.setState({
            showNameModal: false,
        })
    }

    changeEmail = () => {
        this.setState({
            showEmailChangeModal: true
        })
    }

    closeEmailModal = () => {
        this.setState({
            showEmailChangeModal: false
        })
    }

    setNotif = async () => {
        if (isPressingToggle) {
            return
        }

        isPressingToggle = true

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {
            notification_news: this.state.notificationsNews,
            notification_events: this.state.notificationsEvents,
            notification_public_events: this.state.notificationsPublicEvents,
            wants_to_receive_emails: this.state.wantsToReceiveEmails
        }

        this.setState({
            notificationsAll: this.state.notificationsNews && this.state.notificationsEvents && this.state.notificationsPublicEvents
        })

        await axios.put(process.env.REACT_APP_DOMAIN + "user/updateProfileToggles", obj, auth)
            .then(_ => {

                toast.success(this.props.intl.formatMessage({id: 'general.alerts.changes_saved'}))
            })
            .catch(err => {
                toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
            })

        isPressingToggle = false
    }

    clearPushNotifToken = () => {

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            let data = {
                token: ""
            }

            axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)

    }

    logOut = () => {
        this.clearPushNotifToken()

        localStorage.clear();

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'clearJwt'
            }))
        }

        this.props.history.push('/Login')
    }

    getInfoRow = (icon, name, text) => {
        return <div className="Row">
            <Icon icon={icon} size="2x" style={{margin: "0px 10px", color: "#06aff2"}}/>

            <div className="Data">
                <div>{name}</div>

                <p style={{color: "#121213", fontSize: "16px"}}>{text}</p>
            </div>
        </div>
    }

    getOptionRow = (icon, text, func, color) => {
        return <div className="Row" onClick={func}>
            <Icon icon={icon} size="2x" style={{margin: "0px 10px", color: color}}/>

            <div className="Data">
                {text}
            </div>
        </div>
    }

    editProfile = () => {
        this.props.history.push('/editProfile')
    }

    goBack = () => {
        this.props.history.go(-1)
    }

    requestDelete = async () => {

        this.setState({
            deleteLoading: true
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.post(process.env.REACT_APP_DOMAIN + 'auth/requestAccountDelete', {}, auth)
            .then((response) => {

                toast.error(this.props.intl.formatMessage({id: 'profile.alerts.account_deleted'}))

                localStorage.clear();
                this.props.history.push('/Login')

            })
            .catch(err => {
                toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
            })


        this.setState({
            deleteLoading: false
        })

    }

    openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    render() {
        if (this.state.loading) {
            return <div className="Profile-container font-family-poppins-300">
                <HeaderWhite/>

                <h4 className="Profile-TopText">{this.props.intl.formatMessage({id: 'profile.title'})}</h4>

                <div className="Profile-modal"></div>

                <div className="Profile-contents">
                    <Loader/>
                </div>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        }

        let inputStyle = {margin: "10px 0px", textAlign: 'center', maxWidth: "500px", alignSelf: 'center'}

        let style = {width: "120vw", marginLeft: "-5vw"};

        if (window.innerWidth >= 768) {
            style = {width: "100vw", marginLeft: "0px"};
        }

        return (
            <div className="Profile-container font-family-poppins-300">
                <HeaderWhite/>

                <h4 className="Profile-TopText">{this.props.intl.formatMessage({id: 'profile.title'})}</h4>


                <div className="Profile-modal">
                    <ResetModal showModal={this.state.showResetModal} closeModal={this.closeResetModal}
                                email={this.state.email}
                                forceReset={this.state.forceReset} history={this.props.history}/>

                    <EmailChangeModal showModal={this.state.showEmailChangeModal} closeModal={this.closeEmailModal}
                                      email={this.state.email}/>

                    {this.state.showNameModal &&
                    <ResetName showModal={this.state.showNameModal} closeModal={this.closeNameModal}
                               name={this.state.name} surname={this.state.surname}
                               setNameAndSurname={this.setNameAndSurname}/>}
                </div>

                <div className="Profile-contents">

                    <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                        <div className="Section" style={{flex:1, marginRight:'5px'}}>
                            {this.getOptionRow("ticket", this.props.intl.formatMessage({id: 'profile.my_tickets'}), this.goToMyTickets, "#06aff2")}
                        </div>

                        <div className="Section" style={{flex:1, marginLeft:'5px'}}>
                            {this.getOptionRow("envelope-open-o", this.props.intl.formatMessage({id: 'profile.my_invites'}), this.goToMyInvites, "#06aff2")}
                        </div>
                    </div>

                    {
                        this.state.email && this.adminEmails.includes(this.state.email) ?
                            <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                            <div className="Section" style={{flex:1, marginLeft:'5px'}}>
                                {this.getOptionRow("cogs", this.props.intl.formatMessage({id: 'profile.admin_panel'}), this.goToOrganizerPanel, "#06aff2")}
                            </div>
                            </div>
                            :null
                    }



                    <div className="Section-Title">
                        <p>{this.props.intl.formatMessage({id: 'profile.personal_data'})}</p>
                    </div>

                    <div className="Section">
                        <div style={{borderBottom:'1px solid #e0e0e0'}}>

                            <div className={"avatar-cont"} onClick={() => this.props.history.push('/editProfile')}>
                                {
                                        this.state.imageLink ?
                                            <div className={"image-preview"}>
                                                <img  alt={""} src={this.state.imageLink} style={{width:'100%'}}/>
                                            </div>
                                            :
                                            <div className={"image-preview"}>
                                                <img  alt={""} src={"/images/avatar_placeholder.jpg"} style={{width:'100%'}}/>
                                            </div>
                                }
                            </div>


                            <div className={"details-cont"}>
                            <div className={"details-item"} onClick={() => this.props.history.push('/editProfile')}>
                        {this.getInfoRow("user-info", this.props.intl.formatMessage({id: 'general.last_name'}), this.state.name)}
                                </div>
                                <div className={"details-item"} onClick={() => this.props.history.push('/editProfile')}>
                                {this.getInfoRow("user-info", this.props.intl.formatMessage({id: 'general.first_name'}), this.state.surname)}
                                </div>


                            <div className={"details-item"} onClick={() => this.props.history.push('/editProfile')}>
                                    {this.getInfoRow("at", this.props.intl.formatMessage({id: 'general.email_account'}), this.state.email)}
                                </div>
                                <div className={"details-item"} onClick={() => this.props.history.push('/editProfile')}>
                                    {this.getInfoRow("phone", this.props.intl.formatMessage({id: 'general.phone'}), this.state.phone ? this.state.phone : '-')}
                                </div>



                                <div className={"details-item"} onClick={() => this.props.history.push('/editProfile')}>
                                    {this.getInfoRow("at", this.props.intl.formatMessage({id: 'general.email_work'}), this.state.second_email ? this.state.second_email : '-')}
                                </div>
                                <div className={"details-item"} onClick={() => this.props.history.push('/editProfile')}>
                                    {this.getInfoRow("building", this.props.intl.formatMessage({id: 'general.company_or_institution'}), this.state.company ? this.state.company : '-')}
                                </div>



                                <div className={"details-item"} onClick={() => this.props.history.push('/editProfile')}>
                                    {this.getInfoRow("certificate", this.props.intl.formatMessage({id: 'general.function_or_title'}), this.state.function ? this.state.function : '-')}
                                </div>
                            <div className={"details-item"}>
                                    <span onClick={() => {
                                        if(this.state.website) {
                                            this.openLink(fixWebsiteUrl(this.state.website))
                                        }
                                    }}>
                                    {this.getInfoRow("internet-explorer", this.props.intl.formatMessage({id: 'general.website'}), this.state.website ?
                                        <><Icon icon={'link'} style={{color: "#06aff2"}}/> {getReadableWebsite(this.state.website)}</> : '-')}
                                    </span>
                            </div>
                                <div className={"details-item"}>
                                    <span onClick={() => {
                                        if(this.state.linkedin) {
                                            this.openLink('https://www.linkedin.com/in/' + this.state.linkedin)
                                        }
                                    }}>
                                    {this.getInfoRow("linkedin", this.props.intl.formatMessage({id: 'general.linkedin'}), this.state.linkedin ? <><Icon icon={'link'} style={{color: "#06aff2"}}/> {this.state.linkedin}</> : '-')}
                                    </span>
                                </div>
                                <div className={"details-item"}>
                                    <span onClick={() => {
                                        if(this.state.instagram) {
                                            this.openLink('https://www.instagram.com/' + this.state.instagram)
                                        }
                                    }}>
                                    {this.getInfoRow("instagram", this.props.intl.formatMessage({id: 'general.instagram'}), this.state.instagram ? <><Icon icon={'link'} style={{color: "#06aff2"}}/> {this.state.instagram}</> : '-')}
                                    </span>
                                </div>
                                <div className={"details-item"}>
                                    <span onClick={() => {
                                        if(this.state.facebook) {
                                            this.openLink('https://www.facebook.com/' + this.state.facebook)
                                        }
                                    }}>
                                    {this.getInfoRow("facebook-official", this.props.intl.formatMessage({id: 'general.facebook'}), this.state.facebook ? <><Icon icon={'link'}  style={{color: "#06aff2"}}/> {this.state.facebook}</> : '-')}
                                    </span>
                                </div>
                            </div>

                        </div>



                        {this.getOptionRow("frame", this.props.intl.formatMessage({id: 'profile.edit_profile'}), this.editProfile, "#06aff2")}

                        {this.getOptionRow("key", this.props.intl.formatMessage({id: 'profile.change_password'}), this.changePassword, "#06aff2")}

                        {this.getOptionRow("trash", this.props.intl.formatMessage({id: 'profile.delete_account'}), () => this.setState({showDeleteModal: true}), "#06aff2")}

                        {this.getOptionRow("at", this.props.intl.formatMessage({id: 'profile.change_email'}), this.changeEmail, "#06aff2")}

                    </div>

                    <div className="Section-Title">
                        <p>{this.props.intl.formatMessage({id: 'profile.notifications'})}</p>
                    </div>

                    <div className="Section">

                        <div className="Row">
                            <Icon icon={"envelope"} size="2x" style={{margin: "0px 10px", color: "#06aff2"}}/>

                            <div className="Data notification-type-row">
                                {this.props.intl.formatMessage({id: 'profile.notifications.text'})}
                            </div>
                        </div>

                        {
                            this.state.notificationsAll ?
                                <div className="Row">
                                    <Icon icon={"envelope"} size="2x"
                                          style={{margin: "0px 10px", color: "transparent"}}/>

                                    <div className="Data notification-type-row">
                                        <Toggle
                                            style={inputStyle}
                                            checked={this.state.notificationsAll}
                                            size="lg"
                                            checkedChildren={this.props.intl.formatMessage({id: 'general.yes'})}
                                            unCheckedChildren={this.props.intl.formatMessage({id: 'general.no'})}
                                            onChange={(value) => {
                                                this.setState({
                                                    notificationsAll: value,
                                                })
                                            }}/>

                                        <div className={"label"}>{this.props.intl.formatMessage({id: 'profile.notifications.all'})}</div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="Row">
                                        <Icon icon={"envelope"} size="2x"
                                              style={{margin: "0px 10px", color: "transparent"}}/>

                                        <div className="Data notification-type-row">
                                            <Toggle
                                                style={inputStyle}
                                                checked={this.state.notificationsPublicEvents}
                                                size="lg"
                                                checkedChildren={this.props.intl.formatMessage({id: 'general.yes'})}
                                                unCheckedChildren={this.props.intl.formatMessage({id: 'general.no'})}
                                                onChange={(value) => {
                                                    this.setState({
                                                        notificationsPublicEvents: value
                                                    }, () => this.setNotif())
                                                }}/>

                                            <div className={"label"}>{this.props.intl.formatMessage({id: 'profile.notifications.public_events'})}</div>
                                        </div>
                                    </div>

                                    <div className="Row">
                                        <Icon icon={"envelope"} size="2x"
                                              style={{margin: "0px 10px", color: "transparent"}}/>

                                        <div className="Data notification-type-row">
                                            <Toggle
                                                style={inputStyle}
                                                checked={this.state.notificationsEvents}
                                                size="lg"
                                                checkedChildren={this.props.intl.formatMessage({id: 'general.yes'})}
                                                unCheckedChildren={this.props.intl.formatMessage({id: 'general.no'})}
                                                onChange={(value) => {
                                                    this.setState({
                                                        notificationsEvents: value
                                                    }, () => this.setNotif())
                                                }}/>

                                            <div className={"label"}>{this.props.intl.formatMessage({id: 'profile.notifications.attending_events'})}</div>
                                        </div>
                                    </div>

                                    <div className="Row">
                                        <Icon icon={"envelope"} size="2x"
                                              style={{margin: "0px 10px", color: "transparent"}}/>

                                        <div className="Data notification-type-row">
                                            <Toggle
                                                style={inputStyle}
                                                checked={this.state.notificationsNews}
                                                size="lg"
                                                checkedChildren={this.props.intl.formatMessage({id: 'general.yes'})}
                                                unCheckedChildren={this.props.intl.formatMessage({id: 'general.no'})}
                                                onChange={(value) => {
                                                    this.setState({
                                                        notificationsNews: value
                                                    }, () => this.setNotif())
                                                }}/>

                                            <div className={"label"}>{this.props.intl.formatMessage({id: 'profile.notifications.news'})}</div>
                                        </div>
                                    </div>
                                </>
                        }

                        <div className="Row">
                            <Icon icon={"envelope"} size="2x" style={{margin: "0px 10px", color: "transparent"}}/>

                            <div className="Data notification-type-row">
                                <Toggle
                                    style={inputStyle}
                                    checked={this.state.wantsToReceiveEmails}
                                    size="lg"
                                    checkedChildren={this.props.intl.formatMessage({id: 'general.yes'})}
                                    unCheckedChildren={this.props.intl.formatMessage({id: 'general.no'})}
                                    onChange={(value) => {
                                        this.setState({
                                            wantsToReceiveEmails: value,
                                        }, () => this.setNotif())
                                    }}/>

                                <div className={"label"}>{this.props.intl.formatMessage({id: 'profile.notifications.by_email'})}</div>
                            </div>
                        </div>

                    </div>

                    {this.state.isQrChecker ?
                        <>
                            <div className="Section">
                                {this.getOptionRow("ticket", this.props.intl.formatMessage({id: 'profile.check_ticket'}), this.checkTicket, "#06aff2")}
                            </div>
                            <div className="Section">
                                {this.getOptionRow("tag", this.props.intl.formatMessage({id: 'profile.tag_participants'}), this.goToParticipantsTag, "#06aff2")}
                            </div>
                        </>
                        : null
                    }

                    <div className="Section">
                        {this.getOptionRow("off", "Log Out", this.logOut, "#d3163e")}
                    </div>


                </div>

                <Modal full style={style} backdrop={true} show={this.state.showDeleteModal}
                       onHide={() => this.setState({showDeleteModal: false})}>
                    <Modal.Header>
                        <Modal.Title>{this.props.intl.formatMessage({id: 'profile.delete_account.title'})}</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>

                        <p>{this.props.intl.formatMessage({id: 'profile.delete_account.text1'})}</p>
                        <p>{this.props.intl.formatMessage({id: 'profile.delete_account.text2'})}</p>

                        <br/>

                        <BackAndConfirmButtons
                            textBack={this.props.intl.formatMessage({id: 'general.back'})}
                            textConfirm={this.props.intl.formatMessage({id: 'general.confirm'})}
                            functionBack={() => this.setState({showDeleteModal: false})}
                            functionConfirm={this.requestDelete}/>


                    </Modal.Body>

                </Modal>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        )
    }
}

export default injectIntl(Profile);
