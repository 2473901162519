import React from 'react'
import './EditEvent.scss'
import HeaderWhite from '../../components/HeaderWhite'
import {Uploader, Button, Icon, Toggle} from 'rsuite'
import Modal from "react-responsive-modal";
import {geocodeByAddress} from 'react-google-places-autocomplete';

import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import CustomInput from '../components/custom/CustomInput';
import {ConfirmButton,
    EditPicture2,
    getBase64FromImage,
    getUrlFromImage, reorderLocations, resizeBlob,
    textarea_max_length
} from '../components/Helper';
import LoadingModal from "./LoadingModal/LoadingModal";

let locatiiNunta = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Locatie biserica",
    value: "Locatie biserica"
}, {label: "Locatie cununie", value: "Locatie cununie"}, {label: "Alta locatie", value: "Alta locatie"}]
let locatiiBotez = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Locatie biserica",
    value: "Locatie biserica"
}, {label: "Alta locatie", value: "Alta locatie"}]
let locatiiPetrecere = [{label: "Locatie petrecere", value: "Locatie petrecere"}, {
    label: "Alta locatie",
    value: "Alta locatie"
}]

class PublishEvent extends React.Component {
    state = {
        event: {},
        loading: false,
        showModal: false,
        prenumeMireasa: "",
        prenumeMire: "",
        dataEvent: "",
        eventType: "",
        eventId: "",
        eventDescription: "",
        backgroundImageLink: "",
        backgroundImage: null,
        croppedImage: null,
        locationsTypes: [],
        isPublished: false,

        base64ImageLink: "",
        showPublicNoticeModal: false,

        codEvent: "",
        codEventEdit: '',
        showCodeModal: false,
        newCodeValid: true,

        tutorialModal: false,

        locations: [],
        locationType: "",
        locationName: "",
        locationData: new Date(),
        locationDay: new Date(),
        locationAddress: "",

        editIndex: -1,

        waitModal: false
    }

    params = new URLSearchParams(window.location.search) // id=123

    async componentDidMount() {

        if(this.params && this.params.get('redirectToPreview')) {
            this.props.history.push('/organizer/eventPage/' + this.props.match.params.id)
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

        if (info !== null) {
            this.setState({
                eventType: info.data.eventType,
                eventId: info.data.id,
                locationsTypes: info.data.eventType === "Nunta" ? locatiiNunta : info.data.eventType === "Botez" ? locatiiBotez : locatiiPetrecere,
                prenumeMireasa: info.data.prenumeMireasa,
                prenumeMire: info.data.prenumeMire,
                eventDescription: info.data.eventDescription,
                dataEvent: new Date(info.data.dataEvent),
                locationDay: new Date(info.data.dataEvent),
                backgroundImageLink: info.data.backgroundImage !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(info.data.backgroundImage)}` : null,
                codEvent: info.data.id_eveniment,
                codEventEdit: info.data.id_eveniment,
                isPublished: !!info.data.isPublished,
                locations: info.data.locatii,
                event: info.data,
                tutorialModal: !info.data.tutorials || (info.data.tutorials && !info.data.tutorials.publishEvent),
            })
        }
    }

    updateTutorial = async () => {

        this.setState({
            tutorialModal: false
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

      await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
            {
                publishEvent: true
            },
            auth)

    }

    selectLocationType = (value) => {
        this.setState({
            locationType: value
        })
    }

    changeLocationName = (value) => {
        this.setState({
            locationName: value
        })
    }

    changeLocationDate = (value) => {
        this.setState({
            locationData: value
        })
    }

    changeLocationDay = (value) => {
        this.setState({
            locationDay: value
        })
    }

    changeEventDescription = (value) => {
        if (this.state.eventDescription.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
            return
        }

        this.setState({
            eventDescription: value
        })
    }

    setLocationAddress = (value) => {
        this.setState({
            locationAddress: value.description
        })
    }

    addLocation = () => {
        let {locationAddress, locationData, locationDay, locationName} = this.state;

        if (this.state.locationType === "") {
            toast.error("Adaugati tipul sau numele locatiei")
            return
        }

        if (this.state.locationType === "Alta locatie" && locationName === "") {
            toast.error("Adaugati numele locatiei ")
            return
        }

        if (locationName === "") {
            locationName = this.state.locationType
        }

        if (locationData === "") {
            toast.error("Adaugati ora evenimentului ")
            return
        }

        if (locationDay === "") {
            toast.error("Adaugati ziua evenimentului ")
            return
        }

        if (locationAddress === "") {
            toast.error("Adaugati adresa evenimentului")
            return
        }

        let current = [...this.state.locations]

        geocodeByAddress(locationAddress)
            .then(results => {

                let pinx = parseFloat(results[0].geometry.location.lat())
                let piny = parseFloat(results[0].geometry.location.lng())

                let location = {
                    address: locationAddress,
                    pinx: pinx,
                    piny: piny,
                    time: locationData,
                    day: locationDay,
                    name: locationName
                }

                if (this.state.editIndex !== -1) {
                    current[this.state.editIndex] = location
                } else {
                    current.push(location)
                }

                current = reorderLocations(current)

                this.setState({
                    locations: current,
                    locationName: "",
                    locationAddress: "",
                    locationType: "",
                    locationData: new Date(),
                    locationDay: new Date(this.state.dataEvent),
                    editIndex: -1,
                    showModal: false
                })
            })
    }

    editLocation = (index) => {
        let location = this.state.locations[index]
        let list = this.state.locationsTypes.filter(elem => elem.value === location.name)

        this.setState({
            editIndex: index,
            locationAddress: location.address,
            locationType: list.length === 0 ? "Alta locatie" : location.name,
            locationData: new Date(location.time),
            locationDay: location.day === undefined ? new Date(location.time) : new Date(location.day),
            locationName: list.length === 0 ? location.name : "",

            showModal: true
        })
    }

    removeLocation = () => {
        let index = this.state.editIndex
        let newLocations = [...this.state.locations]

        newLocations.splice(index, 1)

        this.setState({
            locations: newLocations,
            locationName: "",
            locationAddress: "",
            locationType: "",
            locationData: new Date(),
            locationDay: new Date(this.state.dataEvent),
            editIndex: -1,
            showModal: false
        })
    }

    openModal = () => {
        this.setState({
            showModal: true
        })
    }

    closeModal = () => {
        this.setState({
            locationName: "",
            locationAddress: "",
            locationType: "",
            locationData: new Date(),
            locationDay: new Date(this.state.dataEvent),
            editIndex: -1,
            showModal: false
        })
    }

    handleError = err => {
        console.error(err)
    }

    handleChange = (value, event) => {
        this.setState({
            [event.target.name]: value
        })
    }

    onChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    handlePoza = async (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                backgroundImage: null
            })
            return
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        let blob = await resizeBlob(fileList[0].blobFile)

        this.setState({
            backgroundImage: [blob]
        })
    }

    saveCroppedImage = async (croppedImg) => {
        let img = {}
        img.blobFile = croppedImg
        let data = await getBase64FromImage(img)
        // console.log(data);

        this.setState({
            croppedImage: croppedImg,
            base64ImageLink: data,
            backgroundImageLink: data,
            backgroundImage: null
        })
    }

    saveEvent = async (noRedirect = false, callback = () => {}) => {

        this.setState({
            showCodeModal: false
        })

        if (this.state.eventType === "" || this.state.prenumeMire === "" || this.state.dataEvent === "" || this.state.eventDescription === "") {
            toast.error("Nu ati completat toate campurile")
            return;
        }

        if (this.state.eventType === "Nunta" && this.state.prenumeMireasa === "") {
            toast.error("Nu ati completat toate campurile")
            return;
        }

        this.setState({
            waitModal: true
        })

        let obj = {
            id_eveniment: this.state.codEventEdit && this.state.newCodeValid ? this.state.codEventEdit : this.state.codEvent,
            prenumeMireasa: this.state.eventType === "Nunta" ? this.state.prenumeMireasa : "Mireasa",
            prenumeMire: this.state.prenumeMire,
            dataEvent: this.state.dataEvent,
            eventDescription: this.state.eventDescription,
            locations: this.state.locations,
            imageLink: this.state.base64ImageLink,
            isPublished: this.state.isPublished
        }

        //for image
        // let formData = new FormData()
        // formData.append("data", JSON.stringify(obj))

        // if (this.state.backgroundImage !== null) {
        // 	formData.append("files.backgroundImage", this.state.backgroundImage[0])
        // }

        //for imga cropped
        // if (this.state.backgroundImage !== null && this.state.croppedImage !== null) {
        // 	formData.append("files.backgroundImage", this.state.croppedImage)
        // }

        let auth = {
            headers: {
                // "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/edit/' + this.state.eventId, obj, auth)//formData, auth)
            .then(response => {
                if(response.data.status === 1) {
                    if(!noRedirect) {
                        toast.success("Eventul a fost actualizat!")
                        this.props.history.push('/organizer/info/' + response.data.id_eveniment)
                    }
                    callback()
                } else {
                    toast.error("A aparut o eroare!")
                }
            })
            .catch(err => {
                toast.error("Eroare")
                this.setState({
                    waitModal: false
                })
            })
    }

    saveAndCheckNewEventCode = async (newCode) => {

        newCode = newCode.replace(' ','')
        newCode = newCode.replace('\'','_')
        newCode = newCode.replace(/[^a-z0-9]/gi,'')

        this.setState({
            codEventEdit: newCode
        })

        if(!newCode || newCode.length < 4 || newCode.length > 20) {
            this.setState({
                newCodeValid: false
            })
            return
        }

        if(this.state.codEvent === newCode) {
            this.setState({
                newCodeValid: true
            })
            return
        }

        let auth = {
            headers: {
                // "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {
            new_id_eveniment: newCode
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/checkWeddingNewCode', obj, auth)
            .then(response => {
                if(response.data.status === 1) {
                    this.setState({
                        newCodeValid: true
                    })
                } else {
                    this.setState({
                        newCodeValid: false
                    })
                }
            })
            .catch(err => {
            })
    }

    goBack = () => {
        this.props.history.push("/organizer/info/" + this.props.match.params.id)
    }

    handleStartDate = (value) => {
        this.setState({
            dataEvent: value.toISOString(),
            locationDay: value.toISOString()
        })
    }

    render() {

        let customInputStyle = {
            margin: "10px 0px",
            backgroundColor: "#E5F7FE",
            borderRadius: "10px"
        }

        return (
            <div className="EditEvent-container">
                <HeaderWhite goBack={this.goBack}/>

                <div className="EditEvent-contents">
                    <h4 id="title"> Publică evenimentul </h4>

                    <div className="input-zone">
                        <br/>

                        <div style={{textAlign: 'left', fontSize: '12px', padding: '0 5px', color: '#000000'}}>Cod
                            eveniment
                        </div>
                        <div style={{
                            position: 'relative',
                            margin: "0 0 10px 0",
                            backgroundColor: "#E5F7FE",
                            borderRadius: "10px",
                            cursor: 'pointer'
                        }}
                             onClick={() => {
                                 this.setState({
                                     showCodeModal: true
                                 })
                             }}
                        >
                            <div style={{
                                position:'absolute',
                                right: '20px',
                                top: '10px'
                            }}>
                                <Icon icon="question"/>
                            </div>

                            <div style={{
                                color: "#221d5d",
                                width: "100%",
                                height: "100%",
                                textAlign: 'left',
                                padding: '10px 10px'
                            }}>
                                <Icon icon="pencil" style={{marginRight:'10px'}}/> {this.state.codEvent}
                            </div>
                        </div>

                        <CustomInput value={this.state.eventDescription} onChange={this.changeEventDescription}
                                     style={{height: "200px"}}
                                     placeholder={`Descriere Eveniment ${this.state.eventDescription.length}/${textarea_max_length}`}
                                     componentClass={"textarea"} icon={<Icon icon="pencil"/>}/>

                        <p>Imaginea evenimentului</p>
                        {this.state.backgroundImageLink !== null
                            ? <img style={{padding: "5px", width: "291px", height: "150px", alignSelf: "center"}}
                                   src={this.state.backgroundImageLink} alt=""/>
                            : <div>Nicio imagine adaugată</div>
                        }

                        <div style={customInputStyle}>
                            <Uploader onChange={this.handlePoza} accept=".jpg" style={{borderRadius: "5px"}} fileListVisible={false}
                                      autoUpload={false} multiple={false}
                                      disabled={this.state.backgroundImage !== null}>
                                <div style={{color: "#221d5d", width: "100%", height: "100%"}} id="text">Click aici
                                    pentru a încărca imaginea evenimentului
                                </div>
                            </Uploader>
                        </div>

                        {this.state.backgroundImage !== null && this.state.backgroundImage[0] ?
                        <EditPicture2 uploadedImage={this.state.backgroundImage[0]}
                                      width={291} //1164
                                      height={150} //2210
                                      border={0}
                                      borderRadius={0}
                                     saveCroppedImage={this.saveCroppedImage}/>
                        :null
                        }

                        <div style={{margin: "10px 0px", display: "flex", flexDirection: "column"}}>
                            <Toggle style={{width: "150px", alignSelf: "center"}} checked={this.state.isPublished}
                                    onChange={status => {

                                        if(this.state.event.invite && this.state.event.invite.id) {
                                            this.setState({isPublished: status})
                                        } else {
                                            this.setState({isPublished: false, showPublicNoticeModal:true})
                                        }
                                    }} checkedChildren="Publicat"
                                    unCheckedChildren="Nepublicat"/>

                            <div>Publicarea evenimentului îl face vizibil în lista de evenimente, acesta putând fi
                                accesat de persoanele care au codul evenimentului sau care au primit invitație.
                                (Nepublicarea evenimentului nu afectează pe cei care primesc invitație)
                            </div>
                        </div>

                        <ConfirmButton
                            text={"Previzualizează"}
                            style={{width:'100%'}}
                            function={() => {
                                if(this.state.newCodeValid) {
                                    this.saveEvent(true, () => {
                                        this.props.history.push('/organizer/eventPage/' + this.state.event.id_eveniment)
                                    })
                                }
                            }} />

                        <Button
                            onClick={()=> this.setState({tutorialModal:true})}
                            color="green"
                            style={{backgroundColor: "#05AFF2", overflow:'visible', borderRadius: "20px", width: "99%", marginTop:"10px", marginBottom: "5px"}}>Informații utile</Button>

                        <br/>


                        <div className="Footer">

                            <Button onClick={this.goBack} className="back-button" style={{color: "#1E1C59"}}>
                                Înapoi
                            </Button>

                            <Button onClick={() => this.saveEvent()} className="add-button"
                                    style={{backgroundColor: "#00afcc", color: "white"}}>
                                Salvează
                            </Button>
                        </div>
                    </div>

                    <LoadingModal loading={this.state.waitModal}/>
                </div>


                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal-add-location',
                    closeButton: 'modal-close-button'
                }} open={this.state.showCodeModal} onClose={() => this.setState({showCodeModal: false})}>

                    <br/>
                    <p>Pentru ca tinem la intimitatea ta, acest eveniment este privat și doar invitații cărora le-ai trimis invitație pot accesa evenimentu. Aveti si posibilitatea de a publica evenimentul in secțiunea “Evenimente private” prin apăsarea butonului “Publica” astfel invitatii pot accesa evenimentul numai prin introducerea codului de mai jos. Pentru ca invitatii tai sa acceseze cu usurinta evenimentul aveți mai jos posibilitatea de a edita codul.</p>
                    <br/>
                    <CustomInput value={this.state.codEventEdit}
                                 onChange={(value) => this.saveAndCheckNewEventCode(value)} style={customInputStyle}
                                 placeholder={'Cod eveniment'}
                                 icon={<Icon icon="pencil"/>}/>
                    <p>Minim 4, maxim 20 de caractere</p>

                    {
                        !this.state.newCodeValid ?
                            <div style={{color:'red'}}>
                                Codul ales nu este disponibil sau este invalid. Va rugam sa alegeti altul.
                            </div>
                            :
                            null
                    }

                    {
                        this.state.event.invite_sent_date || this.state.event.savethedate_sent_date ?
                            <p style={{color: 'red', marginTop:'10px'}}>Atenție! SaveTheDate și invitațiile deja trimise nu vor mai putea
                                fi accesate după schimbarea codului evenimentului!</p>
                            : null
                    }

                    <Button onClick={() => {
                        if(this.state.newCodeValid) {
                            this.saveEvent()
                        }
                    }} className="add-button"
                            style={{backgroundColor: "#00afcc", color: "white", marginTop:'20px', width:'100%'}}>
                        Salveaza
                    </Button>

                </Modal>

                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button'
                }} open={this.state.showPublicNoticeModal} onClose={() => this.setState({showPublicNoticeModal: false})}>

                    <br/>
                    <p>Pentru a publica evenimentul este necesar ca invitatia sa fie configurata!</p>
                    <br/>
                    <Button onClick={() => {
                        this.saveEvent(true)
                        this.props.history.push('/organizer/addInvite/' + this.state.event.id_eveniment)
                        this.setState({showPublicNoticeModal: false})
                    }} className="add-button"
                            style={{backgroundColor: "#00afcc", color: "white", marginTop:'20px', width:'100%'}}>
                        Mergi la invitatie
                    </Button>


                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.tutorialModal}
                    focusTrapped={false}
                    onClose={this.updateTutorial}
                >
                    <div style={{marginTop: "20px"}}>
                        <h3>Informații utile</h3>
                        <br/>
                        <p>
                            Fiind un eveniment privat lăsăm la alegerea ta dacă sau când evenimentul va fi publicat în lista evenimentelor private, dar stai fără griji chiar dacă îl publicați nimeni nu poate avea
                            acces la eveniment fără a introduce codul aferent evenimentului. Codul evenimentului îl găsești în acest meniu și se poate edita.
                        </p>
                        <Button
                            onClick={this.updateTutorial}
                            color="green"
                            style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
                    </div>
                </Modal>

                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default PublishEvent;
