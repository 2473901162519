import React from 'react'
import './Announcements.scss'
import axios from 'axios'
import AdModal from '../components/AdModal';
import moment from "moment-timezone";
import {FormattedMessage} from "react-intl";

let adFieldName = "announcements"

class Announcements extends React.Component {
	state = {
		event_data: this.props.event_data,
		announcement: [],

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}

	componentDidMount = async () => {
		if (this.props.participantData !== null && this.props.event_data.count_announcements > Number(this.props.participantData.last_announcement_count)) {
			//update local data
			this.props.participantData.last_announcement_count = "" + this.props.event_data.count_announcements

			//update server data
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let obj = {
				last_announcement_count: this.props.participantData.last_announcement_count,
			}

			axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
		}

		let announcements = []
		this.props.event_data.announcement && this.props.event_data.announcement.forEach((announcement,i) => {
			if(announcement.audience && announcement.audience.length > 0) {
				if(announcement.audience === 'paid_ticket' && this.props.participantData.is_paid === true) {
					announcements.push(announcement)
				}

				if(announcement.audience === 'no_paid_ticket' && this.props.participantData.is_paid !== true) {
					announcements.push(announcement)
				}

				if(announcement.audience === 'participate' && this.props.participantData.inviteStatus === 1) {
					announcements.push(announcement)
				}

				if(announcement.audience === 'no_participate' && this.props.participantData.inviteStatus === 3) {
					announcements.push(announcement)
				}

				if(announcement.audience === 'no_invite_answer' && this.props.participantData.inviteStatus === 0) {
					announcements.push(announcement)
				}

				if(announcement.audience === 'all') {
					announcements.push(announcement)
				}

			} else {
				//No audience set o ALL
				announcements.push(announcement)
			}
		})

		this.setState({
			announcement: announcements
		})
	}

	getTimeFromData = (data) => {
		var parts = data.split("T")
		var date = parts[0].split("-")
		var hour = parts[1].split(":")

		return (Number(hour[0]) + 3) % 24 + ":" + hour[1] + "  " + date[2] + "-" + date[1] + "-" + date[0]
	}

	showAnnouncement = (announcement) => {
		return <div key={announcement.id} className="announcement">
			<p style={{fontWeight: "bold"}}><FormattedMessage id={"event_page.announces.title"} />: {announcement.titlu}</p>

			<div className="content" dangerouslySetInnerHTML={{ __html: `<div class="content">${announcement.continut}</div>` }}></div>

			<p style={{backgroundColor: "#A5A5A5", borderRadius: "20px", width: "150px", margin: "0px auto", color: "white"}}>{moment(announcement.published_at).format('DD/MM/YYYY H:m')}</p>
		</div>
	}

	getAnnouncements = (ann) => {
		var ans = []

		for (var i = ann.length - 1; i >= 0; i--) {
			ans.push(this.showAnnouncement(ann[i]))
		}

		return ans
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		if (this.state.showAdModal) {
			return <div className="Announcements-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		return (
			<div className="Announcements-container">
				{this.state.announcement.length > 0
					? this.getAnnouncements(this.state.announcement)
					: <p style={{ fontSize: 20 }}><FormattedMessage id={"event_page.announces.no_items"} /></p>
				}
			</div>
		)
	}
}

export default Announcements;
