import React from 'react'
import { Icon } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import './HostessPage.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import { Button } from 'rsuite'
import ShowTable from './ShowTable'
import NavbarColor from '../../../components/NavbarColor'
import CustomInput from '../../../components/custom/CustomInput'
import Loader from "../../../organizer/components/Loader";
import {MdKeyboardArrowDown, MdKeyboardArrowUp} from "react-icons/md";

class HostessPage extends React.Component {
	state = {
		responses: [],
		participantsData: [],
		displayData: [],
		currentSearch: "",

		positions: [],
		showTable: false,
		responseToShow: {},

		presentFamilies: 0,
		totalFamilies: 0,

		presentParticipants: 0,
		totalParticipants: 0,

		orderByTable: 0,
		orderByName: 0,

		loading: true
	}

	componentDidMount = async () => {
		if (localStorage.getItem("id") === null) {
			toast.error("Trebuie sa va logati pentru a folosi site-ul")
			this.props.history.push('/login');
			return
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let seating = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id)

		let positions = seating.data.seatingChart && seating.data.seatingChart.positions ? seating.data.seatingChart.positions : []

		this.setState({
			positions: positions
		})

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {
				let totalFamilies = 0
				let presentFamilies = 0

				//take all data
				let data = response.data.responses.map((invitee, index) => {
					let ans = { ...invitee.response }
					ans.responseIndex = index
					ans.id = invitee.id
					ans.is_present = invitee.is_present

					if (invitee.response.answer === "yes") {
						totalFamilies++

						if (invitee.is_present) {
							presentFamilies++
						}
					}

					return ans
				})

				if (data.length === 0) {
					this.setState({
						positions: [],
						participantsData: [],
						displayData: [],
						loading: false
					})

					toast.info("Niciun raspuns in acest moment")

					return
				}

				//get only tables from data
				let tablePositions = positions.filter(elem => elem.type === "roundTable" || elem.type === "squareTable" || elem.type === "rectangleTable")

				//set tables for participants from server data
				let i = 0;
				for (; i < tablePositions.length; i++) {
					let peopleAtTable = tablePositions[i].peopleAtTable

					for (let j = 0; j < peopleAtTable.length; j++) {

						for(let x=0; x<data.length; x++) {
							if(data[x].id === peopleAtTable[j].responseId) {
								data[x].table = i
							}
						}

						//if (data[peopleAtTable[j].responseIndex] !== undefined) {
						//	data[peopleAtTable[j].responseIndex].table = i
						//}

						//if(data[peopleAtTable[j].responseId === 13016]) {
						//	console.log(data[peopleAtTable[j]])
						//}
					}

					/*
					for (let j = 0; j < peopleAtTable.length; j++) {
						if (data[peopleAtTable[j].responseIndex] !== undefined) {
							data[peopleAtTable[j].responseIndex].table = i
						}
					}*/
				}

				//take only data from people who will be participating
				let participants = data.filter(ans => ans.answer === "yes")

				let totalParticipants = 0;
				let presentParticipants = 0;

				//set total seats needed
				for (i = 0; i < participants.length; i++) {
					participants[i].seatsNeeded = 1 + (participants[i].insotit ? 1 : 0) + (participants[i].cuCopii ? Number(participants[i].nr_copii) : 0)
					participants[i].participantIndex = i

					totalParticipants += participants[i].seatsNeeded

					if (participants[i].is_present) {
						presentParticipants += participants[i].seatsNeeded
					}
				}

				this.setState({
					// positions: positions,
					responses: data,
					participantsData: participants,
					displayData: participants,

					totalFamilies: totalFamilies,
					presentFamilies: presentFamilies,

					totalParticipants: totalParticipants,
					presentParticipants: presentParticipants,

					loading: false
				})
			})
	}

	onChange = (value) => {

		var filteredByName = this.state.participantsData.filter(element => {
			return element.nume.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1 ||
				element.prenume.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) !== -1
		})

		if(this.state.orderByTable === 1) {
			filteredByName = filteredByName.sort((a,b) => (a.table > -1 ? a.table : -1) - b.table);
		} else if(this.state.orderByTable === 2) {
			filteredByName = filteredByName.sort((a,b) => (a.table > -1 ? a.table : -1) - b.table).reverse()
		}

		if(this.state.orderByName === 1) {
			filteredByName = filteredByName.sort((a,b) => a.prenume.localeCompare(b.prenume));
		} else if(this.state.orderByName === 2) {
			filteredByName = filteredByName.sort((a,b) => a.prenume.localeCompare(b.prenume)).reverse()
		}

		this.setState({
			currentSearch: value,
			displayData: filteredByName
		})
	}

	checkIn = async (index) => {
		let obj = {
			id: this.state.displayData[index].id
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'responses/markAsPresent', obj, auth)
			.then(response => {
				toast.success(this.state.displayData[index].prenume + " " + this.state.displayData[index].nume + " a ajuns")
				let newParticipantsData = this.state.participantsData

				if(response.data.notifyLimit) {
					toast.success("Limita de participare a fost atinsa !")
				}

				newParticipantsData[this.state.displayData[index].participantIndex].is_present = true

				let newPresentFamilies = this.state.presentFamilies + 1
				let newPresentParticipants = this.state.presentParticipants + newParticipantsData[this.state.displayData[index].participantIndex].seatsNeeded

				this.setState({
					participantsData: newParticipantsData,
					responseToShow: this.state.displayData[index],
					presentFamilies: newPresentFamilies,
					presentParticipants: newPresentParticipants,
					showTable: true,
				})

				this.onChange(this.state.currentSearch)
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare la marcarea invitatului, va rog reintrati si reincercati")
			})
	}

	showParticipantTable = (index) => {
		let responseToShow = { responseIndex: -1 }

		if (index !== -1) {
			responseToShow = this.state.displayData[index]
		}


		this.setState({
			responseToShow: responseToShow,
			showTable: true,
		})
	}

	backToCheckIn = () => {
		this.setState({
			showTable: false
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading === true) {
			return <Loader/>
		}

		if (this.state.showTable) {
			return <ShowTable

				responseData={this.state.responseToShow} positions={this.state.positions} eventId={this.props.match.params.id}
				participantsData={this.state.responses} back={this.backToCheckIn}
			/>
		}

		let widthStyle = { width: '20%', textAlign:'center' }
		let isBigScreen = true

		if (window.innerWidth < 700) {
			isBigScreen = false
			widthStyle = { width: '50%',  textAlign:'center'  }
		}

		let displayInvites = this.state.displayData.map((element, index) => {
			return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
				<div style={widthStyle}> {element.prenume} </div>

				{isBigScreen && <div style={widthStyle}> {element.seatsNeeded} </div>}

				{/*{isBigScreen && <div style={widthStyle}> {element.table === undefined ? "Nu este asezat" : element.table + 1}</div>}*/}

				<div style={widthStyle}>
					<Button style={{ width: "100px", borderRadius: "0px" }} color="yellow" disabled={element.table === undefined} appearance="ghost"
							onClick={() => this.showParticipantTable(index)}>
						{element.table === undefined ? "Masa " : "Masa "+ (parseInt(element.table+'') + 1)}
					</Button>
				</div>

				<div style={widthStyle}>
					<Button style={{ width: "100px", borderRadius: "0px" }} color="green" disabled={element.is_present} appearance="ghost" onClick={() => this.checkIn(index)}>
						{element.is_present ? "Checked in" : "Check-in"}
					</Button>
				</div>
			</div>
		})

		return <div className="HostessPage-container">
			<HeaderWhite goBack={this.goBack} />

			<div className="HostessPage-contents">


				<div className={"present_participants_header"}>
					<p style={{ width: "33%" }}>Prezenta familii</p>
					<div style={{ width: "33%" }}>{this.state.presentFamilies}/{this.state.totalFamilies}</div>
					<p style={{ width: "33%" }}>{this.state.presentFamilies === 0 ? 0 : Math.round((this.state.presentFamilies / this.state.totalFamilies) * 100)}%</p>
				</div>
				<div className="present_participants">
					<div className={"progress-bar"}>
						<div className={"inner"} style={{
							width: (this.state.presentFamilies === 0 ? 0 : Math.round((this.state.presentFamilies / this.state.totalFamilies) * 100)) + '%'
						}}>
						</div>
					</div>
				</div>

				<div className="present_participants_header">
					<p style={{ width: "33%" }}>Prezenta participanti</p>
					<div style={{ width: "33%" }}>{this.state.presentParticipants}/{this.state.totalParticipants}</div>
					<p style={{ width: "33%" }}>{this.state.presentParticipants === 0 ? 0 : Math.round((this.state.presentParticipants / this.state.totalParticipants) * 100)}%</p>
				</div>
				<div className="present_participants">
					<div className={"progress-bar"}>
						<div className={"inner"} style={{
							width: (this.state.presentParticipants === 0 ? 0 : Math.round((this.state.presentParticipants / this.state.totalParticipants) * 100)) + '%'
						}}>
						</div>
					</div>
				</div>

				<CustomInput style={{ backgroundColor: "#E5F7FE" }} value={this.state.currentSearch}
					onChange={this.onChange} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

				<div className="data-table" style={{ width: "100%" }}>
					<div className="header">

						<div style={widthStyle} onClick={() => {
							this.setState({
								orderByTable: 0,
								orderByName: this.state.orderByName === 0 ?
								1 :
									this.state.orderByName === 1 ?
								2 :
										this.state.orderByName === 2 ?
								0
								:null,
							}, () => this.onChange(''))
						}}>Nume{
							this.state.orderByName === 1 ?
								<MdKeyboardArrowUp style={{position:"relative", top:"3px"}} />
								:
								this.state.orderByName === 2 ?
								<MdKeyboardArrowDown style={{position:"relative", top:"3px"}} />
									:null
						}
						</div>

						{isBigScreen && <div style={widthStyle}>Persoane</div>}

						{/* {isBigScreen && <div style={widthStyle}>Masa</div>} */}

						<div style={widthStyle} onClick={() => {
								this.setState({
									orderByTable: this.state.orderByTable === 0 ?
										1:
									this.state.orderByTable === 1 ?
										2
										:
										this.state.orderByTable === 2 ?
											0
											:null,
									orderByName: 0,
								}, () => this.onChange(''))
							}}>Masa{
								this.state.orderByTable === 1 ?
									<MdKeyboardArrowUp style={{position:"relative", top:"3px"}} />
									:
									this.state.orderByTable === 2 ?
									<MdKeyboardArrowDown style={{position:"relative", top:"3px"}} />
										:
										null

							}
						</div>

						<div style={widthStyle}>Check-in</div>
					</div>

					{displayInvites}
				</div>
			</div>

			{/* <div style={{ marginBottom: "20px" }}>
				<Button color="green" onClick={() => this.showParticipantTable(-1)}>Vizualizare Plan Mese</Button>
			</div> */}

			<NavbarColor history={this.props.history} />
		</div>
	}
}

export default HostessPage;
