import React from 'react'
import './LineupTab.scss'
import axios from 'axios'
import moment from "moment-timezone";
import Modal from "react-responsive-modal";
import {Button, Dropdown, Icon} from "rsuite";
import {toast} from "react-toastify";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import BeatLoader from "react-spinners/BeatLoader";
import {css} from "@emotion/react";
import GoogleWebLoginButton from "../../components/GoogleLogin/GoogleWebLoginButton";

class LineupDjTab extends React.Component {

    isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

    state = {
        event_data: this.props.event_data,
        showModal: false,
        artistName: '',
        stageName: '',
        date: '',
        notifyDate: '',
        notifyLabel: 'Când începe',
        hasPushToken: false,
        myNotifications: [],
        showGuestModal: false,
        loading: false
    }

    onAppMessage = async (message) => {

        let data = JSON.parse(message.data) ? JSON.parse(message.data) : false

        if(data) {
            // eslint-disable-next-line
            switch (data.action) {
                case 'signInWithApple':
                    this.appleSignIn(data.firstName, data.lastName, data.email, data.accessToken)
                    break
                case 'signInWithFacebook':
                    this.facebookSignIn({accessToken: data.accessToken})
                    break
                case 'signInWithGoogle':
                    this.googleSignIn({tokenId: data.accessToken})
                    break
            }
        }
    }

    componentDidMount() {
        [window, document].forEach(
            el => el.addEventListener('message', this.onAppMessage))

        this.checkPushToken()
        this.getMyNotifications()

        if(localStorage.getItem('jwt') === null) {
            this.setState({
                showGuestModal: true
            })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("message", this.onAppMessage)
        document.removeEventListener("message", this.onAppMessage)
    }

    requestPushToken = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }
        if(window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('getPushNotificationToken')
                .then(token => {

                    let data = {
                        token: token
                    }

                    axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
                })
        }

        if(window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'getPushToken',
                data: localStorage.getItem('jwt')
            }))
        }
    }

    loginGoogle = async () => {
        if (window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('loginGoogle')
                .then(token => {
                    if (token === "NULL" || token === "ERROR") {
                        if (token === "ERROR") {
                            toast.error("Eroare la conectare, va rog reincercati.")
                        }
                    } else {
                        this.googleSignIn({tokenId: token})
                    }
                })
        }

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'signInWithGoogle'
            }))
        }
    }

    googleSignIn = (response) => {

        if (!response.tokenId) {
            return;
        }

        let tokenId = response.tokenId
        let obj = {
            tokenId: tokenId
        }

        this.setState({
            loading: true
        })

        axios.post(process.env.REACT_APP_DOMAIN + 'login/social/google', obj)
            .then(async (response) => {
                if (response.data.user.confirmed) {
                    toast.success("Logare cu succes!")

                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('rememberMe', "remember")

                    this.requestPushToken()

                    let responseId = localStorage.getItem("responseId")

                    if (responseId !== null) {
                        let data = {
                            responseId: responseId
                        }

                        let auth = {
                            headers: {
                                'Authorization': 'Bearer ' + response.data.jwt
                            }
                        }

                        await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
                    }

                    if(this.props.event_data) {
                        this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
                    }
                } else {
                    toast.error("Va rog confirmati email-ul")
                }
                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Email sau parola gresita")
            })


    }

    loginFacebook = async () => {
        if (window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('loginFacebook')
                .then(token => {
                    if (token === "NULL" || token === "ERROR") {
                        if (token === "ERROR") {
                            toast.error("Eroare la conectare, va rog reincercati.")
                        }
                    } else {
                        this.facebookSignIn({accessToken: token})
                    }
                })
        }

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'signInWithFacebook'
            }))
        }
    }

    facebookSignIn = (response) => {

        if (!response.accessToken) {
            return;
        }

        let accessToken = response.accessToken

        let obj = {
            accessToken: accessToken
        }

        this.setState({
            loading: true
        })

        axios.post(process.env.REACT_APP_DOMAIN + 'login/social/facebook', obj)
            .then(async(response) => {
                if (response.data.user.confirmed) {
                    toast.success("Logare cu succes!")

                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

                    this.requestPushToken()

                    let responseId = localStorage.getItem("responseId")

                    if (responseId !== null) {
                        let data = {
                            responseId: responseId
                        }

                        let auth = {
                            headers: {
                                'Authorization': 'Bearer ' + response.data.jwt
                            }
                        }

                        await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
                    }

                    if(this.props.event_data) {
                        this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
                    }
                } else {
                    toast.error("Va rog confirmati email-ul")
                }
                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Email sau parola gresita")
            })


    }

    appleSignIn = (firstName, lastName, email, accessToken) => {

        let obj = {
            accessToken: accessToken,
            email: email,
            first_name: firstName,
            last_name: lastName
        }

        this.setState({
            loading: true
        })

        axios.post(process.env.REACT_APP_DOMAIN + 'login/social/apple', obj)
            .then(async (response) => {
                if (response.data.user.confirmed) {
                    toast.success("Logare cu succes!")

                    localStorage.setItem('jwt', response.data.jwt);
                    localStorage.setItem('id', response.data.user.id);
                    localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

                    this.requestPushToken()

                    let responseId = localStorage.getItem("responseId")

                    if (responseId !== null) {
                        let data = {
                            responseId: responseId
                        }

                        let auth = {
                            headers: {
                                'Authorization': 'Bearer ' + response.data.jwt
                            }
                        }

                        await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
                    }

                    if(this.props.event_data) {
                        this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
                    }
                } else {
                    toast.error("Va rog confirmati email-ul")
                }
                this.setState({
                    loading: false
                })
            })
            .catch(err => {
                this.setState({
                    loading: false
                })
                toast.error("Email sau parola gresita")
            })


    }

    loginApple = async () => {
        if (window.flutter_inappwebview) {
            await window.flutter_inappwebview.callHandler('loginApple')
                .then(data => {
                    let response = data.split('|')
                    this.appleSignIn(response[2], response['1'], response['3'], response['0'])
                })
        }

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'signInWithApple'
            }))
        }
    }

    async checkPushToken () {
        if(localStorage.getItem('jwt')) {

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
                .then(response => {
                    if(response.data.pushToken && response.data.pushToken.length > 0) {
                        this.setState({
                            hasPushToken: true
                        })
                    }
                })
        }
    }

    async getMyNotifications () {
        if(localStorage.getItem('jwt')) {
            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            let payload = {
                id: this.state.event_data.id
            }

            axios.post(process.env.REACT_APP_DOMAIN + 'lineup-notifications/listMyNotifications', payload, auth)
                .then(response => {
                    this.setState({
                        myNotifications: response.data.notifications
                    })
                })
        }
    }

    async setNotification () {
        if(localStorage.getItem('jwt')) {

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            let payload = {
                message: this.state.stageName + ': ' + this.state.artistName + ' începe la ora ' + moment(this.state.date).format('HH:mm'),
                notifyDate: this.state.notifyDate,
                eventId: this.state.event_data.id_eveniment,
            }

            axios.post(process.env.REACT_APP_DOMAIN + 'lineup-notifications/create', payload, auth)
                .then(response => {

                    this.setState({
                        showModal: false,
                        artistName: '',
                        stageName: '',
                        date: '',
                        notifyDate: '',
                        notifyLabel: 'Când începe'
                    })

                    toast.success('Notificarea a fost înregistrată!')

                    this.getMyNotifications()
                })
        }
    }

    goToLogin = () => {
        localStorage.setItem('eventId', this.props.event_data.id_eveniment);
        this.props.history.push("/login")
    }

    goToSignup = () => {
        localStorage.setItem('eventId', this.props.event_data.id_eveniment);
        this.props.history.push("/signup")
    }

    checkNotification(artistName, stageName, date) {

        let found = false

        for(let i=0; i<this.state.myNotifications.length; i++) {
            if(this.state.myNotifications[i].message === (stageName + ': ' +artistName + ' începe la ora ' + moment(date).format('HH:mm'))) {
                found = true
            }
        }
        return found
    }

    openSetNotificationModal = (artist, stage) => {

        if(moment(artist.date) < moment()) {
            toast.error('Data acestui moment a fost deja depășită!')
            return
        }

        if(localStorage.getItem('jwt')) {
            if(artist.date) {
                this.setState({
                    showModal: true,
                    artistName: artist.name,
                    stageName: stage.stage,
                    date: artist.date,
                    notifyDate: artist.date
                })
            }
        } else {
            this.setState({
                showGuestModal: true
            })
        }
    }

    render() {

        return (<div className="LineupTab-container">

                {
                    this.state.loading ?
                        <div className={"loading-overlay"}>
                            <BeatLoader color={"#00B7AD"} loading={true} css={css} size={30} />
                            <span>Se încarcă ...</span>
                        </div>
                        :
                        null
                }


                {
                    this.state.event_data.lineupdj1 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup1'])?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj1_title ? this.state.event_data.lineupdj1_title : 'Ziua 1'}</h3>
                            {
                                this.state.event_data.lineupdj1 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj1.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup1'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup1'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj2 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup2'])?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj2_title ? this.state.event_data.lineupdj2_title : 'Ziua 2'}</h3>
                            {
                                this.state.event_data.lineupdj2 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj2.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup2'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup2'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj3 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup3'])?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj3_title ? this.state.event_data.lineupdj3_title : 'Ziua 3'}</h3>
                            {
                                this.state.event_data.lineupdj3 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj3.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup3'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup3'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj4 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup4']) ?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj4_title ? this.state.event_data.lineupdj4_title : 'Ziua 4'}</h3>
                            {
                                this.state.event_data.lineupdj4 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj4.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup4'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup4'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj5 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup5']) ?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj5_title ? this.state.event_data.lineupdj5_title : 'Ziua 5'}</h3>
                            {
                                this.state.event_data.lineupdj5 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj5.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup5'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup5'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj6 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup6']) ?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj6_title ? this.state.event_data.lineupdj6_title : 'Ziua 6'}</h3>

                            {
                                this.state.event_data.lineupdj6 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj6.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup6'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup6'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj7 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup7']) ?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj7_title ? this.state.event_data.lineupdj7_title : 'Ziua 7'}</h3>
                            {
                                this.state.event_data.lineupdj7 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj7.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup7'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup7'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj8 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup8']) ?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj8_title ? this.state.event_data.lineupdj8_title : 'Ziua 8'}</h3>
                            {
                                this.state.event_data.lineupdj8 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj8.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup8'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup8'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj9 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup9']) ?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj9_title ? this.state.event_data.lineupdj9_title : 'Ziua 9'}</h3>
                            {
                                this.state.event_data.lineupdj9 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj9.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup9'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup9'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }

                {
                    this.state.event_data.lineupdj10 || (this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup10']) ?
                        <div className={"lineup"}>
                            <h3>{this.state.event_data.lineupdj10_title ? this.state.event_data.lineupdj9_title : 'Ziua 10'}</h3>
                            {
                                this.state.event_data.lineupdj10 ?
                                    <img  alt={""}
                                        src={process.env.REACT_APP_DOMAIN.substring(0, process.env.REACT_APP_DOMAIN.length - 1) + this.state.event_data.lineupdj10.url}/>
                                    : null
                            }
                            {
                                this.state.event_data.lineupdjDetails && this.state.event_data.lineupdjDetails['lineup10'] ?
                                    <div>

                                        {
                                            this.state.event_data.lineupdjDetails['lineup9'].map((stage,i) => (
                                                <div key={i}>
                                                    <h4>{stage.stage}</h4>

                                                    {
                                                        stage.artists.map((artist,j) => (
                                                            <div className={"artist-row " + (this.checkNotification(artist.name, stage.stage, artist.date) ? 'set' : '')} key={j} onClick={() => {
                                                                this.openSetNotificationModal(artist,stage)
                                                            }}>
                                                                <div className={"name"}>{artist.name}</div>

                                                                {
                                                                    artist.date ?
                                                                        <div className={"date"}>
                                                                            <div>
                                                                                {artist.date ? moment(artist.date).format('HH:mm') : ''}
                                                                                <Icon icon={"bell"}/>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className={"date"}></div>
                                                                }
                                                            </div>
                                                        ))
                                                    }

                                                </div>
                                            ))
                                        }


                                    </div>
                                    : null
                            }

                        </div>
                        :null
                }


                <Modal
                    classNames={{
                        overlay: 'modal-overlay z3000 modal-align-selft-center',
                        modal: 'modal',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showModal}
                    onClose={() => this.setState({showModal: false})}
                    showCloseIcon={false}
                    focusTrapped={false}
                >

                    <div style={{margin:'10px 20px 30px 20px'}}>
                        Moment/artist: {this.state.artistName}<br/>
                        Ora: {moment(this.state.date).format('HH:mm')}<br/>
                        <br/>

                        Când dorești să primești notificare ?<br/>


                        <Dropdown style={{ color: "#221d5d", backgroundColor: "#addaec", margin: "10px 0px", borderRadius: "10px" }} title={this.state.notifyLabel} className="dropdown_scroll">

                            <Dropdown.Item onClick={() => this.setState({notifyDate: moment(this.state.date).toISOString(), notifyLabel: 'Când începe'})}>
                                Când începe
                            </Dropdown.Item>

                            <Dropdown.Item onClick={() => this.setState({notifyDate: moment(this.state.date).subtract(5, "minutes").toISOString(), notifyLabel: 'Cu 5 minute înainte'})}>
                                Cu 5 minute înainte
                            </Dropdown.Item>

                            <Dropdown.Item onClick={() => this.setState({notifyDate: moment(this.state.date).subtract(15, "minutes").toISOString(), notifyLabel: 'Cu 15 minute înainte'})}>
                                Cu 15 minute înainte
                            </Dropdown.Item>

                            <Dropdown.Item onClick={() => this.setState({notifyDate: moment(this.state.date).subtract(30, "minutes").toISOString(), notifyLabel: 'Cu 30 minute înainte'})}>
                                Cu 30 minute înainte
                            </Dropdown.Item>

                            <Dropdown.Item onClick={() => this.setState({notifyDate: moment(this.state.date).subtract(60, "minutes").toISOString(), notifyLabel: 'Cu o oră înainte'})}>
                                Cu o oră înainte
                            </Dropdown.Item>

                        </Dropdown>

                    </div>

                    {
                        !this.state.hasPushToken && <div style={{borderRadius:'5px', border: '1px solid red', color:'red', padding:'5px 10px'}}>
                            Atenție! Nu ați activat notificările push! Veți primi notificarea pe email.
                        </div>
                    }



                    <div style={{float:'right'}}>
                        <Button style={{marginTop: "10px"}} color="green" onClick={() => this.setNotification()}>Salvează</Button>
                    </div>

                    <Button style={{marginTop: "10px"}} color="red" appearance="ghost" onClick={() => this.setState({showModal:false})}>Inchide</Button>
                </Modal>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-no-top LineupTab-GuestModal',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showGuestModal}
                    focusTrapped={false}
                    onClose={() => this.setState({showGuestModal: false})}
                >
                    <div style={{marginTop: "20px"}}>

                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <h5 style={{textAlign:'center'}}>Nu rata cele mai importante momente ale evenimentului!</h5>
                            <h6 style={{textAlign:'center', marginTop:'10px'}}>Autentifică-te și setează notificări pentru momentele tale preferate</h6>

                            <Button style={{ margin: "20px auto" }} color="green" onClick={this.goToLogin}>Intră în cont</Button>

                            <div className={"social-login-container"}>
                                {
                                    window.flutter_inappwebview || window.alityAppView ?

                                        <div style={{flexDirection:'column',width:'100%'}}>
                                            <button onClick={this.loginGoogle}
                                                    className={"social-login-button social-google-login"}
                                                    style={{width:'100%'}}>
                                                <Icon icon="google"/> Login Google
                                            </button>

                                            <button onClick={this.loginFacebook}
                                                    className={"social-login-button social-facebook-login"}
                                                    style={{width:'100%', marginTop:'10px'}}
                                            >
                                                <Icon icon="facebook"/> Login Facebook
                                            </button>

                                            {
                                                this.isIOS ?
                                                    <button onClick={this.loginApple}
                                                            className={"social-login-button social-apple-login"}
                                                            style={{width: '100%', marginTop: '10px'}}
                                                    >
                                                        <Icon icon="apple"/> Sign in with Apple
                                                    </button>
                                                    : null
                                            }
                                        </div>
                                        :
                                        <>

                                            <GoogleWebLoginButton
                                                callbackFunction={(val) => this.googleSignIn(val)}
                                            />

                                            <FacebookLogin
                                                appId="243653927956471"
                                                autoLoad={false}
                                                fields="name,email"
                                                //onClick={(item) => this.facebookSignIn(item)}
                                                callback={(item) => this.facebookSignIn(item)}
                                                //cookie
                                                //
                                                disableMobileRedirect={true}
                                                //isMobile={true}
                                                redirectUri={'https://app.ality.ro'}
                                                render={renderProps => (
                                                    <button
                                                        className={"social-login-button social-facebook-login"}
                                                        style={{marginLeft: '5px'}}
                                                        type={"button"}
                                                        onClick={renderProps.onClick}
                                                        disabled={renderProps.disabled}
                                                    ><Icon icon="facebook"/> Facebook</button>
                                                )}
                                            />
                                        </>
                                }
                            </div>
                            <Button style={{ margin: "20px auto" }} color="green" onClick={this.goToSignup}>Creează cont</Button>
                            <br/>
                            <Button style={{ margin: "20px auto" }} color="default" onClick={() => this.setState({showGuestModal:false})}>Nu acum</Button>
                        </div>

                    </div>
                </Modal>

            </div>
        )
    }
}

export default LineupDjTab;
