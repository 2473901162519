import React from 'react'
import {Button, Dropdown, Icon} from 'rsuite'
import './GiftMoney.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import Modal from "react-responsive-modal"
import axios from 'axios'
import { toast } from 'react-toastify'
import CustomInput from '../../components/custom/CustomInput'
import {BackAndConfirmButtons, DataCards, ShowPackageData, usedCurrency} from '../../components/Helper'
import NavbarColor from '../../../components/NavbarColor'

import SVG_Budget_Edit from '../../assets/BudgetSVG/edit-budget-icon.svg'
import Loader from "../../components/Loader";
import {currencies} from "../../../const";

class GiftMoney extends React.Component {
	state = {
		participantsData: [],
		displayData: [],

		currentSearch: "",
		showModal: false,
		indexToShow: 0,
		giftMoneyInput: 0,
		tutorialModal: false,
		currency: 'EUR',
		exchangeRates: [],

		packageData: {},

		isSendingData: false,
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let eventData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		this.setState({
			tutorialModal: !eventData.data.tutorials || (eventData.data.tutorials && !eventData.data.tutorials.giftMoney)
		})

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(async response => {

				let invites = response.data.responses.filter((element) => !element.deleted)

				//take all data
				let data = invites.map((invitee, index) => {
					let ans = { ...invitee.response }
					ans.responseIndex = index
					ans.giftMoney = invitee.giftMoney !== null ? invitee.giftMoney : 0
					ans.id = invitee.id
					ans.gift = invitee.gift

					return ans
				})

				if (data.length === 0) {
					this.setState({
						participantsData: [],
						displayData: [],
						packageData: response.data,
						loading: false
					})

					toast.info("Niciun raspuns in acest moment")

					return
				}


				this.setState({
					participantsData: data,
					exchangeRates: response.data.exchangeRates,
					displayData: data,
					packageData: response.data,
					loading: false
				})
			})
	}

	onChange = (value, data = null) => {
		let participantsData = this.state.participantsData

		if (data !== null) {
			participantsData = data
		}

		value = value.toLocaleLowerCase()

		let filteredByName = participantsData.filter(element => {
			let name = element.prenume + " " + element.nume

			return name.toLocaleLowerCase().indexOf(value) !== -1
		})

		this.setState({
			currentSearch: value,
			displayData: filteredByName
		})
	}

	openModal = (index) => {

		if(this.state.participantsData[index].gift && this.state.participantsData[index].gift.currency) {
			this.setState({
				currency: this.state.participantsData[index].gift.currency,
				showModal: true,
				indexToShow: index,
				giftMoneyInput: this.state.participantsData[index].gift.value
			})
		} else {
			this.setState({
				currency: 'EUR',
				showModal: true,
				indexToShow: index,
				giftMoneyInput: this.state.participantsData[index].giftMoney
			})
		}



	}

	closeModal = () => {
		this.setState({
			showModal: false
		})
	}

	getEurValue = (value, currency) => {
		let eurExchangeRate = this.state.exchangeRates['EUR'].amount
		let exchangeRate = this.state.exchangeRates[currency].amount

		//Get ron value
		let ronValue = value * exchangeRate

		//Exchange to the required currency
		let exchangedValue = ronValue / eurExchangeRate


		return Math.round(exchangedValue) //EUR value
	}



	saveGiftMoney = async () => {
		if (this.state.isSendingData) {
			return
		}
		this.setState({
			isSendingData: true
		})

		let index = this.state.indexToShow

		let giftObject = {}
		let giftMoney = Number(this.state.giftMoneyInput)

		if(this.state.currency !== 'EUR') {
			//Exchange currency
			giftObject = {
				currency: this.state.currency,
				value: this.state.giftMoneyInput
			}
			giftMoney = this.getEurValue(this.state.giftMoneyInput, this.state.currency)
		}

		let obj = {
			giftMoney: giftMoney,
			gift: giftObject
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'responses/update/' + this.state.participantsData[index].id, obj, auth)
			.then(_ => {
				toast.success("Salvat dar")
				let newParticipantsData = this.state.participantsData


				if(this.state.currency !== 'EUR') {
					//Exchange currency
					newParticipantsData[index].gift = {
						currency: this.state.currency,
						value: this.state.giftMoneyInput
					}
					newParticipantsData[index].giftMoney = this.getEurValue(this.state.giftMoneyInput, this.state.currency)
				} else {
					newParticipantsData[index].giftMoney = Number(this.state.giftMoneyInput)
				}


				this.setState({
					participantsData: newParticipantsData
				})

				this.onChange(this.state.currentSearch)
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare la setare dar, va rog reintrati si reincercati")
			})


		this.setState({
			isSendingData: false,
			showModal: false
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				giftMoney: true
			},
			auth)

	}

	goBack = () => {
		this.props.history.push("/organizer/guests/" + this.props.match.params.id)
	}

	render() {
		if (this.state.loading === true) {
			return <div className="CheckPresence-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let widthStyle = {
			width: "33%",
			overflow: "hidden",
			whiteSpace: "nowrap",
			textOverflow: "ellipsis",
			borderRadius: "0px",
			textAlign: "center"
		}

		let totalMoney = 0

		//count total amount
		this.state.participantsData.map(element => {
			let isSet = element.giftMoney !== 0

			if (isSet) {
				totalMoney += element.giftMoney
			}

			return ""
		})

		// eslint-disable-next-line
		let displayInvites = this.state.displayData.map((element, index) => {
			let isSet = element.giftMoney !== 0

			if(element && element.answer) {
				return <div className={index % 2 === 0 ? "even-line" : "uneven-line"} key={index}>
					<div style={widthStyle}> {element.prenume} </div>
					<div style={widthStyle}> {isSet ? element.giftMoney + " " + usedCurrency : "-"} </div>
					<div style={widthStyle} onClick={_ => this.openModal(element.responseIndex)}>
						{/* {isSet ? "Modifica" : "Introduceti"} */}
						<img src={SVG_Budget_Edit} alt="card" style={{height: "20px"}}/>
					</div>
				</div>
			}
		})

		return (
			<div className="GiftMoney-container">
				<HeaderWhite goBack={this.goBack} />

				{this.state.showModal && <Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.showModal} onClose={this.closeModal} focusTrapped={false}>
					<div><br/>
						<div> Introduceți dar pentru {this.state.participantsData[this.state.indexToShow].prenume} {this.state.participantsData[this.state.indexToShow].nume}</div>



						<div style={{position:'relative', marginBottom:'80px'}}>

							<div style={{position:'absolute', right:'5px', top:'5px', zIndex:'10000000'}}>
								<Dropdown title={this.state.currency} activeKey={this.state.currency} className="dropdown_scroll" style={{backgroundColor:'rgb(229, 247, 254)'}}>
									{
										currencies.map((currency, index) => (
											<Dropdown.Item eventKey={currency.value} onClick={() => this.setState({
												currency: currency.value,
												giftMoneyInput: 0
											})}>{currency.title}</Dropdown.Item>
										))
									}
								</Dropdown>
							</div>

						<CustomInput value={this.state.giftMoneyInput} onChange={value => this.setState({ giftMoneyInput: value })} placeholder={"Dar"} border={true} inputType={"number"} />
							{
								this.state.currency !== 'EUR' ?
									<p>{this.getEurValue(this.state.giftMoneyInput, this.state.currency)} Euro</p>
									:null
							}
						</div>
						<BackAndConfirmButtons loading={this.state.isSendingData} textBack={"Înapoi"} textConfirm={"Salvează"} functionBack={this.closeModal} functionConfirm={this.saveGiftMoney} />
					</div>
				</Modal>}

				<div className="GiftMoney-contents">
					<div style={{ fontSize: "30px", fontWeight: "bold" }}>Dar</div>

					<div style={{ fontSize: "20px", fontWeight: "bold" }}>
						<DataCards cards={[
							{
								data: `Total dar: ${totalMoney} ${usedCurrency}`,
								color: "#731DD8"
							}
						]} />
					</div>

					<CustomInput value={this.state.currentSearch} onChange={value => this.onChange(value, null)} placeholder={"Numele invitatului"} icon={<Icon icon="search" />} />

					<div className='tabel'>
						<div className="data-table" style={{ width: "100%" }}>
							<div className="header">
								<div style={widthStyle}>Nume</div>
								<div style={widthStyle}>Suma</div>
								<div style={widthStyle}>Modifica</div>
							</div>

							{displayInvites}
						</div>
					</div>

					<BackAndConfirmButtons
						functionBack={this.goBack}
						textBack={'Înapoi'}
						functionConfirm={()=> this.setState({tutorialModal:true})}
						textConfirm={"Informații utile"}
					/>

					<ShowPackageData packageData={this.state.packageData} history={this.props.history} eventId={this.props.match.params.id} />
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Nu există nuntă fără dar, iar noi te ajutăm să îl calculezi! Trece în această secțiune suma primită de la fiecare invitat. Suma totală a darului este calculată automat și în meniul „Buget”. Ai ieșit pe + după acest eveniment? :D
						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default GiftMoney;
