import React from 'react'
import { Button } from 'rsuite'
import './EmailChangeModal.scss'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../../components/custom/CustomInput'

class EmailChangeModal extends React.Component {
    state = {
        newEmail: ""
    }

    componentDidMount = async () => {
        this.setState({
            newEmail: ""
        })
    }


    sentEmailChangeRequest = async () => {

        if (this.state.newEmail.length === 0) {
            toast.error("Adresa de email este invalida")
            return
        }

        let obj = {
            newEmail: this.state.newEmail
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'email-change-request/request', obj, auth)
            .then((response) => {

                toast.success("Un email de confirmare a fost trimis pe noua adresa de email!")

            })
            .catch(_ => {
                toast.error("S-a produs o erorare va rog reincercati")
            })

        this.resetState()
    }

    resetState = () => {
        this.setState({
            newEmail: ''
        })

        this.props.closeModal()
    }

    render() {
        const styles = {
            fontFamily: "sans-serif",
            textAlign: "center",
            marginTop: "20px",
            width: "90vw",
            height: "100%",
        };

        return (
            <div style={styles}>
                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal',
                        closeButton: 'modal-close-button',
                    }}
                    center
                    open={this.props.showModal}
                    onClose={this.props.closeModal}
                >
                    <div style={{marginTop: "20px"}}>
                        <h4>Schimba adresa de email</h4>

                        <p>Va rugam introduceti noua adresa de email. Vom trimite un email cu un link pentru confirmarea acesteia.</p>

                        <CustomInput style={{ margin: "20px 0px 10px 0" }} inputType={"text"} value={this.state.newPass1} onChange={(value) => this.setState({newEmail: value})} placeholder={'Noua adresa de email'} border={true} />


                        <Button onClick={this.sentEmailChangeRequest} color="green" style={{ backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%" }}>Confirm</Button>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default EmailChangeModal