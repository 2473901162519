import React from 'react'
import './EventCard.scss'
import {Icon} from "rsuite";
import {getDefaultWeddingImage, getWeddingParticipantsPackageNumber, getWeddingTitle} from "../../../functions";
import Modal from "react-responsive-modal";
import moment from "moment-timezone";
import {BackAndConfirmButtons} from "../../components/Helper";

class EventCard extends React.Component {
	state = {
		markAsFinishedTutorial: false,
		deleteEventModal: false
	}

	render() {
		let event = this.props.event
		var data = event.dataEvent.split("T")[0].split("-")

		let packageNumber = getWeddingParticipantsPackageNumber(this.props.event)

		return (
			<div className={"EventCard-container-ViewEvents " + (this.props.customClass ? this.props.customClass : '')} >
				<div className="EventCard-content" onClick={() => this.props.moveToEvent(this.props.event.id_eveniment)}>

					{event.backgroundImage ?
						<div className="Picture"  onClick={() => this.props.moveToEvent(this.props.event.id_eveniment)}>
						<img src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${event.backgroundImage.url}`} alt={""} loading={"lazy"} />
					</div>
					:
						<div className="Picture"  onClick={() => this.props.moveToEvent(this.props.event.id_eveniment)}>
							<img src={getDefaultWeddingImage(this.props.event)} alt={""} loading={"lazy"}/>
						</div>
					}


					{
						!event.packages || !event.packages.wedding_participants ?
							<div className={"active-package free"}>
								<Icon icon="money" style={{marginRight:'5px'}} />Pachet gratuit
							</div>
							:
							<div className={"active-package invites"}>
								<Icon icon="money" style={{marginRight:'5px'}}/>
								{event.packages.wedding_participants.package === 1 || event.packages.wedding_participants.package === 3 ?
									'Pachetul invitație cu răspunsuri nelimitate'

									: event.packages.wedding_participants.package === 5 ?
										'Pachetul invitație cu 25 de răspunsuri'

										:
										'Pachetul Premium'

								}
								{
									event.packages && event.packages.wedding_participants &&
									event.packages.wedding_participants.details && event.packages.wedding_participants.details.addons &&
									event.packages.wedding_participants.details.addons.length > 0 ?
										' (+' + event.packages.wedding_participants.details.addons[0].title + ')'
										:
										''
								}
							</div>
					}

					<div className="Title font-family-poppins-700-bold">
						{
							getWeddingTitle(event)
						}
					</div>


					<div className="Data">{data[2]}-{data[1]}-{data[0]}</div>


					<div className="Full-Description">
						{event.eventDescription}
					</div>
				</div>


				<div className={"row buttons"}>
					{
						event.packages && event.packages.wedding_participants && event.packages.wedding_participants.package > 0  ?
							<>
								<div className="Share" onClick={() => this.props.openShareModal(event, "wedding")}>
									<Icon icon="share"/> Invitație
								</div>
							</>
							:
							null
					}

					<div className={"Finish " + (event.isFinished ? 'finished' : '')} onClick={() => {

						if(!event.isFinished && moment(new Date(event.dataEvent)) > moment(new Date())) {
							this.setState({
								markAsFinishedTutorial: true
							})

						} else {
							this.props.changeFinishedStatus()
						}

					}} >
						<Icon icon="check" /> {event.isFinished ? "Setează ca activ" : "Setează ca finalizat"}
					</div>

					<div className={"Delete"} onClick={() => {
						if(packageNumber>0) {
							this.setState({
								deleteEventModal: true
							})
							return
						}

						if(!event.isFinished && moment(new Date(event.dataEvent)) > moment(new Date())) {
							this.setState({
								deleteEventModal: true
							})
						} else {
							this.props.deleteEvent()
						}
					}}>
						<Icon icon="trash" /> Șterge
					</div>
				</div>


				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.markAsFinishedTutorial}
					focusTrapped={false}
					onClose={() => {
						this.setState({
							markAsFinishedTutorial: false
						})
					}}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Confirmare</h3>
						<br/>
						<p>Sunteți sigur/ă că doriți să marcați acest eveniment ca finalizat ?</p>
						<br/><br/>

						<BackAndConfirmButtons
							textBack={'Nu'}
							functionBack={() => {
								this.setState({
									markAsFinishedTutorial: false
								})
							}}
							textConfirm={'Da'}
							functionConfirm={() => {
								this.props.changeFinishedStatus()
								this.setState({
									markAsFinishedTutorial: false
								})
							}}
							/>

					</div>
				</Modal>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.deleteEventModal}
					focusTrapped={false}
					onClose={() => {
						this.setState({
							deleteEventModal: false
						})
					}}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Confirmare</h3>
						<br/>
						{
							packageNumber > 0 ?
								<>
									<p style={{color:'red'}}>Atenție! Acest eveniment are un pachet plătit!</p>
								</>
								:null
						}

						<p>Sunteți sigur/ă că doriți să ștergeți acest eveniment?</p>
						<br/><br/>

						<BackAndConfirmButtons
							textBack={'Nu'}
							functionBack={() => {
								this.setState({
									deleteEventModal: false
								})
							}}
							textConfirm={'Da'}
							functionConfirm={() => {
								this.props.deleteEvent()
								this.setState({
									deleteEventModal: false
								})
							}}
						/>

					</div>
				</Modal>


			</div>
		)
	}
}

export default EventCard;
