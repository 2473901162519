import React from 'react'
import './TestimonialsConfig.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import {Button, Dropdown} from 'rsuite'
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import {getUrlFromImage} from '../../components/Helper';
import Loader from "../../components/Loader";

class TestimonialsConfig extends React.Component {
    state = {
        loading: true,
        eventType: "",
        eventId: "",
        backgroundImageLink: "",
        backgroundImage: null,

        memorybooth: {},
        questions: [],

        duration: 20
    }

    async componentDidMount() {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

        if (info !== null) {

            this.setState({
                loading:false,
                eventType: info.data.eventType,
                eventId: info.data.id,
                backgroundImageLink: info.data.memoryboothBackground !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(info.data.memoryboothBackground)}` : null,
                memoryboothWatermarkLink: info.data.memoryboothWatermark !== null ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(info.data.memoryboothWatermark)}` : null,
                duration: info.data.memorybooth_duration,
                memorybooth: info.data.memorybooth,
                questions: info.data.memorybooth && info.data.memorybooth.questions ? info.data.memorybooth.questions : []
            })
        }
    }

    openModal = () => {
        this.setState({
            showModal: true
        })
    }

    closeModal = () => {
        this.setState({
            locationName: "",
            locationAddress: "",
            locationType: "",
            locationData: new Date(),
            showModal: false
        })
    }

    handleError = err => {
        console.error(err)
    }

    handleChange = (value, event) => {
        this.setState({
            [event.target.name]: value
        })
    }

    onChange = (value, key) => {
        this.setState({
            [key]: value
        })
    }

    handlePoza = (fileList) => {
        if (fileList.length === 0) {
            this.setState({
                backgroundImage: null
            })
            return
        }

        if (fileList[0].blobFile.size > 7000000) {
            toast.error("Fisier prea mare, maxim 7MB")
        }

        this.setState({
            backgroundImage: [fileList[0].blobFile]
        })
    }

    saveCroppedImage = (croppedImg) => {
        this.setState({
            croppedImage: croppedImg
        })
    }

    saveEvent = async () => {

        this.setState({
            loading: true
        })

        let memorybooth = this.state.memorybooth ? this.state.memorybooth : {}
        memorybooth.questions = this.state.questions

        let obj = {
            memorybooth_duration: this.state.duration,
            memorybooth: memorybooth,
            memorybooth_spent_time_limit: this.state.memoryBoothSpentTimeLimit
        }

        let auth = {
            headers: {
                "Content-Type": `multipart/form-data;`,
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let formData = new FormData()
        formData.append("data", JSON.stringify(obj))

        if (this.state.backgroundImage !== null) {
            formData.append("files.memoryboothBackground", this.state.backgroundImage[0])
        }

        //if (this.state.backgroundImage !== null && this.state.croppedImage !== null) {
        //	formData.append("files.backgroundImage", this.state.croppedImage)
        //}

        axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, formData, auth)
            .then(response => {
                toast.success("Modificarile au fost salvate!")
                this.props.history.goBack()
            })
            .catch(err => {
                toast.error("Eroare")
                this.setState({
                    waitModal: false
                })
            })
    }

    goBack = () => {
        this.props.history.goBack()
    }

    handleStartDate = (value) => {
        this.setState({
            dataEvent: value.toISOString()
        })
    }

    addQuestion = () => {

        let newQuestions = [...this.state.questions]

        newQuestions.push("")

        this.setState({
            questions: newQuestions
        })

    }

    editQuestion = (index, value) => {

        let newQuestions = [...this.state.questions]

        newQuestions[index] = value

        this.setState({
            questions: newQuestions
        })

    }

    deleteQuestion = (index) => {

        let newQuestions = [...this.state.questions]

        delete (newQuestions[index])

        newQuestions = newQuestions.filter(function () {
            return true;
        });

        this.setState({
            questions: newQuestions
        })

    }


    render() {

        return (
            <div className="ts-edit-container">
                <HeaderWhite goBack={this.goBack}/>

                {
                    this.state.loading ?
                        <Loader/>
                        :
                        <div className="ts-edit-contents">
                            <h4 id="title"> Setari testimoniale </h4>

                            <div className="input-zone">
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <div style={{textAlign: 'left'}}>
                                        <label>Durata maximă inregistrare</label>
                                        <Dropdown title={this.state.duration + ' secunde'}
                                                  activeKey={this.state.duration}
                                                  className="dropdown_scroll"
                                                  style={{marginBottom: "20px", borderRadius: "5px", width: "100%"}}>

                                            <Dropdown.Item
                                                key={0}
                                                eventKey={10}
                                                onClick={() => this.setState({duration: 10})}>10</Dropdown.Item>

                                            <Dropdown.Item
                                                key={1}
                                                eventKey={15}
                                                onClick={() => this.setState({duration: 15})}>15</Dropdown.Item>

                                            <Dropdown.Item
                                                key={2}
                                                eventKey={20}
                                                onClick={() => this.setState({duration: 20})}>20</Dropdown.Item>

                                            <Dropdown.Item
                                                key={3}
                                                eventKey={25}
                                                onClick={() => this.setState({duration: 25})}>25</Dropdown.Item>

                                            <Dropdown.Item
                                                key={4}
                                                eventKey={30}
                                                onClick={() => this.setState({duration: 30})}>30</Dropdown.Item>

                                        </Dropdown>
                                    </div>
                                </div>
                                {/*
                                <p>Imagine de fundal</p>
                                {this.state.backgroundImageLink !== null
                                    ?
                                    <img style={{padding: "5px", width: "300px", height: "300px", alignSelf: "center"}}
                                         src={this.state.backgroundImageLink} alt="" crossOrigin={"true"}/>
                                    : <div>Nicio imagine adaugata</div>
                                }


                                <div style={customInputStyle}>
                                    <Uploader onChange={this.handlePoza} accept=".jpg" style={{borderRadius: "5px"}}
                                              autoUpload={false} multiple={false}
                                              disabled={this.state.backgroundImage !== null}>
                                        <div style={{color: "#221d5d", width: "100%", height: "100%"}} id="text">Click
                                            aici
                                            pentru a incarca alta fotografie de fundal
                                        </div>
                                    </Uploader>
                                </div>

                                <div className={"questions-cont"}>
                                    <p>Intrebari</p>

                                    <div className='add-question' onClick={() => this.addQuestion()}>
                                        <Icon icon="plus-circle" size="lg"
                                              style={{color: "#05AFF2", marginRight: "10px"}}/>
                                        <div>Adauga intrebare</div>
                                    </div>

                                    {
                                        this.state.questions && this.state.questions.map((question, i) => (
                                            <div className={"question-cont"} key={"q_" + i}>
                                                <div className={"title"}>
                                                    <CustomInput value={this.state.questions[i]}
                                                                 onChange={(value) => this.editQuestion(i, value)}
                                                                 style={customInputStyle}
                                                                 placeholder={"Intrebarea nr. " + (i + 1)}
                                                                 icon={<Icon icon="pencil"/>}/>
                                                </div>

                                                <div className={"delete"} onClick={() => this.deleteQuestion(i)}>
                                                    <div className={"button"}>Sterge</div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </div>
*/}

                                <div className="Footer">
                                    <Button onClick={this.goBack} className="back-button" style={{color: "#1E1C59"}}>
                                        Înapoi
                                    </Button>

                                    <Button onClick={this.saveEvent} className="add-button"
                                            style={{backgroundColor: "#00afcc", color: "white"}}>
                                        Salveaza
                                    </Button>
                                </div>
                            </div>
                        </div>
                }
                <NavbarColor history={this.props.history}/>
            </div>
        )
    }
}

export default TestimonialsConfig;