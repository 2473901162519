import React from 'react'
import Modal from 'react-responsive-modal'
import { Divider, Button, Tooltip } from 'rsuite'
import './style.scss'
import CustomInput from '../../../components/custom/CustomInput'
import { toast } from 'react-toastify'

class nasiModal extends React.Component {
	tooltip = (label) => {
		return (
			<Tooltip>
				{label}
			</Tooltip>
		);
	}

	completeAdd = () => {
		if (this.props.numeNas === "" || this.props.numeNasa === "" || this.props.numeFamilie === "") {
			toast.error("Va rog completati toate campurile")
			return
		}

		this.props.addNasi();
		this.props.toggleModal();
	}

	render() {
		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return (
			<Modal classNames={{
				overlay: 'modal-overlay',
				modal: 'nasi-modal',
				closeButton: 'modal-close-button',
			}} open={this.props.showModal} onClose={this.props.toggleModal} center>
				<h5> Adauga Nume Nasi </h5>
				<Divider />

				<div className="modal-content">
					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "numeNasa")} value={this.props.numeNasa} placeholder="Prenume Nasa" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "numeNas")} value={this.props.numeNas} placeholder="Prenume Nas" />

					<CustomInput style={customInputStyle} onChange={value => this.props.handleChange(value, "numeFamilie")} value={this.props.numeFamilie} placeholder="Nume Nasi" />

					<Button onClick={this.completeAdd} size="lg" block appearance="primary" > Adauga </Button>
				</div>
			</Modal>
		)
	}
}

export default nasiModal;