import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import './GiftTab.scss'
import { getUrlFromImage } from '../../../components/Helper'
import { Button } from 'rsuite'

class GiftTab extends React.Component {
	state = {
		gifts: [],
		userID: "",
		userData: {},

		loggedIn: false,
		isParticipating: true,
		loading: true,
	}

	componentDidMount = async () => {
		let userId = localStorage.getItem("id")
		if (userId === undefined) {
			return
		}

		this.setState({ loggedIn: true, userID: userId })

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.event_id, auth)
			.then((response) => {
				let userData = response.data.responses.filter(elem => elem.user === userId && elem.event === this.props.event_id)

				if (userData.length === 0 || userData[0].response.answer !== "yes") {
					return
				}
				this.setState({
					isParticipating: true,
					userData: userData[0].response
				})
			})
			.catch(err => {
				toast.error("Eroare")
			})

		await axios.get(process.env.REACT_APP_DOMAIN + 'gifts/list/byEvent/' + this.props.event_id, auth)
			.then(response => {
				this.setState({
					gifts: response.data,
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
			})
	}

	// setBoughtGift = async (index) => {
	// 	let obj = {
	// 		boughtBy: this.state.userData.prenume + " " + this.state.userData.nume,
	// 		idBoughtBy: this.state.userID,
	// 	}

	// 	await axios.get('https://server.im a flux.com:5006/api/gifts/clientSetBoughtBy/' + this.props.event_id, obj)
	// 		.then(response => {
	// 			if (response.data === false) {
	// 				toast.info("Cadou ales de alt invitat, va rog reintrati in tab pentru a actualiza")
	// 				return
	// 			}

	// 			toast.success("Cadou asignat")

	// 			var newGifts = [...this.state.gifts]
	// 			newGifts[index].boughtBy = obj.boughtBy
	// 			newGifts[index].idBoughtBy = obj.idBoughtBy

	// 			this.setState({
	// 				gifts: newGifts
	// 			})
	// 		})
	// 		.catch(err => {
	// 			console.log(err);
	// 		})
	// }

	// cancelBuyingGift = async (index) => {
	// 	if (this.state.gifts[index].idBoughtBy !== localStorage.getItem("id")) {
	// 		toast.error("Nu puteti sterge asignarea cadoului pentru alt participant")
	// 	}

	// 	await axios.get('https://server.im a flux.com:5006/api/gifts/deleteBoughtBy/' + this.props.event_id)
	// 		.then(response => {
	// 			toast.success("Cadou neasignat")

	// 			var newGifts = [...this.state.gifts]
	// 			newGifts[index].boughtBy = ""
	// 			newGifts[index].idBoughtBy = ""

	// 			this.setState({
	// 				gifts: newGifts
	// 			})
	// 		})
	// 		.catch(err => {
	// 			console.log(err);
	// 		})
	// }

	openGiftLink = (link) => {
		if (link.startsWith("http")) {
			window.open(link)
		} else {
			let newLink = "https://" + link

			window.open(newLink)
		}
	}

	render() {
		if (!this.state.loggedIn) {
			return <div className="GiftTab-Container">
				<p>Va rog sa intrati in cont pentru a accesa acest tab</p>
			</div>
		}

		if (!this.state.isParticipating) {
			return <div className="GiftTab-Container">
				<p>Pentru a vedea aceste date trebuie sa participati la eveniment</p>
			</div>
		}

		if (this.state.loading) {
			return <div className="GiftTab-Container">
				<p>Loading...</p>
			</div>
		}

		return <div className="GiftTab-Container">
			<div className="GiftTab-Content">
				{this.state.gifts.map((gift, index) => {
					return <div key={index} className="GiftTab-Card">
						{gift.picture !== null && <div className="Picture" style={{
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundImage: `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(gift.picture)})`
						}} onClick={() => this.goToEditGift(index)}></div>}

						<div className="Title font-family-poppins-700-bold">
							{gift.title}
						</div>

						<div className={"Description"}>
							{gift.description}
						</div>

						{gift.link !== "" && <div className="Link" onClick={() => this.openGiftLink(gift.link)}>
							Link
						</div>}

						{gift.boughtBy !== "" && gift.boughtBy !== null
							? <div className='Options'>
								{gift.idBoughtBy === this.state.userID
									? <div>
										<div>Va fi cumparat de dumneavoastra.</div>

										<Button appearance="ghost" color="red" style={{ margin: "10px 0px", borderRadius: "20px" }} onClick={() => this.cancelBuyingGift(index)}>
											Anulati cumpararea cadoului?
										</Button>
									</div>
									: <p>Va fi cumparat de: {gift.boughtBy}</p>
								}
							</div>
							: <div className='Options'>
								<div>Nu este ales de niciun participant.</div>

								{/* <Button appearance="ghost" color="green" style={{ margin: "10px 0px", borderRadius: "20px" }} onClick={() => this.setBoughtGift(index)}>
									Il voi cumpara eu
								</Button> */}
							</div>
						}
					</div>
				})}
			</div>
		</div>
	}
}

export default GiftTab