import React from 'react'
import './SettingCard.scss'
import { Icon } from 'rsuite'

let SettingCard = (props) => {
	if (props.isSmall) {
		return (
			<div onClick={props.onClick} className="SettingCard-container-small">
				<div className="SettingCard-contents">
					<div className="left">
						{props.icon !== null && <Icon icon={props.icon} size="3x" />}
					</div>

					<div className="middle">
						<h5> {props.name} </h5>
					</div>

					<div className="right">
						<Icon icon="angle-right" size="3x" />
					</div>
				</div>
			</div>
		)
	}

	return (
		<div onClick={props.onClick} className="SettingCard-container">
			<div className="SettingCard-contents">
				<div className="left">
					{props.icon !== undefined && <Icon icon={props.icon} size="3x" />}
					{props.svg !== undefined && <img src={props.svg} alt="Participanti" style={{ height: "40px" }} />}
				</div>

				<div className="middle">
					<h5> {props.name} </h5>
				</div>

				<div className="right">
					<Icon icon={props.dropDown ? "angle-down" : "angle-right"} size="3x" />

					{props.hasRedDot && <div className='redBlinkingText'>Nou</div>}
				</div>
			</div>
		</div>
	)
}

export default SettingCard;