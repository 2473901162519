import React from 'react'
import { Button } from 'rsuite'
import axios from 'axios'
import './TicketsPage.scss'

import QRCode from 'qrcode.react'
import Loader from "../../components/Loader";
import {getMobileOperatingSystem} from "../../functions";
import {ConfirmButton} from "../../components/Helper";
import ShareComp from "../../components/ShareComp/ShareComp";
import {FormattedMessage} from "react-intl";

class ShowTickets extends React.Component {
	state = {
		ticketsBought: [],
		ticketIndex: 0,

		loading: true,
		downloadLoading: false,
	}

	os = getMobileOperatingSystem()

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		var obj = {
			id_user: this.props.user_data.id,
			id_event: this.props.event_id
		}

		var ans = await axios.post(process.env.REACT_APP_DOMAIN + "getTicketsPaymentStatus", obj, auth)

		//Hide some tickets
		let filteredTickets = []
		let ticketsToRemove = [
			'Servicii entertainment Gala UMF 2023',
			'Tranșa 1 - Bilet Ceremonie UMFCD Bucuresti 2023',
			'Servicii non-alimentare Gala UMF 2023'
		]
		for(let i=0; i<ans.data.length; i++) {
			if(ticketsToRemove.includes(ans.data[i].title)){

			} else {
				filteredTickets.push(ans.data[i])
			}
		}

		this.setState({
			ticketsBought: filteredTickets, //.filter(elem => elem.used === false)
			loading: false
		}, () => {
			if(this.state.ticketsBought.length > 0) {
				if (window.alityAppView) {
					window.ReactNativeWebView.postMessage(JSON.stringify({
						action: 'askForReview'
					}))
				}
			}
		})
	}

	prevTicket = () => {
		var currentTicketIndex = this.state.ticketIndex

		this.setState({
			ticketIndex: currentTicketIndex - 1
		})
	}

	nextTicket = () => {
		var currentTicketIndex = this.state.ticketIndex

		this.setState({
			ticketIndex: currentTicketIndex + 1
		})
	}

	getDayAndHourFromData = (date) => {
		var parts = date.split("T")
		var data = parts[0].split("-")
		var hour = parts[1].split(":")

		return (Number(hour[0]) + 3) % 24 + ":" + hour[1] + "  " + data[2] + "-" + data[1] + "-" + data[0]
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	downloadImage = async (ticket) => {

		if(!this.state.downloadLoading) {
			this.setState({downloadLoading:true})
		} else {
			return
		}

		fetch(process.env.REACT_APP_DOMAIN + 'tickets/getTicketImage/' + ticket.id_code + '/0', {
			method: 'GET',
			headers: {
				'Content-Type': 'image/jpg',
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'bilet-' + ticket.id + '.jpg',
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);

				this.setState({downloadLoading:false})
			});
	}

	downloadImageWebview  = async (ticket) => {
			fetch(process.env.REACT_APP_DOMAIN + 'tickets/getTicketImage/' + ticket.id_code + '/1', {
				method: 'GET',
			})
				.then((response) => response.text())
				.then((res) => {
					window.ReactNativeWebView.postMessage(JSON.stringify({
						action: 'downloadMedia',
						url: res,
					}))
					//toast.success('Biletul a fost salvat în galeria telefonului!')
				})
	}

	render() {
		if (this.state.loading) {
			return <Loader/>
		}

		let shownTicket = this.state.ticketsBought[this.state.ticketIndex]

		if(this.state.ticketsBought.length === 0) {
			return (
				<div className="Tickets-content">

				</div>
			)
		}

			return (
			<div className="Tickets-content" style={{marginBottom:"20px"}}>
				<h4 style={{ marginBottom: "30px", color: "#05AFF2", fontWeight: "bold" }}><FormattedMessage id={"event_page.tickets.show.title"} /> ({this.state.ticketsBought.length})</h4>

				{/*
				<div className="section">
					<div onClick={() => this.props.setTab("buy")}><Icon icon="ticket" style={{ color: "#05AFF2", marginRight: "10px" }} />Inapoi la cumparare bilete</div>
				</div>

				<div style={{ margin: "30px 0px" }}>
					<p>Dacă ați achiziționat deja biletul si nu este afisat codul QR, vă rugăm apăsați pe butonul de mai jos.</p>
					<Button onClick={this.props.showModal} color="blue" style={{ alignSelf: 'center', marginTop: 10, marginBottom: 10 }}>Verificare comanda</Button>
				</div>
				*/}

				<div className="ticket">
					<h4>{shownTicket.title}</h4>

					<div>{shownTicket.event.title}</div>

					<p><FormattedMessage id={"event_page.tickets.show.ticket_owner"} />: {this.props.user_data.first_name} {this.props.user_data.last_name}</p>

					<div><FormattedMessage id={"event_page.tickets.show.ticket_number"} />: {shownTicket.id}</div>

					{shownTicket.scanned_at !== null && <p><FormattedMessage id={"event_page.tickets.show.scanned_at"} />: {this.getDayAndHourFromData(shownTicket.scanned_at)}</p>}

					{shownTicket.scanned_by !== null && <div><FormattedMessage id={"event_page.tickets.show.scanned_by"} />: {shownTicket.scanned_by.first_name} {shownTicket.scanned_by.last_name}</div>}

					<div>
						<QRCode value={"https://app.ality.ro/checkTicket/000000000000" + shownTicket.id_code} />
					</div>

					<div className="ticket-state"><FormattedMessage id={"event_page.tickets.show.status"} />:
						{shownTicket.used
							? <p style={{ color: "red" }}> <FormattedMessage id={"event_page.tickets.show.status.scanned"} /></p>
							: <p style={{ color: "green" }}> <FormattedMessage id={"event_page.tickets.show.status.valid"} /></p>
						}
					</div>

					{
						window.alityAppView ?
							<div style={{width:'100%'}}>
								<ConfirmButton
									text={<FormattedMessage id={"event_page.tickets.show.save_in_gallery"} />}
									function={() => this.downloadImageWebview(shownTicket)}
								/>
							</div>
							:
							<div style={{width:'100%'}}>
								<ConfirmButton
									text={<FormattedMessage id={"event_page.tickets.show.download_ticket"} />}
									function={() => this.downloadImage(shownTicket)}
								/>
							</div>

					}

					{
						this.os === 'iOS' ?
							<div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateAppleWalletPass/' + shownTicket.id_code)}>
								<img src={'/images/icons/apple_wallet.svg'} style={{width:'140px'}} alt={""} />
							</div>
							:null
					}

					{
						this.os === 'Android'?
							<div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateGoogleWalletPass/' + shownTicket.id_code)}>
								<img src={'/images/icons/google_wallet.svg'} style={{height:'30px'}} alt={""} />
							</div>
							:null
					}

					<ShareComp
						url={process.env.REACT_APP_PAGE + 'downloadTicket/' + shownTicket.id_code}
						message={''}
					/>
				</div>

				{this.state.ticketsBought.length > 1 &&
				<div className="button">
					<Button color="red" disabled={this.state.ticketIndex === 0 ? true : false}
							onClick={this.prevTicket}><FormattedMessage id={"event_page.tickets.show.previous"} /></Button>

					<Button color="green"
							disabled={this.state.ticketIndex === this.state.ticketsBought.length - 1 ? true : false}
							onClick={this.nextTicket}><FormattedMessage id={"event_page.tickets.show.next"} /></Button>
				</div>
				}
			</div>
		)
	}
}

export default ShowTickets;
