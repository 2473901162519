import React from 'react'
import './AddInvite.scss'
import HeaderWhite from '../../components/HeaderWhite'
import { Uploader, Icon, Dropdown, Input, Button } from 'rsuite'
import Modal from "react-responsive-modal"
import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import { toast } from 'react-toastify'
import ParintiComponent from './AddInvite/ParintiComponent'
import NasiComponent from './AddInvite/NasiComponent'
import LoadingModal from './LoadingModal/LoadingModal'
import { CirclePicker } from 'react-color'
import CustomInput from '../components/custom/CustomInput'
import {
	BackAndConfirmButtons,
	ConfirmButton,
	getBase64FromImage,
	SelectInviteType,
	textarea_max_length,
	EditPicture,
	resizeBlob
} from '../components/Helper'
import CustomQuestions from './CustomQuestions/CustomQuestions'

import MicRecorder from 'mic-recorder-to-mp3';
import Loader from "../components/Loader";
import InviteEditComp from "./Invite/InviteEditComp";
import {injectIntl} from 'react-intl';
import AddAudio from "./EditInvite/components/AddAudio";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class AddInvite extends React.Component {
	state = {
		inviteType: "",

		mamaMireasa: "",
		tataMireasa: "",
		numeParintiMireasa: "",
		numeParintiMire: "",
		mamaMire: "",
		tataMire: "",

		numeNas: "",
		numeNasa: "",
		numeFamilie: "",

		timerStarted: false,
		timer: 0,

		nasi: [],

		description: "",
		fontSize: "20px",
		fontColor: "#000",
		fontFamily: "Open Sans",
		fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],
		linkVideo: "",
		poza_invite: null,
		tutorialModal: false,

		isChangingColor: false,

		loading: false,
		loadingData: true,

		initialWeddingData: {},
		eventType: "",
		eventId: "",

		showModalChangeType: false,

		isUsingAudio: false,
		isRecording: false,
		blobURL: '',
		audioBlobData: null,
		isBlocked: false,

		base64ImageLink: "",

		questions: [],
		isUsingNotifications: false,
		isUsingCustomQuestions: false,
		questionTypes: [{ name: "Raspuns liber", type: 0 }, { name: "Grila", type: 1 }],
		canModifyQuestions: true,

		useNewInvite: true,
		templateDetails: {}
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let weddingData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

		this.setState({
			initialWeddingData: weddingData.data,
			eventType: weddingData.data.eventType,
			eventId: weddingData.data.id,
			questions: weddingData.data.questions !== null ? weddingData.data.questions : [],
			isUsingNotifications: weddingData.data.notifications,
			isUsingCustomQuestions: weddingData.data.useCustomQuestions,
			tutorialModal: !weddingData.data.tutorials || (weddingData.data.tutorials && !weddingData.data.tutorials.invite)
		})

		//check if invitations have been sent (can't change settings here in this case)
		await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
			.then(response => {
				if (response.data.responses.length > 0) {
					this.setState({
						canModifyQuestions: false
					})
				}
			})

		this.setState({
			loadingData: false
		})
	}

	start = () => {
		if (this.state.isBlocked) {
			toast.info("Microfonul este blocat, acordati permisiune pentru a-l folosii.")
		} else {
			Mp3Recorder
				.start()
				.then(() => {
					this.setState({ blobURL: "", isRecording: true, audioBlobData: null, timer:0, timerStarted: true });
				}).catch((e) => { console.error(e); toast.warn("Microfonul nu poate fi detectat") });
		}
	};

	stop = () => {
		Mp3Recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				// console.log(buffer);
				// console.log(blob);
				const blobURL = URL.createObjectURL(blob)
				// console.log(blobURL);
				this.setState({ blobURL, isRecording: false, audioBlobData: blob, timer:0, timerStarted: false });
			}).catch((e) => console.log(e));
	};

	setInviteType = (type) => {
		this.setState({
			inviteType: type
		})
	}

	removeNasi = (id) => {
		let current = [...this.state.nasi];

		for (var i = 0; i < current.length; i++) {
			if (current[i].id === id) {
				current.splice(i, 1);
			}
		}

		this.setState({
			nasi: current
		})
	}

	makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	addNasi = () => {
		let { numeNasa, numeNas, numeFamilie } = this.state;
		let current = this.state.nasi;

		current.push({
			id: this.makeid(3),
			numeNasa,
			numeNas,
			numeFamilie
		})

		this.setState({
			numeNas: "",
			numeNasa: "",
			numeFamilie: "",
			nasi: current
		})
	}

	handleChange = (value, key) => {
		this.setState({
			[key]: value
		})
	}

	changeUseQuestionsState = async (state) => {
		if (state === false && this.state.canModifyQuestions === false) {
			toast.error("Aveti raspunsuri, modificarile nu mai sunt permise")
			return
		}

		this.setState({
			isUsingCustomQuestions: state
		})
	}

	changeQuestions = (newQuestions) => {
		this.setState({
			questions: newQuestions
		})
	}

	changeInviteDescription = (value) => {
		if (this.state.description.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
			return
		}

		this.setState({
			description: value
		})
	}

	saveNotificationStatus = () => {
		let obj = {
			notifications: this.state.isUsingNotifications
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(_ => {
			})
			.catch(err => {
				console.log(err);
			})
	}

	saveCustomQuestions = () => {
		if (!this.state.canModifyQuestions) {
			toast.error("S-au primit raspunsuri la invitatie, modificarile la intrebari nu vor fi salvate")
			return
		}

		let obj = {
			questions: this.state.questions,
			useCustomQuestions: this.state.isUsingCustomQuestions
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj, auth)
			.then(_ => {
			})
			.catch(err => {
				console.log(err);
			})
	}

	saveInviteData = async () => {
		let obj = {
			user: localStorage.getItem('id'),
			event: this.state.eventId,
			inviteType: this.state.inviteType,
			parinti: [{ mamaMire: this.state.mamaMire }, { tataMire: this.state.tataMire }, { mamaMireasa: this.state.mamaMireasa }, { tataMireasa: this.state.tataMireasa }, { numeParintiMire: this.state.numeParintiMire }, { numeParintiMireasa: this.state.numeParintiMireasa }],
			nasi: this.state.nasi,
			description: this.state.description,
			locatii: this.state.locations,
			linkVideo: this.state.linkVideo,
			fontColor: this.state.fontColor,
			fontSize: this.state.fontSize,
			fontFamily: this.state.fontFamily,
			audioType: this.state.isUsingAudio ? "useAudio" : "none",
			imageLink: this.state.base64ImageLink,
			templateDetails: this.state.templateDetails,
			isUsingOGImage: false
		}

		if (window.flutter_inappwebview) {
			let auth = {
				headers: {
					// "Content-Type": `multipart/form-data;`,
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			await axios.post(process.env.REACT_APP_DOMAIN + 'invites/create', obj, auth)//formData, auth)
				.then(async response => {
					toast.success("Invitatie creata cu succes!")

					let obj2 = {
						invitatieInfo: true
					}

					let auth2 = {
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						}
					}

					await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj2, auth2)

					this.props.history.push('/organizer/info/' + this.props.match.params.id)
				})
				.catch(err => {
					toast.error("Eroare")
				})
		} else {
			let formData = new FormData()
			formData.append("data", JSON.stringify(obj))

			if (this.state.poza_invite !== null) {
				formData.append("files.image", this.state.poza_invite)
			}

			if (this.state.isUsingAudio && this.state.audioBlobData !== null) {
				formData.append("files.audio", this.state.audioBlobData)
			}

			let auth = {
				headers: {
					"Content-Type": `multipart/form-data;`,
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			await axios.post(process.env.REACT_APP_DOMAIN + 'invites', formData, auth)//formData, auth)
				.then(async response => {
					toast.success("Invitatie creata cu succes!")

					let obj2 = {
						invitatieInfo: true
					}

					let auth2 = {
						headers: {
							'Authorization': 'Bearer ' + localStorage.getItem('jwt')
						}
					}

					await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, obj2, auth2)

					this.props.history.push('/organizer/info/' + this.props.match.params.id)
				})
				.catch(err => {
					toast.error("Eroare")
				})
		}


	}

	createInvite = async () => {
		if (this.state.isUsingCustomQuestions) {
			let questions = this.state.questions
			//check questions for validity
			for (let i = 0; i < questions.length; i++) {
				if (questions[i].title === "") {
					toast.error(`Va rog completati toate titlurile. Tilu intrebare ${i + 1}`)
					return
				}

				if (questions[i].type === 1) {
					if (questions[i].options.length === 0) {
						toast.error(`Ati ales intrebare grila dar nu ati oferit optiuni. Intrebare ${i + 1}`)
						return
					}

					for (let j = 0; j < questions[i].options.length; j++) {
						if (questions[i].options[j] === "") {
							toast.error(`Va rog completati toate optiunile unde ati ales grila. Intrebare ${i + 1}, grila ${j + 1}`)
							return
						}
					}
				}
			}
		}

		this.setState({
			loading: true
		})

		this.saveNotificationStatus()

		this.saveCustomQuestions()

		this.saveInviteData()

		this.setState({
			loading: false
		})
	}

	handlePoza = async (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				poza_invite: null
			})
			return
		}

		let blob = await resizeBlob(fileList[0].blobFile)

		this.setState({
			poza_invite: blob
		})
	}

	saveCroppedImage = async (croppedImg) => {
		let img = {}
		img.blobFile = croppedImg
		let data = await getBase64FromImage(img)

		this.setState({
			croppedImage: croppedImg,
			base64ImageLink: data
		})
	}

	openModalChangeType = () => {
		this.setState({
			showModalChangeType: true
		})
	}

	closeModalChangeType = () => {
		this.setState({
			showModalChangeType: false
		})
	}

	changeType = () => {
		this.setState({
			inviteType: "",

			mamaMireasa: "",
			tataMireasa: "",
			numeParintiMireasa: "",
			numeParintiMire: "",
			mamaMire: "",
			tataMire: "",

			numeNas: "",
			numeNasa: "",
			numeFamilie: "",

			nasi: [],

			description: "",
			fontSize: "20px",
			fontColor: "#000",
			fontFamily: "Open Sans",
			fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],
			linkVideo: "",
			poza_invite: null,

			isRecording: false,
			blobURL: '',
			isUsingAudio: false,
			audioBlobData: null,

			questions: this.state.initialWeddingData.questions,
			isUsingNotifications: this.state.initialWeddingData.notifications,
			isUsingCustomQuestions: this.state.initialWeddingData.useCustomQuestions,

			showModalChangeType: false
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				invite: true
			},
			auth)

	}

	goBack = () => {
		this.props.history.push("/organizer/info/" + this.props.match.params.id)
	}

	render() {

		if (this.state.loadingData) {
			return <div className="AddInvite-container">
				<HeaderWhite shadow={true} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		let body = <div></div>

		if (this.state.inviteType === "Personal") {
			body = <div>
				<div className="Upload-zone">
					<h3> Adauga fotografie cu invitatia personala </h3>
					{/* <p> Fisiere acceptate: .jpg, recomandam fotografii tip portret</p> */}
					<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }} autoUpload={false} multiple={false} disabled={this.state.poza_invite !== null}>
						<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">Click aici pentru a uploada fotografie</div>
					</Uploader>
				</div>

				{this.state.poza_invite !== null && <EditPicture imageLink={URL.createObjectURL(this.state.poza_invite)} saveCroppedImage={this.saveCroppedImage} />}

				{!window.flutter_inappwebview ? <div className="Section">
					<div className="Title">Doriți să adăugați o melodie sau să înregistrați un mesaj audio pentru invitație?</div>

					<div className="Options-Row">
						<div className={this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: true })} style={{ marginRight: "30px" }}>Da</div>
						<div className={!this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: false })}>Nu</div>
					</div>
				</div>
					:<div className="Section"><div className="Title">Pentru a adauga un mesaj audio va rugam sa folositi un laptop/pc</div></div>}

				{this.state.isUsingAudio &&
					<AddAudio
						initialWeddingData={this.state.initialWeddingData}
						audioType={this.state.audioType} serverAudioUrl={this.state.serverAudioUrl}
						deleteServerAudio={this.state.deleteServerAudio} allowAudioRequest={this.state.allowAudioRequest}
						customAudioLink={this.state.customAudioLink} customAudioSecond={this.state.customAudioSecond}
						songSelected={this.state.songSelected}
						setAudioType={(value) => this.setState({audioType: value})}
						setAudioBlobData={(value) => this.setState({audioBlobData: value})}
						setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
						setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
						setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
						setSongSelected={(value) => this.setState({songSelected: value})}
					/>}
			</div>
		}

		if (this.state.inviteType === "Video") {
			body = <div>
				<CustomInput value={this.state.linkVideo} onChange={value => this.setState({ linkVideo: value })} placeholder={"Link video Invitatie"}
					style={customInputStyle} icon={<Icon icon="text-height" />} />
			</div>
		}

		if (this.state.inviteType === "Custom") {


			if(this.state.useNewInvite) {
				body = <InviteEditComp
					eventType={this.state.eventType}
					weddingData={this.state.initialWeddingData}
					templateDetails={this.state.templateDetails}
					setTemplateDetails={(val) => this.setState({templateDetails: val})}
					changeTypeToCustom={() => {
						this.setState({
							templateDetails: null,
							useNewInvite: false
						})}}
				/>
			} else {
				body = <div>
					{this.state.eventType !== "Petrecere" &&
					<ParintiComponent eventType={this.state.eventType} handleChange={this.handleChange}
									  tataMire={this.state.tataMire} mamaMire={this.state.mamaMire}
									  numeParintiMire={this.state.numeParintiMire}
									  tataMireasa={this.state.tataMireasa} mamaMireasa={this.state.mamaMireasa}
									  numeParintiMireasa={this.state.numeParintiMireasa}/>}

					{this.state.eventType !== "Petrecere" &&
					<NasiComponent nasi={this.state.nasi} handleChange={this.handleChange} addNasi={this.addNasi}
								   removeNasi={this.removeNasi} numeNasa={this.state.numeNasa}
								   numeNas={this.state.numeNas} numeFamilie={this.state.numeFamilie}/>}

					<CustomInput value={this.state.description} onChange={this.changeInviteDescription}
								 componentClass={"textarea"} icon={<Icon icon="pencil"/>}
								 placeholder={`Text Invitatie ${this.state.description.length}/${textarea_max_length}`}
								 style={customInputStyle}/>

					<div style={{fontSize: "18px"}}>Format text</div>
					<div className="Invite-Design">
						<div>
							<p>Dimensiune</p>

							<Input value={this.state.fontSize.split("p")[0]}
								   onChange={value => this.setState({fontSize: value + "px"})}
								   placeholder={"Dimensiune"}
								   style={{backgroundColor: "#E5F7FE", borderRadius: "10px", width: "100px"}}
								   icon={<Icon icon="text-height"/>} type={"number"}/>
						</div>

						<div className="color-container">
							<p>Culoare</p>

							<div className="color-choice">
								<div style={{
									height: "35px",
									width: "35px",
									backgroundColor: this.state.fontColor,
									borderRadius: "50%"
								}} onClick={() => this.setState({isChangingColor: true})}></div>

								{this.state.isChangingColor &&
								<Modal classNames={{
									overlay: 'modal-overlay',
									modal: 'modal-colors',
									closeButton: 'modal-close-button',
								}} center open={this.state.isChangingColor}
									   onClose={() => this.setState({isChangingColor: false})}>
									<div className="color-options">
										<CirclePicker color={this.state.fontColor} onChange={newColor => this.setState({
											fontColor: newColor.hex,
											isChangingColor: false
										})}/>

										<div className="basic-color">
											<div className="basic-color-white" onClick={() => this.setState({
												fontColor: "#fff",
												isChangingColor: false
											})}></div>
											<div className="basic-color-black" onClick={() => this.setState({
												fontColor: "#000",
												isChangingColor: false
											})}></div>
										</div>
									</div>
								</Modal>}
							</div>
						</div>

						<div style={{textAlign: "center"}}>
							<p>Font</p>

							<Dropdown title={this.state.fontFamily} activeKey={this.state.fontFamily}
									  className="dropdown_scroll"
									  style={{backgroundColor: "#E5F7FE", borderRadius: "10px"}}>
								{this.state.fontsOptions.map((option, index) => {
									return <Dropdown.Item key={index} eventKey={option}
														  onClick={() => this.setState({fontFamily: option})}>{option}</Dropdown.Item>
								})}
							</Dropdown>
						</div>
					</div>

					<p style={{
						fontSize: this.state.fontSize,
						fontFamily: this.state.fontFamily,
						color: "" + this.state.fontColor,
						marginBottom: "20px"
					}}>Text Final</p>

					<div className="Upload-zone">
						<h3> Adauga Fotografie de Fundal </h3>
						{/* <p> Fisiere acceptate: .jpg, recomandam fotografii tip portret</p> */}
						<Uploader onChange={this.handlePoza} accept=".jpg" style={{borderRadius: "5px"}}
								  autoUpload={false} multiple={false} disabled={this.state.poza_invite !== null}>
							<div style={{color: "#221d5d", width: "100%", height: "100%"}} id="text">Click aici pentru a
								uploada fotografie de fundal
							</div>
						</Uploader>
					</div>

					{this.state.poza_invite !== null &&
					<EditPicture imageLink={URL.createObjectURL(this.state.poza_invite)}
								 saveCroppedImage={this.saveCroppedImage}/>}

					{!window.flutter_inappwebview ? <div className="Section">
							<div className="Title">Doriti sa inregistrati un mesaj audio?</div>

							<div className="Options-Row">
								<div className={this.state.isUsingAudio ? "active-option" : "inactive-option"}
									 onClick={_ => this.setState({isUsingAudio: true})} style={{marginRight: "30px"}}>Da
								</div>
								<div className={!this.state.isUsingAudio ? "active-option" : "inactive-option"}
									 onClick={_ => this.setState({isUsingAudio: false})}>Nu
								</div>
							</div>
						</div>
						: <div className="Section">
							<div className="Title">Pentru a adauga un mesaj audio va rugam sa folositi un laptop/pc
							</div>
						</div>}

					{this.state.isUsingAudio && <AddAudio
						initialWeddingData={this.state.initialWeddingData}
						audioType={this.state.audioType} serverAudioUrl={this.state.serverAudioUrl}
						deleteServerAudio={this.state.deleteServerAudio} allowAudioRequest={this.state.allowAudioRequest}
						customAudioLink={this.state.customAudioLink} customAudioSecond={this.state.customAudioSecond}
						songSelected={this.state.songSelected}
						setAudioType={(value) => this.setState({audioType: value})}
						setAudioBlobData={(value) => this.setState({audioBlobData: value})}
						setCustomAudioLink={(value) => this.setState({customAudioLink: value})}
						setCustomAudioSecond={(value) => this.setState({customAudioSecond: value})}
						setDeleteServerAudio={(value) => this.setState({deleteServerAudio: value})}
						setSongSelected={(value) => this.setState({songSelected: value})}
					/>}
				</div>
			}
		}

		return (
			<div className="AddInvite-container">
				<HeaderWhite goBack={this.goBack} />

				<LoadingModal loaCustomding={this.state.loading} />

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.showModalChangeType} onClose={this.closeModalChangeType}>
					<div style={{ marginTop: "30px" }}>
						<div>Daca schimbati tipul de invitatie, pierdeti datele introduse in cadrul acesteia</div>

						<ConfirmButton text={"Confirm"} function={this.changeType} />
					</div>
				</Modal>

				<div className="AddInvite-contents">

							<h4>Creaza Invitatie </h4>


					<div className="input-zone">
						{/* {this.state.inviteType === "" && <div>
							<div className="Input_Type_Options">
								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Personal")}>Invitatie personala</div>

								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Video")}>Incarca link</div>

								<div className="Input_Type_Option" onClick={_ => this.setInviteType("Custom")}>Invitatie personalizata</div>
							</div>

							<BackButton text={"Înapoi"} function={this.goBack} />
						</div>}

						{this.state.inviteType !== "" && <div>
							<div onClick={this.openModalChangeType} className="ChangeChoiceButton">
								Alege alt tip de invitatie
							</div>
						</div>} */}

						<SelectInviteType inviteType={this.state.inviteType}
										  setInviteType={this.setInviteType}
										  setTutorialModal={(val) => this.setState({tutorialModal:val})}
										  useNewInvite={this.state.useNewInvite}
							goBack={this.goBack} openModalChangeType={this.openModalChangeType} text={"invitatie"} />

						{body}

						{this.state.inviteType !== "" && <div>
							<div className="Section">
								<div className="Title">Doriti sa primiti notificari cand primiti raspuns?</div>

								<div className="Options-Row">
									<div className={this.state.isUsingNotifications ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingNotifications: true })} style={{ marginRight: "30px" }}>Da</div>
									<div className={!this.state.isUsingNotifications ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingNotifications: false })}>Nu</div>
								</div>
							</div>

							<CustomQuestions eventId={this.state.eventId} questions={this.state.questions} shouldUseQuestions={this.state.isUsingCustomQuestions}
								canModifyQuestions={this.state.canModifyQuestions} changeUseQuestionsState={this.changeUseQuestionsState} changeQuestions={this.changeQuestions} intl={this.props.intl} />

							<BackAndConfirmButtons textBack={"Înapoi"} textConfirm={"Salveaza"} functionBack={this.goBack} functionConfirm={this.createInvite} />
						</div>}
					</div>
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Poți crea invitație personalizată în Ality, importa invitatia deja creata in format JPEG sau PNG ori în format video, prin simpla adaugare a linkului de YouTube. Mai mult, în aceasta sectiune poti adauga întrebări personalizate care vor fi centralizate ulterior și puse la dispoziția ta pentru a te ajuta sa creezi planul meselor și mai apoi așeza invitații în funcție de preferințele lor în sala evenimentului. Tot aici, se pot inregistra mesaje audio personalizate fiecărui invitat ori atașa o melodie invitației trimise. Te-am impresionat până aici?
							<br/><br/>
							P.S. Un aspect foarte important persoanele care au primit invitația și au răspuns au posibilitatea ca după trimiterea răspunsului sa își creeze cont în aplicație.<br/>
							De ce este important ca acestia sa isi faca cont și apoi sa descarce aplicația?<br/>
							Astfel vor fi asignati evenimentului tău și vor fi la curent cu toate detaliile evenimentului (vor vedea locațiile integrate cu Waze și Google Maps, lista de cadouri, la ce masa vor sta, meniul, etc) și vor primi notificări cu privire la data evenimentului.<br/>
							Dacă invitații tăi aleg sa nu-si faca cont după ce au răspuns invitației, nu este nici un fel de problema, au posibilitatea sa o faca ulterior, dar va trebui sa introducă codul evenimentului.<br/>
							<br/>
							Pentru a vizualiza răspunsurile primite intrați în meniul “Informații eveniment” -> “Invitație” -> “Răspunsuri” sau din meniul “Participanți” -> “Răspunsuri invitație”.
							<br/>
							Te ținem la curent la fiecare pas prin e-mailuri și notificări pentru ca evenimentul să iasă așa cum ti-ai dorit! Poți verifica și fereastra ,,Informații utile” din fiecare meniu al aplicației.

						</p>

						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default injectIntl(AddInvite);
