import React, {useEffect, useState} from "react";
import Loader from "../components/Loader";
import {useParams} from "react-router-dom";
import {getMobileOperatingSystem} from "../functions";

export default function DownloadTicket() {

    const os = getMobileOperatingSystem()
    const { code } = useParams();
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        downloadImage()
// eslint-disable-next-line
    },[])

    const downloadImage = async () => {



        fetch(process.env.REACT_APP_DOMAIN + 'tickets/getTicketImage/' + code + '/0', {
            method: 'GET',
            headers: {
                'Content-Type': 'image/jpg',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {


                if(window.flutter_inappwebview || window.alityAppView ) {
                    setLoading(false)
                    return
                }

                if(os === 'iOS' || os === 'Android') {
                    setLoading(false)
                    return
                }

                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'bilet-' + code + '.jpg',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

             setLoading(false)
            });
    }

    return (
        <div style={{textAlign:'center'}}>

            {
                loading ?
                    <div style={{display:'flex', flex:1, height:'100vh', width:'100vw', justifyContent:'center'}}>
                    <Loader/>
                    </div>
                        :
                    <div>
                        <h4>Vizualizare bilet</h4>
                    <img  alt={""} src={process.env.REACT_APP_DOMAIN + 'tickets/getTicketImage/' + code + '/0'} style={{width:'100%', padding:'10px', maxWidth:'500px'}}/>
                    </div>
                        }


        </div>
    )
}
