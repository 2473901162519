import React from 'react'
import { toast } from 'react-toastify'
import HeaderWhite from '../components/HeaderWhite'

import axios from 'axios'

import './ConfirmEmailChangePage.scss'
import {css} from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";

class ConfirmEmailChangePage extends React.Component {
	componentDidMount = () => {
		let obj = {
			token: this.props.match.params.id
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'email-change-request/confirm', obj)
			.then((response) => {
				// console.log(response)
				toast.success("Email schimbat cu succes")
				this.goToLogin()
			})
			.catch(err => {
				// this.displayErrors(err.response.data);
				toast.error("Link-ul de confirmare nu este valid")
				this.goToLogin()
			})
	}

	goToLogin = () => {
		this.props.history.push("/login")
	}

	render() {
		return (
			<div className="ConfirmEmailChangePage-container">
				<HeaderWhite />

				<BeatLoader color={"#00B7AD"} loading={true} css={css} size={30} />

				<p></p>
			</div>
		)
	}
}

export default ConfirmEmailChangePage;