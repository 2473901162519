import React from 'react'
import './TicketsPage.scss'
import {Button} from 'rsuite'
import axios from 'axios'
import NrComanda from '../../assets/NrComanda.jpeg'

import {toast} from 'react-toastify'
import Modal from "react-responsive-modal";
import BuyTicket from './BuyTicket'
import ShowTickets from './ShowTickets'
import AdModal from '../../components/AdModal'
import CustomInput from '../../components/custom/CustomInput'
import {BackAndConfirmButtons, CustomQuestions} from '../../components/Helper'
import {FormattedMessage} from "react-intl";
import {injectIntl} from 'react-intl';
import {sleep} from "../../functions";
let adFieldName = "bilet"

class TicketsPage extends React.Component {
    state = {
        nume: "",
        prenume: "",
        optiuni_eveniment: [],
        optiuni_eveniment_partener: [],
        insotit: false,
        isPaid: false,
        loading: true,
        cod_qr: "",
        checkQR: false,

        orderId: '',

        participantId: '',
        showRecoverModal: false,
        showOrderExemple: false,
        loadingRecover: false,
        recoverError: false,

        ticketQuestionsActivated: false,
        ticketQuestionsPublished: false,
        ticketQuestionsAnswered: false,
        ticketQuestions: [],
        ticketQuestionsAnswers: [],

        tabOption: "buy",
        showQuestionsAnswers: false,

        showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
    }

    onMount = async () => {
        let participant = this.props.participantData

        this.setState({
            nume: participant.nume,
            prenume: participant.prenume,
            optiuni_eveniment: participant.optiuni_eveniment,
            optiuni_eveniment_partener: participant.optiuni_partener,
            insotit: participant.insotit,
            isPaid: participant.is_paid,
            cod_qr: participant.cod_qr ? participant.cod_qr : "",
            participantId: participant.id
        })

        var isPaidNow = {
            data: participant.is_paid
        }

        //daca nu este platit in spate se verifica tranzactia
        if (!participant.is_paid) {
            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            let obj = {}

            //check ticket status
            if (this.props.event_data.id <= 30) {
                obj = {
                    first_name: participant.prenume,
                    last_name: participant.nume,
                    email: participant.email
                }

                isPaidNow = await axios.post(process.env.REACT_APP_DOMAIN + "getPaymentStatus", obj, auth)
            } else {
                obj = {
                    id_user: this.props.user_data.id,
                    id_event: this.props.event_id
                }

                let ans = await axios.post(process.env.REACT_APP_DOMAIN + "getTicketsPaymentStatus", obj, auth)

                if (ans.data.length === 0) {
                    isPaidNow = {
                        data: false
                    }
                } else {
                    isPaidNow = {
                        data: true
                    }
                }
            }
        }

        this.setState({
            loading: false,
            showRecoverModal: false,
            isPaid: isPaidNow.data
        })
    }

    componentDidMount = async () => {

        if(!this.props.participantData) {
            await sleep(2000)
        }

        if(!this.props.participantData) {
            await sleep(1000)
        }

        //check if user answered ticket questions
        if (this.props.participantData && this.props.participantData.questions_answers_tickets !== null) {
            this.setState({
                ticketQuestionsAnswered: true
            })
        }
        //check if questions are needed
        let isActivated = this.props.event_data.activate_tickets_questions
        let isPublished = this.props.event_data.published_tickets_questions

        if (isActivated && isPublished) {
            this.setState({
                ticketQuestionsActivated: true,
                ticketQuestionsPublished: true,
            })

            let questions = []
            let answers = []

            for (let i = 0; i < this.props.event_data.questions_tickets.length; i++) {
                let question = this.props.event_data.questions_tickets[i]

                let elem = {
                    title: question.title,
                    type: question.type,
                    options: []
                }

                if (elem.type === 1)
                    question.options.map(element => {
                        elem.options.push({label: element, value: element})

                        return null
                    })

                questions.push(elem)
                answers.push("")
            }

            if (this.props.participantData.questions_answers_tickets !== null) {
                answers = []

                for (let i = 0; i < this.props.participantData.questions_answers_tickets.length; i++) {
                    answers.push(this.props.participantData.questions_answers_tickets[i])
                }
            }

            this.setState({
                ticketQuestions: questions,
                ticketQuestionsAnswers: answers,
            })
        }

        this.onMount()
    }

    showModal = () => {
        this.setState({
            showRecoverModal: true
        })
    }

    closeModal = () => {
        this.setState({
            showRecoverModal: false,
            showOrderExemple: false
        })
    }

    recoverTicket = async () => {
        this.setState({
            loadingRecover: true,
            recoverError: false
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        var obj = {
            userId: this.props.user_data.id,
            orderId: parseInt(this.state.orderId),
            eventId: this.props.event_id,
        }

        try {
            await axios.post(process.env.REACT_APP_DOMAIN + "recoverTicket", obj, auth)

            this.setState({
                loadingRecover: false,
                loading: true,
            })

            this.onMount();
        } catch (e) {
            this.setState({
                loadingRecover: false,
                recoverError: true
            })
        }
    }

    changeAnswers = (newAnswers) => {
        this.setState({
            ticketQuestionsAnswers: newAnswers
        })
    }

    saveAnswers = () => {
        var answeredAll = true

        this.state.ticketQuestionsAnswers.map(ans => {
            if (ans === "") {
                answeredAll = false
            }

            return null
        })

        if (!answeredAll) {
            toast.error(<FormattedMessage id={"event_page.tickets.error_answer_all_questions"} />)
            return
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {
            questions_answers_tickets: JSON.stringify(this.state.ticketQuestionsAnswers),
        }

        axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
            .then(response => {
                toast.success(<FormattedMessage id={"event_page.tickets.answers_sent"} />)

                this.props.participantData.questions_answers_tickets = this.state.ticketQuestionsAnswers

                this.setState({
                    ticketQuestionsAnswered: true,
                    showQuestionsAnswers: false,
                })
            })
            .catch(err => {
                toast.error(<FormattedMessage id={"general.alerts.error_occurred"} />)
            })
    }

    setTab = (choice) => {
        this.setState({
            tabOption: choice
        })
    }

    closeAdModal = () => {
        this.props.updateSeenAds(adFieldName)

        this.setState({
            showAdModal: false
        })
    }

    showQuestions = () => {
        this.setState({
            showQuestionsAnswers: true
        })
    }

    render() {
        if (this.state.loading === true) {
            return <div className="Tickets-container">
                <p><FormattedMessage id={"general.loading"} /></p>
            </div>
        }

        if (this.state.showAdModal) {
            return <div className="Tickets-container">
                <AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData}/>
            </div>
        }

        if (this.state.showQuestionsAnswers) {
            return (
                <div className="Tickets-container" style={{textAlign: "left"}}>
                    <CustomQuestions questions={this.state.ticketQuestions} answers={this.state.ticketQuestionsAnswers}
                                     changeAnswers={this.changeAnswers} intl={this.props.intl}/>

                    <BackAndConfirmButtons textBack={<FormattedMessage id={"general.back"} />} textConfirm={<FormattedMessage id={"general.save"} />}
                                           functionBack={() => this.setState({showQuestionsAnswers: false})}
                                           functionConfirm={this.saveAnswers}/>
                </div>
            )
        }

        if (this.state.ticketQuestionsActivated && this.state.ticketQuestionsPublished && !this.state.ticketQuestionsAnswered) {
            return (
                <div className="Tickets-container" style={{textAlign: "left"}}>
                    <CustomQuestions questions={this.state.ticketQuestions} answers={this.state.ticketQuestionsAnswers}
                                     changeAnswers={this.changeAnswers} intl={this.props.intl}/>

                    <div style={{alignSelf: "center"}}>
                        <Button style={{margin: "20px 0px"}} color="green" onClick={this.saveAnswers}><FormattedMessage id={"event_page.tickets.send_answer"} /></Button>
                    </div>
                </div>
            )
        }

        return (

            this.props.event_data.questions_requires_approval === true && this.props.participantData.inviteStatus !== 1 ?
                <div className="Tickets-container">

                    {this.props.participantData.inviteStatus === 4 ?
                    <p><FormattedMessage id={"event_page.tickets.approval.text1"} /><br/><br/><FormattedMessage id={"event_page.tickets.approval.text2"} /></p>

                    :null}

                    {this.props.participantData.inviteStatus === 5 ?
                            <p style={{
                                marginTop: '30px',
                                color: 'red',
                                fontSize: '1.1rem',
                                textAlign: 'center'
                            }}>
                                <FormattedMessage id={"event_page.tickets.approval.rejected"} />
                            </p>
                            : null
                    }
                </div>
                :
                <div className="Tickets-container">

                    {this.state.tabOption === "buy" &&
                        <BuyTicket showModal={this.showModal} setTab={this.setTab}
                                   canBuy={this.props.event_data.sell_tickets} nume={this.state.nume}
                                   prenume={this.state.prenume}
                                   id={this.props.user_data.id} event_id={this.props.event_id}
                                   participantData={this.props.participantData} event_data={this.props.event_data}
                                   user_data={this.props.user_data} showQuestions={this.showQuestions}
                        />
                    }




                    <div style={{marginTop: "20px"}}>
                        <ShowTickets showModal={this.showModal} setTab={this.setTab} user_data={this.props.user_data}
                                     event_id={this.props.event_id}
                                     participantData={this.props.participantData} nume={this.state.nume}
                                     prenume={this.state.prenume}/>
                    </div>


                    <Modal
                        classNames={{
                            overlay: 'modal-overlay',
                            modal: 'modal',
                            closeButton: 'modal-close-button',
                        }}
                        center
                        open={this.state.showRecoverModal}
                        onClose={this.closeModal}
                        showCloseIcon={false}
                        closeOnOverlayClick={true}
                        closeOnEsc={false}
                    >
                        {this.state.showOrderExemple
                            ? <div>
                                <img src={NrComanda} style={{width: '100%'}} alt="Exemplu"
                                     onClick={() => this.setState({showOrderExemple: false})}/>

                                <Button onClick={() => this.setState({showOrderExemple: false})}
                                        color="red"><FormattedMessage id={"general.back"} /></Button>
                            </div>
                            : <div>
                                <label>
                                    <FormattedMessage id={"event_page.tickets.recover_order_intro"} /><br/>
                                    <p style={{fontWeight: "20px", color: "blue"}}
                                       onClick={() => this.setState({showOrderExemple: true})}><FormattedMessage id={"event_page.tickets.recover.show_example"} /></p>
                                    <CustomInput style={{margin: "10px 0px"}} value={this.state.orderId}
                                                 onChange={(val) => this.setState({orderId: val})}
                                                 placeholder={<FormattedMessage id={"event_page.tickets.recover.order_number"} />}/>
                                </label>

                                <br/><br/>

                                {
                                    this.state.recoverError
                                        ? <p style={{color: 'red'}}><FormattedMessage id={"event_page.tickets.recover.order_number.invalid"} /></p>
                                        : null
                                }
                                {
                                    this.state.loadingRecover
                                        ? <p><FormattedMessage id={"general.loading"} /></p>
                                        : <BackAndConfirmButtons textBack={<FormattedMessage id={"general.back"} />} textConfirm={<FormattedMessage id={"general.confirm"} />}
                                                                 functionBack={this.closeModal}
                                                                 functionConfirm={this.recoverTicket}/>
                                }
                            </div>
                        }

                    </Modal>
                </div>
        )
    }
}

export default injectIntl(TicketsPage);
