import React from 'react'
import Webcam from "react-webcam";
import { Button, Icon } from 'rsuite'
import './FaceRecog.scss'
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';
import Modal from 'react-responsive-modal'
import axios from 'axios'

class FaceRecog extends React.Component {
	state = {
		webcamRef: React.createRef(),
		imgSrc: null,
		showModal: true,
		showConfirmModal: false,
		showOverlay: null,
		loading: true,
		isPictureSent: false,

		isUsingFaceRecog: this.props.event_data.isUsingFaceRecog,
		inWebView: false,
	}

	componentDidMount = async () => {
		let obj = {
			email: this.props.user_data.email,
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'facerecog/checkRequest', obj)
			.then((response) => {
				//check response
				this.setState({
					loading: false,
					imgSrc: response.data,
					isPictureSent: true,
					showModal: false,
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
			})

		//check if it's web or flutter_webview
		if (window.flutter_inappwebview) {
			this.setState({
				inWebView: true,
			})
		}
	}

	capture = () => {
		if (this.state.inWebView) {
			window.flutter_inappwebview.callHandler('faceRecog', 'recog').then(res => {
				if (res === "NULL") {
					return;
				}

				this.setState({
					imgSrc: `data:image/jpeg;base64,${res}`
				})
			})
		} else {
			const imageSrc = this.state.webcamRef.current.getScreenshot();

			this.setState({
				imgSrc: imageSrc
			})
		}
	}

	deletePic = () => {
		this.setState({
			imgSrc: null,
		})
	}

	savePicture = () => {
		let obj = {
			email: this.props.email,
			tel: "",
			type: "email",
			selfie: this.state.imgSrc,
			weddingId: this.props.event_id
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'facerecog/saveRequest', obj, auth)
			.then((response) => {
				// this.props.history.push('/finish');
				toast.success("Selfie trimis")
				// console.log(response);
			})
			.catch(err => {
				toast.error("Mail-ul acesta este deja inregistrat")
			})

		this.setState({
			showConfirmModal: false,
			isPictureSent: true
		})
	}

	render() {
		// TODO: uncomment and test camera on phone and live
		const videoConstraints = {
			facingMode: "user"
		};

		if (this.state.loading) {
			return (
				<div className="FaceRecog-container">
					<p>Se verifica daca s-a trimis selfie</p>
				</div>
			)
		}

		return (
			(this.state.isUsingFaceRecog //check if face recog option is opted
				? <LoadingOverlay
					active={this.state.showOverlay}
					spinner
					text='Se încarcă'
					styles={{
						overlay: (base) => ({
							...base,
							background: 'rgba(0, 0, 0, 0.5)'
						})
					}}
				>
					<Fade >
						<>
							{(this.state.imgSrc === null)
								? !this.state.inWebView && <div style={{ borderRadius: "20px" }} >
									<Webcam
										audio={false}
										ref={this.state.webcamRef}
										screenshotFormat="image/jpeg"
										mirrored={true}
										videoConstraints={videoConstraints}
									/>
									<div className="overlay-pic"></div>
									<div className="circle-pic"></div>
								</div>
								: <img style={{ borderRadius: "20px", width: "80vw" }} className={this.state.inWebView ? "img-webView" : ""}
									src={this.state.imgSrc} alt=""
								/>}

							{(this.state.imgSrc === null)
								? <Button style={{ margin: "auto", marginTop: "10px", width: "90vw", maxWidth: '500px' }} block size="lg" appearance="primary" onClick={this.capture}>  Fa un selfie </Button>
								: this.state.isPictureSent
									? <p style={{ color: "green" }}>Poza a fost trimisa </p>
									: <div style={{ marginTop: "10px", flexDirection: "column" }}>
										<Button size="lg" appearance="ghost" onClick={this.deletePic}> Repeta Poza </Button>
										<Button size="lg" appearance="primary" onClick={() => this.savePicture()}> Continua </Button>
									</div>
							}

							<Modal classNames={{
								overlay: 'modal-overlay',
								modal: 'modal-code',
								closeButton: 'modal-close-button',
							}} center open={this.state.showConfirmModal} onClose={() => this.setState({ showConfirmModal: false })}>
								<div style={{ marginTop: "10px", display: "flex", height: "500px", flexDirection: "column" }}>
									<p style={{ color: "red" }}>Poza nu poate fi schimbata odata apasat Confirm</p>
									<Button style={{ marginTop: "20vh" }} size="lg" appearance="primary" onClick={this.savePicture}> Confirm </Button>
									<Button style={{ marginTop: "10px" }} size="lg" appearance="ghost" onClick={() => this.setState({ showConfirmModal: false })}> Cancel </Button>
								</div>
							</Modal>

							<Modal classNames={{
								overlay: 'modal-overlay',
								modal: 'modal-code',
								closeButton: 'modal-close-button',
							}} center open={this.state.showModal} onClose={() => this.setState({ showModal: false })}>

								<h6 style={{ marginTop: "15px" }}>Recomandari pentru a garanta ca te identificam in poze:</h6>

								<div style={{ textAlign: "left", fontFamily: "Signika, sans-serif", marginTop: "30px" }}>
									<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Cuprinde cat mai bine fata ta </Icon>
									<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Fara ochelari de soare sau de vedere </Icon>
									<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Fara masca in timp ce faci selfie-ul </Icon>
									<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Fara sapca sau palarie </Icon>
									<Icon style={{ color: "red", marginTop: "10px" }} icon='check-circle' size="lg"> Sa nu vina lumina soarelui din spatele tau </Icon>
								</div>

								<Button style={{ marginTop: "20px" }} size="lg" appearance="primary" onClick={() => this.setState({ showModal: false })}> Continua </Button>
							</Modal>
						</>
					</Fade>
				</LoadingOverlay>
				: <div className="FaceRecog-container">
					<p>Optiunea de recunoastere faciala nu este bifata de organizator</p>
				</div>
			)
		)
	}
}

export default FaceRecog
