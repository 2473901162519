import React from 'react'
import './FaceRecog.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import { toast } from 'react-toastify'
import axios from 'axios'
import { ConfirmButton } from '../../components/Helper'
import Loader from "../../components/Loader";
import {Button, Icon} from "rsuite";
import CustomInput from "../../components/custom/CustomInput";
import Modal from "react-responsive-modal";

class FaceRecog extends React.Component {
	state = {
		eventId: "",

		currentPackage: false,
		statusFaceRecog: false,
		isSendingData: false,
		driveLink: '',
		limit: 0,

		showAfterModal: false,

		loading: true,
	}

	componentDidMount = async () => {
		//get faceRecog status from server
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let eventInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);


		let currentFaceRecogPackage = false
		let totalLimit = 0
			if(eventInfo.data.packages && eventInfo.data.packages.wedding_facerecog) {
				currentFaceRecogPackage = eventInfo.data.packages.wedding_facerecog
				totalLimit = eventInfo.data.packages.wedding_facerecog.details.limit
			}

			if(eventInfo.data.packages && eventInfo.data.packages.wedding_facerecog_extra && eventInfo.data.packages.wedding_facerecog_extra.length > 0) {
				totalLimit = totalLimit + eventInfo.data.packages.wedding_facerecog_extra.reduce((a, b) => +a + +b.details.limit, 0);
			}



		//save faceRecog status
		this.setState({
			eventId: eventInfo.data.id,
			statusFaceRecog: eventInfo.data.isUsingFaceRecog,
			currentPackage: currentFaceRecogPackage,
			limit: totalLimit,
			loading: false
		})
	}

	saveNewStatus = async () => {
		let prevStatus = this.state.statusFaceRecog

		if(prevStatus && this.state.driveLink.length === 0) {
			toast.success('Va rugam sa specificati un link de Google Drive de unde sa preluam fotografiile!')
			return;
		}

		if(prevStatus) {
			this.setState({showAfterModal: true})
		}

		this.setState({
			isSendingData: true
		})

		//save new status on server
		let obj = {
			isUsingFaceRecog: !prevStatus,
			id_eveniment: this.props.match.params.id,
			driveLink: this.state.driveLink
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/toggleWeddingFaceRecognition', obj, auth)
			.then(_ => {
				toast.success(prevStatus ? "Face Recognition Dezactivat" : "Face Recognition Activat")
			})
			.catch(_ => {
				toast.error("A aparut o erorare, va rog reincercati")
				prevStatus = !prevStatus
			})

		this.setState({
			statusFaceRecog: !prevStatus,
			isSendingData: false
		})
	}
	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	goToMoreInfo = () => {
		this.openLink("https://ality.ro/facerecognition/")
	}

	goToPackage = () => {
		this.props.history.push('/organizer/package/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {

		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		return <div className="FaceRecog-container">
			<HeaderWhite goBack={this.goBack} />

			<div className="FaceRecog-contents">
				<div className="title">Face Recognition</div>

				<div className='description'>
					Face Recognition are rolul de a diminua timpul pe care un participant îl așteaptă până sa primeasca pozele de la un anumit eveniment.
					Acesta incarca selfie, si la finalul evenimentului primeste pozele in care apare.
				</div>

				<div className='more-info' onClick={this.goToMoreInfo}>
					Mai multe informații
				</div>


				{
					this.state.loading ?
						<Loader/>
						:
						this.state.currentPackage ?
							<>

								<div style={{ marginBottom: "20px" }}>
									Limita de poze pentru Face recognition: {this.state.limit}
								</div>

								<div style={{ marginBottom: "20px" }}>
									Pentru o limită de poze mai mare, achiziționati pachetul următor.
									<div onClick={this.goToPackage} style={{ color: "blue" }} >Către pachete</div>
								</div>

								<div>Status: <div style={{ fontWeight: "bold" }}>{this.state.statusFaceRecog ? "Activ" : "Inactiv"}</div></div>

								{
									this.state.statusFaceRecog ?
										<CustomInput value={this.state.driveLink}
													 onChange={(value) => this.setState({driveLink: value})}
													 style={customInputStyle} placeholder={"Link poze Google Drive"}
													 icon={<Icon icon="link"/>}/>
										: null
								}
								<ConfirmButton
									style={{width:'100%'}}
									function={this.saveNewStatus}
									text={this.state.statusFaceRecog ? "Trimite pozele către participanți" : "Activează"} loading={this.state.isSendingData} />


							</>
							:
							<div>
								<div style={{margin: "20px"}}>
									Pentru a beneficia de acest serviciu trebuie să achiziționați un pachet
									<div onClick={() => this.props.history.push('/organizer/package/' + this.props.match.params.id)} style={{color: "blue"}}>Cumpără pachet</div>
								</div>
							</div>
				}

			</div>

			<Modal
				classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}}
				center
				focusTrapped={false}
				open={this.state.showAfterModal}
				onClose={() => this.setState({showAfterModal:false})}
			>
				<div style={{marginTop:'30px'}}>Va mulțumim, în 24 de ore pozele vor ajunge la toți participanții care au încărcat selfie.</div>

				<Button
					onClick={() => this.setState({showAfterModal:false})}
					color="green"
					style={{
						backgroundColor: "#05AFF2",
						borderRadius: "20px",
						width: "100%",
						marginTop: "20px"
					}}>Ok</Button>
			</Modal>

			<NavbarColor history={this.props.history} />
		</div>
	}
}

export default FaceRecog;
