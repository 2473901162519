import React from 'react'
import './Feedback.scss'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BackAndConfirmButtons, BackButton, ConfirmButton, CustomQuestions } from '../../components/Helper'
import {injectIntl} from 'react-intl';
class Feedback extends React.Component {
	state = {
		event_data: this.props.event_data,
		areFeedbackQuestionsPublished: this.props.event_data.published_feedback_questions === true,
		feedbackQuestions: [],
		feedbackQuestionsAnswers: [],
		isAnswerSend: this.props.participantData.questions_answers_feedback !== null && this.props.participantData.questions_answers_feedback.length > 0,

		has_change: false,
		is_sending: false,
		showQuestionsAnswers: false
	}

	componentDidMount = () => {
		var questions = []
		var answers = []

		for (let i = 0; i < this.props.event_data.questions_feedback.length; i++) {
			let question = { ...this.props.event_data.questions_feedback[i] }

			let elem = {
				title: question.title,
				type: question.type,
				options: []
			}

			if (elem.type === 1)
				question.options.map(element => {
					elem.options.push({ label: element, value: element })

					return null
				})

			questions.push(elem)
			answers.push("")
		}

		if (this.props.participantData.questions_answers_feedback !== null) {
			answers = []

			for (let i = 0; i < this.props.participantData.questions_answers_feedback.length; i++) {
				answers.push(this.props.participantData.questions_answers_feedback[i])
			}
		}

		this.setState({
			feedbackQuestions: questions,
			feedbackQuestionsAnswers: answers,
		})

		//update if needed
		if (this.props.participantData.last_feedback_state !== "checked") {
			//update local data
			this.props.participantData.last_feedback_state = "checked"

			//update server data
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let obj = {
				last_feedback_state: "checked",
			}

			axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
		}
	}

	changeAnswers = (newAnswers) => {
		this.setState({
			has_change: true,
			feedbackQuestionsAnswers: newAnswers
		})
	}

	saveAnswers = async () => {
		if (this.state.is_sending) {
			return
		}

		this.setState({
			is_sending: true
		})

		this.props.participantData.questions_answers_feedback = []

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			questions_answers_feedback: this.state.feedbackQuestionsAnswers,
		}

		await axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
			.then(response => {
				toast.success("Raspuns trimis")

				this.props.updateFeedbackAnswers(this.state.feedbackQuestionsAnswers)

				this.setState({
					isAnswerSend: true,
					showQuestionsAnswers: false,
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("S-a produs o eroare, va rog reincercati sau reveniti mai tarziu")
			})

		this.setState({
			is_sending: false
		})
	}

	render() {
		// TODO: sa apara salveaza doar daca a modificat ceva
		if (this.state.showQuestionsAnswers) {
			return (
				<div className="Tickets-container" style={{ textAlign: "left" }}>
					<CustomQuestions questions={this.state.feedbackQuestions} answers={this.state.feedbackQuestionsAnswers} changeAnswers={this.changeAnswers} intl={this.props.intl} />

					{
						this.state.has_change
							? <BackAndConfirmButtons loading={this.state.is_sending} textBack={"Inapoi"} textConfirm={"Trimite"} functionBack={() => this.setState({ showQuestionsAnswers: false })} functionConfirm={this.saveAnswers} />
							: <BackButton text={"Inapoi"} function={() => this.setState({ showQuestionsAnswers: false })} />
					}
				</div>
			)
		}

		return (
			<div className="Feedback-container">
				{this.state.areFeedbackQuestionsPublished
					? <div className="Feedback-contents">
						{this.state.isAnswerSend
							? <div>
								<p>Raspunsul a fost trimis</p>

								<ConfirmButton loading={this.state.is_sending} text={"Arata raspunsuri"} style={{ width: "150px" }} function={() => this.setState({ showQuestionsAnswers: true })} />
							</div>
							: <div className="Feedback-questions">
								<CustomQuestions questions={this.state.feedbackQuestions} answers={this.state.feedbackQuestionsAnswers} changeAnswers={this.changeAnswers} intl={this.props.intl}/>

								<ConfirmButton loading={this.state.is_sending} text={"Trimite"} function={this.saveAnswers} />
							</div>
						}</div>
					: <p>Intrebarile de feedback nu sunt publicate</p>
				}
			</div>
		)
	}
}

export default injectIntl(Feedback);
