import React from 'react'
import { Button, Icon } from 'rsuite'
import NasiModal from './Modals/NasiModal'
import './NasiComponent.scss'

class LocationComponent extends React.Component {
	state = {
		nasi: [],
		showModal: false,
	}

	toggleModal = () => {
		let current = this.state.showModal;

		this.setState({
			showModal: !current
		})
	}

	render() {
		let nasi = this.props.nasi.map((element, index) => {
			return <div className="nas-element" key={index}>
				<p id="nume"> {element.numeNasa + " & " + element.numeNas + " " + element.numeFamilie}</p>
				<Icon style={{ color: "red", fontSize: "30px" }} icon="times-circle" onClick={() => this.props.removeNasi(element.id)} />
			</div>
		})

		let buttonStyle = {
			backgroundColor: "#05AFF2",
			color: "white",
			width: "100%",
			height: "40px",
			fontSize: "20px"
		}

		return (
			<div className="NasiComponent-container">
				<div className="NasiComponent-content">
					<NasiModal handleChange={this.props.handleChange} showModal={this.state.showModal} toggleModal={this.toggleModal}
						addNasi={this.props.addNasi} numeNasa={this.props.numeNasa} numeNas={this.props.numeNas} numeFamilie={this.props.numeFamilie} />

					<Button style={buttonStyle} block onClick={this.toggleModal} > Adauga Nume nasi </Button>

					<div className="Display">
						{nasi}
					</div>
				</div>
			</div>
		)
	}
}


export default LocationComponent;