import React from 'react'
import {Icon} from 'rsuite'
import {toast} from 'react-toastify'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'

import './MyTickets.scss'
import Loader from "../../../components/Loader";
import QRCode from "qrcode.react";
import moment from "moment-timezone";
import {getEventNextDate, getMobileOperatingSystem} from "../../../functions";
import {BackAndConfirmButtons, ConfirmButton} from "../../../components/Helper";
import ShareComp from "../../../components/ShareComp/ShareComp";
import {injectIntl} from 'react-intl';
import Modal from "react-responsive-modal";

class MyTickets extends React.Component {
    state = {

        personalEvents: ["Active", "Terminate"],
        filterChoice: "Active",
        tickets: [],
        loading: true,
        downloadLoading: false,

        deleteTicketId: null,
        showDeleteTicketModal: false
    }

    os = getMobileOperatingSystem()

    componentDidMount() {
        if (localStorage.getItem('jwt') === null) {
            toast.error("Trebuie sa va logati pentru a folosi site-ul")
            this.props.history.push('/login?redirectBackTo=mytickets');
            return
        }

        this.getData()

    }

    getData = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.post(process.env.REACT_APP_DOMAIN + 'users/myTickets', {}, auth)
            .then(response => {

                let newTickets = response.data
                newTickets.forEach((event,j) => {
                    newTickets[j].event.data_eveniment = getEventNextDate(newTickets[j].event).toISOString()
                })
                newTickets = newTickets.filter((item,i) => (item.user_deleted !== true) )

                //Hide some tickets
                let filteredTickets = []
                let ticketsToRemove = [
                    'Servicii entertainment Gala UMF 2023',
                    'Tranșa 1 - Bilet Ceremonie UMFCD Bucuresti 2023',
                    'Servicii non-alimentare Gala UMF 2023'
                ]
                for(let i=0; i<newTickets.length; i++) {
                    if(ticketsToRemove.includes(newTickets[i].title)){

                    } else {
                        filteredTickets.push(newTickets[i])
                    }
                }

                this.setState({
                    tickets: filteredTickets,
                    loading: false
                })
            })
            .catch(err => {
                if(err && err.response && err.response.status === 401) {
                    localStorage.clear()
                    toast.error("Informațiile de autentificare au expirat, vă rugăm să vă autentificați!")
                    this.props.history.push('/login');
                } else {
                    toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                }
            })
    }

    downloadImage = async (ticket) => {

        if(!this.state.downloadLoading) {
            this.setState({downloadLoading:true})
        } else {
            return
        }

        fetch(process.env.REACT_APP_DOMAIN + 'tickets/getTicketImage/' + ticket.id_code + '/0', {
            method: 'GET',
            headers: {
                'Content-Type': 'image/jpg',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'bilet-' + ticket.id + '.jpg',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                this.setState({downloadLoading:false})
            });
    }

    downloadImageWebview  = async (ticket) => {
        fetch(process.env.REACT_APP_DOMAIN + 'tickets/getTicketImage/' + ticket.id_code + '/1', {
            method: 'GET',
        })
            .then((response) => response.text())
            .then((res) => {
                window.ReactNativeWebView.postMessage(JSON.stringify({
                    action: 'downloadMedia',
                    url: res,
                }))
                //toast.success('Biletul a fost salvat în galeria telefonului!')
            })
    }

    openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    goBack = () => {
        this.props.history.go(-1)
    }

    getTicketsFiltered = () => {


        if(this.state.filterChoice === 'Active') {
            return this.state.tickets.filter((item, i) =>
                (item.event.data_sfarsit_eveniment &&  item.event.data_sfarsit_eveniment.length > 0 ?
                    moment(item.data_sfarsit_eveniment).endOf('day') >= moment()
                    :
                    moment(item.event.data_eveniment) >= moment(new Date())))
        } else {
            return this.state.tickets.filter((item, i) =>
                (item.event.data_sfarsit_eveniment &&  item.event.data_sfarsit_eveniment.length > 0 ?
                    moment(item.data_sfarsit_eveniment).endOf('day') < moment()
                    :
                    moment(item.event.data_eveniment) < moment(new Date())))
        }


    }

    deleteTicket = async () => {
        this.setState({loading: true,
            deleteTicketId: null,
            showDeleteTicketModal: false
        })

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'tickets/userDeleteTicket', {
            ticket_id: this.state.deleteTicketId
        }, auth)

        this.getData()
    }

    render() {
        if (this.state.loading) {
            return <div className="MyTickets-container font-family-poppins-300">
                <HeaderWhite goBack={this.goBack}/>

                <h4 className="MyTickets-TopText">Biletele mele</h4>

                <div className="MyTickets-contents">
                    <Loader/>
                </div>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        }

        return (
            <div className="MyTickets-container font-family-poppins-300">
                <HeaderWhite goBack={this.goBack}/>

                <h4 className="MyTickets-TopText">Biletele mele</h4>

                <div className="MyTickets-contents">

                    <div className="Events-Filter">
                        {this.state.personalEvents.map((type, index) => {
                            return <div key={index} className={this.state.filterChoice === type ? "Events-Status-Choice" : "Events-Status-Option"}
                                        onClick={() => this.setState({filterChoice: type})}>
                                {/*{type === 'Terminate' ? 'Finalizate' : type} */}
                                {this.props.intl.formatMessage({id: 'events.attending_events.types.' + type})}
                            </div>
                        })}
                    </div>

                    {
                        this.state.tickets.length === 0 ?
                            <p style={{marginTop:'20px', textAlign:'center'}}>
                                Nu ați achiziționat niciun bilet.
                            </p>
                            :null
                    }

                    {
                        this.getTicketsFiltered().map((ticket,i) => (
                            <div key={i} className="ticket" style={(ticket.used ? {borderColor:'red'} : {borderColor:'green'})}>

                                <div style={{width:'100%', textAlign:'right'}}>
                                    <span style={{padding:'5px', marginRight:'5px', marginTop:'2px', color:'#F26157', cursor:'pointer'}} onClick={() => {
                                        this.setState({
                                            deleteTicketId: ticket.id,
                                            showDeleteTicketModal: true
                                        })
                                    }}>
                                    <Icon icon='trash' style={{ fontSize: '17px'}}/></span>
                                </div>

                                <h4>{ticket.title}</h4>
                                <div>{ticket.event.title}</div>

                                {/*
                                {
                                    ticket.event && ticket.event.locations_and_dates && ticket.event.locations_and_dates.ball && ticket.event.locations_and_dates.ball.date ?
                                        <div>{moment(ticket.event.locations_and_dates.ball.date).format('HH:mm DD/MM/YYYY')}</div>
                                        :
                                        <div>{moment(ticket.event.data_eveniment).format('HH:mm DD/MM/YYYY')}</div>
                                }*/}


                                <div>Nr bilet: {ticket.id}</div>

                                {ticket.scanned_at !== null && <p>Scanat la: {moment(ticket.scanned_at).format('HH:mm DD/MM/YYYY')}</p>}
                                <div>
                                    <QRCode size={180} value={"https://app.ality.ro/checkTicket/000000000000" + ticket.id_code} />
                                </div>

                                <div className="ticket-state">Stare bilet:
                                    {ticket.used
                                        ? <p style={{ color: "red" }}> SCANAT</p>
                                        : <p style={{ color: "green" }}> VALID</p>
                                    }
                                </div>

                                {
                                    window.alityAppView ?
                                        <div style={{width:'100%'}}>
                                            <ConfirmButton
                                                text={'Salvează în galeria foto'}
                                                function={() => this.downloadImageWebview(ticket)}
                                                />
                                        </div>
                                        :
                                        <div style={{width:'100%'}}>
                                            <ConfirmButton
                                                text={'Descarcă biletul'}
                                                function={() => this.downloadImage(ticket)}
                                            />
                                        </div>

                                }

                                {
                                    this.os === 'iOS'?
                                        <div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateAppleWalletPass/' + ticket.id_code)}>
                                            <img src={'/images/icons/apple_wallet.svg'} style={{width:'140px'}} alt={""} />
                                        </div>
                                        :null
                                }
                                {
                                    this.os === 'Android'?
                                        <div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateGoogleWalletPass/' + ticket.id_code)}>
                                            <img src={'/images/icons/google_wallet.svg'} style={{height:'30px'}} alt={""} />
                                        </div>
                                        :null
                                }

                                <ShareComp
                                    url={process.env.REACT_APP_PAGE + 'downloadTicket/' + ticket.id_code}
                                    message={''}
                                    />
                            </div>
                        ))
                    }


                </div>

                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button',
                }} center open={this.state.showDeleteTicketModal} onClose={() => this.setState({
                    deleteTicketId: null,
                    showDeleteTicketModal: false
                })} focusTrapped={false}>

                    <br/>
                    <h5>Sigur doriți să ștergeți acest bilet ?</h5>
                    <br/>
                    <p>Operațiunea este ireversibilă.</p>
                    <br/>

                    <BackAndConfirmButtons
                        textBack={'Nu'}
                        functionBack={() => this.setState({
                            deleteTicketId: null,
                            showDeleteTicketModal: false
                        })}
                        textConfirm={'Da'}
                        functionConfirm={() => this.deleteTicket()}
                    />

                </Modal>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        )
    }
}

export default injectIntl(MyTickets);
