import React from 'react'
import { Stage, Layer, Rect, Text, Circle, Group, Star, Wedge, Line } from 'react-konva';
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/core";
import {seatingChartCanvasHeight, seatingChartCanvasWidth} from "../../../components/Helper";

let elementsTypes = {
	roundTable: "roundTable",
	squareTable: "squareTable",
	rectangleTable: "rectangleTable",
	scene: "scene",
	danceRing: "danceRing",
	candyBar: "candyBar",
	entranceAndExit: "intrare/Iesire",
	prezidiuTable: "masaPrezidiu",
	toilet: "toaleta",
	wall: "wall",
	stair: "stair",
	bar: "bar",
	cocktailBar: 'cocktailBar',
	shotBar: 'shotBar',
	smokingPlace: 'smokingPlace',
	terrace: 'terrace',
}

class SeatingChartForExports extends React.Component {
	state = {
		color: 'white',
		isDragging: false,
		id: 0,
		tableCount: 1,
		otherObjectsId: 0,
		loading: true,
		toRender: [],
		positions: [],

		isSeatingPlanDone: true,
		currentUserData: {},
		usersTableNumber: -1,

		tableInfo: { peopleAtTable: [] },
		tableNumber: 0,

		//for option "addTable"
		tableType: "Rotunda",
		tableSize: ""
	}

	setScreenOrientation = () => {
		window.location.reload();
	}

	async componentDidMount() {
		await window.addEventListener('orientationchange', this.setScreenOrientation);

		//get current user response
		let userID = localStorage.getItem("id")

		let invite_responses = this.props.responses_data

		let currentUserData = {}

		for (let i = 0; i < invite_responses.length; i++) {
			if (invite_responses[i].user === Number(userID)) {
				currentUserData = { ...invite_responses[i].response }
				currentUserData.responseIndex = i
				break
			}
		}

		this.setState({
			currentUserData: currentUserData
		})

		//get seating info
		let seating = this.props.seatingChart

		var maxId = 0;

		seating.positions.map((element, index) => {
			if (maxId < element.id) {
				maxId = element.id
			}

			if (element.peopleAtTable !== undefined) {
				for (let i = 0; i < element.peopleAtTable.length; i++) {
					if (element.peopleAtTable[i].responseIndex === currentUserData.responseIndex) {
						this.setState({
							usersTableNumber: index + 1
						})

						break;
					}
				}
			}

			this.addItem(false, element, element.type, index)

			return null;
		})

		this.setState({
			id: maxId + 1,
			loading: false
		})
	}

	addItem = (newPos, info, elemType, key) => {
		let index = this.state.positions.length;

		let my_id = this.state.id
		this.setState({
			id: my_id + 1
		})

		let tableNumber = this.state.tableCount
		var isTable = false

		if (elemType === elementsTypes.roundTable || elemType === elementsTypes.squareTable || elemType === elementsTypes.rectangleTable) {
			isTable = true

			this.setState({
				tableCount: tableNumber + 1
			})
		}

		let obj =
			<Group
				key={key}
				x={(newPos === false) ? info.x : 100}
				y={(newPos === false) ? info.y : 100}
				id={(newPos === false) ? "node-" + info.id : "node-" + my_id}
				onTap={() => isTable ? this.showModal(info, tableNumber) : null}
				onClick={() => isTable ? this.showModal(info, tableNumber) : null}
			>
				{/* Scene */}

				{elemType === elementsTypes.scene && <Rect id={elemType + "-" + this.state.positions.length} x={200}
														   y={200}
														   width={150}
														   height={50}
														   fill={this.state.color}
														   shadowBlur={5}
														   rotation={info.rotate ? -90 : 0}
				/>}

				{elemType === elementsTypes.scene && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={info.rotate ? 220 : 255}
					rotation={info.rotate ? -90 : 0}
					y={((typeof this.state.positions[index] === 'undefined') ? 220 : this.state.positions[index].y) + (info.rotate ? -70 : 0) } text="Scena"

				/>}

				{/* Candy Bar */}

				{elemType === elementsTypes.candyBar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.candyBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={184}
					y={(typeof this.state.positions[index] === 'undefined') ? 190 : this.state.positions[index].y} text="Candy"
				/>}

				{elemType === elementsTypes.candyBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 200 : this.state.positions[index].y} text="Bar"
				/>}

				{/* Entrance & Exit */}

				{elemType === elementsTypes.entranceAndExit && <Rect id={elemType + "-" + this.state.positions.length} x={200}
																	 y={200}
																	 width={80}
																	 height={25}
																	 fill={this.state.color}
																	 shadowBlur={5}
				/>}

				{elemType === elementsTypes.entranceAndExit && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={207}
					y={(typeof this.state.positions[index] === 'undefined') ? 205 : this.state.positions[index].y} text="Intrare/Iesire"
				/>}

				{/* Dance Ring */}

				{elemType === elementsTypes.danceRing && <Rect id={elemType + "-" + this.state.positions.length} x={200}
															   y={200}
															   width={info.danceRingWidth ? info.danceRingWidth : 100}
															   height={info.danceRingHeight ? info.danceRingHeight : 100}
															   fill={this.state.color}
															   shadowBlur={5}
				/>}

				{elemType === elementsTypes.danceRing && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={info.danceRingWidth ? info.danceRingWidth/2 + 200 - 13 : 235}
					y={info.danceRingWidth ?  info.danceRingHeight/2 + 195 :((typeof this.state.positions[index] === 'undefined') ? 250 : this.state.positions[index].y)} text="Ring"
				/>}

				{/* Toilet */}

				{elemType === elementsTypes.toilet && <Wedge
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					radius={50}
					angle={60}
					rotation={-120}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.toilet && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 160 : this.state.positions[index].y} text="WC"
				/>}

				{/* Prezidiu Table */}

				{elemType === elementsTypes.prezidiuTable && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={80}
					height={40}
					fill={this.state.color}
					shadowBlur={5}
					cornerRadius={10}
					rotation={info.rotate ? -90 : 0}
				/>}

				{elemType === elementsTypes.prezidiuTable && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={info.rotate ? 216 : 202}
					rotation={info.rotate ? -90 : 0}
					y={((typeof this.state.positions[index] === 'undefined') ? 213 : this.state.positions[index].y) + (info.rotate ? -15 : 0) } text="Masa Prezidiu"
				/>}

				{/* Wall */}

				{elemType === elementsTypes.wall && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={newPos ? this.state.isWallVertical ? this.state.wallThickness : this.state.wallLength : info.wallWidth}
					height={newPos ? this.state.isWallVertical ? this.state.wallLength : this.state.wallThickness : info.wallHeight}
					fill={this.state.color}
					shadowBlur={5}
					cornerRadius={0}
				/>}

				{/* Stairs */}

				{elemType === elementsTypes.stair && <Line
					id={elemType + "-" + this.state.positions.length}
					points={[0, 0, 10, 0, 10, 10, 20, 10, 20, 20, 30, 20, 30, 30, 40, 30, 40, 40, 0, 40]}
					fill={this.state.color}
					stroke={"grey"}
					strokeWidth={2}
					closed={true}
					x={200}
					y={200}
				/>}

				{/* Bar */}
				{elemType === elementsTypes.bar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}
				{elemType === elementsTypes.bar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 195 : this.state.positions[index].y} text="Bar"
				/>}

				{/* Cocktail Bar */}
				{elemType === elementsTypes.cocktailBar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}
				{elemType === elementsTypes.cocktailBar && <Text
					id={elemType + "-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={178}
					y={(typeof this.state.positions[index] === 'undefined') ? 190 : this.state.positions[index].y} text="Cocktail"
				/>}

				{elemType === elementsTypes.cocktailBar && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 200 : this.state.positions[index].y} text="Bar"
				/>}

				{/* Shot Bar */}
				{elemType === elementsTypes.shotBar && <Star
					id={elemType + "-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}
				{elemType === elementsTypes.shotBar && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={187}
					y={(typeof this.state.positions[index] === 'undefined') ? 190 : this.state.positions[index].y} text="Shot"
				/>}

				{elemType === elementsTypes.shotBar && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 200 : this.state.positions[index].y} text="Bar"
				/>}


				{/* Round Table */}
				{elemType === elementsTypes.roundTable && <Circle
					id={elemType + "-" + this.state.positions.length}
					x={100}
					y={200}
					width={40}
					height={40}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.roundTable && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={97}
					y={(typeof this.state.positions[index] === 'undefined') ? 195 : this.state.positions[index].y} text={tableNumber}
				/>}

				{/* Square Table */}

				{elemType === elementsTypes.squareTable && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={100}
					y={200}
					width={40}
					height={40}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.squareTable && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={116}
					y={(typeof this.state.positions[index] === 'undefined') ? 215 : this.state.positions[index].y} text={tableNumber}
				/>}

				{/* Rectangular Table */}

				{elemType === elementsTypes.rectangleTable && <Rect
					id={elemType + "-" + this.state.positions.length}
					x={100}
					y={200}
					width={50}
					height={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.rectangleTable && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={121}
					y={(typeof this.state.positions[index] === 'undefined') ? 210 : this.state.positions[index].y} text={tableNumber}
				/>}


				{/* Smoking place */}
				{elemType === elementsTypes.smokingPlace && <Rect id={elemType + "-" + this.state.positions.length}
																  x={200}
																  y={200}
																  width={120}
																  height={40}
																  fill={this.state.color}
																  shadowBlur={5}
				/>}
				{elemType === elementsTypes.smokingPlace && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={215}
					y={((typeof this.state.positions[index] === 'undefined') ? 215 : this.state.positions[index].y)} text="Loc pentru fumat"
				/>}

				{/* Terrace */}
				{elemType === elementsTypes.terrace && <Rect id={elemType + "-" + this.state.positions.length}
															 x={200}
															 y={200}
															 width={120}
															 height={40}
															 fill={this.state.color}
															 shadowBlur={5}
				/>}
				{elemType === elementsTypes.terrace && <Text
					id={elemType + "-" + index + "text"}
					fill={"green"}
					x={240}
					y={((typeof this.state.positions[index] === 'undefined') ? 215 : this.state.positions[index].y)} text="Terasă"
				/>}

			</Group>

		let current_objs = [...this.state.toRender, obj];

		this.setState({
			toRender: current_objs
		})
	}

	render() {
		if (this.state.loading) {
			return <p> Loading responses </p>
		}

		if (!this.state.isSeatingPlanDone) {
			return <div>
				<h3> Planul de mese nu a fost incarcat</h3>
			</div>
		}

		const override = css`
        display: block;
        margin: 25px auto;
        border-color: #221d5d;
        `;

		let toRender = (<BeatLoader
			css={override}
			size={15}
			//size={"150px"} this also works
			color={"#00afcc"}
		/>)

		let finalToRender = this.state.toRender.map(element => {
			return element
		})

		if (this.state.loading === false) {
			toRender = (
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100vw", height: "100vh" }}>
					<Stage width={seatingChartCanvasWidth} height={seatingChartCanvasHeight} style={{ border: "1px solid black", margin: "auto", background:'#FFFFFF' }}>
						<Layer>
							{finalToRender}
						</Layer>
					</Stage>
				</div>
			)
		}

		if (this.state.loading === true)
			toRender = (<BeatLoader
				css={override}
				size={15}
				//size={"150px"} this also works
				color={"#00afcc"}
			/>)

		return (
			<div>
				{toRender}
			</div>
		);

	}
}

export default SeatingChartForExports;
