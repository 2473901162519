import React from 'react'
import './SeatingChart.scss'

import { Stage, Layer, Rect, Text, Circle, Group, Star, Wedge, Line } from 'react-konva';
import HeaderWhite from '../../components/HeaderWhite';
import './SeatingChart.scss'
import { toast } from 'react-toastify'
import { BeatLoader } from "react-spinners";
import { css } from "@emotion/core";
import axios from 'axios';

import Modal from 'react-responsive-modal'
import { seatingChartCanvasHeight, seatingChartCanvasWidth } from '../components/Helper';

let loadingWaitTime = 1000;

let elementsTypes = {
	roundTable: "roundTable",
	squareTable: "squareTable",
	rectangleTable: "rectangleTable",
	scene: "scene",
	danceRing: "danceRing",
	candyBar: "candyBar",
	entranceAndExit: "intrare/Iesire",
	prezidiuTable: "masaPrezidiu",
	toilet: "toaleta",
	wall: "wall",
	stair: "stair"
}

class SeatingChart extends React.Component {
	state = {
		color: 'white',
		isDragging: false,
		id: 0,
		tableCount: 1,
		otherObjectsId: 0,
		selected: null,
		loading: true,
		delete: false,
		members: [],
		toRender: [],
		positions: [],

		isSeatingPlanDone: true,
		currentUserData: {},
		usersTableNumber: -1,
		isPublished: false,

		openModal: false,
		tableInfo: { peopleAtTable: [] },
		tableNumber: 0,

		//for option "addTable"
		tableType: "Rotunda",
		tableSize: ""
	}

	setScreenOrientation = () => {
		window.location.reload();
	}

	async componentDidMount() {
		this.timer = setTimeout(
			() => this.setState({ loading: false }),
			loadingWaitTime
		);

		window.addEventListener('orientationchange', this.setScreenOrientation);

		try {
			//get current user respons
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let invite_responses = await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)

			let currentUserData = {
				responseIndex: -1
			}

			let responses = invite_responses.data.responses

			for (let i = 0; i < responses.length; i++) {
				if (responses[i].user === Number(localStorage.getItem("id"))) {
					currentUserData = { ...responses[i].response }
					currentUserData.responseIndex = i
					break
				}
			}

			//get if it's published
			let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

			this.setState({
				isPublished: event_info.data.isSeatingChartDone,
				currentUserData: currentUserData
			})

			//get seating info
			let seating = event_info.data.seatingChart

			var maxId = 0;

			seating.positions.map((element, index) => {
				if (maxId < element.id) {
					maxId = element.id
				}

				if (element.peopleAtTable !== undefined) {
					for (let i = 0; i < element.peopleAtTable.length; i++) {
						if (element.peopleAtTable[i].responseIndex === currentUserData.responseIndex) {
							this.setState({
								usersTableNumber: index + 1
							})

							break;
						}
					}
				}

				this.addItem(false, element, element.type, index)

				return null;
			})

			this.setState({
				id: maxId + 1,
				loading: false
			})
		}
		catch (e) {
			console.log(e);
			toast.warn("Inca nu exista o configuratie activa de mese")

			this.setState({
				loading: false,
				isSeatingPlanDone: false
			})
		}
	}

	addItem = (newPos, info, elemType, key) => {
		let index = this.state.positions.length;

		let my_id = this.state.id
		this.setState({
			id: my_id + 1
		})

		let tableNumber = this.state.tableCount
		var isTable = false

		if (elemType === elementsTypes.roundTable || elemType === elementsTypes.squareTable || elemType === elementsTypes.rectangleTable) {
			isTable = true

			this.setState({
				tableCount: tableNumber + 1
			})
		}

		let obj =
			<Group
				key={key}
				x={(newPos === false) ? info.x : 100}
				y={(newPos === false) ? info.y : 100}
				id={(newPos === false) ? "node-" + info.id : "node-" + my_id}
				onTap={() => isTable ? this.showModal(info, tableNumber) : null}
				onClick={() => isTable ? this.showModal(info, tableNumber) : null}
			>
				{elemType === elementsTypes.scene && <Rect id={"node-" + this.state.positions.length} x={200}
					y={200}
					width={150}
					height={50}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.scene && <Text
					id={"node-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={255}
					y={(typeof this.state.positions[index] === 'undefined') ? 220 : this.state.positions[index].y} text="Scena"
				/>}

				{elemType === elementsTypes.candyBar && <Star
					id={"node-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					numPoints={5}
					innerRadius={15}
					outerRadius={30}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.candyBar && <Text
					id={"node-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={184}
					y={(typeof this.state.positions[index] === 'undefined') ? 190 : this.state.positions[index].y} text="Candy"
				/>}

				{elemType === elementsTypes.candyBar && <Text
					id={"node-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 200 : this.state.positions[index].y} text="Bar"
				/>}

				{elemType === elementsTypes.entranceAndExit && <Rect id={"node-" + this.state.positions.length} x={200}
					y={200}
					width={80}
					height={25}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.entranceAndExit && <Text
					id={"node-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={207}
					y={(typeof this.state.positions[index] === 'undefined') ? 205 : this.state.positions[index].y} text="Intrare/Iesire"
				/>}

				{elemType === elementsTypes.danceRing && <Rect id={"node-" + this.state.positions.length} x={200}
					y={200}
					width={100}
					height={100}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.danceRing && <Text
					id={"node-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={235}
					y={(typeof this.state.positions[index] === 'undefined') ? 250 : this.state.positions[index].y} text="Ring"
				/>}

				{elemType === elementsTypes.toilet && <Wedge
					id={"node-" + this.state.positions.length}
					x={200}
					y={200}
					width={50}
					height={25}
					radius={50}
					angle={60}
					rotation={-120}
					fill={this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.toilet && <Text
					id={"node-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={190}
					y={(typeof this.state.positions[index] === 'undefined') ? 160 : this.state.positions[index].y} text="WC"
				/>}

				{elemType === elementsTypes.prezidiuTable && <Rect
					id={"node-" + this.state.positions.length}
					x={200}
					y={200}
					width={80}
					height={40}
					fill={this.state.color}
					shadowBlur={5}
					cornerRadius={10}
				/>}

				{elemType === elementsTypes.prezidiuTable && <Text
					id={"node-" + index + "text"}
					fill={(this.state.isDragging === true) ? "white" : "green"}
					x={202}
					y={(typeof this.state.positions[index] === 'undefined') ? 213 : this.state.positions[index].y} text="Masa Prezidiu"
				/>}

				{elemType === elementsTypes.wall && <Rect
					id={"node-" + this.state.positions.length}
					x={200}
					y={200}
					width={info.wallWidth}
					height={info.wallHeight}
					fill={this.state.color}
					shadowBlur={5}
					cornerRadius={0}
				/>}

				{elemType === elementsTypes.stair && <Line
					id={"node-" + this.state.positions.length}
					points={[0, 0, 10, 0, 10, 10, 20, 10, 20, 20, 30, 20, 30, 30, 40, 30, 40, 40, 0, 40]}
					fill={this.state.color}
					stroke={"grey"}
					strokeWidth={2}
					closed={true}
				/>}

				{elemType === elementsTypes.roundTable && <Circle
					id={"node-" + this.state.positions.length}
					x={100}
					y={200}
					width={40}
					height={40}
					fill={this.state.usersTableNumber === tableNumber ? "red" : this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.roundTable && <Text
					id={"node-" + index + "text"}
					fill={this.state.usersTableNumber === tableNumber ? "white" : "green"}
					x={97}
					y={(typeof this.state.positions[index] === 'undefined') ? 195 : this.state.positions[index].y} text={tableNumber}
				/>}

				{elemType === elementsTypes.squareTable && <Rect
					id={"node-" + this.state.positions.length}
					x={100}
					y={200}
					width={40}
					height={40}
					fill={this.state.usersTableNumber === tableNumber ? "red" : this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.squareTable && <Text
					id={"node-" + index + "text"}
					fill={this.state.usersTableNumber === tableNumber ? "white" : "green"}
					x={116}
					y={(typeof this.state.positions[index] === 'undefined') ? 215 : this.state.positions[index].y} text={tableNumber}
				/>}

				{elemType === elementsTypes.rectangleTable && <Rect
					id={"node-" + this.state.positions.length}
					x={100}
					y={200}
					width={50}
					height={30}
					fill={this.state.usersTableNumber === tableNumber ? "red" : this.state.color}
					shadowBlur={5}
				/>}

				{elemType === elementsTypes.rectangleTable && <Text
					id={"node-" + index + "text"}
					fill={this.state.usersTableNumber === tableNumber ? "white" : "green"}
					x={121}
					y={(typeof this.state.positions[index] === 'undefined') ? 210 : this.state.positions[index].y} text={tableNumber}
				/>}
			</Group>

		let current_objs = [...this.state.toRender, obj];

		this.setState({
			toRender: current_objs
		})
	}

	showModal = (info, index) => {
		this.setState({
			openModal: true,
			tableInfo: info,
			tableNumber: index
		})
	}

	closeModal = () => {
		this.setState({
			openModal: false
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	getParticipantsRows = (tableInfo) => {
		let info = tableInfo.peopleAtTable

		let totalPeopleAtTable = 0

		for (let i = 0; i < info.length; i++) {
			totalPeopleAtTable += info[i].nrPeople
		}

		var rows = []

		info.map(elem => {
			let color = "white"

			if (this.state.tableNumber === this.state.usersTableNumber && this.state.currentUserData.responseIndex === elem.responseIndex) {
				color = "red"
			}

			rows.push({ nume: elem.prenume, color: color })

			if (elem.namePartner !== "") {
				rows.push({ nume: elem.namePartner, color: color })
			}

			if (elem.kidsCount !== null) {
				for (var i = 0; i < elem.kidsCount; i++) {
					rows.push({ nume: "copil", color: color })
				}
			}

			return null
		})

		if (window.innerWidth < 700) {
			return <div>
				{rows.map((elem, index) => {
					return <div key={index} style={{color: elem.color === "red" ? elem.color : "black"}}>{index + 1}. {elem.nume}</div>
				})}

				<div>Invitati la masa: {totalPeopleAtTable}/{tableInfo.tableSize}</div>
			</div>
		}

		let tableSize = tableInfo.tableSize

		var radius = 200
		var heigth = 200
		var width = 200
		if (tableInfo.type === elementsTypes.rectangleTable) {
			heigth = 150
			width = 250
		}
		var angle = 0
		// var step = (2 * Math.PI) / rows.length //dupa nr oameni asezati la mese
		var step = (2 * Math.PI) / tableSize //dupa nr oameni max la mesa

		let x = 0
		let y = 0

		let chairX = 0
		let chairY = 0

		return <div className={tableInfo.type === elementsTypes.roundTable ? "RoundTable" : tableInfo.type === elementsTypes.squareTable ? "SquareTable" : "RectangleTable"}>
			{rows.map((elem, index) => {
				if (tableInfo.type === elementsTypes.roundTable) {
					x = Math.round(width / 2 + radius * Math.cos(angle))
					y = Math.round(heigth / 2 + radius * Math.sin(angle))

					chairX = Math.round(width / 2 + radius * Math.cos(angle) * 0.75)
					chairY = Math.round(heigth / 2 + radius * Math.sin(angle) * 0.75)
				} else if (tableInfo.type === elementsTypes.squareTable) {
					let distance = radius * Math.sin(angle - step)
					let correctionForDistance = radius / (tableSize / 3)

					let finalDistanceX = 0
					let finalDistanceY = 0
					let finalDistanceChairX = 0
					let finalDistanceChairY = 0

					if (index < tableSize / 4) {
						finalDistanceX = radius
						finalDistanceY = distance + correctionForDistance
						finalDistanceChairX = finalDistanceX * 0.7
						finalDistanceChairY = finalDistanceY
					}
					else if (index < tableSize / 2) {
						distance = radius * Math.cos(angle - step)
						finalDistanceX = distance - correctionForDistance
						finalDistanceY = radius
						finalDistanceChairX = finalDistanceX
						finalDistanceChairY = finalDistanceY * 0.7
					}
					else if (index < 3 * tableSize / 4) {
						finalDistanceX = - radius
						finalDistanceY = distance - correctionForDistance
						finalDistanceChairX = finalDistanceX * 0.7
						finalDistanceChairY = finalDistanceY
					}
					else {
						distance = radius * Math.cos(angle - step)
						finalDistanceX = distance + correctionForDistance
						finalDistanceY = - radius
						finalDistanceChairX = finalDistanceX
						finalDistanceChairY = finalDistanceY * 0.7
					}

					x = Math.round(width / 2 + finalDistanceX)
					y = Math.round(heigth / 2 + finalDistanceY)
					chairX = Math.round(width / 2 + finalDistanceChairX)
					chairY = Math.round(heigth / 2 + finalDistanceChairY)
				} else {
					//rectangle table
					if (index < tableSize / 2) {
						x = -50 + 840 / tableSize * index
						y = 250
						chairY = 200
					} else {
						x = -50 + 840 / tableSize * (index - tableSize / 2)
						y = -100
						chairY = -50
					}
					chairX = x
				}

				angle += step

				let elemStyle = {
					position: "absolute",
					left: `${x}px`,
					top: `${y}px`,
					transform: "translate(-50%, -50%)"
				}

				let chairStyle = {
					width: "30px",
					height: "30px",
					border: "1px solid black",
					borderRadius: "50%",
					backgroundColor: elem.color,

					position: "absolute",
					left: `${chairX}px`,
					top: `${chairY}px`,
					transform: "translate(-50%, -50%)"
				}

				return <div key={index}>
					<p style={elemStyle}>{elem.nume}</p>
					<div style={chairStyle}></div>
				</div>
			})}
		</div>
	}

	render() {
		if (this.state.loading) {
			return <p> Loading responses </p>
		}

		if (!this.state.isSeatingPlanDone) {
			return <div>
				<HeaderWhite shadow={true} goBack={this.goBack} />

				<h3> Planul de mese nu a fost incarcat</h3>
			</div>
		}

		if (!this.state.isPublished) {
			return <div>
				<HeaderWhite shadow={true} goBack={this.goBack} />

				<h3> Planul de mese nu este publicat</h3>
			</div>
		}

		const override = css`
        display: block;
        margin: 25px auto;
        border-color: #221d5d;
        `;

		let toRender = (<BeatLoader
			css={override}
			size={15}
			color={"#00afcc"}
		/>)

		let finalToRender = this.state.toRender.map(element => {
			return element
		})

		if (this.state.loading === false) {
			toRender = (
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100vw", height: "100vh" }}>
					<HeaderWhite shadow={true} goBack={this.goBack} />

					<Modal classNames={{
						overlay: 'modal-overlay',
						modal: 'std-modal',
						closeButton: 'modal-close-button',
					}} open={this.state.openModal} onClose={this.closeModal} center>
						<div className={window.innerWidth < 700 ? "" : "SeatingChart-Modal-Container"}>
							<div>
								{this.state.tableInfo.peopleAtTable !== undefined && this.state.tableInfo.peopleAtTable.length === 0
									? <h4>Niciun invitat asezat la aceasta masa</h4>
									: <div style={{ alignItems: "center" }}>
										<h4 className={window.innerWidth < 700 ? "" : "TableNumber"}> Masa {this.state.tableNumber}</h4>
										{this.getParticipantsRows(this.state.tableInfo)}
									</div>
								}
							</div>
						</div>
					</Modal>

					<div className='SeatingChart-container'>
						<Stage width={seatingChartCanvasWidth} height={seatingChartCanvasHeight} style={{ border: "1px solid black", margin: "auto" }}>
							<Layer>
								{finalToRender}
							</Layer>
						</Stage>
					</div>
				</div>
			)
		}

		if (this.state.loading === true)
			toRender = (<BeatLoader
				css={override}
				size={15}
				color={"#00afcc"}
			/>)

		return (
			<div>
				{toRender}
			</div>
			// <DeviceOrientation lockOrientation={'landscape'}>
			// 	<Orientation orientation='landscape' alwaysRender={false}>
			// 		{toRender}
			// 	</Orientation>

			// 	<Orientation orientation='portrait' alwaysRender={false}>
			// 		<div className="SeatingChart-container">
			// 			<div className="SeatingChart-contents">
			// 				<HeaderWhite shadow={true} goBack={this.goBack} />

			// 				<h5 style={{ marginTop: "20px", width: "70%", margin: "auto" }}> Ecranul este prea mic pentru a vizualiza planul meselor </h5>

			// 				<Button appearance="primary" style={{ width: "70%", margin: "auto", fontWeight: "500" }} onClick={() => this.props.history.push({
			// 					pathname: "/eventPage/" + this.props.match.params.id,
			// 					state: { seating: "yes" }
			// 				})}>Înapoi la Eveniment </Button>
			// 			</div>
			// 		</div>
			// 	</Orientation>
			// </DeviceOrientation>
		);

	}
}

export default SeatingChart;
