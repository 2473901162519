import React from 'react'
import { Icon, Checkbox, InputGroup, Input } from 'rsuite';
import Modal from 'react-responsive-modal'
import { Button, } from 'rsuite';
import './Login.scss'
import logo from '../assets/logo.png'
import axios from 'axios'
import { toast } from 'react-toastify';
import { Drawer } from 'rsuite';
import CustomInput from '../components/custom/CustomInput';
import NavbarColor from '../components/NavbarColor';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { css } from "@emotion/react";
import BeatLoader from "react-spinners/BeatLoader";
import queryString from 'query-string';
import {isJsonString} from "../functions";
import GoogleWebLoginButton from "../components/GoogleLogin/GoogleWebLoginButton";
import {injectIntl} from 'react-intl';
import LanguageContext from "../contexts/LanguageContext";
class Login extends React.Component {
	static contextType = LanguageContext
	isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream

	urlParams = queryString.parse(this.props.location.search)

	state = {
		email: "",
		pass: "",
		show: false,
		rememberMeCheck: true,
		passPlaceholder: this.props.intl.formatMessage({id: 'general.password'}),
		passwordInputType: "password",

		recoverMail: "",
		showModal: false,
		loading: false,
	}

	requestPushToken = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}
		if(window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('getPushNotificationToken')
				.then(token => {

					let data = {
						token: token
					}
					axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth).catch((e) => {})

				})
		}

		if(window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'getPushToken',
				data: localStorage.getItem('jwt')
			}))
		}
	}

	onAppMessage = async (message) => {

		let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false

		if(data) {
			// eslint-disable-next-line
			switch (data.action) {
				case 'signInWithApple':
					this.appleSignIn(data.firstName, data.lastName, data.email, data.accessToken)
					break
				case 'signInWithFacebook':
					this.facebookSignIn({accessToken: data.accessToken})
					break
				case 'signInWithGoogle':
					this.googleSignIn({tokenId: data.accessToken})
					break
			}
		}
	}

	componentDidMount() {


		[window, document].forEach(
			el => el.addEventListener('message', this.onAppMessage))

		//window.addEventListener("message", this.onAppMessage)

		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
		}

		if (localStorage.getItem("newAccount") !== null) {
			localStorage.removeItem("newAccount")
			this.setState({
				showModal: true
			})
		}

		if (localStorage.getItem('jwt') !== null) {
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
				.then(async _ => {
					if (window.flutter_inappwebview && localStorage.getItem("hasToken") !== null) {
						await window.flutter_inappwebview.callHandler('getPushNotificationToken')
							.then(token => {
								localStorage.setItem("hasToken", true);

								let data = {
									token: token
								}

								axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth).catch((e) => {})
							})
					}


					if(window.alityAppView && localStorage.getItem("hasToken") !== null) {
						window.ReactNativeWebView.postMessage(JSON.stringify({
							action: 'getPushToken',
							data: localStorage.getItem('jwt')
						}))
					}

					if (this.urlParams && this.urlParams.redirectBackTo && this.urlParams.redirectBackTo.length > 0) {
						this.props.history.push('/' + this.urlParams.redirectBackTo)
					} else {
						this.props.history.push('/events')
					}
				})
				.catch(err => {
					localStorage.clear()
				})
		}
	}

	componentWillUnmount() {
		window.removeEventListener("message", this.onAppMessage)
		document.removeEventListener("message", this.onAppMessage)
	}

	displayErrors = (data) => {
		for (var key in data) {
			if (data.hasOwnProperty(key)) {
				toast.error(data[key][0]);
			}
		}

	}

	handleRecoverMail = (value) => {
		if (value[value.length - 1] === " "){
			value = value.substring(0, value.length - 1)
		}

		this.setState({
			recoverMail: value
		})
	}

	handleEmail = (value) => {
		if (value[value.length - 1] === " "){
			value = value.substring(0, value.length - 1)
		}

		this.setState({
			email: value
		})
	}

	handlePass = (value) => {
		if (value[value.length - 1] === " "){
			value = value.substring(0, value.length - 1)
		}

		this.setState({
			pass: value
		})
	}

	changeContext = () => {
		let obj = {
			identifier: this.state.email,
			password: this.state.pass
		}

		this.setState({
			loading:true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'auth/local', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					//get push notification token if on webview
					if (window.flutter_inappwebview) {
						await window.flutter_inappwebview.callHandler('getPushNotificationToken')
							.then(token => {
								localStorage.setItem("hasToken", true);

								let data = {
									token: token
								}

								let auth = {
									headers: {
										'Authorization': 'Bearer ' + response.data.jwt
									}
								}

								axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth).catch((e) => {})
							})
					}

					if(window.alityAppView) {
						window.ReactNativeWebView.postMessage(JSON.stringify({
							action: 'getPushToken',
							data: localStorage.getItem('jwt')
						}))
					}

					var eventId = localStorage.getItem("eventId")

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if (eventId !== null) {
						if (responseId !== null) {
							localStorage.removeItem("responseId")
							this.props.history.push('/eventPageW/' + eventId)
						} else {
							this.props.history.push('/eventPage/' + eventId)
						}

						localStorage.removeItem('eventId');
					} else {
						if (this.urlParams && this.urlParams.redirectBackTo && this.urlParams.redirectBackTo.length > 0) {
							this.props.history.push('/' + this.urlParams.redirectBackTo)
						} else {
							this.props.history.push('/events')
						}
					}

					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading:false
				})
			})
			.catch(err => {
				this.setState({
					loading:false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})
	}

	changeContextToSignUp = () => {
		this.props.history.push('/signup')
	}

	recuperareParola = () => {
		var past = this.state.show

		this.setState({
			show: !past
		})
	}

	resetPassword = () => {
		// eslint-disable-next-line
		const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!emailRegex.test(this.state.recoverMail.toLowerCase())) {
			toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_email'}))

			if (this.state.email.indexOf(' ') >= 0) {
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_email'}))
			}

			return;
		}

		let obj = {
			email: this.state.recoverMail,
			sendEmail: true,
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'auth/forgot-password', obj)
			.then((response) => {
				toast.success(this.props.intl.formatMessage({id: 'login.alerts.forgot_password.sent'}));
				this.recuperareParola()
			})
			.catch(err => {
				// this.displayErrors(err.response.data);
				toast.error(this.props.intl.formatMessage({id: 'login.alerts.forgot_password.email_does_not_exists'}))
			})
	}

	passOnFocus = (file) => {
		this.setState({
			passPlaceholder: ""
		})
	}

	passLostFocus = (file) => {
		this.setState({
			passPlaceholder: this.props.intl.formatMessage({id: 'general.password'})
		})
	}

	closeConfirmMailModal = () => {
		this.setState({
			showModal: false
		})
	}

	loginGoogle = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginGoogle')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
						}
					} else {
						this.googleSignIn({tokenId: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithGoogle'
			}))
		}
	}

	googleSignIn = (response) => {

		if (!response.tokenId) {
			return;
		}

		let tokenId = response.tokenId
		//tokenId = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE4MmU0NTBhMzVhMjA4MWZhYTFkOWFlMWQyZDc1YTBmMjNkOTFkZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE3MDU1NjE0NDAyMTA4NTczOTI3IiwiZW1haWwiOiJ2bGFkLml2YW5jaXUyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiQVFtbllaazQ5czRoZk5VWHV1dW53dyIsIm5hbWUiOiJWbGFkIEl2YW5jaXUiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKeEJpMm9yVktacnlrY1NwRHJnRjh2T3lUM0JVLXg1OTdsV2xBM2g0UT1zOTYtYyIsImdpdmVuX25hbWUiOiJWbGFkIiwiZmFtaWx5X25hbWUiOiJJdmFuY2l1IiwibG9jYWxlIjoicm8iLCJpYXQiOjE2NDQ0MDYzNjgsImV4cCI6MTY0NDQwOTk2OCwianRpIjoiYTA5Mjk3OTBhODliMDliMDVhZDA0MzY3NzM4YjQ0ZDIzOGM1OWU3YSJ9.GfRbRzpfxQVz5OR5T3N_1RvXljePBvrHUwhqAUetJ9_YgLZCulsiKkbuWznKlgtPIVn_6IeF_HE6jxbgpgqDg_0w5KqsKLAp18Tc0ICZqsOhOwCrQYw9USajjc4YmgFMtO_rtytDqvAx67jZFPsnnNarcVUXlni5gmg1mmsRMNw_xnaGc7w9T0wOqTz36og_z2spjxzSNrK3Gp6BE2wy5mt0KvpYFKq8nJJOJLp8FHMLKLhCKUOGiRK8bnHc_RFJ3x8WxmYwBIT9floaS-4YII5mSSN9y2XLYLVKnPa_N2mWinNNe-DsNkby1QJQ8lw2mW5gGoGTuwCFJiSkBCdTMA"

		let obj = {
			tokenId: tokenId
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/google', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					var eventId = localStorage.getItem("eventId")

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if (eventId !== null) {
						if (responseId !== null) {
							localStorage.removeItem("responseId")
							this.props.history.push('/eventPageW/' + eventId)
						} else {
							this.props.history.push('/eventPage/' + eventId)
						}

						localStorage.removeItem('eventId');
					} else {


						if (this.urlParams && this.urlParams.redirectBackTo && this.urlParams.redirectBackTo.length > 0) {
							this.props.history.push('/' + this.urlParams.redirectBackTo)
						} else {
							this.props.history.push('/events')
						}
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	loginFacebook = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginFacebook')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error("Eroare la conectare, va rog reincercati.")
						}
					} else {
						this.facebookSignIn({accessToken: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithFacebook'
			}))
		}
	}

	facebookSignIn = (response) => {

		if (!response.accessToken) {
			return;
		}

		let accessToken = response.accessToken

		let obj = {
			accessToken: accessToken
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/facebook', obj)
			.then(async(response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					var eventId = localStorage.getItem("eventId")

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if (eventId !== null) {
						if (responseId !== null) {
							localStorage.removeItem("responseId")
							this.props.history.push('/eventPageW/' + eventId)
						} else {
							this.props.history.push('/eventPage/' + eventId)
						}

						localStorage.removeItem('eventId');
					} else {


						if (this.urlParams && this.urlParams.redirectBackTo && this.urlParams.redirectBackTo.length > 0) {
							this.props.history.push('/' + this.urlParams.redirectBackTo)
						} else {
							this.props.history.push('/events')
						}
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	appleSignIn = (firstName, lastName, email, accessToken) => {

		let obj = {
			accessToken: accessToken,
			email: email,
			first_name: firstName,
			last_name: lastName
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/apple', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success(this.props.intl.formatMessage({id: 'general.alerts.success_log_in'}))

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					var eventId = localStorage.getItem("eventId")

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if (eventId !== null) {
						if (responseId !== null) {
							localStorage.removeItem("responseId")
							this.props.history.push('/eventPageW/' + eventId)
						} else {
							this.props.history.push('/eventPage/' + eventId)
						}

						localStorage.removeItem('eventId');
					} else {
						if (this.urlParams && this.urlParams.redirectBackTo && this.urlParams.redirectBackTo.length > 0) {
							this.props.history.push('/' + this.urlParams.redirectBackTo)
						} else {
							this.props.history.push('/events')
						}
					}
				} else {
					toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_confirm_email'}))
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error(this.props.intl.formatMessage({id: 'general.alerts.invalid_credentials'}))
			})


	}

	loginApple = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginApple')
				.then(data => {
					let response = data.split('|')
					this.appleSignIn(response[2], response['1'], response['3'], response['0'])
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithApple'
			}))
		}
	}


	render() {
		return (
			<div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
				<div className="Login-container">
					<Drawer
						placement="top"
						show={this.state.show}
						onHide={this.recuperareParola}
					>
						<Drawer.Header>
							<Drawer.Title>{this.props.intl.formatMessage({id: 'login.reset_password.title'})}</Drawer.Title>
						</Drawer.Header>

						<Drawer.Body>
							<div className="Login-drawer-contents">
								<p id="ind">{this.props.intl.formatMessage({id: 'login.reset_password.desc'})}</p>

								<div style={{ width: '100%' }}>
									<CustomInput value={this.state.recoverMail} onChange={this.handleRecoverMail} placeholder={"Email"} icon={<Icon icon="envelope" />} />

									<Button onClick={this.resetPassword} className="Login-button">{this.props.intl.formatMessage({id: 'login.reset_password.reset'})} </Button>
								</div>
							</div>
						</Drawer.Body>
					</Drawer>

					<Modal
						classNames={{
							overlay: 'modal-overlay',
							modal: 'modal',
							closeButton: 'modal-close-button',
						}}
						center
						open={this.state.showModal}
						onClose={this.closeConfirmMailModal}
					>
						<div style={{ marginTop: "20px" }}>
							<h4>{this.props.intl.formatMessage({id: 'login.please_confirm_email'})}</h4>

							<Button onClick={this.closeConfirmMailModal} color="green" style={{ backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%" }}>{this.props.intl.formatMessage({id: 'general.got_it'})}</Button>
						</div>
					</Modal>

					<div className="Login-one">
						<img style={{ width: "170px", height: "auto", marginBottom: "10px" }} src={logo} alt='' />

						{
							this.context.language === 'ro' ?
								<img  alt={""} src={"/images/languages/en.png"} onClick={() => this.context.setLanguage('en')} style={{ position: "absolute", marginTop:"8px", right: "40px", width:"30px" }}/>
								:
								<img  alt={""} src={"/images/languages/ro.png"} onClick={() => this.context.setLanguage('ro')} style={{ position: "absolute", marginTop:"8px", right: "40px", width:"30px" }}/>
						}
						{/* <p id = "ondemand-text"> Smart Event Management </p> */}
					</div>

					<div className="Login-two">
						<InputGroup className="InputGroup-Container" inside>
							<InputGroup.Addon className="InputGroup-Login">
								<Icon icon="envelope" />
							</InputGroup.Addon>

							<Input value={this.state.email} onChange={this.handleEmail} placeholder="Email" name='email' className="InputGroup-Login" autoCorrect="off" autoCapitalize="off" />
						</InputGroup>

						<div className={"password-row"}>
						<InputGroup className="InputGroup-Container" inside>
							<InputGroup.Addon className="InputGroup-Login">
								<Icon icon="key" />
							</InputGroup.Addon>


							<Input value={this.state.pass} onChange={this.handlePass} name='password' type={this.state.passwordInputType}
								   onFocus={this.passOnFocus} onBlur={this.passLostFocus}
								   placeholder={this.state.passPlaceholder} className="InputGroup-Login" />
								<div
								className={"password-show-btn " + (this.state.passwordInputType === 'text' ? 'active' : '')}
									onClick={() => {
									this.state.passwordInputType === 'password' ?
										this.setState({passwordInputType: 'text'})
										:
										this.setState({passwordInputType: 'password'})
								}}>
									<Icon icon="eye" />
								</div>
						</InputGroup>
						</div>

						<Button onClick={this.changeContext} className="Login-button"> Login </Button>

						<div className="checkbox-content" style={{display:'none'}}>
							<Checkbox onChange={_ => this.setState({ rememberMeCheck: !this.state.rememberMeCheck })} checked={this.state.rememberMeCheck} />

							<div onClick={_ => this.setState({ rememberMeCheck: !this.state.rememberMeCheck })}> Remember Me </div>
						</div>

						<p onClick={this.recuperareParola} id="recovery-pass">{this.props.intl.formatMessage({id: 'login.reset_password'})}</p>



					<div className={"social-or-container"}>
						<div className={"left"}>
							<div className={"line"}></div>
						</div>
						<div className={"center"}>{this.props.intl.formatMessage({id: 'login.or'})}</div>
						<div className={"right"}>
							<div className={"line"}></div>
						</div>
					</div>

					<div className={"social-title"}>{this.props.intl.formatMessage({id: 'login.log_in_with'})}</div>

						<div className={"social-login-container"}>


							{
								window.flutter_inappwebview || window.alityAppView ?

									<div style={{flexDirection:'column',width:'100%'}}>
										<button onClick={this.loginGoogle}
												className={"social-login-button social-google-login"}
												style={{width:'100%'}}>
											<Icon icon="google"/> Login Google
										</button>

										<button onClick={this.loginFacebook}
												className={"social-login-button social-facebook-login"}
												style={{width:'100%', marginTop:'10px'}}
										>
											<Icon icon="facebook"/> Login Facebook
										</button>

										{
											this.isIOS ?
												<button onClick={this.loginApple}
														className={"social-login-button social-apple-login"}
														style={{width: '100%', marginTop: '10px'}}
												>
													<Icon icon="apple"/> Sign in with Apple
												</button>
												: null
										}
									</div>
									:
									<>

										<GoogleWebLoginButton
											callbackFunction={(val) => this.googleSignIn(val)}
										/>

										<FacebookLogin
											appId="243653927956471"
											autoLoad={false}
											fields="name,email"
											//onClick={(item) => this.facebookSignIn(item)}
											callback={(item) => this.facebookSignIn(item)}
											//cookie
											//
											disableMobileRedirect={true}
											//isMobile={true}
											redirectUri={'https://app.ality.ro'}
											render={renderProps => (
												<button
													className={"social-login-button social-facebook-login"}
													style={{marginLeft: '5px'}}
													type={"button"}
													onClick={renderProps.onClick}
													disabled={renderProps.disabled}
												><Icon icon="facebook"/> Facebook</button>
											)}
										/>
									</>
							}
						</div>




					</div>

					<div className="Login-three">
						<div className={"social-or-container"}>
							<div className={"left"}>
								<div className={"line"}></div>
							</div>
							<div className={"center"}>{this.props.intl.formatMessage({id: 'login.no_account'})}</div>
							<div className={"right"}>
								<div className={"line"}></div>
							</div>
						</div>

						<Button onClick={this.changeContextToSignUp} className="Login-button" style={{height:'38px', lineHeight:'18px', fontSize:'18px'}}>{this.props.intl.formatMessage({id: 'login.create_account'})}</Button>
					</div>
				</div>

				{
					this.state.loading ?
						<div className={"loading-overlay"}>
							<BeatLoader color={"#00B7AD"} loading={true} css={css} size={30} />
							<span>{this.props.intl.formatMessage({id: 'general.loading'})}</span>
						</div>
						:
						null
				}

				<NavbarColor history={this.props.history} inLogin={"Yes"} active={"Profile"} />
			</div>
		)
	}
}

export default injectIntl(Login);
