import React, {useEffect, useState} from 'react'
import HeaderWhite from '../../components/HeaderWhite'
import NavbarColor from '../../components/NavbarColor'
import './FeedbackForm.scss'
import {toast} from "react-toastify";
import axios from "axios";
import {useHistory} from "react-router-dom";
import Loader from "../../components/Loader";
import {ConfirmButton, CustomQuestions} from "../../components/Helper";
import {useIntl} from "react-intl";
export default function FeedbackForm() {

    let history = useHistory()
    const intl = useIntl();
    const [questions, setQuestions] = useState([])
    const [answers, setAnswers] = useState([])
    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        checkUser()
        // eslint-disable-next-line
    }, [])

    const checkUser = async () => {
        if (localStorage.getItem('jwt') === null) {
            toast.error("Trebuie sa va logati pentru a răspunde la formularul de feedback!")
            history.push('/login?redirectBackTo=feedbackForm');
            return
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
            .then(response => {

                axios.get(process.env.REACT_APP_DOMAIN + 'feedback-questions', auth)
                    .then(response => {

                        let newQuestions = []

                        for (let i = 0; i < response.data.data.length; i++) {
                            let question = { ...response.data.data[i] }

                            let elem = {
                                title: question.title,
                                type: question.type,
                                options: []
                            }

                            if (elem.type === 1)
                                question.options.map(element => {
                                    elem.options.push({ label: element, value: element })

                                    return null
                                })

                            newQuestions.push(elem)
                        }

                        setQuestions(newQuestions)
                        setLoading(false)
                    })
                    .catch(err => {
                        if(err && err.response && err.response.status === 401) {
                            localStorage.clear()
                            toast.error("Informațiile de autentificare au expirat, vă rugăm să vă autentificați!")
                            history.push('/login');
                        } else {
                            toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                        }
                    })

            })
            .catch(err => {
                localStorage.clear()
                toast.error("Informatiile de autentificare au expirat, va rugam sa va autentificati")
                history.push('/login');
            })


    }

    const saveAnswer = async () => {

        setSaveLoading(true)

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let data = {
            response: {
                questions: questions,
                answers: answers
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'feedbacks/create', data, auth)
            .then(response => {

            })
            .catch(err => {

            })

        setSaveLoading(false)
        toast.success('Vă mulțumim!')
        history.push('/events')
    }



    return (

        loading ?
            <div className="FeedbackForm-container">
                <HeaderWhite shadow={true}/>
                <div className={"FeedbackForm-contents"}>
                    <Loader/>
                </div>
                <NavbarColor history={history}/>
            </div>
            :
            <div className="FeedbackForm-container">
                <HeaderWhite shadow={true}/>

                <div className={"FeedbackForm-contents"}>
                    <div className={"questions-form"}>
                        <h3>Formular feedback</h3>
                    <CustomQuestions
                        questions={questions}
                        answers={answers}
                        changeAnswers={(response) => setAnswers(response)} intl={intl}/>
                    </div>

                    <ConfirmButton loading={saveLoading} text={"Trimite"} function={saveAnswer} />
                </div>
                <NavbarColor history={history}/>
            </div>
    )

}

