import React from 'react'
import { toast } from 'react-toastify';
import {Button, Dropdown, Icon} from 'rsuite';
import CustomInput from '../../components/custom/CustomInput';
import HeaderWhiteBack from '../../../components/HeaderWhite';
import { BackAndConfirmButtons } from '../../components/Helper';
import NavbarColor from '../../../components/NavbarColor';
import axios from 'axios'
import './ContactUs.scss'
import Loader from "../../components/Loader";
import HeaderWhite from "../../../components/HeaderWhite";
import Modal from "react-responsive-modal";

const defaultCategory = "Alege"

class ContactUs extends React.Component {
	state = {
		email: "",

		nume: "",
		phone: "",
		title: "",
		text: "",
		categories: ["Cerere pret", "Memory booth", "Defecte", "Informatii", "Alte intrebari"],
		chosenCategory: defaultCategory,
		tutorialModal: false,

		is_sending: false,
		loading: true
	}

	componentDidMount = () => {
		if (localStorage.getItem('jwt') === null) {
			toast.error("Trebuie sa va logati pentru a folosi site-ul")
			this.props.history.push('/organizer/login');
			return
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
			.then(response => {
				this.setState({
					email: response.data.email,
					loading: false
				})
			})
			.catch(err => {
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rugam sa va autentificati")
				this.props.history.push('/organizer/login');
			})

		this.checkEvent()


	}

	checkEvent = async () => {

		if(this.props.match.params.id) {

			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let eventData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

			this.setState({
				tutorialModal: !eventData.data.tutorials || (eventData.data.tutorials && !eventData.data.tutorials.contactUs),
			})
		}
	}

	setter = (key, value) => {
		this.setState({ [key]: value })
	}

	changePhoneNumber = (value) => {
		if (value !== "" && value !== "0") {
			if (!Number(value)) {
				return
			}
		}

		this.setter("phone", value)
	}

	changeText = (value) => {
		this.setState({
			text: value
		})
	}

	sendMessage = async () => {
		//check validity
		if (this.state.title === "") {
			toast.error("Va rog adaugati titlu")
			return
		}

		if (this.state.text === "") {
			toast.error("Va rog adaugati continut")
			return
		}

		if (this.state.chosenCategory === defaultCategory) {
			toast.error("Va rog alegeti categoria intrebarii")
			return
		}

		if (this.state.is_sending) {
			return
		}

		this.setState({
			is_sending: true
		})

		//send data
		var obj = {
			nume: this.state.nume,
			email: this.state.email,
			facultate: "Mesaj nunta",
			prenume: "",
			telefon: this.state.phone,
			titlu: this.state.title,
			mesaj: this.state.text,
			category: this.state.chosenCategory
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		//show success/fail message
		await axios.post(process.env.REACT_APP_DOMAIN + "contacts", obj, auth)
			.then((response) => {
				toast.success("Mesaj trimis")
				this.setState({
					nume: "",
					phone: "",
					title: "",
					chosenCategory: defaultCategory,
					text: ""
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("Eroare")
			})

		//empty fields
		this.setState({
			is_sending: false
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				contactUs: true
			},
			auth)

	}

	getInfoRow = (icon, name, text) => {
		return <div className="Row">
			<Icon icon={icon} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />

			<div className="Data">
				<div>{name}</div>

				<p style={{ color: "#121213", fontSize: "16px" }}>{text}</p>
			</div>
		</div>
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <div className="ContactUs-container">
				<HeaderWhite shadow={true} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="ContactUs-container">
				<HeaderWhiteBack goBack={this.goBack} />

				<div className="ContactUs-contents">
					<div className='Title'>
						Contact
					</div>

					<div className="Section">
						{this.getInfoRow("at", "Email", this.state.email)}
					</div>

					<Dropdown title={this.state.chosenCategory === defaultCategory ? "Alege categorie" : "Categorie: " + this.state.chosenCategory} activeKey={this.state.chosenCategory} className="dropdown_scroll" style={{ backgroundColor: "#E5F7FE", borderRadius: "10px" }}>
						{this.state.categories.map((option, index) => {
							return <Dropdown.Item key={index} eventKey={option} onClick={() => this.setState({ chosenCategory: option })}>{option}</Dropdown.Item>
						})}
					</Dropdown>

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.nume} onChange={value => this.setter("nume", value)} placeholder={"Nume"}
						icon={<Icon icon={"avatar"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.phone} onChange={this.changePhoneNumber} placeholder={"Telefon (Optional)"}
						icon={<Icon icon={"phone"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.title} onChange={value => this.setter("title", value)} placeholder={'Titlu'}
						icon={<Icon icon={"pencil"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<CustomInput style={{ margin: "10px 0px" }} value={this.state.text} onChange={this.changeText} placeholder={`Scrie un mesaj!`}
						componentClass={"textarea"} icon={<Icon icon={"pencil"} size="2x" style={{ margin: "0px 10px", color: "#06aff2" }} />} />

					<Button
						onClick={()=> this.setState({tutorialModal:true})}
						color="green"
						style={{maxWidth: 'var(--container-max-width)', margin:'auto', backgroundColor: "#05AFF2", overflow:'visible', borderRadius: "20px", width: "100%", marginTop:"10px", marginBottom: "20px"}}>Informații utile</Button>


					<BackAndConfirmButtons loading={this.state.is_sending} textBack={"Înapoi"} textConfirm={"Trimite"} functionBack={this.goBack} functionConfirm={this.sendMessage} />
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Hai sa ne cunoaștem mai bine! În acest meniu ne poți adresa orice întrebări ai sau poți semnala dificultatile intalnite intrucat Ality este pentru, cu și despre tine!
						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default ContactUs;