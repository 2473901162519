import React from 'react'
import Webcam from "react-webcam";
import { Button, Icon } from 'rsuite'
import './Selfie.scss'
import LoadingOverlay from 'react-loading-overlay';
import { toast } from 'react-toastify';
import Fade from 'react-reveal/Fade';
import Modal from 'react-responsive-modal'
import axios from 'axios'
import AdModal from '../../components/AdModal';
import {isJsonString} from "../../functions";

let adFieldName = "facerecog"

class Selfie extends React.Component {
	state = {
		webcamRef: React.createRef(),
		imgSrc: null,
		showModal: true,
		showConfirmModal: false,
		showOverlay: null,
		loading: true,
		isPictureSent: false,

		inWebView: false,
		initLoading: true,

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}

	onAppMessage = async (message) => {
		let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false

		if(data) {
			// eslint-disable-next-line
			switch (data.action) {
				case 'setFaceRecogPicture':
					this.receivePicture(data.base64Picture)
					break
			}
		}
	}

	componentDidMount = async () => {

		//check if it's web or flutter_webview
		if (window.flutter_inappwebview || window.alityAppView) {
			this.setState({
				inWebView: true,
				initLoading: false
			})
		} else {
			this.setState({
				initLoading: false
			})
		}

		[window, document].forEach(
			el => el.addEventListener('message', this.onAppMessage))
		//window.addEventListener("message", this.onAppMessage)

		//in case user didn't buy ticket in current data, reload event data
		if (!this.props.isTicketPaid) {
			this.props.reloadEventData()
		}

		let obj = {
			email: this.props.email,
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'facerecog/checkRequest', obj)
			.then((response) => {
				//check response
				this.setState({
					loading: false,
					imgSrc: response.data,
					isPictureSent: true,
					showModal: false,
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
			})

	}

	componentWillUnmount() {
		window.removeEventListener("message", this.onAppMessage)
		document.removeEventListener("message", this.onAppMessage)
	}

	receivePicture = (base64Picture) => {
		this.setState({
			imgSrc: `data:image/jpeg;base64,${base64Picture}`,
			isPictureSent: false,
		})
	}

	capture = () => {
		if (this.state.inWebView) {

			if(window.flutter_inappwebview) {
				window.flutter_inappwebview.callHandler('faceRecog', 'recog').then(res => {
					if (res === "NULL") {
						return;
					}

					this.setState({
						imgSrc: `data:image/jpeg;base64,${res}`,
						isPictureSent: false,
					})
				})
			}

			if(window.alityAppView) {
				window.ReactNativeWebView.postMessage(JSON.stringify({
					action: 'openFaceRecogScreen'
				}))
			}
		} else {
			const imageSrc = this.state.webcamRef.current.getScreenshot();
			this.setState({
				imgSrc: imageSrc,
				isPictureSent: false,
			})
		}
	}

	deletePic = () => {
		this.setState({
			isPictureSent: false,
			imgSrc: null,
		})
	}

	savePicture = () => {
		let obj = {
			email: this.props.email,
			tel: "",
			type: "email",
			selfie: this.state.imgSrc,
			eventId: this.props.event_id,
		}


		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'facerecog/saveRequest', obj, auth)
			.then((response) => {
				// this.props.history.push('/finish');
				toast.success("Selfie trimis")
			})
			.catch(err => {
				toast.error("Mail-ul acesta este deja inregistrat")
			})

		this.setState({
			showConfirmModal: false,
			isPictureSent: true
		})
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		const videoConstraints = {
			facingMode: "user"
		};

		if (this.state.loading) {
			return (
				<div className="Selfie-container">
					<p>Se verifica daca s-a trimis selfie</p>
				</div>
			)
		}

		if (this.state.showAdModal) {
			return <div className="Selfie-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		if (this.props.event_data.FaceRecog.length === 0) {//check if face recog option is opted
			return <div className="Selfie-container">
				<p style={{marginTop:'30px'}}>Opțiunea de recunoaștere facială nu este bifată de organizator</p>
			</div>
		}

		if(this.props.event_data.id_eveniment !== '38KMni2JOf') {
			if (!this.props.isTicketPaid && this.props.event_data.tickets_needed) { //check if ticket is paid
				return <div className="Selfie-container">
					<p style={{marginTop:'30px'}}>Vă rugăm să achizitionați un bilet pentru a putea trimite selfie</p>
				</div>
			}
		}

		if (this.props.event_data.facerecog_finished && this.props.event_data.facerecog_finished === true) {
			return <div className="Selfie-container">
				<p style={{marginTop:'30px'}}>Nu mai acceptam selfie-uri pentru că am început procesarea pozelor</p>
			</div>
		}

		return <LoadingOverlay
			active={this.state.showOverlay}
			spinner
			text='Se încarcă...'
			styles={{
				overlay: (base) => ({
					...base,
					background: 'rgba(0, 0, 0, 0.5)'
				})
			}}
		>
			{
				this.state.initLoading ?
					null
					:
					<Fade style={{height: "100%"}}>
						<>
							{(this.state.imgSrc === null)
								? !this.state.inWebView && <div style={{borderRadius: "20px"}}>
								<Webcam
									audio={false}
									ref={this.state.webcamRef}
									screenshotFormat="image/jpeg"
									mirrored={true}
									videoConstraints={videoConstraints}
								/>

								<div className="overlay-pic"></div>

								<div className="circle-pic"></div>
							</div>
								: <img style={{borderRadius: "20px", width: "80vw"}}
									   className={this.state.inWebView ? "img-webView" : ""}
									   src={this.state.imgSrc} alt=""
								/>
							}

							{(this.state.imgSrc === null)
								? <Button style={{
									margin: "auto",
									marginTop: "30vh",
									width: "60vw",
									maxWidth: '500px',
									borderRadius: "20px",
									backgroundColor: "#05AFF2",
									color: "white"
								}} block size="lg" onClick={this.capture}>
									Fa un selfie
								</Button>
								: this.state.isPictureSent
									? <>
										<p style={{color: "green"}}>Poza a fost trimisă!</p><br/>
										<Button size="lg" appearance="ghost" onClick={() => {this.deletePic()}}> Repetă
											Poza </Button>
										</>
									: <div style={{marginTop: "10px", flexDirection: "column"}}>
										<Button size="lg" appearance="ghost" onClick={this.deletePic}> Repetă
											Poza </Button> <Button size="lg" appearance="primary"
												onClick={() => this.savePicture()}> Continuă </Button>
									</div>
							}

							<Modal classNames={{
								overlay: 'modal-overlay',
								modal: 'modal-code',
								closeButton: 'modal-close-button',
							}} center open={this.state.showConfirmModal}
								   onClose={() => this.setState({showConfirmModal: false})}>
								<div style={{marginTop: "10px", display: "flex", flexDirection: "column"}}>


									<Button style={{marginTop: "20vh"}} size="lg" appearance="primary"
											onClick={this.savePicture}> Confirm </Button>

									<Button style={{marginTop: "10px"}} size="lg" appearance="ghost"
											onClick={() => this.setState({showConfirmModal: false})}> Cancel </Button>
								</div>
							</Modal>

							<Modal classNames={{
								overlay: 'modal-overlay',
								modal: 'modal-code',
								closeButton: 'modal-close-button',
							}} center open={this.state.showModal} onClose={() => this.setState({showModal: false})}>

								<h4 style={{marginTop: "15px", color: "#1E1C59"}}>Recomandari pentru a garanta ca te
									identificam in poze:</h4>

								<div style={{textAlign: "left", marginTop: "30px", color: "#1E1C59"}}>
									<div><Icon style={{color: "green", marginTop: "10px"}} icon='check-circle'
											   size="lg"/> Cuprinde cat mai bine fata ta
									</div>

									<div><Icon style={{color: "green", marginTop: "10px"}} icon='check-circle'
											   size="lg"/> Fara ochelari de soare sau de vedere
									</div>

									<div><Icon style={{color: "green", marginTop: "10px"}} icon='check-circle'
											   size="lg"/> Fara masca in timp ce faci selfie-ul
									</div>

									<div><Icon style={{color: "green", marginTop: "10px"}} icon='check-circle'
											   size="lg"/> Fara sapca sau palarie
									</div>

									<div><Icon style={{color: "green", marginTop: "10px"}} icon='check-circle'
											   size="lg"/> Sa nu vina lumina soarelui din spatele tau
									</div>
								</div>

								<Button style={{
									marginTop: "20px",
									width: "100%",
									backgroundColor: "#05AFF2",
									color: "white",
									borderRadius: "20px"
								}} size="lg" onClick={() => this.setState({showModal: false})}> Continua </Button>
							</Modal>
						</>
					</Fade>
			}
		</LoadingOverlay>
	}
}

export default Selfie
