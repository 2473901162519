export const eventType_to_eventName = {
	"ceremonie_absolvire": "Ceremonie Absolvire",
	"ceremonie_bal_absolvire": "Ceremonie & Bal",
	"bal_absolvire": "Bal Absolvire",
	"bal_boboci": "Bal Boboci",
	"revedere": "Revedere",
	"concert": "Concert",
	"festival": "Festival",
	"maraton": "Maraton",
	"conferinta": "Conferinta",
	"team_building": "Team Building",
	"corporate_party": "Petreceri Corporate",
	"revelion": "Revelion",
	"congress": "Congres",

	"Nunta": "Nunta",
	"Botez": "Botez",
	"Petrecere": "Petrecere",
}

export const eventName_to_eventType = {
	"Ceremonie Absolvire": "ceremonie_absolvire",
	"Ceremonie & Bal": "ceremonie_bal_absolvire",
	"Bal Absolvire": "bal_absolvire",
	"Bal Boboci": "bal_boboci",
	"Revedere": "revedere",
	"Concert": "concert",
	"Festival": "festival",
	"Maraton": "maraton",
	"Conferinta": "conferinta",
	"Team Building": "team_building",
	"Petreceri Corporate": "corporate_party",
	"Revelion": "revelion",
	"Congres": "congress",

	"Nunta": "Nunta",
	"Botez": "Botez",
	"Petrecere": "Petrecere",
}

export const locationTypes = {
	"ceremony": "Ceremonie",
	"ball": "Bal",
	"institution": "Institutie",
	"restaurant": "Restaurant",
	"concert": "Concert",
	"festival": "Festival",
	"marathon": "Maraton",
	"conference": "Conferinta",
	"teambuilding": "Team Building",
	"party": "Petrecere",
	"congress": "Congres"
}

export const seatingChartCanvasWidth = 1500;
export const seatingChartCanvasHeight = 800;
