import React from 'react'
import Modal from 'react-responsive-modal'
import './style.scss'
import {
	WhatsappShareButton,
	EmailShareButton
} from "react-share";
import whatsapp from './icons/whatsapp.svg'
import messenger from './icons/messenger.svg'
import gmail from './icons/gmail.svg'

class invitesModal extends React.Component {
	state = {
		isLoading: false,
		isDataLoaded: false,
		totalParticipants: 0,
		presentParticipants: 0
	}

	openFb = () => {
		window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(process.env.REACT_APP_PAGE + "invitation/" + this.props.id))
		//window.open('fb-messenger://share?link=' + encodeURIComponent(process.env.REACT_APP_PAGE + "invitation/" + this.props.id) + '&app_id=' + encodeURIComponent("1437009096459536"));
	}

	render() {
		let url = process.env.REACT_APP_PAGE + "invitation/" + this.props.id

		let title = `${this.props.mireasa} & ${this.props.mire}, avem placerea de a va invita la nunta noastra!`
		if (this.props.eventType === "Botez") {

			if(this.props.id === '43zDB') {
				title = `Va invit la botezul nostru, ${this.props.mire}`
			} else {
				title = `Va invit la botezul meu, ${this.props.mire}`
			}
		}
		if (this.props.eventType === "Petrecere") {
			title = `Va invit la petrecerea ${this.props.mire}`
		}

		return (
			<Modal classNames={{
				overlay: 'modal-overlay',
				modal: 'invites-modal',
				closeButton: 'modal-close-button',
			}} open={this.props.showModal} onClose={this.props.toggleModal} onOverlayClick={this.props.toggleModal} center>
				<div className="modal-content">
					<div id="invites" style={{ marginTop: "10vh" }}>
						<h5 style={{textAlign: "center"}}>Trimite Invitatia</h5>
						<div id="icons">
							<WhatsappShareButton title={title} separator=" " url={url} >
								<img id="whatsapp" src={whatsapp} alt="" />
							</WhatsappShareButton>

							<img onClick={this.openFb} id="messenger" src={messenger} alt="" />

							<EmailShareButton url={url}>
								<img id="messenger" src={gmail} alt="" />
							</EmailShareButton>
						</div>
					</div>
				</div>
			</Modal>
		)
	}
}

export default invitesModal;
