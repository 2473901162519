import React, {useState} from "react";
import './Walkthrough.scss'
import axios from "axios";

export default function WalkthroughOverlay({id_eveniment, history, children, callback= ()=>{}}) {

    const [visible, setVisible] = useState(true)

    const finishWalkthrough = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

      await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + id_eveniment,
            {
                walkthrough: true
            },
            auth)


        history.push('/organizer/adminEventPage/' + id_eveniment + '?refresh=3')
        callback()
        setVisible(false)
    }

    return (
        <>
            {
                visible ?
                    <div className={"walkthrough-overlay"}>

                        <div className={"content"}>
                            {children}
                        </div>

                        <div className={"skip-button"} onClick={() => finishWalkthrough()}>
                            Treci peste
                        </div>
                    </div>
                    : null
            }
        </>)
}