import React from 'react'
import axios from 'axios'
import PreviewInviteForExport from '../organizer/mainPages/ExportsPage/ForExports/PreviewInviteForExports'
import { toast } from 'react-toastify'


import {getWeddingParticipantsPackageNumber} from "../functions";
import {toPng} from "html-to-image";
import queryString from 'query-string';

let hasData = true;
let defaultChoice = "Invitatie";
const inviteRef = React.createRef();
const saveTheDateRef = React.createRef();
const seatingChartRef = React.createRef();


class ExportInviteJpg extends React.Component {

    urlParams = queryString.parse(this.props.location.search)

    state = {
        event_data: [],
        responses_data: [],
        eventName: "",

        downloadChoice: defaultChoice,
        downloadOptions: ["Task-uri", "Cadouri", "Daruri", "Programul zilei", "Participanti la mese",
            "Toate Raspunsurile", "Participanti Confirmati", "Invitatie", "SaveTheDate", "Plan mese"],

        downloadOptionsIcons: [false,false,false,false,false,false,false,false,false,false, false],

        tasks: [],
        gifts: [],
        responses: [],
        yesResponses: [],
        tutorialModal: false,

        seatingData: [],
        tableData: [],

        pdfInvitePreviewDataUrl: '',
        pdfSaveTheDatePreviewDataUrl: '',
        pdfSeatingChartPreviewDataUrl: '',

        loading: true,
        loadingData: false,

        loadingType: ''
    }

    componentDidMount = () => {
        this.getEventData("")
    }

    getEventData = async (choice) => {
        let auth = {

        }

        // get event data
        let event_info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id, auth);
        // console.log(event_info);


        //Edit an additional invite
        if (this.props.match.params.additional_invite_id) {
            let additionalInvite = event_info.data.additional_invites.find((item) => item.id === parseInt(this.props.match.params.additional_invite_id + ''))

            if(additionalInvite) {
                event_info.data.invite = additionalInvite
            }
        }

        if (choice === "Invitatie") {
            if(!this.state.event_data.invite) {
                toast.info("Invitatia nu este creata")
                hasData = false
            } else {

                if (!event_info.data.invitatieInfo) {
                    toast.info("Invitatia nu este creata")
                    hasData = false
                } else {
                    if (event_info.data.invite.inviteType === "Video") {
                        toast.info("Invitatia video nu se poate printa")
                        hasData = false
                    }
                }
            }

            if (window.flutter_inappwebview) {
                toast.info("Descarcarea de pdf-uri nu este disponibila momentan in aplicatie, va rugam intrati din browser")
                hasData = false
            }
        }

        if (choice === "SaveTheDate") {
            if(!this.state.event_data.saveTheDate) {
                toast.info("SaveTheDate nu este creata")
                hasData = false
            } else {
                if (!event_info.data.saveTheDateInfo) {
                    toast.info("Save the date nu este creata")
                    hasData = false
                } else {
                    if (event_info.data.saveTheDate.saveTheDateType === "Video") {
                        toast.info("SaveThedate video nu se poate printa")
                        hasData = false
                    }
                }
            }

            if (window.flutter_inappwebview) {
                toast.info("Descarcarea de pdf-uri nu este disponibila momentan in aplicatie, va rugam intrati din browser")
                hasData = false
            }
        }


        this.setState({
            event_data: event_info.data,
            eventName: event_info.data.eventType === "Nunta" ? `Nunta_${event_info.data.prenumeMire}-${event_info.data.prenumeMireasa} ` : `Eveniment_${event_info.data.prenumeMire}`,
            tutorialModal: !event_info.data.tutorials || (event_info.data.tutorials && !event_info.data.tutorials.exports),
            loading: false
        })



        setTimeout(this.exportInviteImage, 2000)




        //seating data
        let tableData = []
        if (event_info.data.isSeatingChartDone) {
            tableData = event_info.data.seatingChart.positions.filter(elem => elem.type.endsWith("Table"))
        } else {
            if (choice === "Participanti la mese" || choice === "Plan mese") {
                toast.info("Planul de mese nu a fost publicat")
                hasData = false
            }
        }

        if (window.flutter_inappwebview && choice === "Plan mese") {
            toast.info("Descarcarea de pdf-uri nu este disponibila momentan in aplicatie, va rugam intrati din browser")
            hasData = false
        }

        let maxPeopleAtTable = 0
        let i = 0

        //get highest number of people at a table
        for (i = 0; i < tableData.length; i++) {
            if (tableData[i].peopleAtTable.length > 0) {
                let total = 0

                for (let j = 0; j < tableData[i].peopleAtTable.length; j++) {
                    total += tableData[i].peopleAtTable[j].nrPeople
                }

                if (maxPeopleAtTable < total) {
                    maxPeopleAtTable = total
                }
            }
        }

        let seatingData = []

        //create empty array
        for (i = 0; i < maxPeopleAtTable; i++) {
            let newData = {}

            for (let j = 0; j < tableData.length; j++) {
                newData["masa" + j] = "-"
            }

            seatingData.push(newData)
        }

        //add valid data
        for (i = 0; i < tableData.length; i++) {
            if (tableData[i].peopleAtTable.length > 0) {
                let seatingIndex = 0

                for (let j = 0; j < tableData[i].peopleAtTable.length; j++) {
                    let info = tableData[i].peopleAtTable[j]

                    seatingData[seatingIndex]["masa" + i] = info.prenume
                    seatingIndex++

                    if (info.kidsCount !== null) {
                        for (let k = 0; k < Number(info.kidsCount); k++) {
                            seatingData[seatingIndex]["masa" + i] = "copil - " + info.prenume
                            seatingIndex++
                        }
                    }

                    if (seatingIndex === info.nrPeople && info.namePartner !== "") {
                        seatingData[seatingIndex]["masa" + i] = info.namePartner
                        seatingIndex++
                    }
                }
            }
        }

        this.setState({
            seatingData: seatingData,
            tableData: tableData,
            loading: false
        })
    }

     exportInviteImage = async () => {
        const element = inviteRef.current;

        await toPng(element, { cacheBust: true, skipAutoScale: false })
            .then((dataUrl) => {
                this.setState({
                    pdfInvitePreviewDataUrl: dataUrl
                })
                this.downloadURI(dataUrl, 'invitatie_'+this.state.event_data.id_eveniment+'.png')
            })
            .catch((err) => {
                console.log(err)
            })
    }

    getTasksFromServer = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let tasksInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'tasks/list/byEvent/' + this.props.match.params.id, auth);
        // console.log(tasksInfo);

        let tasks = tasksInfo.data.map((elem, index) => {
            elem.index = index + 1

            return elem
        })

        if (tasks.length === 0) {
            toast.info("Nu aveti task-uri create.")
            hasData = false
        }

        this.setState({
            tasks: tasks
        })
    }

    getGiftsFromServer = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let giftsInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'gifts/list/byEvent/' + this.props.match.params.id, auth);
        // console.log(giftsInfo);

        let gifts = giftsInfo.data.map((elem, index) => {
            elem.index = index + 1

            return elem
        })

        if (gifts.length === 0) {
            toast.info("Nu aveti cadouri adaugate.")
            hasData = false
        }

        this.setState({
            gifts: gifts
        })
    }

    getResponsesFromServer = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let responsesInfo = await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
        // console.log(responsesInfo);

        this.setState({
            responses_data: responsesInfo.data.responses
        })

        if (responsesInfo.data.responses.length === 0) {
            toast.info("Nu aveti raspunsuri primite.")
            hasData = false
        }

        let responses = responsesInfo.data.responses.map((elem, index) => {
            let newVal = { ...elem.response }
            newVal.index = index + 1

            let newGiftMoneyValue = responsesInfo.data.responses[index].giftMoney
            if (newGiftMoneyValue === null) {
                newGiftMoneyValue = 0
            }
            newVal.giftMoney = newGiftMoneyValue

            return newVal
        })

        //get only yes answers
        let yesResponses = responses.filter(elem => elem.answer === "yes")

        this.setState({
            responses: responses,
            yesResponses: yesResponses
        })
    }

    changeDownloadChoice = async (choice) => {

        if((getWeddingParticipantsPackageNumber(this.state.event_data) === 5) &&
            (choice === 'Invitatie' || choice !== 'SaveTheDate')) {
            toast.warn('Această funcționalitate nu este disponibilă pentru pachetul curent!')
            return;
        }

        if((getWeddingParticipantsPackageNumber(this.state.event_data) !== 2 && getWeddingParticipantsPackageNumber(this.state.event_data) !== 4) &&
            (choice !== 'Invitatie' && choice !== 'SaveTheDate' && choice !== "Participanti la mese" && choice !== "Toate Raspunsurile")) {
            toast.warn('Această funcționalitate este disponibilă doar pentru pachetul Premium')
            return;
        }

        if(window.alityAppView) {
            toast.warn('Această funcționalitate nu este disponibilă momentan pe dispozitivele mobile. Vă rugăm folosiți un laptop / desktop.')
            return;
        }




        this.setState({
            loadingData: true
        })

        hasData = true;

        //load data
        if (choice === "Task-uri") {
            await this.getTasksFromServer()
        } else if (choice === "Cadouri") {
            await this.getGiftsFromServer()
        } else if (choice === "Toate Raspunsuri" || choice === "Participanti Confirmati" || choice === "Daruri") {
            await this.getResponsesFromServer()
        } else {
            await this.getEventData(choice)
        }
        if (choice === "Invitatie") {



        }
        if (choice === "SaveTheDate") {

            const element = saveTheDateRef.current;
            await toPng(element, { cacheBust: false, skipAutoScale: true })
                .then((dataUrl) => {
                    this.setState({
                        pdfSaveTheDatePreviewDataUrl: dataUrl
                    })
                })
                .catch((err) => {
                    console.log(err)
                })

        }
        if (choice === "Plan mese") {
            const element = seatingChartRef.current;

            await toPng(element, { cacheBust: false, skipAutoScale: true })
                .then((dataUrl) => {
                    this.setState({
                        pdfSeatingChartPreviewDataUrl: dataUrl
                    })
                })
                .catch((err) => {
                    console.log(err)
                })
        }

        //change choice & stop loading
        let newChoice = choice

        if (!hasData) {
            newChoice = defaultChoice
        }

        this.setState({
            downloadChoice: newChoice,
            loadingData: false
        })
    }

    downloadURI = (uri, name) => {
        let link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getImageWidth = () => {

        let defaultWidth = '700px'

        if(this.urlParams && this.urlParams.size) {
            let sizeValue = parseInt(this.urlParams.size+'')

            if(sizeValue === 1) {
                return '500px'
            } else if(sizeValue === 2) {
                return '600px'
            } if(sizeValue === 3) {
                return defaultWidth
            }
        } else {
            return defaultWidth
        }

    }

    render() {
        if (this.state.loading) {
            return <div className="Exports-container">

            </div>
        }

        return <div className="Exports-container">
            <>
                <div style={{position:'absolute', display:"none", width:'100vw', height:'100%', minHeight:'100vh', backgroundColor:'#ffffff', zIndex:1000}}>
                    Vă rugăm așteptați, descărcarea va începe imediat.
                </div>

                <div style={{position: 'absolute', width: this.getImageWidth()}}
                     className={"export-invite-cont"}>
                    <div ref={inviteRef} id={"inviteID"} style={{height: "100%", width: "100%"}}>
                        {
                            this.state.event_data.invite ?
                                <PreviewInviteForExport event_data={this.state.event_data}
                                                        ref={el => (this.refInvitatie = el)}/>
                                : null
                        }
                    </div>
                </div>




                <div className="Exports-contents">
                    <div className="Title">Exporturi</div>


                    {/* {downloadChoice} */}
                </div>


            </>
        </div>
    }
}

export default ExportInviteJpg;
