import React from 'react'
import './AddAlbum.scss'
import HeaderWhite from '../../components/HeaderWhite'
import { Uploader, Button, Icon } from 'rsuite'
import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import { toast } from 'react-toastify'
import LoadingModal from './LoadingModal/LoadingModal'
import CustomInput from '../components/custom/CustomInput'
import {EditPicture, getBase64FromImage, resizeBlob, textarea_max_length} from '../components/Helper'
import Modal from "react-responsive-modal";

class AddAlbum extends React.Component {
	state = {
		numeAlbum: "",
		description: "",
		pozaAlbum: null,
		linkPozeAlbum: "",
		base64ImageLink: "",
		tutorialModal: false,
		eventId: "",
		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		// let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);
		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWeddingId/' + this.props.match.params.id, auth)
			.then(response => {
				this.setState({
					eventId: response.data,
					tutorialModal: !response.data.tutorials || (response.data.tutorials && !response.data.tutorials.albumsAdd),
					loading: false
				})
			})
			.catch(err => {
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rugam sa va autentificati")
				this.props.history.push('/organizer/login');
			})

		// this.setState({
		// 	eventId: info.data.id,
		// 	loading: false
		// })
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				albumsAdd: true
			},
			auth)

	}

	makeid(length) {
		var result = '';
		var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	handleChange = (value, event) => {
		this.setState({
			[event.target.name]: value
		})
	}

	changeAlbumDescription = (value) => {
		if (this.state.description.length > textarea_max_length - 1 && value.length > textarea_max_length - 1) {
			return
		}

		this.setState({
			description: value
		})
	}

	createAlbum = async () => {
		if (this.state.numeAlbum === "") {
			toast.error("Nu ati completat titlul")
			return;
		}

		this.setState({
			loading: true
		})

		let obj = {
			user: localStorage.getItem('id'),
			event: this.state.eventId,
			numeAlbum: this.state.numeAlbum,
			descriereAlbum: this.state.description,
			linkPozeAlbum: this.state.linkPozeAlbum,
			imageLink: this.state.base64ImageLink,
		}

		/*
		let formData = new FormData()
		formData.append("data", JSON.stringify(obj))

		if (this.state.pozaAlbum !== null) {
			formData.append("files.image", this.state.pozaAlbum)
		}
*/
		let auth = {
			headers: {
				//"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'albums/create', obj, auth)
			.then(async response => {
				toast.success("Album creat cu succes!")

				this.setState({
					loading: false
				})

				this.props.history.push('/organizer/viewAlbums/' + this.props.match.params.id)
			})
			.catch(err => {
				console.log(err.message);
				toast.error("Eroare")
			})
	}

	handlePoza = async (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				pozaAlbum: null
			})
			return
		}

		let blob = await resizeBlob(fileList[0].blobFile)

		this.setState({
			pozaAlbum: blob,
		})
	}

	saveCroppedImage = async (croppedImg) => {
		let img = {}
		img.blobFile = croppedImg
		let data = await getBase64FromImage(img)

		this.setState({
			croppedImage: croppedImg,
			base64ImageLink: data
		})
	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		if (this.state.loading) {
			return <div className="AddAlbum-container">
				<HeaderWhite goBack={this.goBack} />

				<div className="AddAlbum-contents"></div>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="AddAlbum-container">
				<HeaderWhite goBack={this.goBack} />

				<LoadingModal loading={this.state.loading} />

				<div className="AddAlbum-contents">
					<h4 id="title"> Adauga Album </h4>

					<div className="input-zone">
						<CustomInput value={this.state.numeAlbum} onChange={value => this.setState({ numeAlbum: value })} placeholder={"Nume Album"}
							style={{ margin: "10px 0px" }} icon={<Icon icon="text-height" />} />

						<CustomInput value={this.state.description} onChange={this.changeAlbumDescription} placeholder={`Descriere Album ${this.state.description.length}/${textarea_max_length}`}
							style={{ margin: "10px 0px" }} componentClass={"textarea"} icon={<Icon icon="pencil" />} />

						<div className="Upload-zone">
							<div style={{ textAlign: "left" }}> Adauga poza pt coperta album </div>

							<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }}
								autoUpload={false} multiple={false} disabled={this.state.pozaAlbum !== null}>
								<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text"> Click aici pentru a incarca fotografii </div>
							</Uploader>
						</div>

						{this.state.pozaAlbum !== null && <EditPicture imageLink={URL.createObjectURL(this.state.pozaAlbum)} saveCroppedImage={this.saveCroppedImage} />}

						<div style={{ textAlign: "left" }}>Albumul va fi vizibil pentru participanti doar daca ati adaugat link de drive</div>
						<CustomInput value={this.state.linkPozeAlbum} onChange={value => this.setState({ linkPozeAlbum: value })} placeholder={"Link catre poze album"}
							style={{ margin: "10px 0px" }} icon={<Icon icon="link" />} />

						<div className="Footer">
							<Button onClick={this.createAlbum} className="add-button" style={{ backgroundColor: "#00afcc", color: "white" }}>Adauga Album</Button>
						</div>
					</div>
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Prin apăsarea butonului ,Adaugă album” vei crea un album și vei putea sa distribui pozele si filmarile de la evenimentul tău. Tot ce ai de făcut este sa adaugi linkul de drive unde ai salvate toate fișierele media primite de la echipa foto&video. Astfel toți participanții la eveniment, care si-au facut cont, vor primi o notificare și vor putea să să descarce fișierele media dorite.
						</p>
						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default AddAlbum;