import React, {lazy, Suspense} from 'react'
import Loader from "../../../organizer/components/Loader";

const IntroScreenTemplateRender = lazy(() => import("./IntroScreenTemplateRender"))

export default function IntroScreenTemplateRenderLazy(
    {
        templateDetails,
        showIntroScreen,
        weddingData,
        mode,
        toggleModalIntro = (val) => {
        },
        openInvitation = () => {},
        goToAnswer = () => {},
        goToLocations = () => {},
        showResponseButton,
        goToInvitation = () => {}
    }
) {

    return <Suspense fallback={<div style={{padding: "30px 0"}}><Loader/></div>}>

        <IntroScreenTemplateRender
            templateDetails={templateDetails}
            weddingData={weddingData}
            mode={mode}
            toggleModalIntro={toggleModalIntro}
            openInvitation={openInvitation}
            goToAnswer={goToAnswer}
            goToLocations={goToLocations}
            showResponseButton={showResponseButton}
            goToInvitation={goToInvitation}
            showIntroScreen={showIntroScreen}
        />

    </Suspense>

}
