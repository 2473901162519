import React from 'react'
import './AlbumsTab.scss'
import axios from 'axios'
import AdModal from '../../components/AdModal';

let adFieldName = "albums"

class AlbumsTab extends React.Component {
	state = {
		images: [],
		previewImg: [],
		preview: false,

		loading: true,

		linkPoze: "",

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}

	componentDidMount = async () => {
		//in case user didn't buy ticket in current data, reload event data
		if (!this.props.isTicketPaid) {
			this.props.reloadEventData()
		}

		this.setState({
			loading: false,
		})

		var link = this.props.event_data.link_poze_public

		if (link !== null && link !== "") {
			this.setState({
				linkPoze: link,
			})
		}

		if (this.props.participantData !== null && this.props.event_data.albums && "" + this.props.event_data.albums.length !== this.props.participantData.last_link_poze_public) {
			//update local data
			this.props.participantData.last_link_poze_public = "" + this.props.event_data.albums.length

			//update server data
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			let obj = {
				last_link_poze_public: this.props.participantData.last_link_poze_public,
			}

			axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
		}
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLinkAlbums', link)
		} else {
			window.open(link)
		}
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		if (this.state.showAdModal) {
			return <div className="FeedTab-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		if (this.state.loading) {
			return (
				<div className="FeedTab-container">
					<div className="FeedTab-contents">
						<p>Verificare achizitionare bilet</p>
					</div>
				</div>
			)
		}

		return (
			<div className="AlbumsTab-container">
				<div className="AlbumsTab-content">
					{this.props.isTicketPaid || !this.props.event_data.tickets_needed
						? <div>
							{this.state.linkPoze === ""
								? this.props.event_data.albums
									? <div>{this.props.event_data.albums.map((album, index) =>
										<div key={index} className="link" onClick={() => this.openLink(album.link)}>{album.title}</div>
									)}</div>
									: <div> Vor fi disponibile dupa eveniment </div>
								: <div className="link" onClick={() => this.openLink(this.state.linkPoze)}>Acceseaza poze eveniment</div>}
						</div>
						: <p>Va rog achizitionati bilet petru a vedea albumul</p>
					}
				</div>
			</div>
		)
	}
}

export default AlbumsTab;