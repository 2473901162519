import React, {useEffect, useState} from 'react'
import HeaderWhite from '../../components/HeaderWhite'
import NavbarColor from '../../components/NavbarColor'
import './GuestUnsubscribe.scss'
import axios from "axios";
import {useHistory, useParams} from "react-router-dom";
import Loader from "../../components/Loader";

export default function GuestUnsubscribe() {

    let history = useHistory()
    let {id} = useParams()
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        unsubscribe()
        // eslint-disable-next-line
    }, [])

    const unsubscribe = async () => {


        let data = {
            id: id
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'responses/guestUnsubscribe', data)
            .then(response => {

            })
            .catch(err => {

            })

        setLoading(false)
    }




    return (

        loading ?
            <div className="GuestUnsubscribe-container">
                <HeaderWhite shadow={true}/>
                <div className={"GuestUnsubscribe-contents"}>
                    <Loader/>
                </div>
                <NavbarColor history={history}/>
            </div>
            :
            <div className="GuestUnsubscribe-container">
                <HeaderWhite shadow={true}/>

                <div className={"GuestUnsubscribe-contents"}>
                    <div className={"questions-form"}>
                        <h3>Te-ai dezabonat!</h3>
                    </div>

                </div>
                <NavbarColor history={history}/>
            </div>
    )

}

