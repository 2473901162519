import React from 'react'
import {Button, Checkbox, Icon} from 'rsuite'
import './ResetPass.scss'
import { toast } from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../../components/custom/CustomInput'
import TermsModal from "../../../SignUp/Modals/terms";
import ConfidModal from "../../../SignUp/Modals/confid";
import {injectIntl} from 'react-intl';
class ResetPass extends React.Component {
	state = {
		newPass1: "",
		newPass2: "",
		checkedTerms: false,
		checkedConfid: false,
		showTermsModal: false,
		showConfidModal: false

	}

	componentDidMount = async () => {
		this.setState({
			newPass1: "",
			newPass2: ""
		})
	}

	setNewPass1 = (value) => {
		this.setState({ newPass1: value });
	}

	setNewPass2 = (value) => {
		this.setState({ newPass2: value });
	}

	checkNewPassword = async () => {

		//verificare daca parola noua este corecta
		const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

		if (!passRegex.test(this.state.newPass1)) {
			toast.error("Parola este invalida")
			return
		}

		//verificare daca parola noua este identica in ambele parti
		if (this.state.newPass1 !== this.state.newPass2) {
			toast.error("Parolele nu sunt identice")
			return
		}

		if(!this.state.checkedTerms) {
			toast.error("Pentru a continua trebuie să fiți de acord cu termenii și condițiile de utilizare.")
			return
		}

		if(!this.state.checkedConfid) {
			toast.error("Pentru a continua trebuie să fiți de acord cu politica de confidențialitate.")
			return
		}

		//trimite modificarile pe server
		//get code
		let obj = {
			email: this.props.email,
			sendEmail: false,
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'auth/forgot-password', obj)
			.then((response) => {
				let obj2 = {
					password: this.state.newPass1,
					passwordConfirmation: this.state.newPass2,
					code: response.data.code
				}

				axios.post(process.env.REACT_APP_DOMAIN + 'auth/reset-password', obj2)
					.then(_ => {
						toast.success("Parola schimbata")
					})
					.catch(_ => {
						toast.error("S-a produs o erorare va rog reincercati")
					})
			})
			.catch(_ => {
				toast.error("S-a produs o erorare va rog reincercati")
			})

		let eventId = localStorage.getItem("eventId")
		let forceReset = localStorage.removeItem("changePassword")

		if (eventId !== null) {
			localStorage.removeItem("eventId")
			localStorage.removeItem("changePassword")

			this.props.history.push('/eventPage/' + eventId);
			return
		} else {
			if(forceReset !== null) {
				localStorage.removeItem("changePassword")
				this.props.history.push('/events/');
				return;
			}
		}

		//reset state and close modal
		this.resetState()
	}

	resetState = () => {
		this.setState({
			newPass1: "",
			newPass2: ""
		})

		this.closeModal()
	}

	closeModal = () => {
		if (this.props.forceReset) {
			toast.error("Trebuie să vă setați o parolă pentru a putea continua!")
			return
		}

		this.props.closeModal()
	}

	changeCheckedTermsState = () => {
		let state = this.state.checkedTerms

		this.setState({
			checkedTerms: !state
		})
	}

	changeCheckedConfidState = () => {
		let state = this.state.checkedConfid

		this.setState({
			checkedConfid: !state
		})
	}

	toggleTermsModal = () => {
		let current = this.state.showTermsModal;
		this.setState({
			showTermsModal: !current
		})
	}

	toggleConfidModal = () => {
		let current = this.state.showConfidModal;

		this.setState({
			showConfidModal: !current
		})
	}

	render() {
		const styles = {
			fontFamily: "sans-serif",
			textAlign: "center",
			marginTop: "20px",
			width: "90vw",
			height: "100%",
		};

		return (
			<div style={styles}>

				<TermsModal toggleModal={this.toggleTermsModal} showModal={this.state.showTermsModal} pb={true} />

				<ConfidModal toggleModal={this.toggleConfidModal} showModal={this.state.showConfidModal} pb={true} />


				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-profile',
						closeButton: 'modal-close-button',
					}}
					open={this.props.showModal}
					onClose={this.resetState}
				>
					<div className={"reset-pass-modal"}>
						{
							this.props.forceReset ?
								<>
								<h4>{this.props.intl.formatMessage({id: 'profile.reset_password.new_account.title1'})}</h4>
									<h5>{this.props.intl.formatMessage({id: 'profile.reset_password.new_account.title2'})}</h5>
									<br/>
								</>
								:
								<h4>{this.props.intl.formatMessage({id: 'profile.reset_password.title'})}</h4>
						}

						<p>{this.props.intl.formatMessage({id: 'profile.reset_password.desc'})}</p>

						<CustomInput style={{ margin: "13px 0px 10px 0" }} inputType={"password"} value={this.state.newPass1} onChange={this.setNewPass1}
							placeholder={this.props.intl.formatMessage({id: 'profile.reset_password.new_password'})} icon={<Icon icon="key" />} regex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/} />

						<CustomInput style={{ margin: "10px 0px" }} inputType={"password"} value={this.state.newPass2} onChange={this.setNewPass2}
							placeholder={this.props.intl.formatMessage({id: 'profile.reset_password.confirm_new_password'})} icon={<Icon icon="key" />} regex={/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/} />

							{this.state.newPass1.length > 7 && this.state.newPass2.length > 7 && this.state.newPass1 !== this.state.newPass2 && <div style={{ color: "red" }}>
								{this.props.intl.formatMessage({id: 'profile.reset_password.passwords_not_match'})}
							</div>}


						<div className="checkbox-content">
							<Checkbox onChange={this.changeCheckedTermsState} checked={this.state.checkedTerms} />

							<p>{this.props.intl.formatMessage({id: 'profile.reset_password.i_agree_with'})} <span onClick={this.toggleTermsModal} style={{ textDecoration: "underline" }}>{this.props.intl.formatMessage({id: 'profile.reset_password.terms'})}</span> </p>
						</div>

						<div className="checkbox-content" >
							<Checkbox onChange={this.changeCheckedConfidState} checked={this.state.checkedConfid} />

							<p>{this.props.intl.formatMessage({id: 'profile.reset_password.i_agree_with'})} <span onClick={this.toggleConfidModal} style={{ textDecoration: "underline" }}>{this.props.intl.formatMessage({id: 'profile.reset_password.privacy'})}</span> </p>
						</div>

						<Button onClick={this.checkNewPassword} color="green" style={{ backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px" }}>{this.props.intl.formatMessage({id: 'general.confirm'})}</Button>
					</div>
				</Modal>
			</div>
		)
	}
}

export default injectIntl(ResetPass)
