import io from 'socket.io-client';
import React from 'react'
import './FeedTab.scss'
import {Input, Icon, Uploader, Button} from 'rsuite'
import axios from 'axios'
import fileDownload from 'js-file-download'
import {toast} from 'react-toastify';
import FeedBox from './FeedComponents/FeedBox';
import Modal from "react-responsive-modal";
import {getBase64FromImage} from "../../../components/Helper";
import Loader from "../../../components/Loader";

var b64toBlobAndBack = require('based-blob');

let socket = ""

class FeedTab extends React.Component {
    state = {
        attachedImg: null,
        text: "",
        posts: [],
        loading: true,
        userId: -1,
        room: "",
        event_name: "",
        response_answer: "yes",

        showImage: false,
        imageToShow: 0,
        inAppWebView: false
    }

    componentDidMount = async () => {
        socket = io(`https://${process.env.REACT_APP_IMAGE_DOMAIN}`);

        //check if it's participating
        try {
            if (this.props.response_data.response.answer !== "yes") {
                this.setState({
                    loading: false,
                    response_answer: "no"
                })

                return
            }
        } catch (err) {
            console.log(err);

            this.setState({
                loading: false,
                response_answer: "no"
            })

            return
        }

        let user_id = Number(localStorage.getItem("id"))
        let wedding_id = this.props.event_id

        let obj = {
            id_eveniment: wedding_id,
            wedding: true
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let info = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.event_id, obj, auth);
        let room = info.data.id

        socket.emit('join', {user_id, room}, (error) => {
            if (error) {
                alert(error);
            } else {
                alert('success join')
            }
        });

        socket.on('message', (message, error) => {
            let newPost = {}
            newPost.id = message.id
            newPost.user = message.user
            newPost.first_name = message.user.first_name
            newPost.last_name = message.user.last_name
            newPost.link_imagine = message.link_imagine
            newPost.mesaj = message.mesaj
            newPost.timestamp = message.timestamp

            this.setState({
                posts: [...this.state.posts, newPost]
            })
        });

        //Reload messages
        let response = await axios.get(process.env.REACT_APP_DOMAIN + "weddings/getMessages/" + info.data.id, auth)
        if (response !== null) {
            this.setState({
                posts: response.data
            })
        }

        this.setState({
            loading: false,
            inAppWebView: window.flutter_inappwebview || window.alityAppView,
            room: room,
            userId: user_id,
            event_name: info.data.event_type === "Nunta" ? `Nunta-${info.data.prenumeMire}-${info.data.prenumeMireaza}` : `Botez-${info.data.prenumeMire}`,
            response_answer: "yes"
        })
    }

    changeMessage = (value) => {
        this.setState({
            text: value
        })
    }

    uploadToS3 = async () => {
        /*
        var obj = new FormData()
        obj.append("image", this.state.attachedImg)
        obj.append("nume_institutie", this.state.event_name)
        obj.append("promotie", new Date().getFullYear())
         */

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt'),
                //'Content-Type': 'multipart/form-data'
            }
        }

        let obj = {
            imageLink: await getBase64FromImage(this.state.previewImg[0]),
            nume_institutie: this.state.event_name,
            promotie: new Date().getFullYear()
        }

        axios.post(process.env.REACT_APP_DOMAIN + "uploadFeedImage", obj, auth)
            .then(res => {
                this.sendFeedWithLink(res.data.Location);
            })
    }

    sendFeedWithLink = async (link) => {
        let message = this.state.text


        if(message.length === 0 && link.length === 0) {
            return
        }

        socket.emit('sendMessage', {
            userId: this.state.userId,
            message,
            room: this.state.room,
            link_imagine: link,
            wedding: true
        }, (error) => {
            if (error) {
                alert(error)
            }
        });

        this.setState({
            attachedImg: null,
            previewImg: [],
            text: "",
        })
    }

    sendFeed = async () => {
        if (this.state.attachedImg !== null) {
            this.uploadToS3()
            return
        }

        this.sendFeedWithLink("")
    }

    addedFile = async (file) => {
        if (file.length === 0) {
            return
        }

        var type = file[0].blobFile.type

        if (type !== "image/jpeg" && type !== "image/jpg" && type !== "image/png") {
            toast.error("Tip fisier gresit")
            this.setState({
                attachedImg: null,
                previewImg: []
            })
            return
        }

        if (file[0] !== undefined) {
            this.setState({
                attachedImg: file[0].blobFile,
                previewImg: file
            })
        } else {
            this.setState({
                attachedImg: null,
                previewImg: []
            })
        }
    }

    setShowImage = (index) => {
        this.setState({
            showImage: true,
            imageToShow: index
        })
    }

    closeImage = () => {
        this.setState({
            showImage: false
        })
    }

    downloadImage = () => {
        var url = this.state.posts[this.state.imageToShow].link_imagine;
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('download_image', 'down_img', url).then(res => {
                if (res === "fail") {
                    toast.error("Descarcare esuata")
                } else {
                    toast.success("Imagine descarcata")
                }
            })
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'downloadMedia',
                url: url,
            }))
        } else {
            var parts = url.split("/")
            var name = parts[parts.length - 1]

            axios.get(url, {
                responseType: 'blob',
            })
                .then((res) => {
                    fileDownload(res.data, name)
                })
        }
    }

    // b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    // 	const byteCharacters = atob(b64Data);
    // 	const byteArrays = [];

    // 	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    // 		const slice = byteCharacters.slice(offset, offset + sliceSize);

    // 		const byteNumbers = new Array(slice.length);
    // 		for (let i = 0; i < slice.length; i++) {
    // 			byteNumbers[i] = slice.charCodeAt(i);
    // 		}

    // 		const byteArray = new Uint8Array(byteNumbers);
    // 		byteArrays.push(byteArray);
    // 	}

    // 	const blob = new Blob(byteArrays, { type: contentType });
    // 	return blob;
    // }

    imageUpload = () => {
        window.flutter_inappwebview.callHandler('addPicture', 'feedPicture').then(async res => {
            if (res === "NULL") {
                return
            }
            // toast.success(atob(res).length)//get image length
            // let img = `data:image/jpeg;base64,${res}`
            // let blobFile = URL.createObjectURL(res)
            // toast(this.b64toBlob(res, "image/jpeg"));

            var contentType = "image/png"

            this.setState({
                // attachedImg: blobFile
                // attachedImg: this.b64toBlob(res, "image/jpeg")
                // attachedImg: res.blobFile
                // attachedImg: `data:image/jpeg;base64,${Buffer.from(res, "base64").toString()}`

                attachedImg: b64toBlobAndBack.toBlob(res, contentType)

                // attachedImg: URL.createObjectURL(this.b64toBlob(res, "image/jpeg"))
            })
        })
    }

    goBack = () => {
        this.props.history.go(-1)
    }

    render() {
        if (this.state.loading) {
            return (
                <div className="FeedTab-container">
                    <Loader/>
                </div>
            )
        }

        if (this.state.response_answer === "no") {
            return (
                <div className="FeedTab-container">
                    <div className="FeedTab-contents">
                        <p>Trebuie să vă autentificați pentru a accesa această secțiune!</p>
                    </div>
                </div>
            )
        }

        return (
            <div className="FeedTab-container">
                <div className="FeedTab-contents">
                    <div className="FeedTab-Feed">
                        <FeedBox posts={this.state.posts} userId={this.state.userId} setShowImage={this.setShowImage}/>
                    </div>

                    <div className="FeedTab-Input">
                        <Uploader disabled={(this.state.attachedImg !== null) ? true : null}
                                  className="uploader" autoUpload={false} onChange={(file) => this.addedFile(file)}
                                  multiple={false} listType="picture" value={this.state.previewImg}
                                  fileList={this.state.previewImg}
                                  onRemove={(file) => this.setState({attachedImg: null, previewImg: []})}>
                            <button>
                                <Icon icon='camera-retro' size="lg"/>
                            </button>
                        </Uploader>


                        <Input style={{margin: "0px 10px"}} className="my-input" onChange={this.changeMessage}
                               value={this.state.text} placeholder={"Scrie un mesaj!"}/>

                        <Icon icon="send" size="lg" onClick={this.sendFeed}/>
                    </div>

                    <Modal classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-code',
                        closeButton: 'modal-close-button',
                    }} center open={this.state.showImage} onClose={this.closeImage}>
                        <img
                            style={window.innerWidth > window.innerHeight ? {
                                width: "60vh",
                                height: "60vh"
                            } : {width: "80vw", height: "80vw"}} alt="ClickedImage"
                            src={this.state.posts[this.state.imageToShow] !== undefined ? this.state.posts[this.state.imageToShow].link_imagine : ""}
                        />

                        <div style={{marginTop: "30px", display: 'flex', justifyContent: 'space-around'}}>
                            {
                                window.flutter_inappwebview || window.alityAppView ?
                                    <Button onClick={this.downloadImage} color="green">Download</Button>
                                    : null
                            }
                            <Button onClick={this.closeImage} color="red">Inapoi</Button>
                        </div>
                    </Modal>
                </div>
            </div>
        )
    }
}

export default FeedTab;
