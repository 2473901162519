import React from 'react'
import 'react-add-to-calendar/dist/react-add-to-calendar.css'
import { getUrlFromImage, LoadingScreen } from '../../../components/Helper'
import { GiChurch, GiFamilyHouse } from 'react-icons/gi';
import { ImLocation } from 'react-icons/im';
import { IoMdRestaurant } from 'react-icons/io'
import InviteTemplateRenderLazy from "../../../../components/inviteTemplates/InviteTemplateRenderLazy";

class PreviewInviteForExport extends React.Component {
	state = {
		eventType: "",
		inviteType: "",
		inviteContents: [],
		current_event: [],
		locations: [],
		loading: true
	}

	componentDidMount = async () => {

		let event_info = this.props.event_data
		let invite = event_info.invite
		this.setState({
			inviteType: invite.inviteType,
			inviteContents: invite,
			locations: event_info.locatii,
			current_event: event_info,
			eventType: event_info.eventType,
			loading: false
		})
	}

	render() {
		if (this.state.loading) {
			return <LoadingScreen />
		}

		if (this.state.inviteContents && this.state.inviteContents.templateDetails && this.state.inviteContents.templateDetails.id) {
			return (<div style={{textAlign:'center'}}>

					<div className={"invite-container"}>
						<InviteTemplateRenderLazy
							templateDetails={this.state.inviteContents.templateDetails}
							weddingData={this.state.current_event}
							locations={this.state.current_event.locatii}
							audioLink={''}
							image1Link={this.state.current_event.invite.image1 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image1.url}` : ''}
							image2Link={this.state.current_event.invite.image2 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image2.url}` : ''}
							image3Link={this.state.current_event.invite.image3 ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.image3.url}` : ''}
							coverImageLink={this.state.current_event.invite.cover_image ? `https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.current_event.invite.cover_image.url}` : ''}
							mode={0}
							hideCountdown={true}
						/>

					</div>

				</div>
			)
		}

		let style = {
			display: "flex",
			height: "100%",
			width: "100%",
			justifyContent: "center",
			alignItems: "center",
			backgroundRepeat: 'no-repeat',
			backgroundSize: 'cover',
			backgroundPosition: 'top',
			backgroundImage: this.state.inviteContents.image !== null ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.inviteContents.image)})` : ""
		}

		let customStyle = {
			overflow: "hidden",
			color: this.state.inviteContents.fontColor,
			fontFamily: this.state.inviteContents.fontFamily,
			fontSize: this.state.inviteContents.fontSize,
		}

		// let dataEvent = this.state.current_event.dataEvent.split("T")[0].split("-")

		let locations = this.state.locations.map((location, index) => {
			let date = location.time.split("T")[0].split("-");
			let hour = location.time.split("T")[1].split(":");

			if (location.day !== undefined) {
				date = location.day.split("T")[0].split("-");
			}

			let iconStyle = {color: this.state.inviteContents.fontColor};

			let icon = <ImLocation style={iconStyle} />

			if (location.name === "Locatie petrecere") {
				icon = <IoMdRestaurant style={iconStyle} />
			} else if (location.name === "Locatie biserica") {
				icon = <GiChurch style={iconStyle} />
			} else if (location.name === "Locatie cununie") {
				icon = <GiFamilyHouse style={iconStyle} />
			}

			return <div key={index} style={{ margin: "10px 0px" }}>
				<div style={{ color: this.state.inviteContents.fontColor, fontFamily: this.state.inviteContents.fontFamily, fontSize: Number(this.state.inviteContents.fontSize.split("px")[0]) + 5 + "px" }}>{location.name}</div>

				{icon}

				<div style={customStyle}>{location.label ? location.label : location.address}</div>

				<div style={customStyle}>{date[2]}-{date[1]}-{date[0]} {(Number(hour[0]) + 3) % 24 + ":" + hour[1]}</div>
			</div>
		})

		let body = <div></div>

		if (this.state.inviteType === "Personal") {
			body = <div style={{ marginTop: "20px" }}>
				<img src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.inviteContents.image)}`} alt={"Invitatie personala"}  style={{width: "80vw"}} />
			</div>
		}

// 		if (this.state.inviteType === "Video") {
// 			// console.log(this.state.inviteContents.linkVideo);
// 			let linkParts = this.state.inviteContents.linkVideo.split("watch?v=")
// 			let link = ""
// 			if (linkParts.length === 1) {
// 				link = linkParts[0]
// 			} else {
// 				link = linkParts[0] + "embed/" + linkParts[1]
// 			}

// 			let videoId = this.state.inviteContents.linkVideo.split("watch?v=")[1].split("&")[0]
// // console.log(link);
// 			body = <div className="MainInvite-contents" style={customStyle}>
// 				{/* <iframe src={link.split("&")[0]}
// 					frameBorder='0'
// 					allow='autoplay; encrypted-media'
// 					allowFullScreen={true}
// 					title='video'
// 					style={{ width: "80vw", height: "500px", margin: "20px auto" }}
// 				/> */}
// 				<PlayVideo  videoId={videoId} link={link.split("&")[0]} style={{ width: "80vw", height: "500px", margin: "20px auto" }} />

// 				<div>
// 					<Button className="action" onClick={() => this.props.history.go(-1)} color="red"> Înapoi </Button>
// 				</div>
// 			</div>
// 		}

		if (this.state.inviteType === "Custom") {

			let has_both_parents_mire = this.state.inviteContents.parinti[1].tataMire !== "" && this.state.inviteContents.parinti[0].mamaMire !== ""
			let has_at_least_one_parent_mire = this.state.inviteContents.parinti[1].tataMire !== "" || this.state.inviteContents.parinti[0].mamaMire !== ""

			let has_both_parents_mireasa = this.state.inviteContents.parinti[3].tataMireasa !== "" && this.state.inviteContents.parinti[4].mamaMireasa !== ""
			let has_at_least_one_parent_mireasa = this.state.inviteContents.parinti[3].tataMireasa !== "" || this.state.inviteContents.parinti[4].mamaMireasa !== ""

			if (this.state.eventType === "Nunta") {
				body = <div className="MainInvite-contents" style={customStyle}>
					<h3 style={{ marginBottom: "40px" }}>{this.state.current_event.prenumeMireasa} și {this.state.current_event.prenumeMire}</h3>

					<p>Spre bucuria părinților,</p>
					<div className="parents">
						{has_at_least_one_parent_mire && <div>
							<div>{this.state.inviteContents.parinti[1].tataMire} {has_both_parents_mire ? "și" : ""} {this.state.inviteContents.parinti[0].mamaMire}</div>

							<div>{this.state.inviteContents.parinti[4].numeParintiMire}</div>
						</div>}

						{has_at_least_one_parent_mireasa && <div>
							<div>{this.state.inviteContents.parinti[3].tataMireasa} {has_both_parents_mireasa ? "și" : ""} {this.state.inviteContents.parinti[2].mamaMireasa}</div>

							<div>{this.state.inviteContents.parinti[5].numeParintiMireasa}</div>
						</div>}
					</div>

					{this.state.inviteContents.nasi.length > 0 && <p style={{ marginBottom: "20px" }}>și a nașilor,</p>}
					{this.state.inviteContents.nasi.length > 0 && <div>{this.state.inviteContents.nasi[0].numeNas} și {this.state.inviteContents.nasi[0].numeNasa} {this.state.inviteContents.nasi[0].numeFamilie}</div>}

					<p style={{ margin: "20px 10px" }}>{this.state.inviteContents.description}</p>

					<div className="locations">
						{locations}
					</div>
				</div>
			}

			if (this.state.eventType === "Botez") {
				body = <div className="MainInvite-contents" style={customStyle}>
					<h3 style={{ marginBottom: "10px" }}>Salut, ma numesc {this.state.current_event.prenumeMire}</h3>

					<div style={{ display: "flex", justifyContent: 'space-around', margin: "20px 0px" }}>
						<p>Alături de parinții mei, {this.state.inviteContents.parinti[1].tataMire} și {this.state.inviteContents.parinti[0].mamaMire}</p>
					</div>

					{this.state.inviteContents.nasi.length > 0 && <div>și nașii, {this.state.inviteContents.nasi[0].numeNas} și {this.state.inviteContents.nasi[0].numeNasa} {this.state.inviteContents.nasi[0].numeFamilie}</div>}

					<p style={{ margin: "20px 10px" }}>{this.state.inviteContents.description}</p>

					<div className="locations">
						{locations}
					</div>
				</div>
			}

			if (this.state.eventType === "Petrecere") {
				body = <div className="MainInvite-contents" style={customStyle}>
					<h3 style={{ marginBottom: "10px" }}>Salut, sunteti invitati la {this.state.current_event.prenumeMire}</h3>

					<p style={{ margin: "20px 10px" }}>{this.state.inviteContents.description}</p>

					<div className="locations">
						{locations}
					</div>
				</div>
			}
		}

		return (
			<div className="MainInvite-container" style={this.state.inviteType === "Custom" ? style : {}} >
				{body}
			</div>
		)
	}

}

export default PreviewInviteForExport;
