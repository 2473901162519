import React from 'react'
import { Button, Rate } from 'rsuite'
import logo from '../../assets/logo.png'
import './ResultsPage.scss'
import axios from 'axios'
import { toast } from 'react-toastify'
import Fade from 'react-reveal/Fade';
import ImageGallery from 'react-image-gallery'

class EmailPage extends React.Component {
	state = {
		email: "",
		images: [],
		rating: 5
	}

	changeEmail = (value) => {
		this.setState({
			email: value
		})
	}

	change = (value) => {
		this.setState({
			rating: value
		})
	}

	sendFeedback = () => {
		axios.post("https://transporter-app1.firebaseio.com/farma.json", { rating: this.state.rating })
			.then(
				toast.success("Multumim pentru feebdack!")
			)
	}

	componentDidMount() {
		if (localStorage.getItem('jwt') === null) {
			toast.error("Trebuie sa va logati pentru a folosi site-ul")
			this.props.history.push('/login');
		}

		let isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1

		if (isAndroid) {
			let viewHeight = window.innerHeight
			let viewport = document.querySelector("meta[name=viewport]");
			viewport.setAttribute("content", "height=" + viewHeight + "px, width=device-width, initial-scale=1.0")
			//document.write('<meta name ="viewport" content="width=device-width, height=' + window.innerHeight+'initial-scale=1.0">');
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'facerecog/getMyResults', {
			eventID: this.props.match.params.eventID,
			requestID: this.props.match.params.requestID
		})

			.then(results => {
				let items = results.data.results;

				let toSet = items.map(element => {
					return {
						original: element,
						thumbnmail: element
					}
				})
				this.setState({
					images: toSet
				})
			})
			.catch(error => {
				console.log(error);
			})
	}

	render() {
		return (
			<Fade  >
				<div className="ResultsPage-container">
					<div className="ResultsPage-content">
						<div className="Login-one">
							<img style={{ width: "120px", height: "auto", margin: "20px" }} src={logo} alt='' />
							{/* <p id = "ondemand-text"> Smart Event Management </p> */}
						</div>

						<div className="Login">
							{this.state.images.length === 0 ? <p style={{ width: "85vw", marginTop: "10vh" }}> Din pacate nu am gasit poze cu tine. Acest lucru se poate intampla din mai multe motive precum: <br /><br /> 1) Nu te-am putut identifica avand masca pe fata <br /> 2) Unghiul surprins de fotograf nu arata clar chipul tau <br /><br /> Dar nu iti face griji, vei primi toate fotografiile de la eveniment</p> :
								<div>
									<ImageGallery items={this.state.images} />

									<h4> Tineti apasat pe o fotografie pentru a o downloada </h4>

									<h6> Pe langa acestea veti primi si toate pozele de la eveniment. </h6>
								</div>
							}
							<div>
								<p>
									Mentiune: In cazul in care nu va regasiti in pozele trimise, exista o posibilitate foarte mare ca selfie-ul sa nu fie in conformitate cu normele descrise.
								</p>
							</div>

							<p style={{ marginTop: "30px" }}> Cum ne-am descurcat? </p>

							<Rate onChange={this.change} size="md" />

							<Button onClick={this.sendFeedback} appearance="primary"> Trimite Feedback </Button>
						</div>
					</div>
				</div>
			</Fade>
		)
	}

}


export default EmailPage;
