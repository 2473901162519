import React from 'react'
import SettingCard from '../Profile/components/SettingCard'
import './EventInfoPage.scss'

import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import {Button, Toggle} from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'
import SaveTheDateModal from '../../secondaryPages/AdminEventPage/Modals/saveTheDateModal'
import InvitesModal from '../../secondaryPages/AdminEventPage/Modals/invitesModal'

import SVG_Albume from '../../assets/EventInfoSVG/albume-icon.svg'
import SVG_Meniu from '../../assets/EventInfoSVG/meniu-icon.svg'
import SVG_Feed from '../../assets/EventInfoSVG/feed.svg'

import SVG_Informatii from '../../assets/EventPageSVGs/informatii-icon.svg'
import SVG_Add_Organizer from '../../assets/EventInfoSVG/add-organizer.svg'
import SVG_Publish_Event from '../../assets/EventInfoSVG/publish-event.svg'
import Modal from "react-responsive-modal";
import Loader from "../../components/Loader";
import CustomInput from "../../components/custom/CustomInput";
import {ConfirmButton} from "../../../components/Helper";
import {getWeddingParticipantsPackageNumber} from "../../../functions";
import WalkthroughOverlay from "../../../components/walkthrough/WalkthroughtOverlay";
import WalkthroughOverlayBack from "../../../components/walkthrough/WalkthroughOverlayBack";

let options = {
	event: "Event",
	saveTheDate: "SaveTheDate",
	invite: "Invite",
	albums: "Albums",
	menu: "Menu",
	feed: "Feed"
}

class EventInfoPage extends React.Component {
	state = {
		current_event: {},
		isInviteCreated: false,
		isSaveTheDateCreated: false,

		showOptionsFor: "",

		isMenuAdded: false,

		std_modal: false,
		inv_modal: false,

		isPublished: false,
		weddingData: {},

		eventType: '',
		showOrganizerTypeModal: false,
		organizerType: -1,
		prenumeOrganizator: '',
		showWalkthrough: false,
		showWalkthroughBack: false,

		showPublishModal:false,

		loading: true
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)
			.then(response => {

				let showWalkthrough = !response.data.tutorials || (response.data.tutorials && !response.data.tutorials.walkthrough)

				this.setState({
					current_event: response.data,
					isInviteCreated: response.data.invitatieInfo,
					isSaveTheDateCreated: response.data.saveTheDateInfo,
					isMenuAdded: response.data.menuFile !== null,
					weddingData: response.data,
					isPublished: response.data.isPublished,
					eventType: response.data.eventType,
					showWalkthrough: showWalkthrough,
					showOrganizerTypeModal: !showWalkthrough && (!response.data.prenumeOrganizator || response.data.prenumeOrganizator.length === 0),
					loading: false
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("A aparut o eroare, va rog reincercati")
				this.props.history.push("/organizer/viewEvents")
			})
	}

	setPublish = async (isPublished) => {


		let obj = {
			prenumeMireasa: this.state.weddingData.prenumeMireasa,
			prenumeMire: this.state.weddingData.prenumeMire,
			dataEvent: this.state.weddingData.dataEvent,
			eventDescription: this.state.weddingData.eventDescription,
			locations: this.state.weddingData.locations,
			imageLink: this.state.weddingData.base64ImageLink,
			isPublished: isPublished
		}

		//for image
		// let formData = new FormData()
		// formData.append("data", JSON.stringify(obj))

		// if (this.state.backgroundImage !== null) {
		// 	formData.append("files.backgroundImage", this.state.backgroundImage[0])
		// }

		//for imga cropped
		// if (this.state.backgroundImage !== null && this.state.croppedImage !== null) {
		// 	formData.append("files.backgroundImage", this.state.croppedImage)
		// }

		let auth = {
			headers: {
				// "Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/edit/' + this.state.weddingData.id, obj, auth)//formData, auth)
			.then(response => {
				toast.success("Event updatat cu succes!")
				this.props.history.push('/organizer/info/' + this.props.match.params.id)
			})
			.catch(err => {
				toast.error("Eroare")
				this.setState({
					waitModal: false
				})
			})
	}


	showEventOptions = () => {
		let newOptions = this.state.showOptionsFor === options.event ? "" : options.event

		this.setState({
			showOptionsFor: newOptions
		})
	}

	goToPreviewEvent = () => {
		this.props.history.push('/organizer/eventPage/' + this.props.match.params.id)
	}

	goToEditEvent = () => {
		this.props.history.push('/organizer/editEvent/' + this.props.match.params.id)
	}

	goToPublishEvent = () => {

		if(getWeddingParticipantsPackageNumber(this.state.current_event) !== 2 && getWeddingParticipantsPackageNumber(this.state.current_event) !== 4) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}

		this.props.history.push('/organizer/publishEvent/' + this.props.match.params.id)
	}

	goToSaveTheDate = () => {
		if (this.state.isSaveTheDateCreated) {
			let newOptions = this.state.showOptionsFor === options.saveTheDate ? "" : options.saveTheDate

			this.setState({
				showOptionsFor: newOptions
			})
		} else {
			this.props.history.push('/organizer/addSaveTheDate/' + this.props.match.params.id)
		}
	}

	goToEditSaveTheDate = () => {
		this.props.history.push('/organizer/editSaveTheDate/' + this.props.match.params.id)
	}

	goToPreviewSaveTheDate = () => {
		this.props.history.push('/organizer/previewSaveTheDate/' + this.props.match.params.id)
	}

	toggleSTDModal = () => {
		if (window.flutter_inappwebview) {
			let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va roaga sa salvati aceasta data!"
			window.flutter_inappwebview.callHandler('shareEvent', `${msg} ${process.env.REACT_APP_PAGE}saveTheDate/${this.state.current_event.id_eveniment}`)
			return
		}

		if (window.alityAppView) {
			let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va roaga sa salvati aceasta data!"
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'shareMessage',
				message: `${msg} ${process.env.REACT_APP_PAGE}saveTheDate/${this.state.current_event.id_eveniment}`,
			}))
			return
		}

		let prevState = this.state.std_modal

		this.setState({
			std_modal: !prevState
		})
	}

	goToInvite = () => {
		if (this.state.isInviteCreated) {
			let newOptions = this.state.showOptionsFor === options.invite ? "" : options.invite

			this.setState({
				showOptionsFor: newOptions
			})
		} else {
			this.props.history.push('/organizer/editInvite/' + this.props.match.params.id)
		}
	}

	goToEditInvite = () => {
		this.props.history.push('/organizer/editInvite/' + this.props.match.params.id)
	}

	goToPreviewInvite = () => {
		this.props.history.push('/organizer/invitationPreview/' + this.props.match.params.id)
	}

	toggleInvModal = () => {
		if (window.flutter_inappwebview) {
			let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va invita la nunta lor!"
			if (this.state.current_event.eventType === "Botez") {
				if(this.state.current_event.id_eveniment === '43zDB')
					msg = `Va invit la botezul nostru, ${this.state.current_event.prenumeMire}`
				else
					msg = `Va invit la botezul meu, ${this.state.current_event.prenumeMire}`
			}
			if (this.state.current_event.eventType === "Petrecere") {
				msg = `Sunteți invitați la urmatoarea petrecere: ${this.state.current_event.prenumeMire}`
			}
			window.flutter_inappwebview.callHandler('shareEvent', `${msg} ${process.env.REACT_APP_PAGE}invitation/${this.state.current_event.id_eveniment}`)
			return
		}

		if (window.alityAppView) {
			let msg = this.state.current_event.prenumeMireasa + " & " + this.state.current_event.prenumeMire + ", va invita la nunta lor!"
			if (this.state.current_event.eventType === "Botez") {
				if(this.state.current_event.id_eveniment === '43zDB')
					msg = `Va invit la botezul nostru, ${this.state.current_event.prenumeMire}`
				else
					msg = `Va invit la botezul meu, ${this.state.current_event.prenumeMire}`
			}
			if (this.state.current_event.eventType === "Petrecere") {
				msg = `Sunteți invitați la urmatoarea petrecere: ${this.state.current_event.prenumeMire}`
			}
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'shareMessage',
				message: `${msg} ${process.env.REACT_APP_PAGE}invitation/${this.state.current_event.id_eveniment}`,
			}))
			return
		}

		let prevState = this.state.inv_modal

		this.setState({
			inv_modal: !prevState
		})
	}

	goToAlbume = () => {
		if(getWeddingParticipantsPackageNumber(this.state.current_event) !== 2 && getWeddingParticipantsPackageNumber(this.state.current_event) !== 4) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}

		this.props.history.push('/organizer/viewAlbums/' + this.props.match.params.id)
	}

	goToMenu = () => {
		if (this.state.isMenuAdded) {
			this.props.history.push('/organizer/editMenu/' + this.props.match.params.id)
		} else {
			this.props.history.push('/organizer/addMenu/' + this.props.match.params.id)
		}
	}

	goToFeed = () => {
		this.props.history.push('/organizer/adminFeed/' + this.props.match.params.id)
	}

	goBack = () => {
		this.props.history.push("/organizer/adminEventPage/" + this.props.match.params.id)
	}


	goToAdministrators= () => {

		if(getWeddingParticipantsPackageNumber(this.state.current_event) !== 2 && getWeddingParticipantsPackageNumber(this.state.current_event) !== 4) {
			this.props.history.push('/organizer/noPremiumPackage/' + this.props.match.params.id)
			return;
		}

		this.props.history.push('/organizer/administrators/' + this.props.match.params.id)
	}

	onChange = (value, key) => {
		this.setState({
			[key]: value
		})
	}

	saveOrganizerType = async () => {
		let obj = {
			organizerType: this.state.organizerType,
			prenumeOrganizator: this.state.prenumeOrganizator
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		this.setState({
			showOrganizerTypeModal: false
		})
		axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.current_event.id, obj, auth)
			.then(_ => {
			})
			.catch(err => {
				console.log(err);
			})
	}


	render() {
		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px",
			width:'100%'
		}

		if (this.state.loading) {
			return <div className="EventInfoPage-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<Loader/>

				<NavbarColor history={this.props.history} />
			</div>
		}

		return (
			<div className="EventInfoPage-container">
				<HeaderWhite style={{ width: "100%" }} goBack={this.goBack} />

				<div className="Title">Informații eveniment</div>

				<SaveTheDateModal
					id={this.props.match.params.id}
					showModal={this.state.std_modal}
					toggleModal={this.toggleSTDModal}
					mire={this.state.current_event.prenumeMire}
					mireasa={this.state.current_event.prenumeMireasa}
				/>

				<InvitesModal
					id={this.props.match.params.id}
					showModal={this.state.inv_modal}
					toggleModal={this.toggleInvModal}
					mire={this.state.current_event.prenumeMire}
					mireasa={this.state.current_event.prenumeMireasa}
					eventType={this.state.current_event.eventType}
				/>

				<div className="EventInfoPage-contents">
					<SettingCard svg={SVG_Informatii} onClick={this.goToEditEvent} name="Detalii eveniment" />
					<SettingCard svg={SVG_Publish_Event} onClick={this.goToPublishEvent} name="Publică eveniment" />

					{/*
					{this.state.showOptionsFor === options.event && <div>
						<SettingCard icon="" onClick={this.goToEditEvent} name="Editează" isSmall={true} />

						<SettingCard icon="" onClick={this.goToPreviewEvent} name="Previzualizează" isSmall={true} />

						<SettingCard icon="" onClick={() => this.setState({showPublishModal: true})} name="Publică" isSmall={true}/>
					</div>}
					*/}

					<SettingCard svg={SVG_Add_Organizer} onClick={this.goToAdministrators} name="Adaugă organizator" />


					<SettingCard svg={SVG_Meniu} onClick={this.goToMenu} name="Publică meniu" />

					<SettingCard svg={SVG_Feed} onClick={this.goToFeed} name="Feed eveniment" />

					<SettingCard svg={SVG_Albume} onClick={this.goToAlbume} name="Publică foto-video" />

					<div>
						<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
							Înapoi
						</Button>
					</div>
				</div>


				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal',
						closeButton: 'modal-close-button',
					}}
					center
					open={this.state.showPublishModal}
					onClose={() => this.setState({showPublishModal:false})}
				>
					<div style={{marginTop: "20px"}}>
						<p>Publicarea evenimentului il face vizibil in lista de evenimente, acesta putand fi accesat de persoanele care au codul evenimentului sau care au primit invitatie. (Nepublicarea evenimentului nu afecteaza pe cei care primesc invitatie)</p>

						<div style={{margin:'auto', textAlign:'center', marginTop:'20px', marginBottom:'20px'}}>
						<Toggle style={{ width: "150px", alignSelf: "center" }}
								checked={this.state.isPublished}
								onChange={status => {
									this.setState({isPublished: status})
									this.setPublish(status)
								}}
								checkedChildren="Publicat"
								unCheckedChildren="Nepublicat" />
						</div>

					</div>
				</Modal>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal modal-organizer-type',
						closeButton: 'modal-close-button',
					}}
					center
					open={this.state.showOrganizerTypeModal}
					onClose={() => this.setState({showOrganizerTypeModal:false})}
					focusTrapped={false}
				>
					<div style={{marginTop: "20px"}}>


						<div className="input-zone">

							{
								this.state.eventType === 'Nunta' ?
									<>
										<h5 className={"section-title"}>Acum că ne-am cunoscut dorim să știm dacă ești mireasa sau mirele.</h5>
										<br/>
										<div className={"organizer-type-selector"}>
											<div
												className={"item " + (this.state.organizerType === 0 ? 'active' : '')}
												onClick={() => {
													this.setState({
														organizerType: 0,
														prenumeOrganizator: this.state.current_event.prenumeMire
													})
												}}>
												Sunt mire
											</div>
											<div
												className={"item " + (this.state.organizerType === 1 ? 'active' : '')}
												onClick={() => {
													this.setState({
														organizerType: 1,
														prenumeOrganizator: this.state.current_event.prenumeMireasa
													})
												}}>
												Sunt mireasă
											</div>
										</div>


										<div className={'controls'}>
											{
												this.state.organizerType > -1 ?
													<ConfirmButton text={"Salvează"} function={() => {
														this.saveOrganizerType()
													}}/>
													: null
											}
										</div>
									</>
									:
									this.state.eventType === 'Botez' ?
										<>
											<h5 className={"section-title"}>Acum că ne-am cunoscut dorim să știm dacă ești mama sau tatal copilului.</h5>
											<br/>
											<div className={"organizer-type-selector"}>
												<div
													className={"item " + (this.state.organizerType === 0 ? 'active' : '')}
													onClick={() => {
														this.setState({
															organizerType: 0,
															prenumeOrganizator: this.state.current_event.prenumeTata
														})
													}}>
													Tată
												</div>
												<div
													className={"item " + (this.state.organizerType === 1 ? 'active' : '')}
													onClick={() => {
														this.setState({
															organizerType: 1,
															prenumeOrganizator: this.state.current_event.prenumeMama
														})
													}}>
													Mamă
												</div>
											</div>

											{
												this.state.organizerType > -1 ?
													<CustomInput value={this.state.prenumeOrganizator}
																 onChange={(value) => this.onChange(value, "prenumeOrganizator")}
																 style={customInputStyle}
																 placeholder={"Prenumele tău"}
													/>
													: null
											}

											<div className={'controls'}>
												{
													this.state.organizerType > -1 && (this.state.prenumeOrganizator && this.state.prenumeOrganizator.length > 0)  ?
														<ConfirmButton text={"Salvează"} function={() => {
															this.saveOrganizerType()
														}}/>
														: null
												}
											</div>

										</>
										:
										<>
											<h5 className={"section-title"}>Acum că ne-am cunoscut dorim să știm care este numele tău.</h5>
											<br/>
											<div className={"organizer-type-selector"}>
												<CustomInput value={this.state.prenumeOrganizator}
															 onChange={(value) => this.onChange(value, "prenumeOrganizator")}
															 style={customInputStyle}
															 placeholder={"Prenumele tău"}
												/>
											</div>

											<div className={'controls'}>
												{
													this.state.prenumeOrganizator && this.state.prenumeOrganizator.length > 0  ?
														<ConfirmButton text={"Salvează"} function={() => {
															this.setState({
																organizerType: 0
															}, () => {
																this.saveOrganizerType()
															})

														}}/>
														: null
												}
											</div>

										</>
							}

						</div>


					</div>
				</Modal>

				{
					this.state.showWalkthrough ?
						<WalkthroughOverlay id_eveniment={this.props.match.params.id} history={this.props.history}>
							<p>Din acest meniu poți seta cele mai importante date ale evenimentului, adauga încă un organizator sau, la finalul evenimentului, poți publica toate pozele realizate. </p>

							<div className={"next-button"} onClick={() => this.setState({
								showWalkthroughBack:true,
								showWalkthrough: false
							})}>Mai departe</div>
						</WalkthroughOverlay>
						:null
				}

				{
					this.state.showWalkthroughBack ?
						<WalkthroughOverlayBack
							action={() => this.props.history.push('/organizer/adminEventPage/' + this.props.match.params.id + '?walkthroughStep=2')}
						/>
						:null
				}

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default EventInfoPage;
