import React from 'react'
import {Button, Toggle} from 'rsuite'
import {toast} from 'react-toastify'
import HeaderWhite from '../../components/HeaderWhite'
import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import {injectIntl} from 'react-intl';
import './EditProfile.scss'
import Loader from "../../components/Loader";
import CustomInput from "../../components/custom/CustomInput";
import AvatarEditor from "react-avatar-editor";
import {ConfirmButton, getBase64FromImage} from "../../organizer/components/Helper";
import Modal from "react-responsive-modal";
import {BackAndConfirmButtons} from "../../components/Helper";
import queryString from 'query-string';

class EditProfile extends React.Component {

    urlParams = queryString.parse(this.props.location.search)

    constructor(props) {
        super(props)
        this.cropperRef = React.createRef()
    }

    state = {
        first_name: '',
        last_name: '',
        phone: '',
        second_email: '',
        linkedin: '',
        phone_public: false,
        second_email_public: false,
        linkedin_public: false,

        imageLink: null,
        newImage: null,
        newImageTemp: false,
        imageScale: 1,
        image_public: false,

        company: '',
        company_public: false,

        function: '',
        function_public: false,

        email: '',
        email_public: false,

        instagram: '',
        instagram_public: false,

        facebook: '',
        facebook_public: false,

        website: '',
        website_public: false,

        showEmailModal: false,

        saveLoading: false,
        loading: true
    }


    componentDidMount() {
        if (localStorage.getItem('jwt') === null) {
            toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_log_in'}))
            this.props.history.push('/login?redirectBackTo=profile');
            return
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
            .then(response => {

                this.setState({
                    last_name: response.data.last_name,
                    first_name: response.data.first_name,
                    phone: response.data.phone,
                    second_email: response.data.second_email,
                    linkedin: response.data.linkedin,
                    phone_public: response.data.phone_public  !== false,
                    second_email_public: response.data.second_email_public  !== false,
                    linkedin_public: response.data.linkedin_public  !== false,
                    imageLink: response.data.image && response.data.image.url ? 'https://' + process.env.REACT_APP_IMAGE_DOMAIN + response.data.image.url : '',
                    image_public: response.data.image_public !== false,
                    company: response.data.company,
                    company_public: response.data.company_public !== false,
                    function: response.data.function,
                    function_public: response.data.function_public !== false,
                    email: response.data.email,
                    email_public: response.data.email_public !== false,
                    instagram: response.data.instagram,
                    instagram_public: response.data.instagram_public !== false,
                    facebook: response.data.facebook,
                    facebook_public: response.data.facebook_public !== false,
                    website: response.data.website,
                    website_public: response.data.website_public !== false,
                    loading: false
                })


            })
            .catch(err => {
                if(err && err.response && err.response.status === 401) {
                    localStorage.clear()
                    toast.error(this.props.intl.formatMessage({id: 'general.alerts.please_log_in'}))
                    this.props.history.push('/login');
                } else {
                    toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
                }
            })
    }

    saveData = async () => {
        if (this.state.last_name === "") {
            toast.error(this.props.intl.formatMessage({id: 'edit_profile.alerts.please_fill_last_name'}))
            return
        }

        if (this.state.first_name === "") {
            toast.error(this.props.intl.formatMessage({id: 'edit_profile.alerts.please_fill_first_name'}))
            return
        }

        this.setState({saveLoading: true})
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let obj = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            phone: this.state.phone,
            second_email: this.state.second_email,
            linkedin: this.state.linkedin,
            phone_public: this.state.phone_public,
            second_email_public: this.state.second_email_public,
            linkedin_public: this.state.linkedin_public,

            image_public: this.state.image_public,
            company: this.state.company,
            company_public: this.state.company_public,
            function: this.state.function,
            function_public: this.state.function_public,
            email_public: this.state.email_public,

            instagram: this.state.instagram,
            instagram_public: this.state.instagram_public !== false,
            facebook: this.state.facebook,
            facebook_public: this.state.facebook_public !== false,
            website: this.state.website,
            website_public: this.state.website_public !== false,

        }

        if (this.state.newImage !== null) {
            obj["imageLink"] = await getBase64FromImage(this.state.newImage)
        }

        await axios.put(process.env.REACT_APP_DOMAIN + 'user/updateProfile', obj, auth)
            .then((response) => {
                toast.success(this.props.intl.formatMessage({id: 'general.alerts.changes_saved'}))
                this.setState({saveLoading: true})
                this.goBack()
            })
            .catch(_ => {
                this.setState({saveLoading: true})
                toast.error(this.props.intl.formatMessage({id: 'general.alerts.error_occurred'}))
            })

    }

    insertLinkedIn = (val) => {
        let value = val.replace('https://www.linkedin.com/in/', '')
        value = value.replace('https://linkedin.com/in/', '')
        value = value.replace('/','')
        value = value.replace('/','')
        value = value.replace('/','')
        value = value.replace('/','')

        this.setState({linkedin: value})
    }

    insertInstagram = (val) => {
        let value = val.replace('https://www.instagram.com/', '')
        value = value.replace('https://instagram.com/', '')
        value = value.replace('/','')
        value = value.replace('/','')
        value = value.replace('/','')
        value = value.replace('/','')
        value = value.replace('@','')

        this.setState({instagram: value})
    }

    insertFacebook = (val) => {
        let value = val.replace('https://www.facebook.com/', '')
        value = value.replace('https://facebook.com/', '')
        value = value.replace('/','')
        value = value.replace('/','')
        value = value.replace('/','')
        value = value.replace('/','')

        this.setState({facebook: value})
    }

    goBack = () => {
        if (this.urlParams && this.urlParams.redirectBackTo && this.urlParams.redirectBackTo.length > 0) {
            this.props.history.push(this.urlParams.redirectBackTo)
        } else {
            this.props.history.go(-1)
        }
    }

    render() {

        let inputStyle = {
            margin: "10px 0px 10px 5px",
            textAlign: 'center',
            maxWidth: "500px",
            alignSelf: 'center',
            width: '80px'
        }

        let inputStyle2 = {
            margin: "25px 0px 10px 5px",
            textAlign: 'center',
            maxWidth: "500px",
            alignSelf: 'center',
            width: '80px'
        }


        if (this.state.loading) {
            return <div className="EditProfile-container font-family-poppins-300">
                <HeaderWhite goBack={this.goBack}/>

                <h4 className="EditProfile-TopText">{this.props.intl.formatMessage({id: 'edit_profile.title'})}</h4>

                <div className="EditProfile-contents">
                    <Loader/>
                </div>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        }

        return (
            <div className="EditProfile-container font-family-poppins-300">
                <HeaderWhite goBack={this.goBack}/>

                <h4 className="EditProfile-TopText">{this.props.intl.formatMessage({id: 'edit_profile.title'})}</h4>

                <div className="EditProfile-contents">

                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <div className={"avatar-title"}>{this.props.intl.formatMessage({id: 'edit_profile.profile_image'})}</div>

                            {
                                this.state.newImageTemp ?
                                    <div className={"avatar-cont"}>
                                        <AvatarEditor
                                            ref={this.cropperRef}
                                            image={this.state.newImageTemp}
                                            width={230}
                                            height={230}
                                            border={20}
                                            color={[0, 0, 0, 0.6]} // RGBA
                                            scale={parseFloat(this.state.imageScale + '')}
                                            rotate={0}
                                            borderRadius={150}
                                            //onImage
                                            onLoadFailure={(e) => {
                                                toast.error(this.props.intl.formatMessage({id: 'edit_profile.profile_image.format'}))
                                                this.setState({
                                                    newImageTemp: null
                                                })
                                            }}
                                        />
                                        <div className={"actions"}>
                                            <div className={"line"}>
                                                <div className={"col-left"}>
                                                    Zoom
                                                </div>
                                                <div className={"col-right"}>
                                                    <input type="range" className="custom-range" min="1" max="7"
                                                           step="0.1" value={this.state.imageScale}
                                                           onChange={(event) => this.setState({imageScale: event.target.value})}/>
                                                </div>
                                            </div>

                                            <div className={"line"}>
                                                <ConfirmButton text={this.props.intl.formatMessage({id: 'general.save'})} function={async () => {

                                                    const dataUrl = this.cropperRef.current.getImageScaledToCanvas().toDataURL()
                                                    const result = await fetch(dataUrl)
                                                    const blob = await result.blob()

                                                    this.setState({
                                                        newImage: blob,
                                                        imageScale: 1,
                                                        newImageTemp: null
                                                    })
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                    : <div className={"avatar-cont"}>
                                        {
                                            this.state.newImage ?
                                                <div className={"image-preview"}>
                                                    <img  alt={""} src={window.URL.createObjectURL(this.state.newImage)}/>
                                                </div> :
                                                this.state.imageLink ?
                                                    <div className={"image-preview"}>
                                                        <img  alt={""} src={this.state.imageLink}/>
                                                    </div>
                                                    : null
                                        }

                                        <input
                                            type="file"
                                            name="myImage"
                                            onChange={(event) => {
                                                this.setState({
                                                    newImageTemp: event.target.files[0]
                                                })
                                            }}
                                        />
                                    </div>
                            }
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle}
                                checked={this.state.image_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        image_public: value,
                                    })
                                }}/>
                        </div>
                    </div>

                            <CustomInput value={this.state.last_name}
                                         onChange={(val) => this.setState({last_name: val})} placeholder={this.props.intl.formatMessage({id: 'general.last_name'})}/>


                            <CustomInput value={this.state.first_name}
                                         onChange={(val) => this.setState({first_name: val})} placeholder={this.props.intl.formatMessage({id: 'general.first_name'})}/>


                    <div className={"item-row"}>
                        <div className={"item-col"} onClick={() => this.setState({showEmailModal: true})}>
                            <CustomInput value={this.state.email} onChange={(val) => {}}
                                         placeholder={this.props.intl.formatMessage({id: 'general.email_account'})} disabled={true} readonly={true} style={{opacity:0.5}}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle}
                                checked={this.state.email_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        email_public: value,
                                    })
                                }}/>
                        </div>
                    </div>

                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.phone} onChange={(val) => this.setState({phone: val})}
                                         placeholder={this.props.intl.formatMessage({id: 'general.phone'})}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle}
                                checked={this.state.phone_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        phone_public: value,
                                    })
                                }}/>
                        </div>
                    </div>

                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.second_email}
                                         onChange={(val) => this.setState({second_email: val})}
                                         placeholder={this.props.intl.formatMessage({id: 'general.email_work'})}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle}
                                checked={this.state.second_email_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        second_email_public: value,
                                    })
                                }}/>
                        </div>
                    </div>

                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.company} onChange={(val) => this.setState({company: val})}
                                         placeholder={this.props.intl.formatMessage({id: 'general.company_or_institution'})}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle}
                                checked={this.state.company_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        company_public: value,
                                    })
                                }}/>
                        </div>
                    </div>

                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.function} onChange={(val) => this.setState({function: val})}
                                         placeholder={this.props.intl.formatMessage({id: 'general.function_or_title'})}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle}
                                checked={this.state.function_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        function_public: value,
                                    })
                                }}/>
                        </div>
                    </div>

                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.website} onChange={(val) => this.setState({website: val})}
                                         placeholder={this.props.intl.formatMessage({id: 'general.website'})}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle}
                                checked={this.state.website_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        website_public: value,
                                    })
                                }}/>
                        </div>
                    </div>

                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.linkedin} onChange={(val) => this.insertLinkedIn(val)}
                                         placeholder={this.props.intl.formatMessage({id: 'general.linkedin'})} style={{marginBottom:0}}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle2}
                                checked={this.state.linkedin_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        linkedin_public: value,
                                    })
                                }}/>
                        </div>
                    </div>
                    <div style={{textAlign:'left', fontSize:"12px", width:'100%', marginBottom:'10px'}}>{this.props.intl.formatMessage({id: 'edit_profile.social.notice'})}</div>


                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.instagram} onChange={(val) => this.insertInstagram(val)}
                                         placeholder={this.props.intl.formatMessage({id: 'general.instagram'})} style={{marginBottom:0}}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle2}
                                checked={this.state.instagram_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        instagram_public: value,
                                    })
                                }}/>
                        </div>
                    </div>
                    <div style={{textAlign:'left', fontSize:"12px", width:'100%', marginBottom:'10px'}}>{this.props.intl.formatMessage({id: 'edit_profile.social.notice'})}</div>


                    <div className={"item-row"}>
                        <div className={"item-col"}>
                            <CustomInput value={this.state.facebook} onChange={(val) => this.insertFacebook(val)}
                                         placeholder={this.props.intl.formatMessage({id: 'general.facebook'})} style={{marginBottom:0}}/>
                        </div>
                        <div className={"item-col last"}>
                            <Toggle
                                style={inputStyle2}
                                checked={this.state.facebook_public}
                                size="lg"
                                checkedChildren="Public"
                                unCheckedChildren={this.props.intl.formatMessage({id: 'edit_profile.private'})}
                                onChange={(value) => {
                                    this.setState({
                                        facebook_public: value,
                                    })
                                }}/>
                        </div>
                    </div>
                    <div style={{textAlign:'left', fontSize:"12px", width:'100%', marginBottom:'10px'}}>{this.props.intl.formatMessage({id: 'edit_profile.social.notice'})}</div>


                    <div style={{width: '100%'}}>
                        <Button onClick={this.saveData} disabled={this.state.saveLoading} color="green" style={{
                            backgroundColor: "#05AFF2",
                            borderRadius: "20px",
                            width: "100%"
                        }}>{this.props.intl.formatMessage({id: 'general.save'})}</Button>
                    </div>


                </div>


                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal-profile',
                        closeButton: 'modal-close-button',
                    }}
                    open={this.state.showEmailModal}
                    onClose={() => this.setState({showEmailModal:false})}
                    focusTrapped={false}
                >
                    <div style={{marginTop:"15px", marginBottom:"15px"}}>
                        {this.props.intl.formatMessage({id: 'edit_profile.edit_email.text'})}
                         </div>

                    <BackAndConfirmButtons
                    textBack={this.props.intl.formatMessage({id: 'edit_profile.edit_email.got_it'})}
                    functionBack={() => this.setState({showEmailModal:false})}
                    textConfirm={this.props.intl.formatMessage({id: 'edit_profile.edit_email.go_there'})}
                    functionConfirm={() => {
                        localStorage.setItem("changePasswordGracefully", "yes")
                        this.props.history.push('/profile')
                    }}
                    />
                </Modal>

                <NavbarColor history={this.props.history} active={"Profile"}/>
            </div>
        )
    }
}

export default injectIntl(EditProfile);
