import React from 'react'
import {toast} from 'react-toastify'
import HeaderWhite from '../../components/HeaderWhite'
import axios from 'axios'

import './QrCheckerInvite.scss'
import Loader from "../../components/Loader";

class QrCheckerInvite extends React.Component {
    state = {
        loading: true,
    }


    componentDidMount() {



            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }


        axios.post(process.env.REACT_APP_DOMAIN + 'event/claimQrCheckerInvite', {
            code: this.props.match.params.code
        }, auth)
            .then(response => {
                if(response.data.status === 0) {
                    toast.error('Invitația nu mai este valabilă sau nu vi se adresează!')
                    this.props.history.push('/')
                } else {
                    toast.success('Felicitări! Ai devenit QR checker în cadrul acestui eveniment!')
                    this.props.history.push('/eventPage/' + response.data.eventId)
                }

            })
            .catch(err => {
                if(err && err.response && err.response.status === 401) {
                    toast.error('Vă rugăm să vă autentificați!')
                    this.props.history.push('/login?redirectBackTo=qrCheckerInvite/' + this.props.match.params.code)
                } else {
                    toast.error('A apărut o eroare! Vă rugăm reîncercați!')
                    this.props.history.push('/')
                }
            })

    }


    render() {
            return <div className="QrCheckerInvite-container font-family-poppins-300">
                <HeaderWhite/>

                <h4 className="QrCheckerInvite-TopText">Invitație</h4>

                <div className="QrCheckerInvite-contents">
                    <Loader/>
                </div>


            </div>

    }
}

export default QrCheckerInvite;
