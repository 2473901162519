import React from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import './PdfViewer.scss'
import { MdZoomIn, MdZoomOut } from 'react-icons/md';
import { Button } from 'rsuite'

class PdfViewer extends React.Component {
	state = {
		pdfWidth: 340,
		numberOfPages: 1,
		pageNumber: 1,
	}

	componentDidMount = async () => {
		pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
	}

	onFileLoadSuccess = (numPages) => {
		this.setState({
			numberOfPages: numPages._pdfInfo.numPages,
			pageNumber: 1
		})
	}

	changePage = (offset) => {
		var currentPage = this.state.pageNumber

		this.setState({
			pageNumber: currentPage + offset
		})
	}

	changePdfWidth = (offset) => {
		var currentPdfWidth = this.state.pdfWidth

		this.setState({
			pdfWidth: currentPdfWidth + offset
		})
	}

	render() {
		const url = this.props.pdfUrl
		const zoomAmount = 20

		return (
			<div className="PdfViewer-container">
				<Document file={url} onLoadSuccess={this.onFileLoadSuccess}>
					<Page pageNumber={this.state.pageNumber} width={this.state.pdfWidth} className="InfoPage-page" />
				</Document>

				<div className="zoom">
					<Button className="left" onClick={() => { this.changePdfWidth(-zoomAmount) }} disabled={this.state.pdfWidth < 300}>
						<MdZoomOut style={{ transform: "rotate(90deg)" }} />
					</Button>

					<Button className="right" onClick={() => { this.changePdfWidth(+zoomAmount) }} disabled={this.state.pdfWidth > 400}>
						<MdZoomIn />
					</Button>
				</div>

				<div className="PdfViewer-buttons">
					<Button onClick={() => { this.changePage(-1) }} style={{ backgroundColor: this.state.pageNumber < 2 ? "#ACAAC1" : "#05AFF2", color: "white", borderRadius: "20px", fontSize: "11px" }} disabled={this.state.pageNumber < 2}>
						Pagina anterioara
					</Button>

					<p>{this.state.pageNumber}/{this.state.numberOfPages}</p>

					<Button onClick={() => { this.changePage(+1) }} style={{ backgroundColor: this.state.pageNumber === this.state.numberOfPages ? "#ACAAC1" : "#05AFF2", color: "white", borderRadius: "20px", fontSize: "11px" }} disabled={this.state.pageNumber === this.state.numberOfPages}>
						Pagina urmatoare
					</Button>
				</div>
			</div>
		)
	}
}

export default PdfViewer;
