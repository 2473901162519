import React from 'react'
import './Partners.scss'

// import axios from 'axios'
import AdModal from '../../components/AdModal';
import { getUrlFromImage } from '../../components/Helper';

let adFieldName = "partners"

class Partners extends React.Component {
	state = {
		event_data: this.props.event_data,

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}


	getLogos = () => {
		let logos = this.state.event_data.logo_parteneri.map((logo, index) => {
			// return <div key={logo.id} className="Partners-logo" style={this.getBackgroundImageStyle(index)}></div>
			return <img key={logo.id} style={{ width: "100%", height: "auto", maxWidth: "400px", margin: "0px auto" }}
				src={'https://' + process.env.REACT_APP_IMAGE_DOMAIN + "" + getUrlFromImage(this.state.event_data.logo_parteneri[index])} alt="Logo"
			/>
		})

		return logos
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		if (this.state.showAdModal) {
			return <div className="Partners-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		// if (this.state.loading === true) {
		// 	return <p> Se încarcă...</p>
		// }

		return (
			<div className="Partners-container">
				{this.state.event_data.logo_parteneri.length > 0
					? this.getLogos()
					: <p>No logo added</p>}
			</div>
		)
	}
}

export default Partners;
