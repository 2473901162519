import {useGoogleLogin} from "@react-oauth/google";
import {Icon} from "rsuite";
import React from "react";

export default function GoogleWebLoginButton({callbackFunction, style={}}) {

    const triggerGoogleLogin = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            tokenResponse['tokenId'] = tokenResponse.access_token
            callbackFunction(tokenResponse)
        },
    });
    return (
        <button
            className={"social-login-button social-google-login"}
            type={"button"}
            style={style}
            onClick={() => triggerGoogleLogin()}
        ><Icon icon="google"/> Google</button>
    )
}
