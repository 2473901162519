import React from 'react'
import './EditSaveTheDate.scss'
import HeaderWhite from '../../components/HeaderWhite'
import { Uploader, Icon, Dropdown, Input, Button } from 'rsuite'
import Modal from "react-responsive-modal"

import NavbarColor from '../../components/NavbarColor'
import axios from 'axios'
import { toast } from 'react-toastify'

import MicRecorder from 'mic-recorder-to-mp3';
import LoadingModal from './LoadingModal/LoadingModal'

import { CirclePicker } from 'react-color'
import CustomInput from '../components/custom/CustomInput'
import {
	BackAndConfirmButtons,
	ConfirmButton,
	LoadingScreen,
	getBase64FromImage,
	getUrlFromImage,
	PlayAudio,
	textarea_max_length,
	PlayVideo,
	SelectInviteType,
	EditPicture,
	resizeBlob
} from '../components/Helper'
import { BsFillMicFill } from 'react-icons/bs';
import SendSaveTheDateModal from "../../components/SendSaveTheDateModal/SendSaveTheDateModal";
import {getDefaultWeddingSendSaveTheDateMessage} from "../../functions";

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class EditSaveTheDate extends React.Component {
	state = {
		saveTheDateType: "",

		// eventType: "Nunta",
		eventId: -1,
		isUsingNotifications: false,
		isUsingNotificationsInitialState: false,

		showModalChangeType: false,
		showSendModal: false,

		timerStarted: false,
		timer: 0,

		city: "",
		description: "",
		fontSize: "20px",
		fontColor: "#000",
		fontFamily: "Open Sans",
		fontsOptions: ["Open Sans", "Cursive", "Monospace", "Revert", "Fantasy", "Poppins"],
		fotoID: "",
		linkVideo: "",
		backgroundImageLink: null,
		deleteBackgroundImageLink: false,
		poza_saveTheDate: null,
		saveTheDateID: "",

		isChangingColor: false,

		base64ImageLink: "",
		isPublished: false,
		tutorialModal: false,

		isUsingAudio: false,
		isRecording: false,
		blobURL: '',
		serverAudioUrl: null,
		deleteServerAudio: false,
		audioBlobData: null,
		isBlocked: false,

		sendSaveTheDateMessage: '',
		editSendSaveTheDateMessage: false,

		loading: false,
		loadingData: true
	}

	async componentDidMount() {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let weddingData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth);

		let current_saveTheDate = weddingData.data.saveTheDate

		if (current_saveTheDate !== null) {
			this.setState({
				eventId: weddingData.data.id,
				weddingData: weddingData.data,
				isUsingNotifications: weddingData.data.notifications,
				isUsingNotificationsInitialState: weddingData.data.notifications,

				saveTheDateType: current_saveTheDate.saveTheDateType,
				city: current_saveTheDate.localitate,
				description: current_saveTheDate.description,
				linkVideo: current_saveTheDate.linkVideo,
				saveTheDateID: current_saveTheDate.id,
				backgroundImageLink: current_saveTheDate.backgroundImage,
				serverAudioUrl: current_saveTheDate.audio !== null && current_saveTheDate.audio !== undefined ? current_saveTheDate.audio.url : null,
				isUsingAudio: current_saveTheDate.audioType === "useAudio",
				fontColor: current_saveTheDate.fontColor,
				fontSize: current_saveTheDate.fontSize,
				fontFamily: current_saveTheDate.fontFamily,
				isPublished: current_saveTheDate.isPublished ? true : false,
				tutorialModal: !weddingData.data.tutorials || (weddingData.data.tutorials && !weddingData.data.tutorials.saveTheDate),
				sendSaveTheDateMessage: current_saveTheDate && current_saveTheDate.sendSaveTheDateMessage &&
					current_saveTheDate.sendSaveTheDateMessage.length > 0 ? current_saveTheDate.sendSaveTheDateMessage : getDefaultWeddingSendSaveTheDateMessage(weddingData.data)
			})
		}

		this.setState({
			loadingData: false
		})
	}

	setSaveTheDateType = (type) => {
		this.setState({
			saveTheDateType: type
		})
	}

	start = () => {
		if (this.state.isBlocked) {
			toast.info("Microfonul este blocat, acordati permisiune pentru a-l folosii.")
		} else {
			Mp3Recorder
				.start()
				.then(() => {
					this.setState({ blobURL: "", isRecording: true, audioBlobData: null, timer:0, timerStarted: true });
				}).catch((e) => { console.error(e); toast.warn("Microfonul nu poate fi detectat") });
		}
	};

	stop = () => {
		Mp3Recorder
			.stop()
			.getMp3()
			.then(([buffer, blob]) => {
				// console.log(buffer);
				// console.log(blob);
				const blobURL = URL.createObjectURL(blob)
				// console.log(blobURL);
				this.setState({ blobURL, isRecording: false, audioBlobData: blob, timer:0, timerStarted: false });
			}).catch((e) => console.log(e));
	};

	handleChange = (value, event) => {
		this.setState({
			[event.target.name]: value
		})
	}

	changeSaveTheDateDescription = (value) => {
		if (value.length > textarea_max_length - 1) {
			this.setState({
				description: value.substring(0, textarea_max_length)
			})
			return
		}

		this.setState({
			description: value
		})
	}

	goBack = () => {
		this.props.history.push("/organizer/plan/" + this.props.match.params.id)
	}

	openSendModal = async () => {

		this.setState({
			showSendModal: true
		})
	}

	setSaveTheDateSentDate = async () => {

		//disabled
		return

		// eslint-disable-next-line
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			id_eveniment: this.props.match.params.id
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/setSaveTheDateSentDate',
			obj,
			auth)

	}

	resetSaveTheDateSentDate = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			id_eveniment: this.props.match.params.id
		}

		 await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/resetSaveTheDateSentDate',
			obj,
			auth)

	}

	createSaveTheDate = async (redirectToPreview = false, openSendModal= false) => {
		this.setState({
			loading: true
		})

		this.resetSaveTheDateSentDate()

		let obj = {
			saveTheDateType: this.state.saveTheDateType,
			prenumeMireasa: this.state.weddingData.prenumeMireasa,
			prenumeMire: this.state.weddingData.prenumeMire,
			dataEvent: this.state.weddingData.dataEvent,
			localitate: this.state.city,
			description: this.state.description,
			linkVideo: this.state.linkVideo,
			fontColor: this.state.fontColor,
			fontSize: this.state.fontSize,
			fontFamily: this.state.fontFamily,
			audioType: this.state.isUsingAudio ? "useAudio" : "none",
			imageLink: this.state.base64ImageLink,
			isPublished: this.state.isPublished,
			sendSaveTheDateMessage: this.state.sendSaveTheDateMessage
		}

		if (window.flutter_inappwebview) {
			let auth = {
				headers: {
					// "Content-Type": `multipart/form-data;`,
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			axios.put(process.env.REACT_APP_DOMAIN + 'save-the-dates/edit/' + this.state.saveTheDateID, obj, auth)//formData, auth)
				.then(async response => {
					this.setState({
						loading: false
					})

					if(openSendModal) {
						this.openSendModal()
					} else {
						toast.success("Save the Date salvat cu succes!")
						if (redirectToPreview) {
							this.props.history.push("/organizer/previewSaveTheDate/" + this.props.match.params.id)
						} else {
							this.props.history.push('/organizer/plan/' + this.props.match.params.id)
						}
					}
				})
				.catch(err => {
					toast.error("Eroare")
					this.setState({
						loading: false
					})
				})
		} else {

			let formData = new FormData()

			if (this.state.poza_saveTheDate !== null) {
				formData.append("files.backgroundImage", this.state.poza_saveTheDate)
			} else {
				if (this.state.deleteBackgroundImageLink) {
					obj["backgroundImage"] = null
				}
			}

			formData.append("data", JSON.stringify(obj))

			if (this.state.isUsingAudio && this.state.audioBlobData !== null) {
				formData.append("files.audio", this.state.audioBlobData)
			}

			let auth = {
				headers: {
					 "Content-Type": `multipart/form-data;`,
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			axios.put(process.env.REACT_APP_DOMAIN + 'save-the-dates/' + this.state.saveTheDateID, formData, auth)//formData, auth)
				.then(async response => {
					this.setState({
						loading: false
					})


					if(openSendModal) {
						this.openSendModal()
					} else {
						toast.success("Save the Date salvat cu succes!")
						if (redirectToPreview) {
							this.props.history.push("/organizer/previewSaveTheDate/" + this.props.match.params.id)
						} else {
							this.props.history.push('/organizer/plan/' + this.props.match.params.id)
						}
					}
				})
				.catch(err => {
					toast.error("Eroare")
					this.setState({
						loading: false
					})
				})
		}


	}

	handleStartDate = (value) => {
		this.setState({
			dataEvent: value.toISOString()
		})
	}

	handlePoza = async (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				poza_saveTheDate: null
			})
			return
		}

		let blob = await resizeBlob(fileList[0].blobFile)

		this.setState({
			poza_saveTheDate: blob
		})
	}

	saveCroppedImage = async (croppedImg) => {
		let img = {}
		img.blobFile = croppedImg
		let data = await getBase64FromImage(img)
		// console.log(data);

		this.setState({
			croppedImage: croppedImg,
			base64ImageLink: data
		})
	}

	openModalChangeType = () => {
		this.setState({
			showModalChangeType: true
		})
	}

	closeModalChangeType = () => {
		this.setState({
			showModalChangeType: false
		})
	}

	changeType = () => {
		this.setState({
			saveTheDateType: "",
			isUsingNotifications: this.state.isUsingNotificationsInitialState,
			city: "",
			description: "",
			fotoID: "",
			linkVideo: "",
			poza_saveTheDate: null,
			fontSize: "20px",
			fontColor: "#000",
			fontFamily: "Open Sans",
			isChangingColor: false,
			isRecording: false,
			blobURL: '',
			isUsingAudio: false,
			serverAudioUrl: null,
			audioBlobData: null,
			showModalChangeType: false
		})
	}
	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				saveTheDate: true
			},
			auth)

	}

	render() {
		if (this.state.loadingData) {
			return <LoadingScreen />
		}

		let customInputStyle = {
			margin: "10px 0px",
			backgroundColor: "#E5F7FE",
			borderRadius: "10px"
		}

		let body = <div></div>

		if (this.state.saveTheDateType === "Personal") {
			body = <div>
				<div className="Upload-zone">
					{this.state.backgroundImageLink !== null
						? <div>
							<p>Invitatie personala</p>
							<img style={{ padding: "5px", width:'100%', alignSelf: "center" }} src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.backgroundImageLink)}`} alt="" />
						</div>
						: <p>Nicio imagine adaugata</p>
					}

					<h3> Adauga fotografie Save the Date </h3>
					{/* <p> Fisiere acceptate: .jpg, recomandam fotografii tip portret</p> */}
					<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }} autoUpload={false} multiple={false} disabled={this.state.poza_saveTheDate !== null}>
						<div style={{ color: "#221d5d", width: "100%", height: "100%" }} id="text">Click aici pentru a uploada fotografie</div>
					</Uploader>
				</div>

				{this.state.poza_saveTheDate !== null && <EditPicture imageLink={URL.createObjectURL(this.state.poza_saveTheDate)} saveCroppedImage={this.saveCroppedImage} />}

				<div className="Section">
					<div className="Title">Personalizare text trimitere SaveTheDate
					</div>

					{
						this.state.editSendSaveTheDateMessage ?
							<div className={""}>
                                        <textarea
											style={{width:'80%', height:'100px', margin:'auto', borderColor:'#ccc', borderRadius:'5px', padding:'5px', fontSize:'15px', fontWeight:'500'}}
											value={this.state.sendSaveTheDateMessage}
											onChange={(e) => this.setState({sendSaveTheDateMessage: e.target.value})}
										/>
							</div>
							:null
					}


					<div className="Options-Row">
						{
							this.state.editSendSaveTheDateMessage ?
								<div
									className={"active-option"}
									style={{width:'unset'}}
									onClick={_ => this.setState({editSendSaveTheDateMessage: false})}>Salvează
								</div>
								:
								<div
									className={"active-option"}
									style={{width:'unset'}}
									onClick={_ => this.setState({editSendSaveTheDateMessage: true})}>Editează
								</div>
						}
					</div>
				</div>

				{!window.flutter_inappwebview ? <div className="Section">
					<div className="Title">Doriti sa inregistrati un mesaj audio?</div>

					<div className="Options-Row">
						<div className={this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: true })} style={{ marginRight: "30px" }}>Da</div>
						<div className={!this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: false })}>Nu</div>
					</div>
				</div>
				:<div className="Section"><div className="Title">Pentru a adauga un mesaj audio va rugam sa folositi un laptop/pc</div></div>}

				{this.state.isUsingAudio && <div style={{ width: "100%", alignContent: "center", margin: "10px 0px" }}>
					{this.state.serverAudioUrl && <div style={{ textAlign: "center", marginLeft: "0px" }}> Audio curent </div>}

					{this.state.serverAudioUrl && <div className="Upload-Audio" style={{ marginBottom: "20px" }}>
						<PlayAudio link={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.serverAudioUrl}`} />

						<div className="Section">
							<div className="Title">Schimbati mesajul audio?</div>

							<div className="Options-Row">
								<div className={this.state.deleteServerAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ deleteServerAudio: true })} style={{ marginRight: "30px" }}>Da</div>
								<div className={!this.state.deleteServerAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ deleteServerAudio: false })}>Nu</div>
							</div>
						</div>
					</div>}

					{(this.state.deleteServerAudio || !this.state.serverAudioUrl) && <div style={{ textAlign: "center", marginLeft: "0px" }}> Inregistreaza mesaj audio {this.state.serverAudioUrl ? "(va inlocuii mesajul audio anterior)" : ""} </div>}

					{(this.state.deleteServerAudio || !this.state.serverAudioUrl) && <div className="Upload-Audio">
						{this.state.blobURL && <PlayAudio link={this.state.blobURL} />}

						{this.state.isRecording && <BsFillMicFill />}

						{
							this.state.timerStarted ?
								<div style={{textAlign:'center'}}>
									{this.state.timer} secunde
									{console.log(setTimeout(() => {
										this.setState({timer: this.state.timer+1})
									}, 1000))}
									<br/>
								</div>
								:null
						}

						<div className="Buttons">

							{!this.state.isRecording && <Button size="lg" appearance="primary" style={{ marginRight: "10px" }} onClick={this.start} disabled={this.state.isRecording}>Inregistreaza</Button>}

							{this.state.isRecording && <BsFillMicFill color={"red"} style={{ margin: "0px 10px", alignSelf: "center" }} />}

							<Button size="lg" color="red" style={{ marginLeft: "10px" }} onClick={this.stop} disabled={!this.state.isRecording}>Stop</Button>
						</div>
					</div>}
				</div>}
			</div>
		}

		if (this.state.saveTheDateType === "Video") {
			let linkParts = this.state.linkVideo.split("watch?v=")
			let link = ""
			if (linkParts.length === 1) {
				link = linkParts[0]
			} else {
				link = linkParts[0] + "embed/" + linkParts[1]
			}

			let videoId = ""

			if (this.state.linkVideo !== "") {
				videoId = this.state.linkVideo.split("watch?v=")[1].split("&")[0]
			}

			body = <div>
				{/* <iframe src={link.split("&")[0]}
					frameBorder='0'
					allow='autoplay; encrypted-media'
					allowFullScreen={true}
					title='video'
				/> */}
				<PlayVideo videoId={videoId} link={link.split("&")[0]} />

				<div>Daca nu merge video-ul, va rog acordati permisiunile necesare din youtube. </div>

				<CustomInput value={this.state.linkVideo} onChange={value => this.setState({ linkVideo: value })} placeholder={"Link video save the date"}
					style={customInputStyle} icon={<Icon icon="text-height" />} />
			</div>
		}

		if (this.state.saveTheDateType === "Custom") {
			body = <div>
				<CustomInput value={this.state.city} onChange={value => this.setState({ city: value })} placeholder={"In ce oras este evenimentul?"}
					style={customInputStyle} icon={<Icon icon="map-marker" />} />

				<CustomInput value={this.state.description} onChange={this.changeSaveTheDateDescription} placeholder={`Informatiile evenimentului ${this.state.description.length}/${textarea_max_length}`}
					style={customInputStyle} componentClass={"textarea"} icon={<Icon icon="pencil" />} />

				<div style={{ fontSize: "18px" }}>Format text</div>
				<div className="Invite-Design">
					<div>
						<p>Dimensiune</p>

						<Input value={this.state.fontSize.split("p")[0]} onChange={value => this.setState({ fontSize: value + "px" })} placeholder={"Dimensiune"}
							style={{ backgroundColor: "#E5F7FE", borderRadius: "10px", width: "100px" }} icon={<Icon icon="text-height" />} type={"number"} />
					</div>

					<div className="color-container">
						<p>Culoare</p>

						<div className="color-choice">
							<div style={{ height: "35px", width: "35px", backgroundColor: this.state.fontColor, borderRadius: "50%", border:'1px solid #000' }} onClick={() => this.setState({ isChangingColor: true })}></div>

							{this.state.isChangingColor &&
								<Modal classNames={{
									overlay: 'modal-overlay',
									modal: 'modal-colors',
									closeButton: 'modal-close-button',
								}} center open={this.state.isChangingColor} onClose={() => this.setState({ isChangingColor: false })}>
									<div className="color-options">
										<CirclePicker color={this.state.fontColor} onChange={newColor => this.setState({ fontColor: newColor.hex, isChangingColor: false })} />

										<div className="basic-color">
											<div className="basic-color-white" onClick={() => this.setState({ fontColor: "#fff", isChangingColor: false })}></div>
											<div className="basic-color-black" onClick={() => this.setState({ fontColor: "#000", isChangingColor: false })}></div>
										</div>
									</div>
								</Modal>}
						</div>
					</div>

					<div style={{ textAlign: "center" }}>
						<p>Font</p>

						<Dropdown title={this.state.fontFamily} activeKey={this.state.fontFamily} className="dropdown_scroll" style={{ backgroundColor: "#E5F7FE", borderRadius: "10px" }}>
							{this.state.fontsOptions.map((option, index) => {
								return <Dropdown.Item key={index} eventKey={option} onClick={() => this.setState({ fontFamily: option })}>{option}</Dropdown.Item>
							})}
						</Dropdown>
					</div>
				</div>

				<p style={{ fontSize: this.state.fontSize, fontFamily: this.state.fontFamily, color: "" + this.state.fontColor, marginBottom: "20px" }}>Text final</p>

				{this.state.backgroundImageLink !== null
					? <div>
						<p>Imagine fundal</p>
						<img style={{ padding: "5px", width:'100%', alignSelf: "center" }} src={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${getUrlFromImage(this.state.backgroundImageLink)}`} alt="" />

						{this.state.poza_saveTheDate === null && <div className="Section">
							<div className="Title">Doriti sa stergeti imaginea de fundal curenta?</div>

							<div className="Options-Row">
								<div className={this.state.deleteBackgroundImageLink ? "active-option" : "inactive-option"} onClick={_ => this.setState({ deleteBackgroundImageLink: true })} style={{ marginRight: "30px" }}>Da</div>
								<div className={!this.state.deleteBackgroundImageLink ? "active-option" : "inactive-option"} onClick={_ => this.setState({ deleteBackgroundImageLink: false })}>Nu</div>
							</div>
						</div>}
					</div>
					: <p>Nicio imagine adaugata</p>
				}

				<div className="input-section">
					<p>  {this.state.backgroundImageLink !== null ? "Schimba Fotografia de Fundal (va sterge imaginea curenta)" : "Adauga fotografie de fundal"} </p>

					<Uploader onChange={this.handlePoza} accept=".jpg" style={{ borderRadius: "5px" }} autoUpload={false}>
						<div style={{ color: "#221d5d", width: "100%" }}>Click aici pentru a incarca fotografia</div>
					</Uploader>
				</div>

				{this.state.poza_saveTheDate !== null && <EditPicture imageLink={URL.createObjectURL(this.state.poza_saveTheDate)} saveCroppedImage={this.saveCroppedImage} />}

				<div className="Section">
					<div className="Title">Personalizare text trimitere SaveTheDate
					</div>

					{
						this.state.editSendSaveTheDateMessage ?
							<div className={""}>
                                        <textarea
											style={{width:'80%', height:'100px', margin:'auto', borderColor:'#ccc', borderRadius:'5px', padding:'5px', fontSize:'15px', fontWeight:'500'}}
											value={this.state.sendSaveTheDateMessage}
											onChange={(e) => this.setState({sendSaveTheDateMessage: e.target.value})}
										/>
							</div>
							:null
					}


					<div className="Options-Row">
						{
							this.state.editSendSaveTheDateMessage ?
								<div
									className={"active-option"}
									style={{width:'unset'}}
									onClick={_ => this.setState({editSendSaveTheDateMessage: false})}>Salvează
								</div>
								:
								<div
									className={"active-option"}
									style={{width:'unset'}}
									onClick={_ => this.setState({editSendSaveTheDateMessage: true})}>Editează
								</div>
						}
					</div>
				</div>

				{!window.flutter_inappwebview ? <div className="Section">
					<div className="Title">Doriti sa inregistrati un mesaj audio?</div>

					<div className="Options-Row">
						<div className={this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: true })} style={{ marginRight: "30px" }}>Da</div>
						<div className={!this.state.isUsingAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingAudio: false })}>Nu</div>
					</div>
				</div>
					: <div className="Section">
						<div className="Title">Pentru a adauga un mesaj audio va rugam sa folositi un laptop/pc</div>
					</div>}

				{this.state.isUsingAudio && <div style={{ width: "100%", alignContent: "center", margin: "10px 0px" }}>
					{this.state.serverAudioUrl && <div style={{ textAlign: "center", marginLeft: "0px" }}> Audio curent </div>}

					{this.state.serverAudioUrl && <div className="Upload-Audio" style={{ marginBottom: "20px" }}>
						<PlayAudio link={`https://${process.env.REACT_APP_IMAGE_DOMAIN}${this.state.serverAudioUrl}`} />

						<div className="Section">
							<div className="Title">Schimbati mesajul audio?</div>

							<div className="Options-Row">
								<div className={this.state.deleteServerAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ deleteServerAudio: true })} style={{ marginRight: "30px" }}>Da</div>
								<div className={!this.state.deleteServerAudio ? "active-option" : "inactive-option"} onClick={_ => this.setState({ deleteServerAudio: false })}>Nu</div>
							</div>
						</div>
					</div>}

					{(this.state.deleteServerAudio || !this.state.serverAudioUrl) && <div style={{ textAlign: "center", marginLeft: "0px" }}> Inregistreaza mesaj audio {this.state.serverAudioUrl ? "(va inlocuii mesajul audio anterior)" : ""} </div>}

					{(this.state.deleteServerAudio || !this.state.serverAudioUrl) && <div className="Upload-Audio">
						{this.state.blobURL && <PlayAudio link={this.state.blobURL} />}

						{
							this.state.timerStarted ?
								<div style={{textAlign:'center'}}>
									{this.state.timer} secunde
									{console.log(setTimeout(() => {
										this.setState({timer: this.state.timer+1})
									}, 1000))}
								</div>
								:null
						}

						<div className="Buttons">

							{!this.state.isRecording && <Button size="lg" appearance="primary" style={{ marginRight: "10px" }} onClick={this.start} disabled={this.state.isRecording}>Inregistreaza</Button>}

							{this.state.isRecording && <BsFillMicFill color={"red"} style={{ margin: "0px 10px", alignSelf: "center" }} />}

							<Button size="lg" color="red" style={{ marginLeft: "10px" }} onClick={this.stop} disabled={!this.state.isRecording}>Stop</Button>
						</div>
					</div>}
				</div>}

				{/* <CustomInput value={this.state.linkVideo} onChange={value => this.setState({ linkVideo: value })} placeholder={"Link video Save the Date"}
					style={customInputStyle} icon={<Icon icon="text-height" />} /> */}
			</div>
		}

		return (
			<div className="EditSaveTheDate-container">
				<HeaderWhite goBack={this.goBack} />

				<LoadingModal loading={this.state.loading} />

				<Modal classNames={{
					overlay: 'modal-overlay',
					modal: 'modal',
					closeButton: 'modal-close-button',
				}} open={this.state.showModalChangeType} onClose={this.closeModalChangeType}>
					<div style={{ marginTop: "30px" }}>
						<div>Daca schimbati tipul de invitatie, pierdeti datele introduse in cadrul acesteia</div>

						<ConfirmButton text={"Confirm"} function={this.changeType} />
					</div>
				</Modal>

				<div className="EditSaveTheDate-contents">
					<h4> Editeaza Save the Date </h4>

					<div className="data-zone">
						{/* {this.state.saveTheDateType === "" && <div>
							<div className="Input_Type_Options">
								<div className="Input_Type_Option" onClick={_ => this.setSaveTheDateType("Personal")}>Invitatie personala</div>

								<div className="Input_Type_Option" onClick={_ => this.setSaveTheDateType("Video")}>Incarca link</div>

								<div className="Input_Type_Option" onClick={_ => this.setSaveTheDateType("Custom")}>Invitatie personalizata</div>
							</div>

							<BackButton text={"Înapoi"} function={this.goBack} />
						</div>}

						{this.state.saveTheDateType !== "" && <div>
							<div onClick={this.openModalChangeType} className="ChangeChoiceButton">
								Alege alt tip de invitatie
							</div>
						</div>} */}

						<SelectInviteType inviteType={this.state.saveTheDateType} setInviteType={this.setSaveTheDateType}
							goBack={this.goBack} openModalChangeType={this.openModalChangeType} text={"save the date"} />

						{body}

						{this.state.saveTheDateType !== "" && <div>
							<div className="Section">
								<div className="Title">Doriti sa primiti notificari cand primiti raspuns?</div>

								<div className="Options-Row">
									<div className={this.state.isUsingNotifications ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingNotifications: true })} style={{ marginRight: "30px" }}>Da</div>
									<div className={!this.state.isUsingNotifications ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isUsingNotifications: false })}>Nu</div>
								</div>
							</div>

							<div className="Section">
								<div className="Title">Doriti ca invitatia sa apara in cadrul evenimentului?</div>

								<div className="Options-Row">
									<div className={this.state.isPublished ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isPublished: true })} style={{ marginRight: "30px" }}>Da</div>
									<div className={!this.state.isPublished ? "active-option" : "inactive-option"} onClick={_ => this.setState({ isPublished: false })}>Nu</div>
								</div>
							</div>

							<Button
								onClick={()=> this.setState({tutorialModal:true})}
								color="green"
								style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"10px", marginBottom: "10px"}}>Informații utile</Button>

							<Button
								onClick={()=> this.createSaveTheDate(true)}
								color="green"
								style={{backgroundColor: "#F26157", borderRadius: "20px", width: "100%", marginTop:"10px", marginBottom: "10px"}}>Previzualizează</Button>

							<Button
								onClick={()=> {
									this.setSaveTheDateSentDate()
									this.createSaveTheDate(false, true)
								}}
								color="green"
								style={{backgroundColor: "green", borderRadius: "20px", width: "100%", marginTop:"10px", marginBottom: "20px"}}>Trimite invitația</Button>


							<BackAndConfirmButtons
								textBack={"Înapoi"}
								textConfirm={"Salveaza"}
								functionBack={this.goBack}
								functionConfirm={() => this.createSaveTheDate(false)} />

							<div style={{paddingTop:'20px'}}></div>
						</div>}
					</div>
				</div>

				<Modal
					classNames={{
						overlay: 'modal-overlay',
						modal: 'modal-no-top',
						closeButton: 'modal-close-button',
					}}
					open={this.state.tutorialModal}
					focusTrapped={false}
					onClose={this.updateTutorial}
				>
					<div style={{marginTop: "20px"}}>
						<h3>Informații utile</h3>
						<br/>
						<p>
							Cand dorești ca invitatii tai sa își „salveze data” - „Save the date” - ai posibilitatea de a crea acest lucru direct in platforma, foarte simplu si intuitiv. Totodată, dacă ai deja creat „Save the Date” îl poti importa, trebuie doar sa ții cont că documentul trebuie sa fie în format PNG sau JPEG. Dacă ai un clip prin care dorești sa iti anunti invitații să își „salveze ziua” , trebuie sa il incarci in YouTube și apoi sa adaugi linkul clipului în aplicația Ality.<br/>
							Cu atatea posibilitati, cat de greu poate fi? :)
							<br/><br/>
							Poți oricând să modifici detaliile prin accesarea meniului „Informații generale” -> „Save the date” -> „Editează”.<br/>
							După ce sunteți siguri că toate detaliile sunt corecte va ramane doar sa incepeti sa trimiteti „Save the date” către toți invitații. Totul este foarte simplu din meniul „Save the date” -> „Trimite” mai departe trebuie doar sa iti alegi cum vrei să o trimiți: email, Whatsapp, Facebook, etc.<br/>
							Pentru a vizualiza răspunsurile primite intrați în meniul „Informații eveniment” -> „Save the date” -> „Răspunsuri” sau din meniul „Participanți” -> „Răspunsuri save the date”
							<br/><br/>
							Te ținem la curent la fiecare pas prin e-mailuri și notificări pentru ca evenimentul să iasă așa cum ti-ai dorit! Poți verifica și fereastra „Informații utile” din fiecare meniu al aplicației.

						</p>

						<Button
							onClick={this.updateTutorial}
							color="green"
							style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
					</div>
				</Modal>

				<SendSaveTheDateModal
					showModal={this.state.showSendModal}
					setShowModal={(value) => this.setState({showSendModal: value})}
					event={this.state.weddingData}
				/>

				<NavbarColor history={this.props.history} />
			</div>
		)
	}
}

export default EditSaveTheDate;
