import React from 'react'
import { Uploader, Button } from 'rsuite'
import './AddMenu.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import NavbarColor from '../../../components/NavbarColor'
import { toast } from 'react-toastify'
import axios from 'axios'
import Modal from "react-responsive-modal";

class AddMenu extends React.Component {
	state = {
		eventId: "",

		isSaving: false,
		tutorialModal: false,

		pdfMeniu: null
	}

	componentDidMount = async () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getWedding/' + this.props.match.params.id, auth)
			.then(response => {
				this.setState({
					eventId: response.data.id,
					tutorialModal: !response.data.tutorials || (response.data.tutorials && !response.data.tutorials.menu)
				})
			})
			.catch(err => {
				localStorage.clear()
				toast.error("Informatiile de autentificare au expirat, va rugam sa va autentificati")
				this.props.history.push('/organizer/login');
			})
	}

	savePDF = (fileList) => {
		if (fileList.length === 0) {
			this.setState({
				pdfMeniu: null
			})
			return
		}

		this.setState({
			pdfMeniu: fileList[0].blobFile
		})
	}

	savePDFToServer = async () => {
		if (this.state.pdfMeniu === null) {
			toast.error("Nu ati incarcat pdf")
			return;
		}

		this.setState({
			isSaving: true
		})

		let formData = new FormData()
		formData.append("data", JSON.stringify({}))

		if (this.state.pdfMeniu !== null) {
			formData.append("files.menuFile", this.state.pdfMeniu)
		}

		let auth = {
			headers: {
				"Content-Type": `multipart/form-data;`,
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.put(process.env.REACT_APP_DOMAIN + 'weddings/' + this.state.eventId, formData, auth)
			.then(response => {
				toast.success("Meniu incarcat!")
				this.props.history.push('/organizer/adminEventPage/' + this.props.match.params.id)
			})
			.catch(err => {
				toast.error("Eroare")
			})

		this.setState({
			isSaving: false
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.match.params.id,
			{
				menu: true
			},
			auth)

	}

	goBack = () => {
		this.props.history.push("/organizer/info/" + this.props.match.params.id)
	}

	render() {
		return <div className="AddMenu-container">
			<HeaderWhite goBack={this.goBack} />

			<div className="AddMenu-contents">
				<p className="title"> Meniu </p>

				{
					window.flutter_inappwebview ?
						<p>Pentru a incarca meniul va rugam sa accesati aplicatia de pe un laptop sau PC.</p>
						:
						<Uploader onChange={this.savePDF} accept=".pdf" className="Uploader" autoUpload={false}
								  multiple={false}>
							<div style={{color: "#221d5d", width: "100%"}} id="text">Click pentru a încărca meniul de tip PDF</div>
						</Uploader>
				}

				<Button
					onClick={()=> this.setState({tutorialModal:true})}
					color="green"
					style={{backgroundColor: "#05AFF2", borderRadius: "20px", margin:'auto', width: "93%", marginTop:"10px", marginBottom: "20px"}}>Informații utile</Button>


				<div className="Buttons">
					<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack}>
						Înapoi
					</Button>

					{
						window.flutter_inappwebview ?
							null
							:
							<Button loading={this.state.isSaving} style={{
								margin: "20px 0px",
								backgroundColor: "#05AFF2",
								color: "white",
								borderRadius: "80px",
								width: "200px"
							}} onClick={this.savePDFToServer}>
								Încărcați meniul
							</Button>
					}
				</div>
			</div>

			<Modal
				classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-no-top',
					closeButton: 'modal-close-button',
				}}
				open={this.state.tutorialModal}
				focusTrapped={false}
				onClose={this.updateTutorial}
			>
				<div style={{marginTop: "20px"}}>
					<h3>Informații utile</h3>
					<br/>
					<p>
						Ai fost vreodată la un eveniment și ai rămas surprins de felurile de mancare? Cu Ality nu exista astfel de surprize, poate doar plăcute! Acum poti incarca meniul în aplicație, astfel incat invitații au timp suficient sa iti transmită prin intermediul aplicației intolerantele sau alergiile legate de meniul prezentat.
					</p>
					<Button
						onClick={this.updateTutorial}
						color="green"
						style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
				</div>
			</Modal>

			<NavbarColor history={this.props.history} />
		</div>
	}
}

export default AddMenu;