import React from 'react'
import './Votes.scss'
import axios from 'axios'
import { toast } from 'react-toastify'
import { BackAndConfirmButtons, ConfirmButton, CustomQuestions } from '../../components/Helper'
import {injectIntl} from 'react-intl';
class Votes extends React.Component {
	state = {
		current_questions_set: [],
		answers_sets: [],
		active_index: 0,
		title: "",

		isAnswering: false,
		needBackButton : false,
		loading: true
	}

	componentDidMount = () => {
		let current_active_set = this.props.event_data.active_vote_set
		let vote_set = this.props.event_data.vote_set[current_active_set]
		let title = vote_set.title
		let questions = []
		let answers_sets = []
		let isAnswering = false

		//take current questions
		for (let i = 0; i < vote_set.questions.length; i++) {
			let question = { ...vote_set.questions[i] }

			let elem = {
				title: question.title,
				type: question.type,
				options: []
			}

			if (elem.type === 1)
				question.options.map(element => {
					elem.options.push({ label: element, value: element })

					return null
				})

			questions.push(elem)
		}

		//take previous answers, if any
		if (this.props.participantData.vote_set_answers !== null) {
			answers_sets = [...this.props.participantData.vote_set_answers]
		}

		//fill answers for missing sets (add null) and current set (add "")
		if (answers_sets.length < this.props.event_data.vote_set.length) {
			for (let i = answers_sets.length; i < this.props.event_data.vote_set.length; i++) {
				let new_answer_set = []

				if (i === current_active_set) {
					isAnswering = true
				}

				for (let j = 0; j < this.props.event_data.vote_set[i].questions.length; j++) {
					new_answer_set.push("")
				}

				answers_sets.push(new_answer_set)
			}
		}

		//check if previously unanswered question set, is being asked again
		if (answers_sets[current_active_set] == null) {
			let new_answer_set = []

			for (let j = 0; j < this.props.event_data.vote_set[current_active_set].questions.length; j++) {
				new_answer_set.push("")
			}

			answers_sets[current_active_set] = new_answer_set
		}

		this.setState({
			current_questions_set: questions,
			answers_sets: answers_sets,
			active_index: current_active_set,
			title: title,
			isAnswering: isAnswering,
			loading: false
		})
	}

	changeAnswers = (newAnswers) => {
		let new_answers_sets = [...this.state.answers_sets]

		new_answers_sets[this.state.active_index] = newAnswers

		this.setState({
			answers_sets: new_answers_sets,
			showConfirmButton: true
		})
	}

	saveAnswers = () => {
		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		let obj = {
			vote_set_answers: this.state.answers_sets
		}
		// console.log(obj);

		axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
			.then(_ => {
				toast.success("Raspuns trimis")
				this.props.participantData.vote_set_answers = this.state.answers_sets

				this.setState({
					isAnswering: false
				})
			})
			.catch(err => {
				console.log(err);
				toast.error("S-a produs o eroare, va rog reincercati sau reveniti mai tarziu")
			})
	}

	render() {
		if (this.state.loading) {
			return <div className="Votes-container">
			</div>
		}

		if (!this.state.isAnswering) {
			return <div className="Votes-container">
				<div>Ati trimis raspuns pentru acest set de intrebari</div>

				<ConfirmButton text={"Editeaza"} function={() => {this.setState({isAnswering: true, needBackButton: true})}} />
			</div>
		}

		return (
			<div className="Votes-container">
				<div style={{ fontSize: "18px", fontWeight: "bold" }}>{this.state.title}</div>

				<CustomQuestions questions={this.state.current_questions_set} answers={this.state.answers_sets[this.state.active_index]} changeAnswers={this.changeAnswers} intl={this.props.intl}/>

				{this.state.showConfirmButton && !this.state.needBackButton && <ConfirmButton text={"Trimite"} function={this.saveAnswers} />}

				{this.state.needBackButton && <BackAndConfirmButtons textBack={"Inapoi"} textConfirm={"Salveaza"} functionBack={() => {this.setState({isAnswering: false})}} functionConfirm={this.saveAnswers} />}
			</div>
		)
	}
}

export default injectIntl(Votes);
