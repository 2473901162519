import React from 'react'
import { Button, Icon } from 'rsuite'
import axios from 'axios'
import { toast } from 'react-toastify'

import HeaderWhite from '../../../../../components/HeaderWhite'

import './AddTablesData.scss'
import NavbarColor from '../../../../../components/NavbarColor'
import CustomInput from '../../../../components/custom/CustomInput'
import Modal from "react-responsive-modal";

class AddTablesData extends React.Component {
	state = {
		tableTypeState: "",
		tableSizeState: "",
		tableCountState: "",

		tutorialModal: !this.props.eventData.tutorials || (this.props.eventData.tutorials && !this.props.eventData.tutorials.seatingChart),
		tableArray: []
	}

	addData = () => {
		if (this.state.tableTypeState === "") {
			toast.error("Va rog alegeti un tip de masa")
			return
		}

		if (this.state.tableSizeState === "" || this.state.tableSizeState === "") {
			toast.error("Nu ati completat ambele campuri")
			return
		}

		let tableData = {
			tableTypeArray: this.state.tableTypeState,
			tableSizeArray: this.state.tableSizeState,
			tableCountArray: this.state.tableCountState
		}

		let newData = [...this.state.tableArray, tableData]

		this.setState({
			tableSizeState: "",
			tableCountState: "",

			tableArray: newData
		})
	}

	confirmData = async () => {
		if (this.state.tableArray.length === 0) {
			toast.error("Nicio masa adaugata")
			return
		}

		var newPosition = []

		var id = 0
		this.state.tableArray.map(data => {
			for (var i = 0; i < Number(data.tableCountArray); i++) {
				let pos = {
					id: id,
					x: 100,
					y: 100,
					type: data.tableTypeArray === "Rotunda" ? "roundTable" : data.tableTypeArray === "Patrata" ? "squareTable" : "rectangleTable",
					tableSize: Number(data.tableSizeArray),
					peopleAtTable: []
				}

				newPosition[id] = pos

				id++
			}

			return null
		})

		let obj = {
			toRender: [],
			positions: newPosition,
			user: localStorage.getItem('id'),
			wedding: this.props.event_id + ""
		}

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

		await axios.post(process.env.REACT_APP_DOMAIN + 'seating-charts', obj, auth)
			.then(response => {
				toast.success("Salvare efectuata cu succes")

				this.props.goToNext(newPosition, response.data.id)
			})
			.catch(err => {
				console.log(err);
				toast.error("S-a produs o eroare, va rog reincercati")
			})
	}

	deleteTable = (tableIndex) => {
		let newTableArray = this.state.tableArray.filter((_, index) => index !== tableIndex)

		this.setState({
			tableArray: newTableArray
		})
	}

	updateTutorial = async () => {

		this.setState({
			tutorialModal: false
		})

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}

	 await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + this.props.eventData.id_eveniment,
			{
				seatingChart: true
			},
			auth)

	}

	goBack = () => {
		this.props.history.go(-1)
	}

	render() {
		return <div className="AddTablesData-container">
			<HeaderWhite shadow={true} goBack={this.goBack} />

			<p className="Title"> Plan locatie </p>

			<div className="AddTablesData-contents">
				<div className="AddTablesData-TableType">
					<div className={this.state.tableTypeState === "Rotunda" ? "active-tableType" : "tableType"} onClick={() => this.setState({ tableTypeState: "Rotunda" })}>
						Rotunda
					</div>

					<div className={this.state.tableTypeState === "Patrata" ? "active-tableType" : "tableType"} onClick={() => this.setState({ tableTypeState: "Patrata" })}>
						Patrata
					</div>

					<div className={this.state.tableTypeState === "Dreptunghiulara" ? "active-tableType" : "tableType"} onClick={() => this.setState({ tableTypeState: "Dreptunghiulara" })}>
						Dreptunghiulara
					</div>
				</div>

				<CustomInput style={{ margin: "10px 0px", backgroundColor: "#E5F7FE", borderRadius: "10px" }} inputType={"number"} value={this.state.tableCountState} onChange={value => { this.setState({ tableCountState: value }) }} placeholder={"Nr de astfel de mese"} />

				<CustomInput style={{ margin: "10px 0px", backgroundColor: "#E5F7FE", borderRadius: "10px" }} inputType={"number"} value={this.state.tableSizeState} onChange={value => { this.setState({ tableSizeState: value }) }} placeholder={"Nr persoane la masa"} />

				{this.state.tableCountState !== "" && this.state.tableSizeState !== "" && this.state.tableTypeState !== "" && <p>
					{this.state.tableCountState === "1" ? "o masa" : this.state.tableCountState + " mese"} {this.state.tableTypeState === "Rotunda" ? "rotund" : this.state.tableTypeState === "Patrata" ? "patrat" : "dreptunghiular"}
					{this.state.tableCountState === "1" ? "a" : "e"} de {this.state.tableSizeState} persoane
				</p>}

				<Button style={{ backgroundColor: "#05AFF2", color: "white", borderRadius: "10px" }} onClick={this.addData} > Adauga </Button>

				<p>Lista mese:</p>

				<div className="AddTablesData-Data">
					{this.state.tableArray.map((data, index) => {
						return <div key={index} >
							{data.tableCountArray === "1" ? "o masa" : data.tableCountArray + " mese"} {data.tableTypeArray === "Rotunda" ? "rotund" : data.tableTypeArray === "Patrata" ? "patrat" : "dreptunghiular"}
							{data.tableCountArray === "1" ? "a" : "e"} de {data.tableSizeArray} persoane <Icon icon="close-circle" style={{ color: "red", marginLeft: "10px" }} onClick={() => { this.deleteTable(index) }} />
						</div>
					})}
				</div>

				<div className="Buttons">
					<Button style={{ margin: "20px 20px 20px 0px", color: "#1E1C59", border: "1px solid #1E1C59", borderRadius: "80px", width: "200px" }} onClick={this.goBack} >
						Înapoi
					</Button>

					<Button style={{ margin: "20px 0px", backgroundColor: "#05AFF2", color: "white", borderRadius: "80px", width: "200px" }} onClick={this.confirmData} >
						Confirma mese
					</Button>
				</div>
			</div>

			<Modal
				classNames={{
					overlay: 'modal-overlay',
					modal: 'modal-no-top',
					closeButton: 'modal-close-button',
				}}
				open={this.state.tutorialModal}
				focusTrapped={false}
				onClose={this.updateTutorial}
			>
				<div style={{marginTop: "20px"}}>
					<h3>Informații utile</h3>
					<br/>
					<p>
						Asezatul la mese a fost mereu un subiect delicat: invitații nu se cunosc, unii nu au o relație buna cu ceilalți, unii prefera o anumită categorie de varsta etc. Tocmai de aceea, în aplicația Ality reușești sa faci planul perfect al locației cu puncte de interes (mese, photobooth, bar, cocktail bar, zona de dans, scena, etc.) pe baza răspunsurilor invitaților la celelalte întrebări adresate în aplicație. Nu-ți face griji, totul este editabil și poți oricând face schimbările dorite!
						La început alegi tipul, numărul meselor și cati oameni vor fi asezati la fiecare masa.
						<br/><br/>
						La final, cand toata lumea este asezata la mese ai posibilitatea sa publici planul locației, astfel toți invitații care au aplicația descarcata in telefon vor putea vedea din timp unde si cu cine vor sta.
						<br/><br/>
						P.S. Pentru a crea planul locației, va rugăm sa folosiți inițial o tableta cu ecranul de minim 10”, un laptop sau un PC. Ulterior, planul locației se poate edita și direct de pe dispozitivul mobil.
					</p>
					<Button
						onClick={this.updateTutorial}
						color="green"
						style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%", marginTop:"20px"}}>Ok</Button>
				</div>
			</Modal>

			<NavbarColor history={this.props.history} />
		</div>
	}
}

export default AddTablesData;