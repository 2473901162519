import React from 'react'
import { Button } from 'rsuite'
import ParintiModal from './Modals/ParintiModal'
import './ParintiComponent.scss'

class ParintiComponent extends React.Component {
	state = {
		parinti: "",
		showModal: false,
	}

	toggleModal = () => {
		let current = this.state.showModal;

		this.setState({
			showModal: !current
		})
	}

	render() {
		let line1 = "";

		let line2 = "";

		if (this.props.mamaMireasa !== "") {
			line1 = this.props.mamaMireasa
		}

		if (this.props.tataMireasa !== "") {

			if (this.props.mamaMireasa !== "")
				line1 += " & "

			line1 += this.props.tataMireasa
		}

		line1 += " " + this.props.numeParintiMireasa

		if (this.props.mamaMire !== "") {
			line2 = this.props.mamaMire
		}

		if (this.props.tataMire !== "") {
			if (this.props.mamaMire !== "")
				line2 += " & "

			line2 += this.props.tataMire
		}

		line2 += " " + this.props.numeParintiMire

		let buttonStyle = {
			backgroundColor: "#05AFF2",
			color: "white",
			width: "100%",
			height: "40px",
			fontSize: "20px",
			marginBottom: "10px"
		}

		return (
			<div className="ParintiComponent-container">
				<div className="ParintiComponent-content">
					<ParintiModal eventType={this.props.eventType} mamaMireasa={this.props.mamaMireasa} tataMireasa={this.props.tataMireasa} numeParintiMire={this.props.numeParintiMire}
					mamaMire={this.props.mamaMire} tataMire={this.props.tataMire} numeParintiMireasa={this.state.numeParintiMireasa} handleChange={this.props.handleChange} showModal={this.state.showModal} toggleModal={this.toggleModal} />

					{(this.props.mamaMireasa !== "" && this.props.tataMireasa !== "" && this.props.mamaMire !== "" && this.props.tataMire !== "")
						? <Button style={buttonStyle} onClick={this.toggleModal}> Modifica Nume Parinti </Button>
						: <Button style={buttonStyle} onClick={this.toggleModal}> Adauga Nume Parinti </Button>
					}

					<div className="Display">
						{(line1 !== "") ? <p className="nas-element"> {line1} </p> : null}

						{(line2 !== "") ? <p className="nas-element"> {line2} </p> : null}
					</div>
				</div>
			</div>
		)
	}
}


export default ParintiComponent;