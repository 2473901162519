import React, {useState, useEffect} from 'react'
import './HostessInvite.scss'
import HeaderWhite from '../../../components/HeaderWhite'

import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import {ConfirmButton} from '../../components/Helper';
import {useHistory, useParams} from "react-router-dom";

export default function HostessInvite() {

    let history = useHistory()
    let {uid} = useParams()

    const [invite, setInvite] = useState({})

    useEffect(() => {
        getInvite()
        // eslint-disable-next-line
    }, [])


    const getInvite = async () => {

        if(!localStorage.getItem('jwt') ||  localStorage.getItem('jwt').length === 0)  {
            return;
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            uid: uid
        }

       await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/hostess/getInvite', payload, auth)
            .then(response => {
                setInvite(response.data)
            })
            .catch(err => {
                setInvite({
                    status: 0
                })
            })



    }

    const accept = async () => {
        if(!localStorage.getItem('jwt') ||  localStorage.getItem('jwt').length === 0)  {
            return;
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            uid: uid
        }

       await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/hostess/acceptInvite', payload, auth)

        toast.success('Invitatia a fost acceptata!')
        window.location.href = 'https://app.ality.ro?' + localStorage.getItem("jwt")
    }

    const goBack = () => {
        history.goBack()
    }


    return (
        <div className="HostessInvite-container">
            <HeaderWhite goBack={goBack}/>

            <div className={"HostessInvite-contents"}>


                {
                    invite.status === 0 ?
                        <div>
                            <p>Aceasta invitatie se adreseaza unei alte persoane sau a fost deja acceptata!</p>
                        </div>
                        :null
                }

                {
                    (!localStorage.getItem('jwt') ||  localStorage.getItem('jwt').length === 0)  ?
                        <div>
                            <p>Pentru a putea accepta invitatia va rugam sa va autentificati!</p>

                            <ConfirmButton
                                function={() => history.push('/login?redirectBackTo=organizer/hostessInvite/'+uid)}
                                text={"Autentificare"}
                            />
                        </div>
                        :
                        <div>

                            {
                                invite && invite.wedding ?
                                    <>

                                        <p className="Name">

                                            Ai fost invitat ca hostess in cadrul evenimentului <br/>

                                            {invite.wedding.eventType === "Nunta" &&
                                            <> Nunta {invite.wedding.prenumeMire} & {invite.wedding.prenumeMireasa} </>}

                                            {invite.wedding.eventType === "Botez" &&
                                            <> Botez {invite.wedding.prenumeMire}</>}

                                            {invite.wedding.eventType === "Petrecere" &&
                                            <> Petrecere {invite.wedding.prenumeMire}</>}

                                        </p>


                                        <ConfirmButton
                                            text={"Accept invitatia"}
                                            function={() => accept()}
                                        />
                                    </>
                                    : null
                            }
                        </div>
                }

            </div>
            <NavbarColor history={history}/>
        </div>
    )

}
