import React from 'react'
import './MenuPage.scss'
// import axios from 'axios'
import PdfViewer from '../../components/PdfViewer/PdfViewer'
import axios from 'axios'
import AdModal from '../../components/AdModal';

let adFieldName = "meniu"

class MenuPage extends React.Component {
	state = {
		event_data: this.props.event_data,

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}

	componentDidMount = async () => {
		if (this.props.event_data.menu.length > 0 && this.props.participantData !== null) {
			if (this.props.participantData.menu !== this.props.event_data.menu[0].url) {
				//update local data
				this.props.participantData.menu = this.props.event_data.menu[0].url

				//update server data
				let auth = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					}
				}

				let obj = {
					menu: this.props.participantData.menu,
				}

				axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
			}
		}
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		if (this.state.showAdModal) {
			return <div className="MenuPage-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		return (
			this.state.event_data.menu.length === 0
				? <div className="MenuPage-container">
					<p>Pdf-ul meniului nu a fost incarcat</p>
				</div>
				: <PdfViewer pdfUrl={this.state.event_data.menu[0].url} />
		)
	}
}

export default MenuPage;