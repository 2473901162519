import React from 'react'
import './SeatingChart.scss'

import axios from 'axios'
import PdfViewer from '../components/PdfViewer/PdfViewer'
import AdModal from '../components/AdModal';

let adFieldName = "planlocatie"

class SeatingChart extends React.Component {
	state = {
		event_data: this.props.event_data,

		showAdModal: this.props.shoudShowAdModal(this.props.adData, adFieldName)
	}

	componentDidMount = async () => {
		if (this.props.event_data.plan_mese.length > 0 && this.props.participantData !== null) {
			if (this.props.participantData.last_plan_mese !== this.props.event_data.plan_mese[0].url) {
				//update local data
				this.props.participantData.last_plan_mese = this.props.event_data.plan_mese[0].url

				//update server data
				let auth = {
					headers: {
						'Authorization': 'Bearer ' + localStorage.getItem('jwt')
					}
				}

				let obj = {
					last_plan_mese: this.props.participantData.last_plan_mese,
				}

				axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
			}
		}
	}

	closeAdModal = () => {
		this.props.updateSeenAds(adFieldName)

		this.setState({
			showAdModal: false
		})
	}

	render() {
		if (this.state.showAdModal) {
			return <div className="SeatingPlan-container">
				<AdModal showModal={this.state.showAdModal} closeModal={this.closeAdModal} adData={this.props.adData} />
			</div>
		}

		return (
			this.state.event_data.plan_mese.length === 0
				? <div className="SeatingPlan-container">
					<p>Planul de mese nu a fost incarcat</p>
				</div>
				: this.state.event_data.id === 97 ?
				<img src={"/images/plan_mese_superbet3.png"} style={{width:'100%', marginTop:'20px'}} alt={""}/>
				: <PdfViewer pdfUrl={this.state.event_data.plan_mese[0].url} />
		)
	}
}

export default SeatingChart;
