import React from 'react'
import './EventNews.scss'
import {FormattedMessage} from "react-intl";

class EventNews extends React.Component {
	state = {
		event_data: this.props.event_data,
		announcement: this.props.event_data.announcement
	}

	getTimeFromData = (data) => {
		var parts = data.split("T")
		var date = parts[0].split("-")
		var hour = parts[1].split(":")

		return (Number(hour[0]) + 3) % 24 + ":" + hour[1] + "  " + date[2] + "-" + date[1] + "-" + date[0]
	}

	showAnnouncement = (announcement) => {
		return <div key={announcement.id} className="announcement">
			<p style={{ fontWeight: "bold" }}><FormattedMessage id={"event_page.announces.title"} />: {announcement.titlu}</p>

			<div className="content" dangerouslySetInnerHTML={{ __html: `<div class="content">${announcement.continut}</div>` }}></div>

			<p style={{ backgroundColor: "#A5A5A5", borderRadius: "20px", width: "150px", margin: "0px auto", color: "white" }}>{this.getTimeFromData(announcement.updated_at)}</p>
		</div>
	}

	getAnnouncements = (ann) => {
		var ans = []

		for (var i = ann.length - 1; i >= 0; i--) {
			ans.push(this.showAnnouncement(ann[i]))
		}

		return ans
	}

	render() {
		return (
			<div className="Announcements-container">
				{this.state.announcement.length > 0
					? this.getAnnouncements(this.state.announcement)
					: <p style={{ fontSize: 20 }}><FormattedMessage id={"event_page.announces.no_items"} /></p>
				}
			</div>
		)
	}
}

export default EventNews;
