import React from 'react'
import './IntermediatePageEvents.scss'
import HeaderWhite from '../components/HeaderWhite'
import axios from 'axios'
import NavbarColor from '../components/NavbarColor'
import SettingCard from '../components/SettingCard'

import SVG_Events from '../assets/forIntermediatePage/informatii-icon.svg'
import SVG_My_Events from '../assets/forIntermediatePage/participanti-icon.svg'

class IntermediatePageEvents extends React.Component {
	state = {
		showOptionsFor: "Events"
	}

	componentDidMount = async () => {
		if (this.props.location.search !== "") {
			let jwt = this.props.location.search.split("?")[1]

			if (jwt === "null") {
				localStorage.clear();
			} else {
				localStorage.setItem("jwt", this.props.location.search.split("?")[1])
			}
		}

		if (this.props.location.search !== "" && localStorage.getItem("id") === null) {
			let auth = {
				headers: {
					'Authorization': 'Bearer ' + localStorage.getItem('jwt')
				}
			}

			axios.get(process.env.REACT_APP_DOMAIN + 'users/me', auth)
				.then(response => {
					localStorage.setItem("id", response.data.id)
					localStorage.setItem("rememberMe", "remember")
				})
		}
	}

	showOptionsForEvents = () => {
		this.setState({
			showOptionsFor: "Events"
		})
	}

	showOptionsForMyEvents = () => {
		this.setState({
			showOptionsFor: "MyEvents"
		})
	}

	goToEvents = (category) => {
		localStorage.setItem('choiceInEvents', category)

		this.props.history.push('/events')
	}

	goToMyEvents = (category) => {
		localStorage.setItem('choiceInMyEvents', category)

		this.props.history.push('/myEvents')
	}

	render() {
		return (
			<div className="IntermediatePageEvents-container">
				<HeaderWhite />

				<div className="IntermediatePageEvents-contents">
					<SettingCard svg={SVG_Events} onClick={this.showOptionsForEvents} name="Lista evenimente" dropDown={this.state.showOptionsFor === "Events"} />

					{this.state.showOptionsFor === "Events" && <div>
						<SettingCard icon="" onClick={() => { this.goToEvents("All") }} name="Toate" isSmall={true} />

						<SettingCard icon="" onClick={() => { this.goToEvents("Public") }} name="Publice" isSmall={true} />

						<SettingCard icon="" onClick={() => { this.goToEvents("Private") }} name="Private" isSmall={true} />

						<SettingCard icon="" onClick={() => { this.goToEvents("Speciale") }} name="Speciale" isSmall={true} />

						<SettingCard icon="" onClick={() => { this.goToEvents("Corporate") }} name="Corporate" isSmall={true} />

						<SettingCard icon="" onClick={() => { this.goToEvents("Finished") }} name="Finalizate" isSmall={true} />
					</div>}

					<SettingCard svg={SVG_My_Events} onClick={this.showOptionsForMyEvents} name="Evenimentele la care particip" dropDown={this.state.showOptionsFor === "MyEvents"} />

					{this.state.showOptionsFor === "MyEvents" && <div>
						<SettingCard icon="" onClick={() => { this.goToMyEvents("Toate") }} name="Toate" isSmall={true} />

						<SettingCard icon="" onClick={() => { this.goToMyEvents("Favorite") }} name="Favorite" isSmall={true} />

						<SettingCard icon="" onClick={() => { this.goToMyEvents("Terminate") }} name="Terminate" isSmall={true} />
					</div>}
				</div>

				<NavbarColor history={this.props.history} active={""} />
			</div>
		)
	}
}

export default IntermediatePageEvents;