import React from 'react'
import './DiplomaTab.scss'
import axios from 'axios'
import {BackButton, ConfirmButton} from '../../components/Helper'
import {injectIntl} from 'react-intl';
import Loader from "../../components/Loader";

class DiplomaTab extends React.Component {
    state = {
        event_data: this.props.event_data,
        areFeedbackQuestionsPublished: this.props.event_data.published_feedback_questions === true,
        feedbackQuestions: [],
        feedbackQuestionsAnswers: [],
        isAnswerSend: this.props.participantData.questions_answers_feedback !== null && this.props.participantData.questions_answers_feedback.length > 0,

        has_change: false,
        is_sending: false,
        showQuestionsAnswers: false,

        diplomaImageUrl: null,
        noDiploma: null,
        sendByEmailLoading: false,
        sentByEmail: false
    }

    componentDidMount = () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.get(process.env.REACT_APP_DOMAIN + "event/extra/getSartissDiploma/" + this.props.participantData.id, auth)
            .then(response => {
                if (response.data.status === 1) {
                    this.setState({
                        diplomaImageUrl: response.data.url
                    })
                } else {
                    this.setState({noDiploma: true})
                }
            })
            .catch(err => {

            })
    }

    downloadImage = async () => {

        if (!this.state.downloadLoading) {
            this.setState({downloadLoading: true})
        } else {
            return
        }

        fetch(process.env.REACT_APP_DOMAIN + this.state.diplomaImageUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'image/jpg',
            },
        })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'diploma.jpg',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // Clean up and remove the link
                link.parentNode.removeChild(link);

                this.setState({downloadLoading: false})
            });
    }

    downloadImageWebview = async () => {
        window.ReactNativeWebView.postMessage(JSON.stringify({
            action: 'downloadMedia',
            url: process.env.REACT_APP_DOMAIN + this.state.diplomaImageUrl,
        }))
    }

    sendByEmail = async () => {

        this.setState({sendByEmailLoading: true, sentByEmail: true})
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.get(process.env.REACT_APP_DOMAIN + "event/extra/sendSartissDiplomaByEmail/" + this.props.participantData.id, auth)
            .then(response => {
                if (response.data.status === 1) {
                    this.setState({sentByEmail: true})
                }
                this.setState({sendByEmailLoading: true})
            })
            .catch(err => {
                this.setState({sendByEmailLoading: true})
            })
    }

    render() {
        return (
            <div className="DiplomaTab-container">
                {this.state.areFeedbackQuestionsPublished
                    ? <div className="DiplomaTab-contents">
                        {this.state.isAnswerSend
                            ? <div>
                                {
                                    this.state.diplomaImageUrl ?
                                        <>
                                            <img src={process.env.REACT_APP_DOMAIN + this.state.diplomaImageUrl}
                                                 style={{width: '100%'}} alt={""}/>

                                            {
                                                window.alityAppView ?
                                                    <div style={{width: '100%'}}>
                                                        <ConfirmButton
                                                            style={{width:'90%', maxWidth:'400px'}}
                                                            text={'Salvează în galeria foto'}
                                                            function={() => this.downloadImageWebview()}
                                                        />
                                                    </div>
                                                    :
                                                    <div style={{width: '100%'}}>
                                                        <ConfirmButton
                                                            style={{width:'90%', maxWidth:'400px'}}
                                                            text={'Descarcă diploma'}
                                                            function={() => this.downloadImage()}
                                                        />
                                                    </div>
                                            }

                                            <div style={{width: '100%'}}>

                                                {
                                                    this.state.sentByEmail ?
                                                        <p>Diploma a fost trimisă pe adresa de email {this.props.user_data.email}! Dacă doriți să
                                                            schimbați adresa de email vă rugăm accesați secțiunea <strong>Contul meu -> Schimbă adresa de email</strong></p>
                                                        :
                                                    this.state.sendByEmailLoading ?
                                                        <Loader/>
                                                        :
                                                        <BackButton
                                                            style={{width:'90%', maxWidth:'400px'}}
                                                            text={'Trimite-mi diploma pe email'}
                                                            function={() => this.sendByEmail()}
                                                        />
                                                }
                                            </div>
                                        </>
                                        :
                                        this.state.noDiploma ?
                                            <p>{this.props.intl.formatMessage({id: 'diploma_tab.no_diploma_for_you'})}</p>
                                            :
                                            <Loader/>
                                }

                            </div>
                            : <div>
                                <br/>
                                <p>{this.props.intl.formatMessage({id: 'diploma_tab.please_fill_feedback_form'})}</p>
                                <br/>
                                <ConfirmButton loading={this.state.is_sending}
                                               text={this.props.intl.formatMessage({id: 'diploma_tab.button_fill_feedback_form'})}
                                               style={{}}
                                               function={this.props.goToFeedbackTab}/>
                            </div>
                        }</div>
                    : <p></p>
                }
            </div>
        )
    }
}

export default injectIntl(DiplomaTab);
