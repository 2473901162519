import React from 'react'
import {Button} from 'rsuite'
import './CustomModal.scss'
import {toast} from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import {CustomQuestions} from '../../../components/Helper'
import {FormattedMessage} from "react-intl";
import {injectIntl} from 'react-intl';
var sent = false;

class CustomModalUpdate extends React.Component {
    state = {
        event_data: this.props.event_data,
        areQuestionsPublished: this.props.event_data.published_questions === true,
        questions: [],
        questionsAnswers: [],
        hasCustomQuestions: false,

        name: this.props.user_data.last_name,
        surname: this.props.user_data.first_name,
        hasClaseSerii: false,
        clase_serii: [],
        seria: "",
        phase: 0,
        createParticipantLoading: false,

        hadNames: false,

        loading: true
    }

    componentDidMount = () => {
        let hasQuestions = this.props.event_data.activate_questions && this.props.event_data.published_questions

        this.setState({
            hasCustomQuestions: hasQuestions
        })

        if (hasQuestions) {
            let questions = []
            let answers = []

            for (var i = 0; i < this.props.event_data.questions.length; i++) {
                let question = {...this.props.event_data.questions[i]}

                let elem = {
                    title: question.title,
                    type: question.type,
                    options: []
                }

                if(typeof question.conditionQuestionIndex !== "undefined" && question.condition && question.conditionQuestionIndex > -1) {
                    elem['condition'] = question.condition
                    elem['conditionQuestionIndex'] = question.conditionQuestionIndex
                }

                if (elem.type === 1 || elem.type === 2)
                    question.options.map(element => {
                        elem.options.push({label: element, value: element})

                        return null
                    })

                questions.push(elem)
                answers.push("")
            }


            this.setState({
                questions: questions,
                questionsAnswers: answers
            })
        }

        if (this.props.event_data.activate_clase_serii) {
            this.setState({
                hasClaseSerii: true,
                clase_serii: this.props.event_data.clase_serii.split("\n")
            })
        }

        this.setState({
            loading: false
        })
    }

    setName = (value) => {
        this.setState({
            name: value
        })
    }

    setSurname = (value) => {
        this.setState({
            surname: value
        })
    }

    changeAnswers = (newAnswers) => {
        this.setState({
            questionsAnswers: newAnswers
        })
    }

    saveAnswers = async () => {

        //Validate fields

        let valid = true
        this.state.questionsAnswers.forEach((answer,i) => {

            //Check for conditions
            if(typeof this.state.questions[i].conditionQuestionIndex !== "undefined" && this.state.questions[i].conditionQuestionIndex > -1) {

                let conditionValid = false

                //Find conditions
                let orConditions = this.state.questions[i].condition.split('||')
                for(let j=0; j<orConditions.length; j++) {

                    if(orConditions.length > 0) {
                        if (this.state.questionsAnswers[this.state.questions[i].conditionQuestionIndex] === orConditions[j]) {
                            conditionValid = true
                        }
                    }
                }

                if(conditionValid) {
                    if(!answer || answer.length === 0) {
                        valid = false
                    }
                }

            } else {
                if(!answer || answer.length === 0) {
                    valid = false
                }
            }

        })

        if(!valid) {
            toast.error(<FormattedMessage id={"event_page.general_tab.custom_modal_update.please_answer_all_questions"} />)
            return
        }

        if(!valid) {
            toast.error(<FormattedMessage id={"event_page.general_tab.custom_modal_update.please_answer_all_questions"} />)
            return
        }


        if (sent) {
            return;
        }

        if (this.state.createParticipantLoading) {
            return;
        } else {
            this.setState({
                createParticipantLoading: true
            })
        }

        sent = true;

        let obj = {
            questions_answers: JSON.stringify(this.state.questionsAnswers)
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.put(process.env.REACT_APP_DOMAIN + "participants/updateMyParticipant/" + this.props.participantData.id, obj, auth)
            .then(_ => {
                this.setState({
                    createParticipantLoading: false
                })

                sent = false
                this.props.toggleModal();
                return
            })
            .catch(_ => {
                this.setState({
                    createParticipantLoading: false
                })
                toast.error("S-a produs o eroare. Va rugăm reîncercați!")
                sent = false
            })
    }

    render() {
        if (this.state.loading) {
            return <></>
        }

        const styles = {
            fontFamily: "sans-serif",
            textAlign: "center",
        };

        var body = <div></div>

            body = (
                <div className="body">
                    <p><FormattedMessage id={"event_page.general_tab.custom_modal_update.please_fill_in_the_form"} /></p>
                    <CustomQuestions questions={this.state.questions} answers={this.state.questionsAnswers}
                                     changeAnswers={this.changeAnswers} required={true} intl={this.props.intl}/>

                    <div className="buttons">
                        <Button onClick={this.saveAnswers} style={{
                            marginTop: '20px',
                            borderRadius: "20px",
                            backgroundColor: "#05AFF2",
                            color: "white"
                        }}>
                            <FormattedMessage id={"event_page.general_tab.custom_modal_update.send_answer"} />
                        </Button>
                    </div>
                </div>
            )


        return (
            <div style={styles}>
                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal customModalDisableScroll',
                        closeButton: 'modal-close-button',
                    }}
                    center
                    open={localStorage.getItem('jwt') ? (this.state.loading ? false :this.props.showModal) : false}
                    onClose={this.props.toggleModal}
                    showCloseIcon={false}
                    closeOnOverlayClick={false}
                    closeOnEsc={false}
                >
                    {body}
                </Modal>
            </div>
        )
    }
}

export default injectIntl(CustomModalUpdate);
