import React, {useEffect, useRef, useState} from "react";
import {FiPauseCircle, FiPlayCircle} from "react-icons/all";
import './PlayAudioCustom.scss'
import {translateInviteTexts} from "../../../functions";

export default function PlayAudioCustom({link, color,language}) {

    const audioPlayer = useRef()
    const [play, setPlay] = useState(false)
    useEffect(() => {
        setInterval(() => {
            if (audioPlayer && audioPlayer.current && audioPlayer.current.paused === false && play === false){
                setPlay(true)
            }
        }, 1000);
// eslint-disable-next-line
    }, []);

    return <div className={"play-audio-custom"}>
        <audio
            ref={audioPlayer} id={"audioMP3"}
        >
            <source src={link} type={"audio/mp3"}/>
        </audio>
        <div className={"play-audio-button"} onClick={() => {
            if (play){
                setPlay(false)
                audioPlayer.current.pause()
            }
            else {
                setPlay(true)
                audioPlayer.current.play()
            }
        }}>
            <div className={"icon-container"}>
                {
                    play ?
                        <FiPauseCircle color={color} size="3rem"/>:
                        <FiPlayCircle color={color} size="3rem"/>
                }
            </div>
            <div className={"text-container"} style={{color: color}}>
                {
                    play ?
                        translateInviteTexts('Oprește melodia', language)
                        :
                        translateInviteTexts('Pornește melodia', language)}
            </div>
        </div>
    </div>

}