import React from 'react'
import {Button, Toggle, SelectPicker, CheckTree} from 'rsuite'
import './EditResponse.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import {toast} from 'react-toastify'
import Modal from 'react-responsive-modal'
import axios from 'axios'
import CustomInput from '../../components/custom/CustomInput'
import moment from "moment-timezone";
import {BackButton} from "../../components/Helper";

// const foodOptions = [{ label: 'Meniu normal', value: 'normal' }, { label: 'Meniu vegetarian', value: 'vegetarian' },
// { label: 'Meniu post', value: 'post' }];

const ansOptions = [{label: 'Participa', value: 'yes'}, {label: 'Nu știe daca participa', value: "don't know"},
    {label: 'Nu Participa', value: 'no'}]

// const limitaPersoaneMentionate = 5;

class EditResponse extends React.Component {
    state = {
        responseId: -1,
        answer: "yes",
        nume: "",
        prenume: "",
        insotit: false,
        nume_insotitor: "",
        cuCopii: false,
        nr_copii: null,
        hasTable: false,
        date_created: '',
        email: '',

        questions: [],
        answers: [],
        data: false,

        loading: true,
        isModalOpen: false,
        modalChoice: "Edit"
    }

    componentDidMount = async () => {
        this.getData()
    }

    getData = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let response = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + this.props.match.params.id, auth)

        let questions = response.data.questions
        if (!response.data.useCustomQuestions) {
            questions = []
        }
        if (questions === null) {
            questions = []
        }
        let answers = []

        for (let i = 0; i < questions.length; i++) {
            answers.push("")

            let newOptions = []

            for (let j = 0; j < questions[i].options.length; j++) {
                newOptions.push({label: questions[i].options[j], value: questions[i].options[j]})
            }

            questions[i].options = newOptions
        }

        this.setState({
            questions: questions,
            answers: answers,
            data: response.data
        })

        await axios.get(process.env.REACT_APP_DOMAIN + 'responses/list/byEvent/' + this.props.match.params.id, auth)
            .then(async response => {
                if (response.data.length === 0) {
                    toast.error("Nu exista acest raspuns")

                    this.goBack()

                    return
                }

                let data = response.data.responses.filter(elem => elem.id === Number(this.props.match.params.idResponse))
                if (data.length === 0) {
                    toast.info("Nu exista acest raspuns");
                    this.props.history.push('/organizer/raspunsuriInvitati/' + this.props.match.params.id)
                    return
                }
                let neededResponse = data[0].response


                this.setState({
                    responseId: data[0].id
                })

                if (neededResponse.answer === "yes") {
                    for (let i = 0; i < questions.length; i++) {
                        answers[i] = neededResponse["answer" + i]
                    }


                    this.setState({
                        email: data[0].email,
                        answer: neededResponse.answer,
                        nume: neededResponse.nume,
                        prenume: neededResponse.prenume,
                        insotit: neededResponse.insotit,
                        nume_insotitor: neededResponse.nume_insotitor,
                        cuCopii: neededResponse.cuCopii,
                        nr_copii: neededResponse.nr_copii + '',
                        hasTable: !!data[0].table,
                        created_at: data[0].created_at,
                        loading: false,
                        answers: answers,
                        language: neededResponse.language ? neededResponse.language : 'ro'
                    })
                } else {
                    this.setState({
                        email: data[0].email,
                        answer: neededResponse.answer,
                        nume: neededResponse.nume,
                        prenume: neededResponse.prenume,
                        insotit: neededResponse.insotit,
                        nume_insotitor: neededResponse.nume_insotitor,
                        cuCopii: neededResponse.cuCopii,
                        hasTable: !!data[0].table,
                        nr_copii: neededResponse.nr_copii + '',
                        created_at: data[0].created_at,
                        language: 'ro',
                        loading: false
                    })
                }
            })
    }

    showModal = () => {
        if (this.state.answer === "") {
            toast.error("Va rog alegeti raspunsul participantului")
            return
        }

        // if (this.state.nume === "") {
        // 	toast.error("Va rog introduceti numele invitatului")
        // 	return
        // }

        if (this.state.prenume === "") {
            toast.error("Va rog introduceti prenumele invitatului")
            return
        }

        if (this.state.insotit === true && this.state.nume_insotitor === "") {
            toast.error("Va rog introduceti numele insotitorului")
            return
        }

        if (this.state.cuCopii === true && this.state.nr_copii === null) {
            toast.error("Va rog mentionati cu cati copii vine invitatul")
            return
        }

        this.setState({
            isModalOpen: true,
            modalChoice: "Edit"
        })
    }

    deleteAns = () => {
        this.setState({
            isModalOpen: true,
            modalChoice: "Delete"
        })
    }

    closeModal = () => {
        this.setState({
            isModalOpen: false
        })
    }

    editResponse = () => {
        this.closeModal()

        var obj = {
            answer: this.state.answer,
            nume: this.state.nume,
            prenume: this.state.prenume,
            insotit: this.state.insotit,
            nume_insotitor: this.state.nume_insotitor,
            cuCopii: this.state.cuCopii,
            nr_copii: parseInt(this.state.nr_copii + ''),
            language: this.state.language
        }

        for (let i = 0; i < this.state.answers.length; i++) {
            obj["answer" + i] = this.state.answers[i]
        }

        var data = {
            response: obj
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.put(process.env.REACT_APP_DOMAIN + 'responses/update/' + this.state.responseId, data, auth)
            .then(response => {
                toast.success("Raspuns modificat")

                this.goBack()
            })
    }

    deleteResponse = () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.delete(process.env.REACT_APP_DOMAIN + 'responses/delete/' + this.state.responseId, auth)
            .then(response => {
                toast.success("Raspuns modificat")

                this.goBack()
            })
    }


    removeFromSeatingChart = () => {
        this.setState({loading: true})
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        axios.post(process.env.REACT_APP_DOMAIN + 'responses/removeFromSeatingChart/' + this.state.responseId, {}, auth)
            .then(response => {
                toast.success("Participantul a fost ridicat de la masă!")

                this.getData()
            })
    }

    setter = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    selectQuestionAnswer = (index, value) => {
        var newAsnwers = [...this.state.answers]

        newAsnwers[index] = value
        if (value.value !== undefined)
            newAsnwers[index] = value.value

        this.setState({
            answers: newAsnwers
        })
    }

    goBack = () => {
        this.props.history.go(-1)
    }

    getQuestionTitle = (index) => {
        var newQuestions = [...this.state.questions]

        if (this.state.language && this.state.language !== 'ro' && newQuestions[index]['title_' + this.state.language] && newQuestions[index]['title_' + this.state.language].length > 0) {
            return newQuestions[index]['title_' + this.state.language]
        } else {
            return newQuestions[index].title
        }
    }

    getQuestionOptions = (index) => {
        var newQuestions = [...this.state.questions]

        if (this.state.language && this.state.language !== 'ro') {

            if (newQuestions[index]['options_' + this.state.language]) {

                let response = newQuestions[index]['options_' + this.state.language].map((item) => {
                    return {'label': item, 'value': item}
                })
                let updatedResponse = []

                for (let i = 0; i < response.length; i++) {
                    if (response[i].label && response[i].label.length > 0) {
                        updatedResponse.push(response[i])
                    } else {
                        if (newQuestions[index]['options'][i] && newQuestions[index]['options'][i].label) {
                            updatedResponse.push({
                                'label': newQuestions[index]['options'][i].label,
                                'value': newQuestions[index]['options'][i].value
                            })
                        }
                    }
                }

                return updatedResponse
            } else {
                return newQuestions[index].options
            }
        } else {
            return newQuestions[index].options
        }
    }

    render() {
        if (this.state.loading) {
            return <div className="EditResponse-container">
                <HeaderWhite goBack={this.goBack}/>

                <p>Loading...</p>
            </div>
        }

        let showData = (title, data) => {
            return <div>
                <h5>{title}</h5>
                <div style={{marginBottom: "15px"}}>{data}</div>
            </div>
        }

        return (
            <div className="EditResponse-container">
                <HeaderWhite goBack={this.goBack}/>

                <Modal classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal',
                    closeButton: 'modal-close-button',
                }} open={this.state.isModalOpen} onClose={this.closeModal}>
                    {this.state.modalChoice === "Edit" && <div style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "20px",
                        width: "200px"
                    }}>
                        {showData("Raspuns", this.state.answer === "yes" ? "Participa" : this.state.answer === "no" ? "Nu Participa" : "Nu știe daca participa")}

                        {/* {showData("Nume", this.state.nume)} */}

                        {showData("Prenume, Nume", this.state.prenume)}


                        {showData("Insotit", this.state.insotit ? this.state.nume_insotitor : "Neinsotit")}

                        {showData("Copii", this.state.cuCopii ? this.state.nr_copii : "Fara")}

                        {this.state.answers.map((ans, index) => {
                            return <div key={index}>{showData(this.getQuestionTitle(index), ans)}</div>
                        })}

                        <Button onClick={this.editResponse} style={{
                            alignSelf: "center",
                            borderRadius: "80px",
                            backgroundColor: "#00afcc",
                            color: "white"
                        }} color="green"> Confirmă </Button>
                    </div>}

                    {this.state.modalChoice === "Delete" &&
                        <div style={{display: "flex", flexDirection: "column", marginTop: "20px", width: "200px"}}>
                            <h5>Răspunsul se va șterge și nu se va mai putea recupera!</h5>

                            <Button onClick={this.deleteResponse}
                                    style={{alignSelf: "center", marginTop: "40px", borderRadius: "80px"}}
                                    color="red"> Confirm ștergerea răspunsului </Button>
                        </div>}
                </Modal>

                <div className="EditResponse-contents">

                    <div style={{textAlign: 'left', marginTop: '20px'}}>
                        <CustomInput
                            value={this.state.created_at ? moment(this.state.created_at).format('DD-MM-YYYY HH:mm') : ''}
                            onChange={() => {
                            }}
                            style={{margin: "10px 0px"}}
                            disabled={true}
                            placeholder={"Data răspuns"}/>
                    </div>

                    {this.state.email && this.state.email.length > 0 ?
                        <div style={{textAlign: 'left', marginTop: '10px'}}>
                            <CustomInput
                                value={this.state.email}
                                onChange={() => {
                                }}
                                style={{margin: "10px 0px"}}
                                disabled={true}
                                placeholder={"Adresa de email"}/>
                        </div>
                        : null}


                    <SelectPicker
                        searchable={false}
                        style={{marginBottom: 25, marginTop: "20px"}}
                        data={ansOptions}
                        appearance="default"
                        onChange={(value) => this.setState({answer: value})}
                        value={this.state.answer}
                        placeholder="Raspuns"
                    />

                    {/* <CustomInput value={this.state.nume} onChange={(value) => this.setState({ nume: value })} style={{ margin: "10px 0px" }} placeholder={"Nume"} /> */}

                    <CustomInput value={this.state.prenume} onChange={(value) => this.setState({prenume: value})}
                                 style={{margin: "10px 0px"}} placeholder={"Prenume, Nume"}/>

                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                        marginBottom: "20px",
                        justifyContent: "space-around"
                    }}>
                        <p> Vine singur </p>
                        <Toggle checked={this.state.insotit} onChange={checked => this.setter("insotit", checked)}/>
                        <p> Vine insoțit </p>
                    </div>
                    {this.state.insotit && (
                        <CustomInput style={{margin: "10px 0px"}} value={this.state.nume_insotitor}
                                     onChange={value => this.setter("nume_insotitor", value)}
                                     placeholder="Nume Insotitor"/>
                    )}

                    <p>Vin copii?</p>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "10px",
                        marginBottom: "20px",
                        justifyContent: "space-around"
                    }}>
                        <p> Nu </p>
                        <Toggle checked={this.state.cuCopii} onChange={checked => this.setter("cuCopii", checked)}/>
                        <p> Da </p>
                    </div>
                    {this.state.cuCopii && (
                        <SelectPicker
                            searchable={false}
                            style={{marginBottom: 20}}
                            data={[{label: "1", value: "1", role: "Nr copii"}, {
                                label: "2",
                                value: "2",
                                role: "Nr copii"
                            }, {label: "3", value: "3", role: "Nr copii"}, {
                                label: "4",
                                value: "4",
                                role: "Nr copii"
                            }, {label: "5", value: "5", role: "Nr copii"}]}
                            appearance="default"
                            onChange={(value) => this.setState({nr_copii: value})}
                            value={this.state.nr_copii}
                            placeholder="Numar copii insotitori"
                        />
                    )}

                    {this.state.questions.map((question, index) => {
                        return <div key={index} style={{margin: "20px 0px"}}>
                            <h5>{this.getQuestionTitle(index)}</h5>

                            {question.type === 0 &&
                                <CustomInput value={this.state.answers[index]}
                                             onChange={(value) => this.selectQuestionAnswer(index, value)}
                                             style={{margin: "10px 0px"}} placeholder={"Introduceti Raspuns"}/>
                            }

                            {question.type === 1 &&
                                <CheckTree
                                    defaultExpandAll
                                    renderTreeIcon={null}
                                    cascade={true}
                                    data={this.getQuestionOptions(index)}
                                    onSelect={value => this.selectQuestionAnswer(index, value)}
                                    value={[this.state.answers[index]]}
                                />
                            }
                        </div>
                    })}


                    {
                        this.state.hasTable ?
                            <div style={{display: "flex", width: "100%", flexDirection: 'column'}}>
                                <p style={{color: 'red'}}>Acest participant a fost deja așezat la o masa!<br/>
                                    Pentru a putea modifica detaliile acestuia este necesară ridicarea lui de la masă.
                                </p>
                                <Button onClick={this.removeFromSeatingChart} style={{
                                    flex: 1,
                                    marginTop: 20,
                                    marginBottom: 20,
                                    marginRight: '10px',
                                    borderRadius: "80px"
                                }} color="red">Ridică de la masă</Button>
                            </div>
                            :
                            <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                                <Button onClick={this.deleteAns} style={{
                                    flex: 1,
                                    marginTop: 20,
                                    marginBottom: 20,
                                    marginRight: '10px',
                                    borderRadius: "80px"
                                }} color="red">Șterge răspunsul</Button>
                                <Button onClick={this.showModal} style={{
                                    flex: 1,
                                    marginTop: 20,
                                    marginBottom: 20,
                                    marginLeft: '10px',
                                    borderRadius: "80px",
                                    backgroundColor: "#00afcc",
                                    color: "white"
                                }} color="green">Salvează modificările</Button>
                            </div>
                    }

                    <div>
                        <BackButton text={"Înapoi"} function={this.goBack}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditResponse;
