import React from 'react'
import { Button } from 'rsuite'
import { toast } from 'react-toastify'
import HeaderWhite from '../components/HeaderWhite'

import axios from 'axios'

import './ResetPage.scss'
import CustomInput from '../components/custom/CustomInput'

class ResetPage extends React.Component {
	state = {
		pass1: "",
		pass2: "",
		goToLogin: false,
		code: this.props.match.params.id
	}

	handleChange = (value, key) => {
		this.setState({
			[key]: value
		})
	}

	savePass = () => {
		if (this.state.pass1 === "") {
			toast.error("Introdu parola")
			return
		}

		const passRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;

		if (!passRegex.test(this.state.pass1)) {
			toast.error("Parola este invalida")
			return
		}

		if (this.state.pass1 !== this.state.pass2) {
			toast.error("Parolele sunt diferite")
			return
		}

		let obj = {
			password: this.state.pass1,
			passwordConfirmation: this.state.pass2,
			code: this.state.code
		}

		axios.post(process.env.REACT_APP_DOMAIN + 'auth/reset-password', obj)
			.then((response) => {
				toast.success("Parola schimbata")
				// console.log(respose);

				this.setState({
					goToLogin: true
				})
			})
			.catch(err => {
				// this.displayErrors(err.response.data);
				toast.error("Codul nu este valid")
			})
	}

	render() {
		return (
			<div className="ResetPage-container">
				<HeaderWhite />
				{this.state.goToLogin
					? <div>
						<div style={{ height: '30vh' }}></div>
						<Button className="style-width" color="green" onClick={() => this.props.history.push("/login")}>Go To Login</Button>
					</div>
					: <div className="style-inputs">
						<div style={{ height: '10vh' }}></div>

						<p>Parola trebuie sa contina cel putin 8 caractere, printre care o litera mare, o litera mica, o cifra</p>

						<CustomInput style={{ width: "80vw", margin: "30px 0px" }} value={this.state.pass1} onChange={value => this.handleChange(value, "pass1")}
							placeholder={"Parola noua"} inputType={"password"} border={true} />

						<CustomInput style={{ width: "80vw" }} value={this.state.pass2} onChange={value => this.handleChange(value, "pass2")}
							placeholder={"Confirma parola noua"} inputType={"password"} border={true} />

						<Button className="style-width" color="green" onClick={this.savePass}>Save</Button>
					</div>
				}
			</div>
		)
	}
}

export default ResetPage;