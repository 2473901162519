import React from 'react'
import { Button } from 'rsuite'
import { toast } from 'react-toastify'
import HeaderWhite from '../components/HeaderWhite'

import axios from 'axios'

import './ConfirmMailPage.scss'

class ConfirmMailPage extends React.Component {
	componentDidMount = () => {
		axios.get(process.env.REACT_APP_DOMAIN + 'auth/email-confirmation?code=' + this.props.match.params.id)
			.then((response) => {
				// console.log(response)
				toast.success("Cont confirmat cu succes")
			})
			.catch(err => {
				// this.displayErrors(err.response.data);
				toast.error("Codul nu este valid")
			})
	}

	goToLogin = () => {
		this.props.history.push("/login")
	}

	render() {
		return (
			<div className="ConfirmMailPage-container">
				<HeaderWhite />

				<Button className="style-width" onClick={this.goToLogin} color="green">Intră în cont</Button>

				<p></p>
			</div>
		)
	}
}

export default ConfirmMailPage;
