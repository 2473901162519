import React, {useState, useEffect} from 'react'
import './Administrators.scss'
import HeaderWhite from '../../../components/HeaderWhite'
import {Button, Icon} from 'rsuite'
import Modal from "react-responsive-modal";
import NavbarColor from '../../../components/NavbarColor'
import axios from 'axios'
import {toast} from 'react-toastify'
import CustomInput from '../../components/custom/CustomInput';
import {ConfirmButton} from '../../components/Helper';
import {useHistory, useParams} from "react-router-dom";
import whatsapp from "./Modals/icons/whatsapp.svg";
import {WhatsappShareButton} from "react-share";


export default function Administrators() {

    let history = useHistory()
    let {id} = useParams()

    const [administrators, setAdministrators] = useState(null)
    const [addModal, setAddModal] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [deleteUserId, setDeleteUserId] = useState(0)
    const [deleteUserName, setDeleteUserName] = useState('')
    const [tutorialModal, setTutorialModal] = useState(false)
    const [whatsAppModal, setWhatsAppModal] = useState(false)

    // eslint-disable-next-line
    const [code, setCode] = useState('')
    const [whatsAppMessage, setWhatsAppMessage] = useState('')
    const [email, setEmail] = useState('')

    useEffect(() => {
        getAdministrators()
        // eslint-disable-next-line
    }, [])


    const getAdministrators = async () => {

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id
        }

        let eventData = await axios.get(process.env.REACT_APP_DOMAIN + 'weddings/getThisWedding/' + id, auth);

        setTutorialModal(!eventData.data.tutorials || (eventData.data.tutorials && !eventData.data.tutorials.administrators))

        let info = await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/list', payload, auth);

        if (info.data && info.data.length > 0) {
            setAdministrators(info.data)
        } else {
            setAdministrators([])
        }


    }

    const sendInvite = async () => {

        if (email.length > 0) {

            let auth = {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('jwt')
                }
            }

            let payload = {
                id_eveniment: id,
                email: email.toLowerCase()
            }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/invite', payload, auth)
                .then(response => {
                    if (response.data.status === 1) {
                        setAddModal(false)
                        setWhatsAppModal(true)
                        setWhatsAppMessage(response.data.whatsappMessage)
                        setEmail('')
                        setCode(response.data.code)
                        toast.success('Invitația a fost trimisă pe email!')
                    }

                    if (response.data.status === 2) {
                        toast.error('O invitație a fost deja trimisă pe această adresă de email!')

                        if(response.data.code) {
                            setAddModal(false)
                            setEmail('')
                            setCode(response.data.code)
                            setWhatsAppMessage(response.data.whatsappMessage)
                            setWhatsAppModal(true)
                        }
                    }

                })
        }
    }

    const deleteAdmin = async () => {
        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        let payload = {
            id_eveniment: id,
            user_id: deleteUserId
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/administrators/delete', payload, auth)
            .then(response => {
                toast.success('Contul a fost eliminat!')
                setDeleteModal(false)
                getAdministrators()
            })
    }

    const updateTutorial = async () => {

        setTutorialModal(false)

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }
        await axios.post(process.env.REACT_APP_DOMAIN + 'weddings/update/tutorials/' + id,
            {
                administrators: true
            },
            auth)

    }

    const goBack = () => {
        history.goBack()
    }

    const customInputStyle = {
        margin: "25px 0px 25px 0px",
        backgroundColor: "#E5F7FE",
        borderRadius: "10px",
    }

    const widthStyle = {
        width: '33%',
        overflow: "hidden",
        // whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        borderRadius: "0px",
        textAlign: "center"
    }

    return (
        <div className="Administrators-container">
            <HeaderWhite goBack={goBack}/>

            <div className={"Administrators-contents"}>


                <div className='add-response-button'>
                    <Icon icon="plus-circle" size="lg" style={{color: "#05AFF2", marginRight: "10px"}}
                          onClick={() => setAddModal(true)}/>
                    <div onClick={() => setAddModal(true)}>Adauga organizator</div>
                </div>

                <div className="data-table">
                    <div className="header">

                        <div style={widthStyle}> Nume</div>

                        <div style={widthStyle}> Email</div>

                        <div style={widthStyle}> Elimina</div>
                    </div>

                    {
                        administrators && administrators.map((administrator, i) => (
                            <div className={i % 2 === 0 ? "even-line" : "uneven-line"} key={i}>
                                <div style={{...widthStyle, fontWeight: "bold"}} onClick={() => {
                                }}> {administrator.first_name} {administrator.last_name} </div>

                                <div style={widthStyle}> {administrator.email} </div>

                                <div style={widthStyle}>
                                    <div
                                        className={"btn-remove-access"}
                                        onClick={() => {
                                            setDeleteUserId(administrator.id)
                                            setDeleteUserName(administrator.first_name + ' ' + administrator.last_name)
                                            setDeleteModal(true)
                                        }}>
                                        Elimina
                                    </div>
                                </div>
                            </div>
                        ))
                    }


                </div>

                <Button
                    onClick={()=> setTutorialModal(true)}
                    color="green"
                    style={{maxWidth: 'var(--container-max-width)', margin:'auto', backgroundColor: "#05AFF2", overflow:'visible', borderRadius: "20px", width: "100%", marginTop:"10px", marginBottom: "20px"}}>Informații utile</Button>


            </div>
            <NavbarColor history={history}/>

            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-response',
                closeButton: 'modal-close-button',
            }} open={addModal} onClose={() => setAddModal(false)}>

                <div className={"administrators-modal-inner"}>
                    <h5>Invita organizator</h5>

                    <CustomInput
                        value={email}
                        onChange={setEmail}
                        style={customInputStyle}
                        placeholder={"Adresa de email"}
                        icon={<Icon icon="at"/>}
                    />

                    <ConfirmButton
                        function={() => sendInvite()}
                        text={"Trimite invitatia"}
                    />

                </div>
            </Modal>

            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-response',
                closeButton: 'modal-close-button',
            }} open={whatsAppModal} onClose={() => {
                setCode('')
                setWhatsAppModal(false)
                setWhatsAppMessage('')
            }}>

                <div className={"administrators-modal-inner"}>
                    <h5>Trimite pe whatsapp</h5>


                    {
                        window.alityAppView ?

                            <div id={"icons"} onClick={() => {
                                window.ReactNativeWebView.postMessage(JSON.stringify({
                                    action: 'shareMessage',
                                    message: whatsAppMessage,
                                }))}
                            }>
                                <img id="whatsapp" src={whatsapp} alt={''} />
                            </div>

                            :
                            <div id={"icons"} style={{textAlign:'center'}}>
                                <WhatsappShareButton title={''} separator=""
                                                     url={whatsAppMessage}>
                                    <img id="whatsapp" src={whatsapp} alt=""/>
                                </WhatsappShareButton>
                            </div>
                    }



                </div>
            </Modal>


            <Modal classNames={{
                overlay: 'modal-overlay',
                modal: 'modal-response',
                closeButton: 'modal-close-button',
            }} open={deleteModal} onClose={() => setDeleteModal(false)}>

                <div className={"administrators-modal-inner"}>
                    <h5>Elimina organizator</h5>

                    <p style={{marginTop: '20px', marginBottom: '20px'}}>Esti sigur ca doresti sa opresti accesul la
                        acest eveniment pentru {deleteUserName} ?</p>


                    <ConfirmButton
                        function={() => deleteAdmin()}
                        text={"Opreste accesul"}
                    />

                </div>
            </Modal>

            <Modal
                classNames={{
                    overlay: 'modal-overlay',
                    modal: 'modal-no-top',
                    closeButton: 'modal-close-button',
                }}
                open={tutorialModal}
                focusTrapped={false}
                onClose={updateTutorial}
            >
                <div style={{marginTop: "20px"}}>
                    <h3>Informații utile</h3>
                    <br/>
                    <p>
                        Ai nevoie de ajutor sau vrei sa delegi task-uri?<br/>
                        Tocmai pentru ca un eveniment nu se poate organiza de unul singur din acest meniu ai
                        posibilitatea de a invita pe altcineva care sa te ajute. Introdu adresa de e-mail a
                        persoanei/persoanelor care te vor ajuta în organizare și începe planificarea unui eveniment de
                        succes. :)
                    </p>
                    <Button
                        onClick={updateTutorial}
                        color="green"
                        style={{
                            backgroundColor: "#05AFF2",
                            borderRadius: "20px",
                            width: "100%",
                            marginTop: "20px"
                        }}>Ok</Button>
                </div>
            </Modal>

        </div>
    )

}
