import React from 'react'
import './Invite.scss'
import QRCode from 'qrcode.react'
import {ConfirmButton} from '../../components/Helper'
import axios from "axios";
import {toast} from "react-toastify";
import {Button, Icon} from "rsuite";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import {getMobileOperatingSystem, isJsonString} from "../../functions";
import ShareComp from "../../components/ShareComp/ShareComp";
import GoogleWebLoginButton from "../../components/GoogleLogin/GoogleWebLoginButton";

class Invite extends React.Component {

	isIOS = (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && !window.MSStream
	os = getMobileOperatingSystem()

	state = {
		downloadLoading: false
	}

	onAppMessage = async (message) => {

		let data = message.data && isJsonString(message.data) && JSON.parse(message.data) ? JSON.parse(message.data) : false

		if(data) {
			// eslint-disable-next-line
			switch (data.action) {
				case 'signInWithApple':
					this.appleSignIn(data.firstName, data.lastName, data.email, data.accessToken)
					break
				case 'signInWithFacebook':
					this.facebookSignIn({accessToken: data.accessToken})
					break
				case 'signInWithGoogle':
					this.googleSignIn({tokenId: data.accessToken})
					break
			}
		}
	}

	openLink = (link) => {
		if (window.flutter_inappwebview) {
			window.flutter_inappwebview.callHandler('openLink', link)
		} else if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'openLink',
				url: link,
			}))
		} else {
			window.open(link)
		}
	}

	componentDidMount = () => {
		[window, document].forEach(
			el => el.addEventListener('message', this.onAppMessage))

	}

	componentWillUnmount() {
		window.removeEventListener("message", this.onAppMessage)
		document.removeEventListener("message", this.onAppMessage)
	}

	requestPushToken = async () => {

		let auth = {
			headers: {
				'Authorization': 'Bearer ' + localStorage.getItem('jwt')
			}
		}
		if(window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('getPushNotificationToken')
				.then(token => {

					let data = {
						token: token
					}

					axios.post(process.env.REACT_APP_DOMAIN + 'utils/storePushToken', data, auth)
				})
		}

		if(window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'getPushToken',
				data: localStorage.getItem('jwt')
			}))
		}
	}

	loginGoogle = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginGoogle')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error("Eroare la conectare, va rog reincercati.")
						}
					} else {
						this.googleSignIn({tokenId: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithGoogle'
			}))
		}
	}

	googleSignIn = (response) => {

		if (!response.tokenId) {
			return;
		}

		let tokenId = response.tokenId
		//tokenId = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjE4MmU0NTBhMzVhMjA4MWZhYTFkOWFlMWQyZDc1YTBmMjNkOTFkZjgiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiMjMwMjE1NzQzNTE3LTczbDU4ZG91MDhwa3Z0b29zNmZldmpqNGdwM2hpZDBzLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE3MDU1NjE0NDAyMTA4NTczOTI3IiwiZW1haWwiOiJ2bGFkLml2YW5jaXUyQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiQVFtbllaazQ5czRoZk5VWHV1dW53dyIsIm5hbWUiOiJWbGFkIEl2YW5jaXUiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKeEJpMm9yVktacnlrY1NwRHJnRjh2T3lUM0JVLXg1OTdsV2xBM2g0UT1zOTYtYyIsImdpdmVuX25hbWUiOiJWbGFkIiwiZmFtaWx5X25hbWUiOiJJdmFuY2l1IiwibG9jYWxlIjoicm8iLCJpYXQiOjE2NDQ0MDYzNjgsImV4cCI6MTY0NDQwOTk2OCwianRpIjoiYTA5Mjk3OTBhODliMDliMDVhZDA0MzY3NzM4YjQ0ZDIzOGM1OWU3YSJ9.GfRbRzpfxQVz5OR5T3N_1RvXljePBvrHUwhqAUetJ9_YgLZCulsiKkbuWznKlgtPIVn_6IeF_HE6jxbgpgqDg_0w5KqsKLAp18Tc0ICZqsOhOwCrQYw9USajjc4YmgFMtO_rtytDqvAx67jZFPsnnNarcVUXlni5gmg1mmsRMNw_xnaGc7w9T0wOqTz36og_z2spjxzSNrK3Gp6BE2wy5mt0KvpYFKq8nJJOJLp8FHMLKLhCKUOGiRK8bnHc_RFJ3x8WxmYwBIT9floaS-4YII5mSSN9y2XLYLVKnPa_N2mWinNNe-DsNkby1QJQ8lw2mW5gGoGTuwCFJiSkBCdTMA"

		let obj = {
			tokenId: tokenId
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/google', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success("Logare cu succes!")

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', "remember")

					this.requestPushToken()


					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.props.event_data) {
						this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
					}
				} else {
					toast.error("Va rog confirmati email-ul")
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error("Email sau parola gresita")
			})


	}

	loginFacebook = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginFacebook')
				.then(token => {
					if (token === "NULL" || token === "ERROR") {
						if (token === "ERROR") {
							toast.error("Eroare la conectare, va rog reincercati.")
						}
					} else {
						this.facebookSignIn({accessToken: token})
					}
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithFacebook'
			}))
		}
	}

	facebookSignIn = (response) => {

		if (!response.accessToken) {
			return;
		}

		let accessToken = response.accessToken

		let obj = {
			accessToken: accessToken
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/facebook', obj)
			.then(async(response) => {
				if (response.data.user.confirmed) {
					toast.success("Logare cu succes!")

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.props.event_data) {
						this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
					}
				} else {
					toast.error("Va rog confirmati email-ul")
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error("Email sau parola gresita")
			})


	}

	appleSignIn = (firstName, lastName, email, accessToken) => {

		let obj = {
			accessToken: accessToken,
			email: email,
			first_name: firstName,
			last_name: lastName
		}

		this.setState({
			loading: true
		})

		axios.post(process.env.REACT_APP_DOMAIN + 'login/social/apple', obj)
			.then(async (response) => {
				if (response.data.user.confirmed) {
					toast.success("Logare cu succes!")

					localStorage.setItem('jwt', response.data.jwt);
					localStorage.setItem('id', response.data.user.id);
					localStorage.setItem('rememberMe', this.state.rememberMeCheck ? "remember" : "no")

					this.requestPushToken()

					let responseId = localStorage.getItem("responseId")

					if (responseId !== null) {
						let data = {
							responseId: responseId
						}

						let auth = {
							headers: {
								'Authorization': 'Bearer ' + response.data.jwt
							}
						}

						await axios.post(process.env.REACT_APP_DOMAIN + 'utils/attachResponseToUser', data, auth)
					}

					if(this.props.event_data) {
						this.props.history.push('/eventPage/' + this.props.event_data.id_eveniment)
					}
				} else {
					toast.error("Va rog confirmati email-ul")
				}
				this.setState({
					loading: false
				})
			})
			.catch(err => {
				this.setState({
					loading: false
				})
				toast.error("Email sau parola gresita")
			})


	}

	loginApple = async () => {
		if (window.flutter_inappwebview) {
			await window.flutter_inappwebview.callHandler('loginApple')
				.then(data => {
					let response = data.split('|')
					this.appleSignIn(response[2], response['1'], response['3'], response['0'])
				})
		}

		if (window.alityAppView) {
			window.ReactNativeWebView.postMessage(JSON.stringify({
				action: 'signInWithApple'
			}))
		}
	}

	downloadImage = async (participant) => {

		if(!this.state.downloadLoading) {
			this.setState({downloadLoading:true})
		} else {
			return
		}

		fetch(process.env.REACT_APP_DOMAIN + 'event/getInviteImage/' + participant.id_participant + '/0', {
			method: 'GET',
			headers: {
				'Content-Type': 'image/jpg',
			},
		})
			.then((response) => response.blob())
			.then((blob) => {
				// Create blob link to download
				const url = window.URL.createObjectURL(
					new Blob([blob]),
				);
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					'invitatie-' + participant.id_participant + '.jpg',
				);

				// Append to html link element page
				document.body.appendChild(link);

				// Start download
				link.click();

				// Clean up and remove the link
				link.parentNode.removeChild(link);

				this.setState({downloadLoading:false})
			});
	}

	downloadImageWebview  = async (participant) => {
		fetch(process.env.REACT_APP_DOMAIN + 'event/getInviteImage/' + participant.id_participant + '/1', {
			method: 'GET',
		})
			.then((response) => response.text())
			.then((res) => {
				window.ReactNativeWebView.postMessage(JSON.stringify({
					action: 'downloadMedia',
					url: res,
				}))
				//toast.success('Invitația a fost salvată în galeria telefonului!')
			})
	}

	goToLogin = () => {
		localStorage.setItem('eventId', this.props.event_data.id_eveniment);
		this.props.history.push("/login")
	}

	goToSignup = () => {
		localStorage.setItem('eventId', this.props.event_data.id_eveniment);
		this.props.history.push("/signup")
	}

	render() {
		let style = {}

		return <div className="Invite-container" style={style}>

			{
				this.props.participantData !== null ?

					this.props.participantData.inviteStatus === 0 || this.props.participantData.inviteStatus === 2 || this.props.participantData.inviteStatus === 3 ?

						<p style={{marginTop: '30px', fontSize: '1.1rem', textAlign: 'center'}}>
							<h3>Atenție!</h3>
							Vă rugăm să răspundeți la invitație!
						<br/><br/>
							Accesați secțiunea "Info Participant" și alegeți dacă participați sau nu la acest eveniment!
						</p>

						:

					this.props.participantData.inviteStatus !== 4 && this.props.participantData.inviteStatus !== 5 ?
						<div className="Invite-content"
							 style={{border: `5px solid ${this.props.participantData.bilet_verificat ? "red" : "green"}`}}>
							<div style={{textAlign:'center'}}>{this.props.event_data.title}</div>

							<p>Posesor invitație: {this.props.user_data.first_name} {this.props.user_data.last_name}</p>

							<p style={{color: 'red', fontSize: '1rem', fontWeight: '500', textAlign: 'center', padding:'0 10px'}}>Accesul
								în cadrul evenimentului se face pe baza codului QR!</p>

							<div style={{textAlign: 'center'}}>

								<QRCode size={180}
										value={"https://app.ality.ro/checkTicket/000000000000" + this.props.participantData.id_participant}/>

							</div>

							<div className="ticket-validity">Stare invitație:
								{this.props.participantData.bilet_verificat
									? <p style={{color: "red"}}> SCANATĂ</p>
									: <p style={{color: "green"}}> VALIDĂ</p>
								}
							</div>

							{
								window.alityAppView ?
									<div style={{width:'100%'}}>
										<ConfirmButton
											text={'Salvează în galeria foto'}
											function={() => this.downloadImageWebview(this.props.participantData)}
										/>
									</div>
									:
									<div style={{width:'100%'}}>
										<ConfirmButton
											text={'Descarcă invitația'}
											function={() => this.downloadImage(this.props.participantData)}
										/>
									</div>

							}

							{
								this.os === 'iOS'?
									<div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateAppleWalletInvitePass/' + this.props.participantData.id_participant)}>
										<img src={'/images/icons/apple_wallet.svg'} style={{width:'140px'}} alt={""} />
									</div>
									:null
							}

							{
								this.os === 'Android'?
									<div onClick={() => this.openLink(process.env.REACT_APP_DOMAIN + 'event/generateGoogleWalletInvitePass/' + this.props.participantData.id_participant)}>
										<img src={'/images/icons/google_wallet.svg'} style={{height:'30px'}} alt={""} />
									</div>
									:null
							}

							<ShareComp
								url={process.env.REACT_APP_PAGE + 'downloadInvite/' + this.props.participantData.id_participant}
								message={''}
							/>
						</div>
						:
						<div style={{display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
							{
								this.props.participantData.inviteStatus === 4 ?


									this.props.event_data.participantsLimit && this.props.event_data.participantsLimit > 0 &&
									this.props.event_data.confirmedParticipantsCount
									&& this.props.event_data.confirmedParticipantsCount >= this.props.event_data.participantsLimit ?

										<p style={{marginTop: '30px', fontSize: '1.1rem', textAlign: 'center'}}>Atenție!
											Limita maximă de participanți pentru acest eveniment a fost atinsă! Cererea
											dumneavoastră va sta în lista de așteptare și veți primi o notificare cand
											se va elibera un loc.</p>
										:
										<p style={{marginTop: '30px', fontSize: '1.1rem', textAlign: 'center'}}>Vă
											mulțumim pentru interesul arătat în a participa la acest eveniment.
											<br/><br/>Cererea ta de participare este acum analizată de organizator. În
											momentul în care organizatorul
											are un răspuns pentru tine vei fi anunțat fie printr-o notificare în
											aplicația Ality, dacă o ai
											instalată, fie pe mailul cu care ți-ai făcut acest cont, în cazul în care nu
											ai aplicația Ality instalată.</p>
									: null
							}
							{
								this.props.participantData.inviteStatus === 5 ?
									<p style={{
										marginTop: '30px',
										color: 'red',
										fontSize: '1.1rem',
										textAlign: 'center'
									}}>
										Ne pare rău! Organizatorul evenimentului a respins cererea ta de a participa la
										acest eveniment!.
									</p>
									: null
							}
						</div>
					:
					<div>
						<div style={{ display: "flex", flexDirection: "column" }}>
							<p>Pentru a participa la acest eveniment este necesar sa vă autentificați!</p>

							<Button style={{ margin: "20px auto" }} color="green" onClick={this.goToLogin}>Intră în cont</Button>

							<div className={"social-login-container"}>
								{
									window.flutter_inappwebview || window.alityAppView ?

										<div style={{flexDirection:'column',width:'100%'}}>
											<button onClick={this.loginGoogle}
													className={"social-login-button social-google-login"}
													style={{width:'100%'}}>
												<Icon icon="google"/> Login Google
											</button>

											<button onClick={this.loginFacebook}
													className={"social-login-button social-facebook-login"}
													style={{width:'100%', marginTop:'10px'}}
											>
												<Icon icon="facebook"/> Login Facebook
											</button>

											{
												this.isIOS ?
													<button onClick={this.loginApple}
															className={"social-login-button social-apple-login"}
															style={{width: '100%', marginTop: '10px'}}
													>
														<Icon icon="apple"/> Sign in with Apple
													</button>
													: null
											}
										</div>
										:
										<>

											<GoogleWebLoginButton
												callbackFunction={(val) => this.googleSignIn(val)}
											/>

											<FacebookLogin
												appId="243653927956471"
												autoLoad={false}
												fields="name,email"
												//onClick={(item) => this.facebookSignIn(item)}
												callback={(item) => this.facebookSignIn(item)}
												//cookie
												//
												disableMobileRedirect={true}
												//isMobile={true}
												redirectUri={'https://app.ality.ro'}
												render={renderProps => (
													<button
														className={"social-login-button social-facebook-login"}
														style={{marginLeft: '5px'}}
														type={"button"}
														onClick={renderProps.onClick}
														disabled={renderProps.disabled}
													><Icon icon="facebook"/> Facebook</button>
												)}
											/>
										</>
								}
							</div>
							<Button style={{ margin: "20px auto" }} color="green" onClick={this.goToSignup}>Creează cont</Button>
						</div>
					</div>
			}
		</div>
	}
}

export default Invite;
