import React from 'react'
import './VouchersTab.scss'
import {Button} from "rsuite";
import Modal from "react-responsive-modal";
import axios from "axios";
import {toast} from "react-toastify";
import {SiGooglemaps, SiWaze} from "react-icons/si";



class VouchersTab extends React.Component {
    state = {
        items: this.props.event_data.vouchers,
        voucher: false,
        showModal: false
    }

    async redeemVoucher () {

        let obj = {
            voucherId: this.state.voucher.id
        }

        let auth = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('jwt')
            }
        }

        await axios.post(process.env.REACT_APP_DOMAIN + 'voucher-redeems/redeem', obj, auth)
            .then((response) => {

                let vouchers = this.state.items

                for(let i=0; i<vouchers.length; i++) {
                    if(vouchers[i].id === this.state.voucher.id) {
                        vouchers[i].alreadyRedeemed = true
                        vouchers[i].code = response.data.code
                    }
                }

                this.setState({
                    items: vouchers,
                    showModal: false
                })

                toast.success("Voucherul a fost revendicat !")

            })
            .catch(_ => {
                toast.error("S-a produs o erorare va rog reincercati")
            })

    }

    openLink = (link) => {
        if (window.flutter_inappwebview) {
            window.flutter_inappwebview.callHandler('openLink', link)
        } else if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: link,
            }))
        } else {
            window.open(link)
        }
    }

    maps = (x, y) => {
        this.openLink("http://maps.google.com/?q=" + x + "," + y);
    }

    waze = (x, y) => {
        this.openLink("https://www.waze.com/ul?ll=" + x + "%2C" + y + "&navigate=yes&zoom=17");
    }

    render() {

        return (
            <div className="VouchersTab-container">
                <div className="VouchersTab-Feed">

                    {
                        this.state.items && this.state.items.map((item, i) => {

                            let hasImage = item.image !== null && item.image !== undefined

                            let backgroundImageStyle = {
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundImage: hasImage ? `url(https://${process.env.REACT_APP_IMAGE_DOMAIN}${item.image.url})` : ''
                            }

                            return <div key={i} className={"Events-Card-Container " + (item.alreadyRedeemed ? 'redeemed' : '')} >
                                {hasImage && <div className="Picture" style={backgroundImageStyle} onClick={() => {
                                    if(!item.alreadyRedeemed)
                                        this.setState({
                                            voucher: item,
                                            showModal: true
                                        })
                                }}></div>}

                                {
                                    item.alreadyRedeemed ?
                                        <div className={"redeemed-overlay"}>
                                            <div className={"text"}>
                                                Revendicat
                                            </div>
                                        </div>
                                        : null
                                }

                                {
                                    item.alreadyRedeemed ?
                                        <div className={"redeemed"} onClick={() => {
                                            if(!item.alreadyRedeemed)
                                                this.setState({
                                                    voucher: item,
                                                    showModal: true
                                                })
                                        }}>Revendicat - {item.code}</div>
                                        :
                                        <div className="Open-Event" onClick={() => {
                                            if(!item.alreadyRedeemed)
                                                this.setState({
                                                    voucher: item,
                                                    showModal: true
                                                })
                                        }}>
                                            Click aici pentru a revendica voucherul
                                        </div>
                                }

                                <div className="Title" onClick={() => {
                                    if(!item.alreadyRedeemed)
                                        this.setState({
                                            voucher: item,
                                            showModal: true
                                        })
                                }}>
                                    <div className="Text font-family-poppins-700-bold" onClick={() => {

                                    }}>{item.title} {item.discount}</div>

                                </div>



                                <div onClick={() => {
                                    if(!item.alreadyRedeemed)
                                        this.setState({
                                            voucher: item,
                                            showModal: true
                                        })
                                }}
                                    className={"Description"}>
                                    {item.description}<br/>
                                    <strong>{item.phone}</strong>
                                </div>


                                {
                                    item.address && item.address.coordinates &&
                                    <div className={"maps-buttons"}>
                                            <Button onClick={() => this.waze(item.address.coordinates.lat, item.address.coordinates.lng)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#05AFF2", width: "150px", marginRight: "20px" }}>
                                                <SiWaze style={{ marginRight: "5px" }} /> Waze
                                            </Button>

                                            <Button onClick={() => this.maps(item.address.coordinates.lat, item.address.coordinates.lng)} style={{ display: "flex", alignItems: "center", justifyContent: "center", color: "white", backgroundColor: "#19B899", width: "150px", marginRight: "20px" }}>
                                                <SiGooglemaps style={{ marginRight: "5px" }} /> Google Maps
                                            </Button>
                                    </div>
                                }


                            </div>
                        })
                    }

                </div>

                <Modal
                    classNames={{
                        overlay: 'modal-overlay',
                        modal: 'modal',
                        closeButton: 'modal-close-button',
                    }}
                    center
                    open={this.state.showModal}
                    onClose={() => {
                        this.setState({
                            voucher: false,
                            showModal: false
                        })
                    }}
                >
                    <div style={{marginTop: "20px"}}>
                        <h4>Revendicare voucher</h4>

                        {
                            this.props.isTicketPaid || !this.props.event_data.tickets_needed ?
                                <>

                                    {this.state.voucher.title} {this.state.voucher.discount} <br/>
                                    {this.state.voucher.description}<br/><br/>

                                    <p>Atentie! Voucherul trebuie revendicat doar in prezenta reprezentantului
                                        locatiei!</p>

                                    <br/>

                                    <Button onClick={() => this.redeemVoucher()} color="green"
                                            style={{backgroundColor: "#05AFF2", borderRadius: "20px", width: "100%"}}>Revendica
                                        voucher</Button>
                                </>
                                :
                                <>
                                    <br/>
                                    Pentru a revendica un voucher trebuie sa achizitionati un bilet la acest eveniment.
                                    <br/>
                                </>
                        }
                    </div>
                </Modal>

            </div>
        )
    }
}

export default VouchersTab;
